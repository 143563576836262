// actions types

export const LOAD_DATA = 'LOAD_DATA';
export const LOAD_PATIENT_DASHBOARD_DATA = 'LOAD_PATIENT_DASHBOARD_DATA';
export const LOAD_WAITING_ROOM_DATA = 'LOAD_WAITING_ROOM_DATA';
export const LOAD_PHYSICIAN_LANDING_DATA = 'LOAD_PHYSICIAN_LANDING_DATA';
export const LOAD_PHYSICIAN_INFO_DETAILS_DATA = 'LOAD_PHYSICIAN_INFO_DETAILS_DATA';
export const LOAD_PROFILE_INFO_DATA = 'LOAD_PROFILE_INFO_DATA';
export const LOAD_SCHEDULE_APPOINTMENT_DATA = 'LOAD_SCHEDULE_APPOINTMENT_DATA';
export const LOAD_RELATION_TO_POLICY_HOLDER_DATA = 'LOAD_RELATION_TO_POLICY_HOLDER_DATA';
export const LOAD_CREDIT_CARD_ISSUER_DATA = 'LOAD_CREDIT_CARD_ISSUER_DATA';
export const LOAD_SNEWZZZ_DATA = 'LOAD_SNEWZZZ_DATA';
export const LOAD_LOADER = 'LOAD_LOADER';