import React, { useEffect, useState } from 'react';
import './styles.scss';
import ScheduleConfirm from './ScheduleConfirm';
import { User } from '../../../services/dto/Security';
import { Appointment, Slot } from '../../../services/dto/Common';
import dayjs, { Dayjs } from 'dayjs';
import SchedulingTopCard from './SchedulingTopCard';
import SchedulingComponent from './SchedulingComponent';
import SelectSleepSpecialist from '../SelectSleepSpecialist';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc);
dayjs.extend(timezone)

export type FetchDataType = {
  timeZone?: string;
  providerId?: string;
  startTime?: any;
  endTime?: any;
};

export type ScheduleFrom = 'register' | 'dashboard';

export interface IAppointmentSchedulerProps {
  data: any; // from UserService.getSchedule
  provider: User;
  title: string;
  timeZone: string;
  duration: number;
  fetchData: (changes: FetchDataType) => void;
  onConfirm: (slot: Slot) => void;
  existingApt?: Appointment;
  onSelect?: (slot?: Slot) => void;
  showProviderToggle?: boolean;
  submitting: boolean;
  providers?: User[];
  confirmText?: string;
  defaultDate?: Dayjs;
  hideTopCard?: boolean;
  hideButtons?: boolean;
}

export const AppointmentScheduler: React.FunctionComponent<IAppointmentSchedulerProps> = (props) => {
  const {
    fetchData,
    onConfirm,
    onSelect,
    existingApt,
    data,
    provider,
    title,
    timeZone,
    duration,
    showProviderToggle = true,
    providers,
    confirmText,
    submitting,
    defaultDate,
    hideTopCard,
    hideButtons,
  } = props;
  // Handle resizing window
  const checkVerticalWindow = () =>
    window.innerWidth < 900 ||
    window.screen.orientation.type === 'portrait-primary';
  const [displayVertical, setDisplayVertical] =
    useState<boolean>(checkVerticalWindow);
  window.addEventListener('resize', () =>
    setDisplayVertical(checkVerticalWindow),
  );
  useEffect(() => {
    setDisplayVertical(window.innerWidth < window.innerHeight);
  }, [window.innerWidth, window.innerHeight]);

  const [selectedSlot, setSelectedSlot] = useState<Slot>();
  const [showProviderPicker, setShowProviderPicker] = useState(false);
  return (
    <>
    {!showProviderPicker && (
      <div className="scheduling-container">
        {existingApt && !hideTopCard && (
          <SchedulingTopCard provider={provider} existingApt={existingApt} />
        )}

        {!selectedSlot ? 
          <SchedulingComponent
            data={data}
            provider={provider}
            title={title}
            timeZone={timeZone}
            duration={duration}
            displayVertical={displayVertical}
            fetchData={(changes: FetchDataType) => {
              fetchData(changes);
              console.log('fetching');
            }}
            onSelectSlot={(s: Slot) => {
              setSelectedSlot(s);
              if (onSelect) onSelect(s);
            }}
            existingAptDate={
              existingApt ? dayjs(existingApt.startTime) : undefined
            }
            defaultDate={defaultDate}
          />
         : (
          <ScheduleConfirm
            aptInfo={{
              subject: title,
              provider: provider,
              times: selectedSlot,
              duration: duration,
              location: 'Video Meeting',
              timeZone: timeZone
            }}
            buttons={hideButtons ? undefined : {
              cancel: {
                text: 'Cancel',
                effect: () => {
                  setSelectedSlot(undefined)
                  if (onSelect) onSelect(undefined)
                },
                disabled: submitting,
              },
              confirm: {
                text: confirmText || (existingApt ? 'Reschedule' : 'Confirm'),
                effect: () => {
                  onConfirm(selectedSlot);
                },
                disabled: submitting,
              },
            }}
          />
        )}
      {showProviderToggle && !selectedSlot && (
        <>
          <p
            onClick={() => setShowProviderPicker(true)}
            style={{
              margin: '2rem auto',
              color: 'white',
              textDecoration: 'underline',
              textAlign: 'center',
              fontSize: '1.5rem',
              cursor: 'pointer',
            }}>
            Browse Other Sleep Specialists
          </p>
        </>)}
      </div>)}
      {showProviderPicker && (
        <SelectSleepSpecialist
          providers={providers || []}
          onBack={() => setShowProviderPicker(false)}
          onSelectProvider={async (p: any) => {
            setShowProviderPicker(false);
            fetchData({
              providerId: p._id,
              timeZone
            });
          }}
          provider={provider}
        />
      )}
    </>
  );
};

export default AppointmentScheduler;
