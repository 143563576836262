import React, {useEffect, useState} from 'react';
import './styles.scss';
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../store/reducers";
import {PaymentState} from "../../../store/reducers/payment";
import {loadPaymentHistory} from "../../../store/actions/payment";
import moment from "moment";
import {DATE_FORMAT, FORMAT_HOUR_FULL} from "../../../config";
import {User} from "../../../services/dto/Security";
import {ProfileIcon} from "../../ProfileIcon";
import {getErrorString, getName, useIsMounted} from "../../../services/utils";
import UserService from "../../../services/userService";
import {AuthState} from "../../../store/reducers/auth";
import {Order} from "../../../services/dto/Common";
import {PayModules} from "../../../containers/WaitingRoomPage/PayModules";
import _ from 'lodash';

export const Orders = () => {
  const {history} = useSelector<IAppState, PaymentState>(s => s.payment)
  const [index, setIndex] = useState(1);
  const dispatch = useDispatch();
  const { authUser } = useSelector<IAppState, AuthState>((s) => s.auth);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState<string>();
  const [orders, setOrders] = useState<Order[]>();
  const isMounted = useIsMounted();
  const items = _.uniqBy(
    [...(orders || []), ...(history.data || [])],
    function (e) {
      return e.id;
    },
  );
  const [payOrder, setPayOrder] = useState<any>();

  useEffect(() => {
    if (!authUser) {
      return;
    }
    loadData();
  }, [authUser]);

  const loadData = () => {
    dispatch(loadPaymentHistory({}));
    setLoading(true);
    UserService.getOrdersWithPage({
      status: ['Authorization Approved'],
      perPage: 100,
      patientId: authUser?.id || '',
    })
      .then((rsp) => {
        if (isMounted.current) {
          setOrders(rsp.data.items);
        }
      })
      .catch((e) => {
        if (isMounted.current) {
          setErr(getErrorString(e));
        }
      });
  };

  const renderPreNext = () => {
    const isLoading = (loading || history.loading) && !items;
    const isHasErr = !!history.err || !!err || !items || items.length <= 0;
    if (isLoading) {
      return <div className={'tip'}>Loading...</div>;
    } else if (isHasErr) {
      return (
        <div className="no-data-container">
          <img
            src="/assets/icon-invoice.svg"
            alt="icon"
            className="icon-payment"
          />
          <div className="msg">No Past Payments</div>
        </div>
      );
    }
    return (
      <>
        <div className="line-bot" />
        <div className="bot-btns">
          <div
            className={`btn btns ${index === 1 ? 'disabled' : ''}`}
            onClick={() => {
              setIndex(index - 1);
            }}>
            Prev
          </div>
          <span className="vertical" />
          <div
            className={`btn btns ${
              index === items?.length - 1 ? 'disabled' : ''
            }`}
            onClick={() => {
              setIndex(index + 2);
            }}>
            Next
          </div>
        </div>
      </>
    );
  };

  const renderUser = (user: User) => {
    return (
      <div className="item">
        <div className="photo">
          <ProfileIcon user={user} color={'blue'} />
        </div>
        <div className="m-left">
          <div className="txt" style={{ color: '#979cb2' }}>
            {getName(user)}
          </div>
          <div className="txt font-upper" style={{ color: '#979cb2' }}>
            {(user.providerInfo?.qualifications || []).join(', ')}
          </div>
        </div>
      </div>
    );
  };

  const renderItem = () => {
    const row1 = items[index - 1];
    const row2 = items[index];
    if (!row1) {
      return <></>;
    }

    return (
      <>
        <div className="line" />
        <div className="tit">{'reason' in row1 ? row1.reason : row1.name}</div>
        <div className="date">
          {moment(row1.createdAt).format(DATE_FORMAT + ' ' + FORMAT_HOUR_FULL)}
        </div>
        {'provider' in row1 &&
          !_.get(row1, 'paymentRawObj.amount_received', false) &&
          !['APAP', 'Other PAP', 'Dental'].includes(row1.type) &&
          renderUser(row1.provider)}
        <div className="flex-bet">
          <div className="cash">
            $
            {('amount' in row1
              ? row1.amount / 100
              : row1.type === 'appointment'
              ? row1.copayAmount / 100
              : row1.copayAmount
            ).toFixed(2)}
          </div>
          {!_.get(row1, 'paymentRawObj.amount_received', false) &&
            !['APAP', 'Other PAP', 'Dental'].includes(row1.type) && (
              <div
                className="btn btn-blue"
                onClick={() => {
                  setPayOrder(row1);
                }}>
                Pay Now
              </div>
            )}
        </div>
        <div style={{ height: 136 }}>
          {row2 && (
            <>
              <div className="line" style={{ marginTop: '1rem' }} />
              <div className="tit">
                {'reason' in row2 ? row2.reason : row2.name}
              </div>
              <div className="date">
                {moment(row2.createdAt).format(
                  DATE_FORMAT + ' ' + FORMAT_HOUR_FULL,
                )}
              </div>
              {'provider' in row2 &&
                !_.get(row2, 'paymentRawObj.amount_received', false) &&
                !['APAP', 'Other PAP', 'Dental'].includes(row2.type) &&
                renderUser(row2.provider)}
              <div className="flex-bet">
                <div className="cash">
                  $
                  {('amount' in row2
                    ? row2.amount / 100
                    : row2.type === 'appointment'
                    ? row2.copayAmount / 100
                    : row2.copayAmount
                  ).toFixed(2)}
                </div>
                {!_.get(row2, 'paymentRawObj.amount_received', false) &&
                  !['APAP', 'Other PAP', 'Dental'].includes(row2.type) && 
                  _.get(row2, 'type') !== 'refund' && (
                    <div
                      className="btn btn-blue"
                      onClick={() => {
                        setPayOrder(row2);
                      }}>
                      Pay Now
                    </div>
                  )}
              </div>
              {renderPreNext()}
            </>
          )}
        </div>
      </>
    );
  };
  return (
    <div className="orders-section h-376">
      <div className="flex-bet">
        <div className="title">Payment History</div>
        {/* {history.data && history.data.length > 0 && (
          <div className="link-all" onClick={(event) => event.preventDefault()}>
            View All
          </div>
        )} */}
      </div>
      <div className="pay-box">{renderItem()}</div>
      {payOrder && (
        <PayModules
          amount={payOrder.copayAmount}
          entityId={payOrder.id}
          description={payOrder.name}
          type={'order'}
          showModal={
            !authUser?.creditCards || authUser.creditCards.length <= 0
              ? 'new'
              : 'exist'
          }
          onClose={(card) => {
            if (card) {
              loadData();
              setIndex(1);
            }
            setPayOrder(undefined);
          }}
        />
      )}
    </div>
  );
};

export default Orders;
