import { get, isArray, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import AuthService from '../../services/authService';
import UserService from '../../services/userService';
import { useQuery } from '../../services/utils';
import Medications from './Medications';
import ProviderHistory from './ProviderHistory';
import SocialHistory from './SocialHistory';
import SleepHistory from './SleepHistory';
import { useSelector, useDispatch } from 'react-redux';
import { updateAuthUser } from '../../store/actions/auth';
import SystemsReview from './SystemsReview';
import FormSelect from './FormSelect';
import Header from '../../components/Header';
import { Api } from '../../services/api';
import CompletedRegistration from './CompletedRegistration';

const FollowUpPage = (props) => {
  const { user } = AuthService.getAuth();
  const query = useQuery();
  const step = query.get('step') || user?.onboardingProgress;
  const view = query.get('view');
  const history = useHistory();
  const dispatch = useDispatch();
  const { providers } = useSelector((r) => r.appointments);
  const [state, setState] = useState({
    showVerify: false,
    showComplete: false,
    email: '',
    medicalHistory: null,
    fetchingHistory: true,
    fetchingLogo: false,
    partner: {},
    base64: '',
    nearestApt: null,
  });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  const validSteps = [
    'register',
    'info',
    'insurance',
    'provider',
    'medications',
    'medical-history',
    'social-history',
    'family-history',
    'sleep-history',
    'details',
  ];

  const getNextStep = (currStep) => ({
    'sleep-update': 'sleep-update&view=2',
    'medications': 'patientDashboardPage',
    'social-history': 'medications',
    'provider': 'patientDashboardPage',
    'details': 'complete',
  }[currStep]);

  const progressSteps = [...validSteps].slice(3, validSteps.length);
  useEffect(() => {
    getPartnerLogo();
  }, []);


  useEffect(() => {
    if (!state.nearestApt) {
      getAppointment();
    }
  }, [state.nearestApt]);

  useEffect(() => {

    if (user?.id && !state.medicalHistory) {
      getMedicalHistory();
    } else if (state.fetchingHistory)
      setGenericState({ fetchingHistory: false });
  }, [step]);

  const getAppointment = () => {
    UserService.getAppointments({ types: ['upcoming', 'ongoing'] })
      .then((res) => {
        if (res.data.length > 0) {
          setGenericState({nearestApt: getNearestAppointment(res.data)});
        } 

      })
      .catch((err) => toast.error(err));
  };

  const getNearestAppointment = (aptList) => {
    let nearestIndex = 0;
    aptList.forEach((time, i) => {
      if (moment(time).isBefore(time[i === 0 ? i : i - 1])) {
        nearestIndex = i;
      }
    });

    return aptList[nearestIndex];
  };

  const updateHistory = (formData, apptdata = {}) => {
    setGenericState({
      medicalHistory: {
        ...state.medicalHistory,
        ...formData,
      },
      // NOTE: apt id used to determine value of medicalId
      // Below fixes the issue with multiple formData records for follow up forms
      nearestApt: { ...state.nearestApt, ...apptdata }
    });
  }


  const getPartnerLogo = () => {
    if (query.get('refId')) {
      setGenericState({ fetchingLogo: true });
      Api.get(`/referralUsers?refId=${query.get('refId') || ''}`)
        .then(async (res) => {
          let data = res.data[0];
          const logoBuffer = data?.logoBuffer?.data;
          const base64 = btoa(
            new Uint8Array(logoBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          );
          setState({
            partner: res.data[0],
            base64: 'data:;base64,' + base64,
          });
        })
        .finally(() => setGenericState({ fetchingLogo: false }));
    }
  };

  const getMedicalHistory = () => {
    setGenericState({ fetchingHistory: false });

    UserService.getMedicalHistoryById(user.id)
    .then((res) => {
      if (res?.data.length) {
        setGenericState({ medicalHistory: res?.data[res?.data.length-1].form})
      }
    })
    .catch((error) => {
      toast.error('Unable to retrieve medical history.');
    })
    .finally(() => setGenericState({ fetchingHistory: false }));
  };

  const onContinue = (step, data) => {
    const providerData = isArray(providers) ? providers : [];
    if (!isEmpty(data)) {
      AuthService.updateUserInfo(data);
      dispatch(updateAuthUser(data));
    }

    if (props.renderType === 'profile' && props.onSave) return props.onSave();
    else if (step === 'apt' && providerData?.length)
      return history.push('/scheduleAppointmentPage');
    else if (step === 'apt' && !providerData?.length)
      return history.push('/followUp?step=provider');
    else if (step === 'complete' && view === '3')
      return setGenericState({ showComplete: true });
    else return history.push(`/followUp?step=${step}`);
  };

  if (state.fetchingHistory || state.fetchingLogo) return <div></div>;
  return (
    <Fragment>
      {props.renderType !== 'profile' && (
        <Header
          currentPage=""
          headerType={state?.partner?.name ? 'Referred' : 'Non-LoggedIn'}
          partnerLogo={state?.base64}
          title={'Follow-up Form'}
          partnerName={state?.partner?.name}
          customClass="registeration-header"
        />
      )}
      {state.showComplete && <CompletedRegistration />}
      {!state.showComplete && (
        <Fragment>
          <FormSelect
            apptId={state.nearestApt?.id}
            medicalHistory={state.medicalHistory}
            medicalId={state.nearestApt?.medicalId || ''}
            step={step}
            updateHistory={updateHistory}
            history={state.medicalHistory}
            onContinue={(user) => onContinue(getNextStep(step), user)}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default FollowUpPage;
