//@ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import AuthService from '../../services/authService';
import { useQuery } from '../../services/utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './marketingRegistration.scss';
import Registration from '../../components/RegistrationComponents/Registration';
import Verification from '../../components/RegistrationComponents/Verification';
import Header from '../../components/Header';
import { updateAuthUser } from '../../store/actions/auth';
import _ from 'lodash';
import PersonalInfo from '../../components/RegistrationComponents/PersonalInfo';
import InsuranceInfo from '../../components/RegistrationComponents/InsuranceInfo';
import PaymentForm from '../../components/PaymentForm';
import UserService from '../../services/userService';
import { get } from 'lodash';
import AppointmentScheduler from '../../components/RegistrationProgressComponents/FormScheduleAppointmentSelectDate';
import moment from 'moment';
import dayjs from 'dayjs';

import SchedulingTopCard from '../../components/RegistrationProgressComponents/FormScheduleAppointmentSelectDate/SchedulingTopCard';
import ScheduleConfirm from '../../components/RegistrationProgressComponents/FormScheduleAppointmentSelectDate/ScheduleConfirm';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal';

enum Steps {
  Schedule = 'schedule',
  Register = 'register',
  Info = 'info',
  Insurance = 'insurance',
  Payment = 'payment',
  Confirm = 'confirm',
}

enum ModalState {
  PaymentInfo = 'payInfo',
  PayConfirm = 'payConfirm',
  Insurance = 'insurance',
}

const MarketingRegistration = () => {
  const { user, token } = AuthService.getAuth();
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const authKey = query.get('token');
  const step: string = query.get('step') || Steps.Schedule;
  const holdAppointment = localStorage.getItem('holdAppointment');
  const { provider, insurance, end, start } = holdAppointment
    ? JSON.parse(holdAppointment)
    : {};
  const [availData, setAvailData] = useState(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [state, setState] = useState({
    showVerify: false,
    showComplete: false,
    email: '',
    base64: '',
    partner: {},
    patientState: '',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    refId: null,
    insuranceCarrier: '',
    showProviderPicker: false,
    loading: false,
    provider,
    modal: null,
    providers: [],
    startTime: moment().format(),
    endTime: moment().add(3, "month").format(),
  });

  const setGenericState = (value: any) => {
    setState((state) => ({ ...state, ...value }));
  };

  const getPhysicianSchedule = useCallback(
    async (data) => {
      data = {
        startTime: state.startTime,
        endTime: state.endTime,
        timeZone: state.timeZone,
        type: 'pseudo',
        state: state.patientState,
        providerId: state?.provider?._id || null,
        ...data,
      };
      try {
        let response;
        response = await UserService.getSchedule(data, true);
        if (!_.isEmpty(response.data)) {
          setAvailData(response.data);
          setGenericState({ provider: response?.data?.provider });
        }
        response = await UserService.getProviders({ state: data.state || state.patientState }, true);
        setGenericState({ providers: response?.data });
      } catch (err) {
        console.log(err);
      }
    },
    [state],
  );

  useEffect(() => {
    const rerouteToDefault = (params?: string) => history.push(`/registration?step=register${params}`);
    const verifySource = async () => {
      setGenericState({ loading: true });
      try {
        // Verify token from url
        const response = await AuthService.verifyToken({
          token: authKey || token,
        });
        
        const { data } = response;
        if (data) {
          if (!data.state) {
            rerouteToDefault(data.partnerId ? `&refType=partner&refId=${data.partnerId}` : '')
          }
          AuthService.saveAuth({ user: { ...(user || {}), ...data }, token: authKey || token });
          // Remove token from url
          window.history.pushState({}, 'Ognomy', '/scheduling');
          if (data?.partnerId) {
            const pResponse = await UserService.getPartnerLogo(data.partnerId);
            setGenericState({ partner: pResponse.partner });
          }
          setGenericState({ 
            patientState: data?.state,
            insuranceCarrier: data?.insuranceCarrier,
            refId: data.partnerId,
            refType: data.refType || "partner",
          });
          await getPhysicianSchedule({
            state: data?.state || user?.state,
          });
        } else {
          console.log("No data received. Rerouting to standard registration page.")
          rerouteToDefault();
        }
        setGenericState({ loading: false });
      } catch (err) {
        console.log(err);
        rerouteToDefault();
      }
    };
    if (!authKey && !user) {
      history.push('/');
    }
    verifySource();
  }, []);

  useEffect(() => {
    const insuranceCarrier = state.insuranceCarrier;
    if (insuranceCarrier) {
      UserService.updateProfile(
        { insuranceCards: [ { insuranceCarrier }] },
        user.id
      )
    }
  }, [user?.id])

  const onContinue = (step?: any, data?: any, prov?: any) => {
    if (!_.isEmpty(data)) {
      AuthService.updateUserInfo(data);
      dispatch(updateAuthUser(data));
    }
    if (step === Steps.Confirm) {
      localStorage.setItem('completeRegister', true);
    }
    setGenericState({ modal: null });
    history.push(`/scheduling?step=${step}`);
  };

  const handleHoldAppointment = (data: any) => {
    localStorage.setItem(
      'holdAppointment',
      JSON.stringify({
        ...data,
        provider: state.provider,
        insuranceCarrier: user.insuranceCarrier,
      }),
    );
  };

  const handleSaveAppointment = async () => {
    setSubmitted(true);
    const data = JSON.parse(localStorage.getItem('holdAppointment'));

    let body: any = {
      description: 'Consultation',
      startTime: data?.start,
      endTime: data?.end,
      on: dayjs(data.start).format('MMM DD, YYYY'),
      at: dayjs(data.start).format('hh:mm a'),
      inviteeEmail: provider.email,
    };
    try {
      await UserService.createAppointment({...body, providerId: provider.id, patientId: user.id});
      setSubmitted(false);
      toast.success('Appointment has been created successfully!');
      localStorage.removeItem('holdAppointment');
      history.push('/patientDashboardPage');
    } catch (err) {
      setSubmitted(false);
      if (
        err.message ===
        'Sorry, this time just got booked, please pick another time.'
      ) {
        toast.error(err.message);
        localStorage.removeItem('holdAppointment');
        history.push('/scheduling');
      }
      console.error(err);
    }
  };

  return (
    <div className="marketing-registration">
      <Header
        currentPage=""
        headerType={
          get(state, 'partner.name', undefined) ? 'Referred' : 'Non-LoggedIn'
        }
        partnerLogo={get(state, 'base64', undefined)}
        partnerName={get(state, 'partner.name', undefined)}
        customClass="registeration-header"
      />
      <>
        {step !== Steps.Schedule && step !== Steps.Confirm && (
          <>
            {state.provider && (
              <SchedulingTopCard
                provider={state.provider}
                timezone={state.timeZone}
                existingApt={{
                  startTime: start,
                  endTime: end,
                }}
              />
            )}
          </>
        )}
        {step === Steps.Schedule && (
          <>
            <div className="onboarding-form">
              <div className="header-info">
                <div className="description">
                  <div className="marketing_header">
                    <h2>Let’s get you scheduled!</h2>
                    {!localStorage.getItem('completeRegister') && <p>
                      We have selected the sleep clinician most suited to you based on
                      your choices so far. Please choose a convenient date and time
                      for your video appointment.
                    </p>}
                    <br/>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {!state.loading && (
                !_.isEmpty(availData?.availableSlots) && (
                  <AppointmentScheduler
                    data={availData}
                    provider={state.provider || {}}
                    providers={state.providers}
                    title={"Sleep Medicine Consultation"}
                    timeZone={state.timeZone}
                    duration={40}
                    onConfirm={(data) => {
                      handleHoldAppointment(data);
                      onContinue(Steps.Register);
                    }}
                    confirmText="Continue"
                    onSelect={(data) => {
                      if (localStorage.getItem('completeRegister')) {
                        handleHoldAppointment(data);
                        history.push(`/scheduling?&step=${Steps.Confirm}`);
                      }
                    }}
                    fetchData={(changes: FetchDataType) => {
                      const { timeZone, startTime, endTime } = changes;
                      if (timeZone) setGenericState({ timeZone });
                      if (startTime && endTime) setGenericState({ startTime, endTime });
                      getPhysicianSchedule(changes);
                    }}
                  />
                )
              )}
            </div>
          </>
        )}
        {step === Steps.Register && (
          <>
            {!state.showVerify ? (
              <Registration
                history={history}
                onContinue={(email: string) =>
                  setGenericState({ showVerify: true, email })
                }
                subtitle=" "
                title={
                  <div className="marketing_header">
                    <h2>
                      <span className="accent">Awesome,</span> we're almost
                      there!
                    </h2>
                    <p>
                      In order to complete your booking, please create an
                      account using your email address. We will send you a
                      verification code which you will need to input on the next
                      screen.
                    </p>
                  </div>
                }
              />
            ) : (
              <Verification
                email={state.email}
                onContinue={() => {
                  setGenericState({ showVerify: false });
                  onContinue(Steps.Info);
                }}
                refId={get(state, 'refId')}
                refType={get(state, 'refType')}
              />
            )}
          </>
        )}
        {step === Steps.Info && (
          <>
            <PersonalInfo
              onContinue={(data: any) => onContinue(Steps.Insurance, data)}
              title={
                <>
                  <span className="accent">Thank you!</span> We just need a
                  little more information!
                </>}
              subtitle="This is to finalize your appointment and complete your account. "
              initialValues={{ state: state.patientState }}
              disabledFields={['state']}
            />
          </>
        )}
        {step === Steps.Insurance && (
          <>
            <InsuranceInfo
              onContinue={(user: any) => onContinue(Steps.Payment, user)}
              defaultCards={[{ insuranceCarrier: insurance }]}
              title={
                <>
                  We’re <span className="accent">almost</span> there!
                </>
              }
              subtitle="Prior to your appointment, we just need to confirm your coverage with your health insurance provider. We will need a copy of your insurance card to do this. "
            />
            <button
              className="text-btn --link"
              style={{
                textAlign: 'center',
                margin: '3rem auto',
                display: 'block',
              }}
              onClick={() => setGenericState({ modal: ModalState.Insurance })}>
              Skip For Now
            </button>
            <Modal
              open={state.modal === ModalState.Insurance}
              onClose={() => setGenericState({ modal: null })}
              buttonActions={[
                {
                  action: () => setGenericState({ modal: null }),
                  style: 'confirm-small',
                  text: window.screen.orientation.type === 'portrait-primary' ? "Close" : "Complete Now",
                },
                {
                  action: () => onContinue(Steps.Payment),
                  style: 'cancel-small',
                  text: window.screen.orientation.type === 'portrait-primary' ? "Skip" : "Skip For Now",
                },
              ]}>
              <p>
                Within the next three days, we kindly ask you to make a decision
                regarding the use of your insurance to cover the cost of your
                care. If so, please upload a picture of your insurance card
                within that time period. If you don’t provide a photograph of
                your card, we may need to cancel your reserved appointment as we
                won’t be able to confirm your insurance coverage. You can always
                call us at 877-664-6669 or email support@ognomy.com with any
                questions. We’re here to help!
              </p>
            </Modal>
          </>
        )}
        {step === Steps.Payment && (
          <div
            style={{
              margin: ' 3rem calc(1rem + 15px)',
            }}>
            <div className="marketing_header">
              <h3>Just one last thing!</h3>
              <p>
                We need a form of payment to finalize your appointment booking.
              </p>
              <button
                style={{
                  textAlign: 'center',
                  margin: '1rem auto 0',
                  display: 'block',
                  fontSize: '16px',
                }}
                className="text-btn --link"
                onClick={() => setGenericState({ modal: ModalState.PaymentInfo })}>
                Why do we need this?
              </button>
            </div>
            <div className="form-container">
              <PaymentForm onSubmit={() => onContinue(Steps.Confirm, user)} />
            </div>
            <button
              style={{
                textAlign: 'center',
                margin: '3rem auto',
                display: 'block',
              }}
              className="text-btn --link"
              onClick={() => setGenericState({ modal: ModalState.PaymentInfo })}>
              Skip For Now
            </button>
            <Modal
              open={state.modal === ModalState.PaymentInfo}
              onClose={() => setGenericState({ modal: null })}
              buttonActions={[
                {
                  action: () => setGenericState({ modal: null }),
                  style: 'confirm-small',
                  text: window.screen.orientation.type === 'portrait-primary' ? "Close" : "Complete Now",
                },
                {
                  action: () => onContinue(Steps.Confirm),
                  style: 'cancel-small',
                  text: window.screen.orientation.type === 'portrait-primary' ? "Skip" : "Skip For Now",
                },
              ]}>
                <p style={{textAlign: "center", fontSize: "18px"}}>
                  In addition to any co-pay, co-insurance, or deductible, we have a no-show policy to protect the clinician’s time. Providing your form of payment now will help to secure your appointment time. If you decide to skip this now, you will get a reminder to enter your payment information later. If we do not get this information within 3 days, your appointment may be canceled, although you can always reschedule. You will not be charged now and will be informed of any co-pay, co-insurance, or deductible amounts in advance. Still have questions? Please call us at 1-877-664-6669.
                </p>
            </Modal>
          </div>
        )}
        {step === Steps.Confirm && (
          <>
            <div className="marketing_header">
              <h3 className="accent">You Did It!</h3>
              <h3>Let’s Confirm Your Appointment</h3>
              <p>
                Please check the details shown below and confirm everything is
                correct
              </p>
            </div>
            <ScheduleConfirm
              aptInfo={{
                subject: 'Sleep Medicine Consultation',
                location: 'Video Meeting',
                provider: state.provider,
                date: start,
                timeZone: state.timeZone,
                duration: 40,
                times: {
                  start: start,
                  end: end,
                },
              }}
              buttons={{
                cancel: { text: 'Cancel', effect: () => history.push(`/scheduling?&step=${Steps.Schedule}`), disabled: submitted },
                confirm: { text: 'Confirm', effect: handleSaveAppointment, disabled: submitted },
              }}
            />
          </>
        )}
      </>
    </div>
  );
};

export default MarketingRegistration;
