import { React } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../Formik/FormikControl';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import { SERVER_URL } from '../../config';
import './styles.scss';
import { borderClass } from './utils';

const Registration = (props) => {
  const initialValues = {
    email: props.email || '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is invalid'),
  });

  const onSubmit = (values, setSubmitting) => {
    UserService.checkPatientExistsByEmail(values.email.toLowerCase())
      .then((res) => {
        if (res.data) {
          props.history.push(`/signInPage?email=${values.email}`);
        } else {
          UserService.sendVerificationCode(values.email, 'signUp', 'email')
            .then((res) => props.onContinue(values.email))
            .catch((err) => toast.error(err.message))
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setSubmitting(false));
  }

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>{props.title || 'Account Creation'}</h3>
          <p>
            {props.subtitle
              ? props.subtitle
              : "Enter your email and we'll send you a verification code."}
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <div className="form-container">
              <FormikControl
                className={borderClass('email', formik)}
                control="input"
                type="email"
                name="email"
                placeholder="Enter Email Address"
              />
              <div className="privacy-info">
                <p>
                  By clicking "Continue" you agree to Ognomy's{' '}
                  <a
                    href={`${SERVER_URL}/conditions/terms-of-use`}
                    target="_blank"
                    rel="noreferrer"
                    className="blue-txt">
                    Terms of Service
                  </a>
                  ,{' '}
                  <a
                    href={`${SERVER_URL}/conditions/privacy-policy`}
                    target="_blank"
                    rel="noreferrer"
                    className="blue-txt">
                    Privacy Policy
                  </a>
                  ,{' '}
                  <a
                    href={`${SERVER_URL}/conditions/notice-of-privacy-practices`}
                    target="_blank"
                    rel="noreferrer"
                    className="blue-txt">
                    Notice of Privacy Practices
                  </a>
                  , and the{' '}
                  <a
                    href={`${SERVER_URL}/conditions/end-user-agreement`}
                    target="_blank"
                    rel="noreferrer"
                    className="blue-txt">
                    End User Agreement
                  </a>
                  . You will receive an email for verifying your email address.
                </p>
              </div>
            </div>
            <button
              type="submit"
              className="submit-btn"
              disabled={formik.isSubmitting}>
              Continue
            </button>

            <div className="signin">
              Already a patient?{' '}
              <a
                href={`/signInPage${formik.values.email && '?email='}${
                  formik.values.email
                }`}>
                Sign in
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Registration;
