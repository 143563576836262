import React, { useEffect, useState } from 'react';
import _, { toNumber } from 'lodash';
import './styles.scss';
import { User } from "../../../services/dto/Security";
import { Slot } from "../../../services/dto/Common";
import dayjs, { Dayjs } from 'dayjs';
import { Grid, Paper, Divider, Select, MenuItem, FormControl } from '@mui/material';
import SchedulingCalendar from "./SchedulingCalendar";
import SlotTimeButtons from "./SlotTimeButtons";
import AppointmentInfo from './AppointmentInfo';
import { FetchDataType } from '.';
import { FORMAT_YEAR_MONTH } from '../../../config';

type ScheduleType = { [key: string]: { [key: string]: Slot[] } };
export interface IAppointmentSchedulerProps {
  data: any // from UserService.getSchedule
  provider: User //
  title: string
  timeZone: string //
  duration: number //
  displayVertical: boolean
  fetchData: (changes: FetchDataType) => any
  onSelectSlot: (slot: Slot) => void
  existingAptDate?: Dayjs //
  defaultDate?: Dayjs //
}

// Schedule received as:
// {
//   availableSlots: {
//     "YYYY-MM-01": {
//       slots: [
//         {
//           start: "date_as_iso_string",
//           end: "date_as_iso_string"
//         },
//         {
//           ...
//         }
//       ]
//     },
//     ...,
//     "YYYY-MM-31": {
//       slots: [
//         ...
//       ]
//     }
//   }
// }

export const SchedulingComponent: React.FunctionComponent<IAppointmentSchedulerProps> = (props) => {

  const cleanSchedule = (uncleanSchedule: any) => {
    let ret: ScheduleType = {};
    _.forEach(Object.keys(uncleanSchedule), (key) => {
      const [yyyy, mm, dd] = key.split('-');
      const newKey = `${yyyy}-${mm}`
      if (!ret.hasOwnProperty(newKey)) { ret[newKey] = {} }
      ret[newKey][dd] = uncleanSchedule[key].slots;
    });
    return ret;
  }

  const getFirstDay = (obj: Object) =>
    !_.isEmpty(obj)
      ? _.sortBy(Object.keys(obj), (d) => toNumber(d))[0]
      : null;

  const getLastDay = (obj: Object) =>
    !_.isEmpty(obj)
      ? dayjs(_.sortBy(Object.keys(obj), (d) => toNumber(d))[Object.keys(obj).length - 1])
      : undefined;

  const getHighlightedDaysForMonth = (sched: ScheduleType, month: Dayjs) =>
    !_.isEmpty(sched[month.format(FORMAT_YEAR_MONTH)])
      ? _.sortBy(Object.keys(sched[month.format(FORMAT_YEAR_MONTH)]), (d) =>
          toNumber(d),
        ).map((x) => toNumber(x))
      : [];

  const getSlots = (sched: ScheduleType, date: Dayjs) =>
    !_.isEmpty(sched[date.format(FORMAT_YEAR_MONTH)])
      ? sched[date.format(FORMAT_YEAR_MONTH)][date.format('DD')]
      : [];

  let schedule = cleanSchedule(props.data.availableSlots);
  const [currDate, setCurrDate] = useState<Dayjs>(dayjs(getFirstDay(props.data.availableSlots)));
  const [highlightedDays, setHighlightedDays] = useState<number[]>(getHighlightedDaysForMonth(schedule, currDate));
  const [timeZone, setTimeZone] = useState<string>(props.timeZone);

  useEffect(() => {
    schedule = cleanSchedule(props.data.availableSlots);
    const newHighlightedDays = getHighlightedDaysForMonth(schedule, currDate);
    setHighlightedDays(newHighlightedDays);
    setCurrDate(dayjs(getFirstDay(props.data.availableSlots)));
  }, [props.data.availableSlots]);

  useEffect(() => {
    if (props.existingAptDate && !props.defaultDate) {
      if (props.existingAptDate.format(FORMAT_YEAR_MONTH) !== currDate.format(FORMAT_YEAR_MONTH)) {
        const newHighlightedDays = getHighlightedDaysForMonth(schedule, props.existingAptDate);
        setHighlightedDays(newHighlightedDays);
      }
      setCurrDate(props.existingAptDate);
    }
  }, [props.existingAptDate]);

  const fetchNewData = async (params: FetchDataType) => props.fetchData(params);

  const handleMonthChange = (newMonth: Dayjs) => {
    const newHighlightedDays = getHighlightedDaysForMonth(schedule, newMonth);
    setHighlightedDays(newHighlightedDays);
    setCurrDate(newMonth.set('date', newHighlightedDays[0] || 1));
  };

  const handleTimeZoneChange = (newTimeZone: string) => {
    setTimeZone(newTimeZone);
    fetchNewData({ timeZone: newTimeZone });
  };

  const panelSizes = {
    aptInfo: props.displayVertical ? 12 : 3,
    schedulingCalendar: props.displayVertical ? 12 : 4,
    SlotTimeButtons: props.displayVertical ? 12 : 3,
  };

  const timeZoneSelect = (
    <FormControl variant="standard" size="small" sx={{ width: '18ch', m: 0 }}>
      <Select
        value={timeZone}
        onChange={(e) => handleTimeZoneChange(e.target.value)}>
        {Intl.supportedValuesOf('timeZone').flatMap((t: string) => {
          if (!t.includes('America')) return [];
          return [
            <MenuItem key={t} value={t}>
              {t}
            </MenuItem>,
          ];
        })}
      </Select>
    </FormControl>
  );
  const vDivider = (
    <Divider orientation='vertical' flexItem sx={{ mr: '-1px' }} />
  );
  const hDivider = (
    <Divider
      orientation='horizontal'
      flexItem
      sx={{ mr: '-1px', width: '100%' }}
    />
  );
  return (
    <Paper className="scheduling-component center">
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        columns={
          props.displayVertical
            ? 12
            : Object.values(panelSizes).reduce((a, b) => a + b)
        } // sum
      >
        {/* Provider Info */}
        <Grid item xs={panelSizes.aptInfo} className="component">
          <AppointmentInfo
            provider={props.provider}
            subject={props.title}
            info={{
              duration: `${props.duration} minutes`,
              location: 'Video Meeting',
              timeZone: timeZoneSelect,
            }}
          />
        </Grid>

        {props.displayVertical ? hDivider : vDivider}

        {/* Calendar Select */}
        <Grid item xs={panelSizes.schedulingCalendar} className="component">
          <SchedulingCalendar
            currDate={currDate}
            monthlyAvailability={highlightedDays}
            onDateChange={(d: Dayjs) => setCurrDate(d)}
            onMonthChange={(m: Dayjs) => handleMonthChange(m)}
            maxDate={getLastDay(props.data.availableSlots)}
          />
        </Grid>

        {props.displayVertical ? hDivider : vDivider}

        {/* Time Slot Buttons */}
        <Grid item xs={panelSizes.SlotTimeButtons} className="component">
          <SlotTimeButtons
            currDate={currDate}
            slots={getSlots(schedule, currDate)}
            onSelectSlot={props.onSelectSlot}
            buttonsPerRow={props.displayVertical ? 2 : 1}
            timezone={timeZone}
          />
        </Grid>
      </Grid>
    </Paper>
  );
  
};

export default SchedulingComponent;
