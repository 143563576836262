import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import Ring from '../Spinners/Ring';

import "./style.scss"

const Dropzone = (props) => {
  const [fileTypeError, setFileTypeError] = useState(false);
  const getFileName = (name) => {
    const newName = name.split('-');
    if (newName.length === 1) return name;
    newName.pop();
    return newName.join('-') + '.pdf';
  };

  return (
    <div className="upload-dropzone mb-4">
      <div className="file-list">
        {Object.keys(props.files).map((name, i) => {
          const file = props.files[name];

          return (
            <div key={name} className="file-item">
              <p className="name">
                {!file.uploaded ? name : getFileName(name)}
              </p>
              {file.loading && <Ring />}
              {!file.loading && file.uploaded && (
                <div className="indicators">
                  <i className="icons icon-done" />
                  <i className="icons icon-close" onClick={async () => 
                    await props?.handleRemoveFile(name)} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <FileUploader
        handleChange={async (file) => {
          setFileTypeError(false)
          await props?.handleFileChange(file)
        }}
        children={
          <span>
            Drag & Drop or <a href="#">Click here</a> to Upload Medication
            Documentation
          </span>
        }
        name="file"
        types={props?.allowedTypes || ['PDF']}
        onTypeError={() => setFileTypeError(true)}
      />
      <p className={fileTypeError ? 'file-type glow' : 'file-type'}>
        {props?.errorMsg || "File must be a PDF"}
      </p>
    </div>
  );
};

export default Dropzone;
