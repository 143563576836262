import {User} from "../../services/dto/Security";
import {AuthTypes, INJECT_AUTH_USER, UPDATE_AUTH_USER} from "../actions/auth";
import AuthService from "../../services/authService";

export interface AuthState {
  authUser?: User
}

const defaultState: AuthState = {
  authUser: AuthService.getAuth().user
}


export const authReducer = (
  state = defaultState,
  action: AuthTypes
): AuthState => {
  switch (action.type) {
    case INJECT_AUTH_USER:
      return {
        ...state,
        authUser: action.payload
      }
    case UPDATE_AUTH_USER:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          ...action.payload
        }
      }
    default:
      return state
  }
}
