/**
 * admin user api service
 */
import {Api} from './api';
import {SignUrl} from "./dto/Common";

export default class AdminUsersService {
  /**
   * search user
   * @param params the params
   */
  static searchUsers(params: any) {
    return Api.get('/users', {params});
  }

  /**
   * delete user
   * @param id the user id
   */
  static deleteUser(id: string) {
    return Api.delete(`/users/${id}`);
  }

  static reSignFile(url: string) {
    return Api.get<SignUrl>('/files/re-sign', {params: {url}});
  }

  /**
   * add user
   * @param body the body
   */
  static addUser(body: any) {
    return Api.post('/newUser', body);
  }
}
