import React from 'react';
import _ from 'lodash';
import './styles.scss';
import { Slot } from "../../../services/dto/Common";
import { Grid, Button } from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc);
dayjs.extend(timezone)

export interface ISlotTimeButtonsProps {
  currDate: Dayjs,
  slots: Slot[],
  onSelectSlot: (slot: Slot) => void,
  buttonsPerRow?: number,
  timezone?: string,
}

export const SlotTimeButtons: React.FunctionComponent<ISlotTimeButtonsProps> = (props) => {
  return (
    <Grid container rowSpacing={1} className="slot-picker center">
      <Grid item xs={12}>
        <h5>{props.currDate.format('ddd, MMM D')}</h5>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        {_.isEmpty(props.slots) ? (
          'No Slots Available'
        ) : (
          <div className="slot-buttons scrollbox">
            <Grid container spacing={2}>
              {props.slots.map((slot: Slot) => (
                <Grid
                  item
                  xs={12 / (props.buttonsPerRow || 1)}
                  key={slot.start}>
                  <Button
                    sx={{
                      width: `${100 - 5 / (props.buttonsPerRow || 1)}%`,
                      fontSize: 16,
                    }}
                    onClick={() => props.onSelectSlot(slot)}
                    variant="outlined">
                    {dayjs(slot.start).tz(props.timezone).format('h:mm a')}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default SlotTimeButtons;