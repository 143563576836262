import React, { useState } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../Formik/FormikControl';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import { borderClass, scrollToError, yupDateValidation } from './utils';
import './styles.scss';
import { useQuery } from '../../services/utils';

const IdentityVerification = (props) => {
  const query = useQuery();
  const code = query.get('code');
  const [showDobError, setShowDobError] = useState(true);
  const initialValues = {
    zipcode: '',
    dob: '',
    // houseNumber: '',
  };

  const validationSchema = Yup.object({
    zipcode: Yup.string().matches(/^\d+$/).length(5).required(),
    dob: yupDateValidation(
      { lt: new Date(), path: 'dob' },
      {
        match: (valid) => setShowDobError(false),
        test: (valid) => setShowDobError(!valid),
      },
    ),
    // houseNumber: Yup.string().matches(/^\d+$/).required(),
  });

  const onSubmit = (values, setSubmitting) => {
    UserService.verifyIdentity({ code, ...values })
      .then((res) => props.onContinue(res.data))
      .catch((e) => toast.error(e.message))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Verify Your Identity</h3>
          <p>In order to give you access to your account, please verify the following details.</p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <div className="form-container">
              <FormikControl
                className={borderClass('zipcode', formik)}
                control="input"
                name="zipcode"
                placeholder="Zipcode"
                showErrorMsg={false}
              />
              <FormikControl
                className={borderClass('dob', formik)}
                format="##/##/####"
                formatType="date"
                control="input"
                onChange={(v) => formik.setFieldValue('dob', v.formattedValue)}
                name="dob"
                placeholder="Date of Birth"
                showErrorMsg={!formik.values.dateOfBirth ? false : showDobError}
              />
              {/* <FormikControl
                className={borderClass('houseNumber', formik)}
                control="input"
                name="houseNumber"
                placeholder="Home Address House Number"
                showErrorMsg={false}
              />
              <div className="hint"><p>Example: If your address is "123 Cherry St.", you would enter "123"</p></div> */}
            </div>
            <button
              type="submit"
              className="submit-btn"
              onClick={() => scrollToError(false)}
              disabled={formik.isSubmitting}>
              Verify
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IdentityVerification;
