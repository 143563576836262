import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { SERVER_URL } from '../../../config';
import AuthService from '../../../services/authService';
import UserService from '../../../services/userService';
import './styles.scss';
import AdminUsersService from '../../../services/adminUsersService';
import get from 'lodash/get';
import { CircularProgress } from '@mui/material';

const TelemedicineSignatureModal = (props) => {
  const [height, setHeight] = useState(1200);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const body = document.body;
  const { user } = AuthService.getAuth();
  const { partnerData } = user;
  const { additionalForms = [] } = partnerData || {};
  const html = document.documentElement;

  useEffect(() => {
    setHeight(Math.max(body.scrollHeight, html.scrollHeight));
  }, [html.scrollHeight, body.scrollHeight]);

  const onSubmit = async () => {
    setLoading(true);
    const ipInfo = await UserService.getPatientIP().catch(() => {});
    const ip = get(ipInfo, "ip", "0.0.0.0")
    const data = {
      ip,
      patientId: user.id,
      device: 'Web',
      additionalForms,
    };
    UserService.createSignatures(data)
      .then((res) => AuthService.updateUserInfo({ isPlatformAuthorized: true }))
      .catch(() => toast.error('Unable to authorize. You may continue and we will try again later!'),)
      .finally(() => {
        props.toggleModal(false);
        setLoading(false);
      });
  };

  const getForm = async (file) => {
    const { data } = await AdminUsersService.reSignFile(file);
    const { url } = data || {};
    window.open(url, '_blank');
  };

  return (
    <div
      className="base-modal in-platform-authorization"
      style={{ height: height + 'px' }}>
      <div className="base-modal-content">
        <h1 className="terms-title">Authorization Required</h1>
        <p className="terms-header">
          By entering your full name and clicking “Agree & Continue”, you
          confirm you have read and agree to the following:
        </p>
        <div className="input-wrap">
          <div className="policyLinks center-list">
            <ul>
              <li>
                <a
                  href={`${SERVER_URL}/conditions/privacy-practices`}
                  target="_blank"
                  rel="noreferrer"
                  className="blue-txt">
                  Acknowledgement Receipt for Notice of Privacy Practices
                </a>
              </li>
              <li>
                <a
                  href={`${SERVER_URL}/conditions/telemedicine`}
                  target="_blank"
                  rel="noreferrer"
                  className="blue-txt">
                  Telemed Consent
                </a>
              </li>
              {additionalForms &&
                (additionalForms || []).map((form) => {
                  const { title, file } = form;
                  return (
                    <li>
                      <a
                        className="blue-txt"
                        href="#"
                        onClick={() => getForm(file)}>
                        {title}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="mt-3">
            <input
              type="text"
              placeholder="Full Name"
              className="signature-input"
              style={{ backgroundColor: 'white' }}
              value={name}
              maxLength={40}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="signature-text">{name}</div>
          </div>
        </div>
        <div className="submit-btn">
          <button
            className="btn btn-blue"
            disabled={loading || !name}
            onClick={() => onSubmit()}>
            {(loading) 
              ? <CircularProgress size={30} style={{ color: "white", verticalAlign: "sub" }} />
              : "Agree & Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TelemedicineSignatureModal;
