import axios from 'axios';
import nprogress from 'accessible-nprogress';
import _ from 'lodash';
import {SERVER_URL} from '../config';
import { store } from '..';

/**
 * these endpoint will not redirect to login if return 401
 */
const NOT_REDIRECT_ENDPOINTS = [
  'login',
];

const getReqUrl = (context: any) => {
  const reqUrl =
    _.get(context, 'request._url', '') ||
    _.get(context, 'request.responseURL', '');
  return reqUrl || '';
};

/**
 * new http/s request instance
 */
const apiInstance = axios.create({
  baseURL: SERVER_URL,
  withCredentials: true
});

let requestCount = 0;

function doneProgress() {
  requestCount -= 1;
  if (requestCount <= 0) {
    nprogress.done();
  }
}

function showProgress() {
  requestCount += 1;
  if (requestCount > 0) {
    nprogress.start();
  }
}

/**
 * inject token
 */

apiInstance.interceptors.request.use((config) => {
  const {dataReducer} = store.getState()
  if(dataReducer?.showLoading !== false) {
    showProgress();
  }
  const authService = require('../services/authService').default;
  const token = config.headers.token;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${authService.getJwtToken() || token}`;
  return config;
}, () => {
  doneProgress();
  return Promise.reject(new Error('Api request create failed'));
});

/**
 * interceptor errors
 */
apiInstance.interceptors.response.use((rsp) => {
  doneProgress();
  return rsp;
}, (error) => {
  doneProgress();
  let errMsg = 'Cannot request to api server';
  if (error.response && error.response.data && error.response.data.message) {
    const message = error.response.data.message;
    if (_.isArray(message)) {
      errMsg = message[0].message;
    } else {
      errMsg = error.response.data.message;
    }

    const reqUrl = getReqUrl(error);
    // skip redirect to login if include these endpoints
    const skipRedirect = NOT_REDIRECT_ENDPOINTS.some((e) =>
      reqUrl.includes(e),
    );

    // goto 401 if not login
    if (error.response.status === 401 && !skipRedirect) {
      const authService = require('../services/authService').default;
      authService.cleanAuth();
      setTimeout(() => {
        window.location.replace('/signInPage');
      }, 2000)
      return Promise.reject(new Error('Session expired'));
    }
  }
  return Promise.reject(new Error(errMsg));
});

export const Api = apiInstance;
