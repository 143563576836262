import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../Formik/FormikControl';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import { borderClass, scrollToError, yupDateValidation, yupRegex } from './utils';
import './styles.scss';
import AuthService from '../../services/authService';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { API_LOAD_PROVIDERS } from '../../store/actions/appointments';
import NoProviders from './NoProviders';

const PersonalInfo = (props) => {
  const { user } = AuthService.getAuth();
  const [states, setStates] = useState([]);
  const [showDobError, setShowDobError] = useState(true);
  const [noProviders, setNoProviders] = useState(false);
  const dispatch = useDispatch();

  const disabledFields = props.disabledFields || [];

  useEffect(() => {
    UserService.getStates()
      .then((res) => {
        const stateList = (res.data || []).map((state) => ({
          key: state,
          value: state,
        }));
        stateList.unshift({ key: 'Select State', value: '', disabled: true });
        setStates(stateList);
      })
      .catch((error) => toast.error('Unable to retreive state list'));
  }, []);

  const nextStep = user?.bypassPlatformFees ? 'provider' : 'insurance'
  const initialValues = {
    onboardingProgress: nextStep,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    dateOfBirth: user?.dateOfBirth || '',
    phone: user?.phone || '',
    state: user?.state || '',
    address: user?.address || '',
    city: user?.city || '',
    zipcode: user?.zipcode || '',
    gender: user?.gender || '',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...props.initialValues,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().matches(yupRegex.letters).required(),
    lastName: Yup.string().matches(yupRegex.letters).required(),
    phone: Yup.string().matches(yupRegex.phone).required(),
    city: Yup.string().required(),
    zipcode: Yup.string()
      .matches(/^\d{5}(-\d{4})?$/, 'Invalid Zip Code')
      .required('Zip Code is required'),
    state: Yup.string().required(),
    address: Yup.string().required(),
    gender: Yup.string().required(),
    dateOfBirth: yupDateValidation(
      { lt: new Date(), path: 'dateOfBirth' },
      {
        match: (valid) => setShowDobError(false),
        test: (valid) => setShowDobError(!valid),
      },
    ),
  });

  const handleZipCodeChange = (e, formik) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/\D/g, '').substring(0, 9);
    const formattedZipCode =
      formattedValue.length > 5
        ? formattedValue.slice(0, 5) + '-' + formattedValue.slice(5)
        : formattedValue;
    formik.setFieldValue(name, formattedZipCode);
  };

  const onSubmit = (values, setSubmitting) => {
    if (props.profile) delete values.onboardingProgress;

    UserService.updateProfile(
      {
        ...values,
        chatbotProgress: {
          sheepa: 16,
          sheepaFinished: false,
          sheepaTime: moment().utc(),
        },
      },
      user.id,
    )
      .then(async (res) => {
        const user = !isEmpty(res.data) ? res.data : null;
        const providers = await UserService.getProviders({
          withProviderId: true,
        });

        const data = providers?.data || [];
        try {
          dispatch({
            type: API_LOAD_PROVIDERS,
            payload: providers?.data || [],
          });

          if (data?.length) {
            props.onContinue(user, data);
          } else setNoProviders(true);
        } catch (error) {
          toast.error('Unable to get provider data.');
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setSubmitting(false));
  };

  if (noProviders) return <NoProviders />;
  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>{props.title || 'Personal Info'}</h3>
          <p>{props.subtitle || 'Please enter your demographic info'}</p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <div className="form-container">
              <FormikControl
                className={borderClass('firstName', formik)}
                control="input"
                type="char"
                name="firstName"
                placeholder="First Name"
                showErrorMsg={false}
                disabled={disabledFields.includes('firstName')}
              />
              <FormikControl
                className={borderClass('lastName', formik)}
                control="input"
                type="char"
                name="lastName"
                placeholder="Last Name"
                showErrorMsg={false}
                disabled={disabledFields.includes('lastName')}
              />
              <FormikControl
                className={borderClass('dateOfBirth', formik)}
                control="input"
                format="##/##/####"
                formatType="date"
                value={formik.values.dateOfBirth}
                onChange={(v) =>
                  formik.setFieldValue('dateOfBirth', v.formattedValue)
                }
                onBlur={formik.handleBlur}
                name="dateOfBirth"
                placeholder="Date of Birth"
                showErrorMsg={!formik.values.dateOfBirth ? false : showDobError}
                disabled={disabledFields.includes('dateOfBirth')}
              />
              <FormikControl
                className={borderClass('phone', formik)}
                control="input"
                type="text"
                name="phone"
                maxLength={15}
                placeholder="Phone Number"
                format="(###) ###-####"
                value={formik.values.phone}
                onChange={(v) =>
                  formik.setFieldValue('phone', v.formattedValue)
                }
                onBlur={formik.handleBlur}
                showErrorMsg={false}
                disabled={disabledFields.includes('phone')}
              />
              <FormikControl
                className={borderClass('state', formik)}
                control="select"
                expanding={true}
                handlers={formik}
                options={states}
                name="state"
                showErrorMsg={false}
                disabled={disabledFields.includes('state')}
              />
              <FormikControl
                className={borderClass('address', formik)}
                control="input"
                type="text"
                name="address"
                placeholder="Street Address"
                showErrorMsg={false}
                disabled={disabledFields.includes('address')}
              />
              <div className="flex city-zip">
                <FormikControl
                  className={`${borderClass('city', formik)}`}
                  containerStyle={{ marginRight: 8 }}
                  control="input"
                  type="text"
                  name="city"
                  placeholder="City"
                  showErrorMsg={false}
                  disabled={disabledFields.includes('city')}
                />
                <FormikControl
                  className={`${borderClass('zipcode', formik)}`}
                  containerStyle={{ marginLeft: 8 }}
                  control="input"
                  type="text"
                  name="zipcode"
                  placeholder="Zip Code"
                  onChange={(e) => handleZipCodeChange(e, formik)}
                  showErrorMsg={false}
                  disabled={disabledFields.includes('zipcode')}
                />
              </div>
              <FormikControl
                className={borderClass('gender', formik)}
                control="custom-btn"
                labelError={borderClass('gender', formik) === 'error-border'}
                onClick={(value) => formik.setFieldValue('gender', value)}
                label="Biological Sex"
                persist={true}
                options={['Male', 'Female']}
                selected={formik.values.gender}
                disabled={disabledFields.includes('gender')}
              />
            </div>
            <button
              type="submit"
              className="submit-btn"
              onClick={() => scrollToError(false)}
              disabled={formik.isSubmitting}>
                {props.profile ? 'Save' : 'Continue'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
