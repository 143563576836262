import React, { Fragment, useEffect, useState } from 'react';
import FormikControl from '../../components/Formik/FormikControl';
import { Formik, Form } from 'formik';
import AuthService from '../../services/authService';
import * as Yup from 'yup';
import {
  borderClass,
  manualFormValidation,
  scrollToError,
  yupDateValidation,
} from './utils';
import { useQuery } from '../../services/utils';
import { useHistory } from 'react-router-dom';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';

const SystemsReview = (props) => {
  const details = props?.reviewOfSystems;
  const apptId = props?.apptId;
  const medicalHistory = props?.medicalHistory;
  const history = useHistory();
  const query = useQuery();
  const view = query.get('view') || '1';
  const { user } = AuthService.getAuth();
  const [showDateError, setShowDateError] = useState(false);

  useEffect(() => {
    if (!parseInt(view) || parseInt(view) > 3) {
      history.push('/');
    }
  }, []);

  const initialValues = {
    unit: details?.unit || 'Imperial',
    height: details?.height || '0',
    weight: details?.weight || '0',
    accidentRelated: {},
    recentSymptoms: details?.recentSymptoms || [],
  };

  const validationSchema = Yup.object({
    recentSymptoms: Yup.array().of(Yup.string()),
  });

  const trim = (v) => v.replace(/\D/g, '').trim();

  const nextView = (v) =>
    history.push(`/followUp?step=sleep-update`);

  const onSubmit = async (values, setSubmitting) => {
    const newValues = JSON.parse(JSON.stringify(values));
    setSubmitting(false);
    let followUpProgress =
      parseInt(view) < 3 ? `/followUp?step=sleep-update` : 'complete';

    const data = {
      userId: user.id,
      type: 'Follow-up',
      appointmentId: apptId,
      form: { 
        // providerHistory: medicalHistory?.providerHistory,
        // medications: medicalHistory?.medications,
        // pastMedicalHistory: medicalHistory?.pastMedicalHistory,
        // socialHistory: medicalHistory?.socialHistory,
        // familyHistory: medicalHistory?.familyHistory,
        // sleepMedicineHistory: medicalHistory?.sleepMedicineHistory,
        reviewOfSystems: newValues 
      },
    };

    try {
      const { updateAppointmentProgress, updateMedicalHistory } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);
      const apptRecord = await updateAppointmentProgress(apptId, { followUpProgress: 'sleep-update', medicalId: formDataRecord?.data?.id });
      props.updateHistory(formDataRecord?.data?.form || {}, apptRecord.data);
      parseInt(view) < 3
        ? nextView()
        : props.onContinue({ followUpProgress });
      setSubmitting(false);
    } catch (error) {
      toast.error('Unable to save social history. Please try again.');
      setSubmitting(false);
    }
  };

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Physiology Review</h3>
          <p>
            Please provide some key details about your body and any recent
            health issues.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          const details = formik.values;
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                manualFormValidation(formik);
                formik.handleSubmit();
              }}>
              <div className="form-container systems-review">
                <p className="mb-3 mt-1">
                  Have you experienced any of the following in the past few
                  weeks?
                </p>
                {recentSymptomItems.map((item, i) => {
                  const recentSymptoms = details?.recentSymptoms;
                  const selected = recentSymptoms.includes(item);
                  return (
                    <FormikControl
                      key={item}
                      control="custom-btn"
                      className="mb-0 mt-0"
                      style={{ width: '95%' }}
                      onClick={(v) => {
                        formik.setFormikState((state) => {
                          let newState = { ...state };
                          let values = newState.values.recentSymptoms;
                          const index = values.indexOf(v);
                          index !== -1
                            ? values.splice(index, 1)
                            : values.push(v);
                          return newState;
                        });
                      }}
                      options={[item]}
                      selected={selected && item}
                    />
                  );
                })}
              </div>
              <button
                type="submit"
                className={`submit-btn`}
                onClick={() => scrollToError(false)}
                disabled={formik.isSubmitting}
                ref={props.continueRef}>
                Continue
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SystemsReview;

const recentSymptomItems = [
  'Skin rash',
  'Sores',
  'Excessive bruising',
  'Excessive thirst',
  'Excessive urination',
  'Significant headaches',
  'Double or blurred vision',
  'Diminished hearing',
  'Dizziness',
  'Sinus problems',
  'Cough',
  'Shortness of breath',
  'Wheezing',
  'Asthma',
  'Coughing up sputum or blood',
  'Blackouts or loss of consciousness',
  'Chest pain or pressure',
  'Rapid or irregular heart beats',
  'Abnormal swelling in legs or feet',
  'Pain in calves when you walk',
  'Difficulty swallowing',
  'Heartburn',
  'Nausea',
  'Vomiting',
  'Significant problems with constipation',
  'Diarrhea',
  'Fever',
  'Large lymph nodes',
  'Weight loss/gain of more than 10 pounds (last 6 months)',
  'Experiencing an unusually stressful situation',
];
