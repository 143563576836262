import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormikControl from '../../components/Formik/FormikControl';
import { LOAD_LOADER } from '../../constants/actionTypes';
import { Api } from '../../services/api';
import AuthService from '../../services/authService';
import UserService from '../../services/userService';
import { useQuery } from '../../services/utils';
import { borderClass, manualFormValidation, scrollToError, yupRegex } from './utils';

const SLEEP_STATUS = ['Better', 'Worse', 'About the same'];

const hourAM = [
{key:'12:00 AM', value:'12:00 AM'},
{key:'12:30 AM', value:'12:30 AM'},
{key:'1:00 AM', value:'1:00 AM'},
{key:'1:30 AM', value:'1:30 AM'},
{key:'2:00 AM', value:'2:00 AM'},
{key:'2:30 AM', value:'2:30 AM'},
{key:'3:00 AM', value:'3:00 AM'},
{key:'3:30 AM', value:'3:30 AM'},
{key:'4:00 AM', value:'4:00 AM'},
{key:'4:30 AM', value:'4:30 AM'},
{key:'5:00 AM', value:'5:00 AM'},
{key:'5:30 AM', value:'5:30 AM'},
{key:'6:00 AM', value:'6:00 AM'},
{key:'6:30 AM', value:'6:30 AM'},
{key:'7:00 AM', value:'7:00 AM'},
{key:'7:30 AM', value:'7:30 AM'},
{key:'8:00 AM', value:'8:00 AM'},
{key:'8:30 AM', value:'8:30 AM'},
{key:'9:00 AM', value:'9:00 AM'},
{key:'9:30 AM', value:'9:30 AM'},
{key:'10:00 AM', value:'10:00 AM'},
{key:'10:30 AM', value:'10:30 AM'},
{key:'11:00 AM', value:'11:00 AM'},
{key:'11:30 AM', value:'11:30 AM'},
{key:'12:00 PM', value:'12:00 PM'},
{key:'12:30 PM', value:'12:30 PM'},
{key:'1:00 PM', value:'1:00 PM'},
{key:'1:30 PM', value:'1:30 PM'},
{key:'2:00 PM', value:'2:00 PM'},
{key:'2:30 PM', value:'2:30 PM'},
{key:'3:00 PM', value:'3:00 PM'},
{key:'3:30 PM', value:'3:30 PM'},
{key:'4:00 PM', value:'4:00 PM'},
{key:'4:30 PM', value:'4:30 PM'},
{key:'5:00 PM', value:'5:00 PM'},
{key:'5:30 PM', value:'5:30 PM'},
{key:'6:00 PM', value:'6:00 PM'},
{key:'6:30 PM', value:'6:30 PM'},
{key:'7:00 PM', value:'7:00 pm'},
{key:'7:30 PM', value:'7:30 PM'},
{key:'8:00 PM', value:'8:00 PM'},
{key:'8:30 PM', value:'8:30 PM'},
{key:'9:00 PM', value:'9:00 PM'},
{key:'9:30 PM', value:'9:30 PM'},
{key:'10:00 PM', value:'10:00 PM'},
{key:'10:30 PM', value:'10:30 PM'},
{key:'11:00 PM', value:'11:00 PM'},
{key:'11:30 PM', value:'11:30 PM'},
]

const hourPM = [
  {key:'12:00 PM', value:'12:00 PM'},
  {key:'12:30 PM', value:'12:30 PM'},
  {key:'1:00 PM', value:'1:00 PM'},
  {key:'1:30 PM', value:'1:30 PM'},
  {key:'2:00 PM', value:'2:00 PM'},
  {key:'2:30 PM', value:'2:30 PM'},
  {key:'3:00 PM', value:'3:00 PM'},
  {key:'3:30 PM', value:'3:30 PM'},
  {key:'4:00 PM', value:'4:00 PM'},
  {key:'4:30 PM', value:'4:30 PM'},
  {key:'5:00 PM', value:'5:00 PM'},
  {key:'5:30 PM', value:'5:30 PM'},
  {key:'6:00 PM', value:'6:00 PM'},
  {key:'6:30 PM', value:'6:30 PM'},
  {key:'7:00 PM', value:'7:00 pm'},
  {key:'7:30 PM', value:'7:30 PM'},
  {key:'8:00 PM', value:'8:00 PM'},
  {key:'8:30 PM', value:'8:30 PM'},
  {key:'9:00 PM', value:'9:00 PM'},
  {key:'9:30 PM', value:'9:30 PM'},
  {key:'10:00 PM', value:'10:00 PM'},
  {key:'10:30 PM', value:'10:30 PM'},
  {key:'11:00 PM', value:'11:00 PM'},
  {key:'11:30 PM', value:'11:30 PM'},
  {key:'12:00 AM', value:'12:00 AM'},
  {key:'12:30 AM', value:'12:30 AM'},
  {key:'1:00 AM', value:'1:00 AM'},
  {key:'1:30 AM', value:'1:30 AM'},
  {key:'2:00 AM', value:'2:00 AM'},
  {key:'2:30 AM', value:'2:30 AM'},
  {key:'3:00 AM', value:'3:00 AM'},
  {key:'3:30 AM', value:'3:30 AM'},
  {key:'4:00 AM', value:'4:00 AM'},
  {key:'4:30 AM', value:'4:30 AM'},
  {key:'5:00 AM', value:'5:00 AM'},
  {key:'5:30 AM', value:'5:30 AM'},
  {key:'6:00 AM', value:'6:00 AM'},
  {key:'6:30 AM', value:'6:30 AM'},
  {key:'7:00 AM', value:'7:00 AM'},
  {key:'7:30 AM', value:'7:30 AM'},
  {key:'8:00 AM', value:'8:00 AM'},
  {key:'8:30 AM', value:'8:30 AM'},
  {key:'9:00 AM', value:'9:00 AM'},
  {key:'9:30 AM', value:'9:30 AM'},
  {key:'10:00 AM', value:'10:00 AM'},
  {key:'10:30 AM', value:'10:30 AM'},
  {key:'11:00 AM', value:'11:00 AM'},
  {key:'11:30 AM', value:'11:30 AM'},
  ]

const SleepHistory = (props) => {
  const apptId = props.apptId;
  const medicalHistory = props?.medicalHistory;
  const {
    sleepQuestionare = [],
    epworthSleepinessScale: epworth = [],
    nearAccidentHistoryDescription: nearAccident = { response: true, description: 'test' },
    pastSleepStudy,
    ...sleepHistory
  } = props.sleepHistory;
  const lastQuestionare = sleepQuestionare[sleepQuestionare.length - 1];
  const lastEpworth = epworth[epworth.length - 1];

  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const view = query.get('view') || '1';
  const { user } = AuthService.getAuth();
  const [state, setState] = useState({ sleepStudyFiles: {}, hasFiles: false });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  useEffect(() => {
    if (!parseInt(view) || parseInt(view) > 4) {
      history.push('/');
    } else if (view === '1') getFileList();
  }, []);

  let validationSchema;
  switch (view) {
    case '1':
      validationSchema = {
        epworthSleepinessScale: Yup.object({
          averageBedtime: Yup.string().required(),
          averageRiseTime: Yup.string().required(),
          numberOfAwakenings: Yup.string().matches(yupRegex.numberDash).required(),
          averageHourSlept: Yup.string().matches(yupRegex.numberDash).required(),
          amountDoseTime: Yup.string().matches(yupRegex.numberDash).required(),
          mySleepStatus: Yup.string().required(),
        }),
      };
      break;
    case '2':
      validationSchema = {
        epworthSleepinessScale: Yup.object({
          reading: Yup.string().required(),
          watchingTV: Yup.string().required(),
          inactivePublic: Yup.string().required(),
          passenger: Yup.string().required(),
          afternoonLyeDown: Yup.string().required(),
          talking: Yup.string().required(),
          afterLunch: Yup.string().required(),
          inTraffic: Yup.string().required(),
          score: Yup.number().required(),
          dateSaved: Yup.date().required(),
        }),
      };
      break;
  }

  const fileLength = Object.keys(state.sleepStudyFiles).length;
  const initialValues = {
    // view 4
    epworthSleepinessScale: {
      reading: '',
      watchingTV: '',
      inactivePublic: '',
      passenger:  '',
      afternoonLyeDown: '',
      talking:'',
      afterLunch:'',
      inTraffic:'',
      score: lastEpworth?.score || 0,
      averageBedtime: lastEpworth?.averageBedtime || '9:00 PM',
      averageRiseTime: lastEpworth?.averageRiseTime || '7:00 AM',
      numberOfAwakenings: lastEpworth?.numberOfAwakenings || '',
      averageHourSlept: lastEpworth?.averageHourSlept || '',
      amountDoseTime: lastEpworth?.amountDoseTime || '',
      mySleepStatus: '',
      dateSaved: new Date(),
    },
  };
  validationSchema = Yup.object({
    ...validationSchema,
  });

  const getFileList = () => {
    user.id &&
      UserService.getFileList(`Key=patients/${user.id}/sleep-study`)
        .then((res) => {
          let sleepStudyFiles = {};
          let hasFiles = false;
          if (res?.data?.length) {
            res.data.forEach((file) => {
              hasFiles = true;
              sleepStudyFiles[file.name] = {
                name: file.name,
                loading: false,
                uploaded: true,
              };
            });

            setGenericState({ sleepStudyFiles, hasFiles });
          }
        })
        .catch((error) => toast.error('Unable to retrieve file list!'));
  };

  const nextView = (v) =>
    history.push(`/followUp?step=social-history`);

  const deleteFile = (name) => {
    let sleepStudyFiles = { ...state.sleepStudyFiles };
    sleepStudyFiles[name].loading = true;
    setGenericState({ sleepStudyFiles });

    UserService.deleteFile(`Key=patients/${user.id}/sleep-study/${name}`)
      .then((res) => {
        delete sleepStudyFiles[name];
        setGenericState({ sleepStudyFiles });
      })
      .catch((error) => {
        sleepStudyFiles[name].loading = false;
        toast.error('Unable to delete this file. Please try again!');
      });
  };

  const handleNumberChange = (e, formik, i) => {
    const value = e.target.value;
    const maxValue = i === 1 ? 24 : 300;
    const maxLength = i === 1 ? 2 : 3;
    if (!value) formik.handleChange(e);
    if (parseInt(value) <= maxValue && value.toString().length <= maxLength) {
      formik.handleChange(e);
    }
  };
  const handleFileChange = (file) => {
    const parts = file.name.split('.');
    const ext = parts.pop();
    const fileName = `PastHSTReport-${parts.join('.')}.${ext}`;

    setState((state) => {
      const newState = { ...state };
      newState.sleepStudyFiles[fileName] = {
        name: fileName,
        loading: true,
        uploaded: false,
      };
      return newState;
    });
    const data = new FormData();
    dispatch({ type: LOAD_LOADER, data: false });
    data.append('file', file, fileName);
    data.append('keyPath', `patients/${user.id}/sleep-study/`);
    UserService.uploadFile(data)
      .then((res) => {
        dispatch({ type: LOAD_LOADER, data: true });
        const uploadedFileName = res?.data?.file;

        Api.post('/forms', {
          category: 'OTHER',
          name: uploadedFileName,
          key: `patients/${user.id}/sleep-study/${uploadedFileName}`,
          patientId: user.id,
          usedFor: 'Patient Onboarding',
        }).catch(() => { });

        setState((state) => {
          const newState = { ...state };
          delete newState.sleepStudyFiles[fileName];
          newState.sleepStudyFiles[uploadedFileName] = {
            name: uploadedFileName,
            loading: false,
            uploaded: true,
          };
          return newState;
        });
      })
      .catch((error) =>
        toast.error(`Unable to upload ${file.name}. Please try again!`),
      );
  };

  const getEpworth = (epworth) => {
    const score = Object.keys(epworth)
      .map((v) => {
        const index = scale.indexOf(epworth[v]);
        return index !== -1 ? index : 0;
      })
      .reduce((prev, curr) => prev + curr);

    epworth.score = score;
    return epworth;
  };

  const constructData = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const keys = ['sleepQuestionare', 'epworthSleepinessScale'];

    const questionnaire = [...sleepQuestionare];
    questionnaire.splice(questionnaire.length - 1, 1, newValues[keys[0]]);

    const epworthVal = [...epworth];

    epworthVal.splice(epworthVal.length - 1, 1, getEpworth(newValues[keys[1]]));


    return view === '1'
      ? omit(newValues, keys)
      : view === '2'
        ? {
          [keys[1]]: epworthVal,
        }
        : {
          [keys[0]]: questionnaire,
        };
  };

  const onSubmit = async (values, setSubmitting) => {
    setSubmitting(false);

    const newValue = getEpworth(values.epworthSleepinessScale);
    let mergedObj2 = {};

    if (view === '2') {
      mergedObj2 = newValue;
      mergedObj2.mySleepStatus = medicalHistory?.sleepMedicineHistory?.epworthSleepinessScale[0].mySleepStatus;
    } else {
      mergedObj2 = { ...newValue, ...lastEpworth };
    }

    const newValues = {
      epworthSleepinessScale: [
        mergedObj2
      ]
    }

    const data = {
      userId: user.id,
      type: 'Follow-up',
      appointmentId: apptId,
      form: {
        // providerHistory: medicalHistory?.providerHistory,
        // medications: medicalHistory?.medications,
        // pastMedicalHistory: medicalHistory?.pastMedicalHistory,
        // socialHistory: medicalHistory?.socialHistory,
        // familyHistory: medicalHistory?.familyHistory,
        sleepMedicineHistory: {
          // pastSleepStudy: medicalHistory?.sleepMedicineHistory?.pastSleepStudy,
          // onPAP: medicalHistory?.sleepMedicineHistory?.onPAP,
          // papVendor: medicalHistory?.sleepMedicineHistory?.papVendor,
          // nearAccidentHistoryDescription: medicalHistory?.sleepMedicineHistory?.nearAccidentHistoryDescription,
          // sleepQuestionare: medicalHistory?.sleepMedicineHistory?.sleepQuestionare,
          ...newValues,
        },
        // reviewOfSystems: medicalHistory?.reviewOfSystems
      },
    };


    try {
      const { updateAppointmentProgress, updateMedicalHistory } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);
      await updateAppointmentProgress(apptId, { followUpProgress: parseInt(view) === 1 ? `sleep-update&view=${parseInt(view) + 1}` : 'social-history', medicalId: formDataRecord?.data?.id });
      props.updateHistory(formDataRecord?.data?.form || {});
      parseInt(view) >= 2
        ? nextView()
        : props.onContinue({ followUpProgress: `sleep-update&view=${parseInt(view) + 1}` });
      setSubmitting(false);
    } catch (error) {
      toast.error('Unable to save social history. Please try again.');
      setSubmitting(false);
    }
  };

  const getDisableBtn = () => {
    const uploading = Object.values(state.sleepStudyFiles).find(
      (file) => file.loading && !file.uploaded,
    );

    return !!uploading;
  };

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Sleep Update</h3>
          <p>
            Please provide some key details about your body and any recent
            health issues.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={view === "2" ? validationSchema : null}
        enableReinitialize={view === '2' || view === '4'}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          let {
            pastSleepStudy,
            onPAP,
            nearAccidentHistoryDescription: newAccident,
            sleepQuestionare,
            epworthSleepinessScale,
          } = formik.values;
          const disableBtn =
            getDisableBtn(formik.values) || formik.isSubmitting;
          const fileLength = Object.keys(state.sleepStudyFiles)?.length;
          const hasFiles = fileLength || pastSleepStudy?.response;

          if (fileLength && typeof pastSleepStudy?.response !== 'boolean') {
            formik.setFieldValue('pastSleepStudy', {
              response: true,
              description: 'test',
            });
          }

          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                manualFormValidation(formik);
                formik.handleSubmit();
              }}>
              {/* VIEW ONE */}
              {view === '1' && (
                <div className="form-container sleep-container">
                  {view === '1' && (
                    <Fragment>
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.averageHourSlept',
                          formik,
                        )}
                        control="input"
                        type="text"
                        maxLength={5}
                        label="How many hours of sleep do you get on a typical night?"
                        name="epworthSleepinessScale.averageHourSlept"
                        placeholder="Average Hours Slept"
                        showErrorMsg={false}
                      />

                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.averageBedtime',
                          formik,
                        )}
                        control="select"
                        label="What time do you usually fall asleep?"
                        name="epworthSleepinessScale.averageBedtime"
                        placeholder="Average Bedtime"
                        handlers={formik}
                        value={
                          formik.values['epworthSleepinessScale'].averageBedtime
                        }
                        options={hourPM}
                        showErrorMsg={false}
                      />
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.averageRiseTime',
                          formik,
                        )}
                        control="select"
                        label="What time do you usually wake up?"
                        name="epworthSleepinessScale.averageRiseTime"
                        placeholder="Average Rise Time"
                        handlers={formik}
                        options={hourAM}
                        value={
                          formik.values['epworthSleepinessScale']
                            .averageRiseTime
                        }
                        showErrorMsg={false}
                      />
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.amountDoseTime',
                          formik,
                        )}
                        control="input"
                        type="text"
                        maxLength={5}
                        name="epworthSleepinessScale.amountDoseTime"
                        id="epworthSleepinessScale.amountDoseTime"
                        label="How long does it take to fall asleep on a typical night?  "
                        placeholder="Average Dose Time"
                        showErrorMsg={false}
                      />
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.numberOfAwakenings',
                          formik,
                        )}
                        control="input"
                        type="text"
                        maxLength={5}
                        name="epworthSleepinessScale.numberOfAwakenings"
                        id="epworthSleepinessScale.numberOfAwakenings"
                        label="How many times do you wake up thoughout a typical night? "
                        placeholder="Number of Awakenings"
                        showErrorMsg={false}
                      />
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.mySleepStatus',
                          formik,
                        )}
                        control="custom-btn"
                        label="Compared to before your last visit, how would you rate your sleep?"
                        name={'epworthSleepinessScale.mySleepStatus'}
                        onClick={async (v) => {
                          await formik.setFieldValue(
                            'epworthSleepinessScale.mySleepStatus',
                            v,
                            false,
                          );
                          formik.setFieldTouched(
                            'epworthSleepinessScale.mySleepStatus',
                          );
                        }}
                        persist={true}
                        options={SLEEP_STATUS}
                      />
                    </Fragment>
                  )}
                </div>
              )}
              {view === '2' && (
                <div className="form-container sleep-history epworth">
                  <p className="mb-3 mt-1">
                    How likely are you to doze off in the situations below?
                  </p>
                  {Object.keys(epworthItems).map((key, i) => {
                    const label = epworthItems[key];
                    const value = epworth[key];
                    const name = `epworthSleepinessScale.${key}`;
                    const borderClassStr = borderClass(name, formik);
                    return (
                      <Fragment key={key}>
                        <p
                          className="title"
                          style={
                            borderClassStr === 'error-border'
                              ? { color: '#FF0404', fontWeight: 500 }
                              : {}
                          }>
                          {label}
                        </p>
                        <FormikControl
                          control="custom-btn"
                          className={borderClassStr}
                          onClick={async (v) => {
                            await formik.setFieldValue(name, v, false);
                            formik.setFieldTouched(name);
                          }}
                          persist={true}
                          options={scale}
                          selected={value}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              )}
              <button
                type="submit"
                className={`submit-btn ${disableBtn ? 'disabled' : ''}`}
                onClick={() => scrollToError()}
                disabled={disableBtn}
                ref={props.continueRef}>
                Continue
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SleepHistory;

const scale = ['None', 'Slight', 'Moderate', 'High'];
const personalSleep = {
  drowsyDay: 'Feel very drowsy or sleepy at any point during the day',
  hallucinationDreams: 'Have strange hallucination-like dreams while napping',
  sleepAttacks:
    'Have "sleep attacks" during the day (i.e., periods when you cannot prevent yourself from falling asleep)',
  cataplecticAttacks:
    'Have "cataplectic attacks" (i.e., episodes of weakness in the legs and/or collapse that occur with emotions like laughter or crying)',
  sleepParalysis:
    'Have episodes of sleep paralysis (i.e., being awake in bed not able to move or speak)',
  legRestlessness:
    'Wake up with “pins and needles” or restlessness in the legs',
};

const sleepBehaviors = [
  'Snoring',
  'Sleepwalking',
  'Sleep talking',
  'Heart Palpitations',
  'Grinding your teeth',
  'Twitching of the legs or arms',
  'Waking up with frequent urge to urinate',
  'Rolling or rocking movement',
  'Shouting, screaming, or swearing',
  'Violent movements',
  'Waking up with anxiety or tension',
  'Excessive sweating',
  'Waking up with chest pain',
  'Waking up with jaw pain',
  'Waking up gasping or choking',
  'Waking up with headache',
  'Waking up with heartburn',
  'Waking up with frightening images',
  'Waking up with air hunger (shortness of breath)',
  'Waking up with the feeling of weight on chest',
  'Apnea (i.e. lapses in breathing, periods of no breathing)',
  'Frequent coughing',
  'Falling out of bed',
  'Bed-wetting',
  'Large body jerks',
  'Loud snoring',
  'Restless sleep',
  'Waking up with terror',
];

const epworthItems = {
  reading: 'Sitting and reading',
  watchingTV: 'Watching TV',
  inactivePublic: 'Sitting inactive in a public place',
  passenger: 'Sitting for an hour as a passenger in a car',
  afternoonLyeDown: 'Lying down in the afternoon to rest',
  talking: 'Sitting & talking to another person',
  afterLunch: 'Sitting quietly after lunch (no alcohol)',
  inTraffic: 'Sitting in a car stopped in traffic',
};
