import React, {useEffect, useState} from 'react';
import './styles.scss';
import {User} from "../../../services/dto/Security";
import {showApiError, useIsMounted} from "../../../services/utils";
import UserService from "../../../services/userService";
import {PatientInfo} from "../../../services/dto/Common";

export interface ICenterInfoProps {
  provider: User;
}

export const CenterInfo: React.FunctionComponent<ICenterInfoProps> = (props) => {
  const {provider} = props;
  const isMounted = useIsMounted();
  const [info, setInfo] = useState<PatientInfo>()

  useEffect(() => {
    UserService.getPhysicianPatientInfo(provider.id)
      .then(rsp => {
        if (isMounted) {
          setInfo(rsp.data)
        }
      })
      .catch(e => {
        if (isMounted.current) {
          showApiError(e)
        }
      })
  }, [])

  const renderCount = (title: string, color: string, count?: number) => {
    if (!isCanShow(count)) {
      return <></>
    }
    return <div className="tag">
      <div className="cen">
        <div className={`font-b color-${color}`}>{count}</div>
        <p className="txt">{title}</p>
      </div>
    </div>
  }

  const isCanShow = (c?: number)=> !(!c || c < 30)
  const renderTreated = () => renderCount('Ognomy Patients Treated', 'green', info?.treated)
  const renderPatients = () => renderCount('Current Ognomy Patients', 'blue', info?.patients)

  return (
    <div className="center-info">
      <div className="tag-box">
        <div className="flex-1">
          <div className="tag">
            <div className="cen">
              {/* <div className="font-b color-purple">{10} Years</div> */}
              <div className="font-b color-gray">Licensed Sleep Medicine Specialist</div>
            </div>
          </div>
          {isCanShow(info?.treated) ? renderTreated() : renderPatients()}
        </div>
        <div className="flex-1">
          <div className="tag">
            <div className="cen">
              <div
                className="font-b color-gray">English</div>
              <p className="txt">Language(s) Spoken</p>
            </div>
          </div>
          {isCanShow(info?.treated) ? renderPatients() : <></>}
        </div>
      </div>
      <div className="txt-box">
        <p className="txt-p">{provider.providerInfo?.biography}</p>
      </div>
    </div>
  );
};

export default CenterInfo;
