import React, { useState } from 'react';
import './styles.scss';
import { Avatar, CardHeader, IconButton } from "@mui/material"
import { User } from "../../../services/dto/Security";
import { InfoOutlined, OpenInFullOutlined } from "@mui/icons-material"
import { ProfileIcon } from '../../ProfileIcon';
import { getName } from '../../../services/utils';
import SleepSpecialistInfo from '../SleepSpecialistInfo';

export interface ITopCardHeaderProps {
  provider: User
  onInfo: () => void;
}

const SchedulingTopCard: React.FunctionComponent<ITopCardHeaderProps> = ({ provider, onInfo }) => {
  return (
    <div>
      {/* Provider Details */}
      <CardHeader
        className="provider-info"
        avatar={
          <Avatar sx={{ width: 80, height: 80 }}>
            <ProfileIcon user={provider} color={"blue"} fontSize={24} />
          </Avatar>
        }
        title={getName(provider)}
        titleTypographyProps={{
          fontSize: 18,
          fontFamily: 'Poppins',
          variant: 'h6',
          lineHeight: 1.2,
        }}
        subheader={
          <h6 style={{ fontSize: "14px", color: '#CCCCCC', fontFamily: 'Poppins' }}>
            {(provider.providerInfo?.qualifications || []).join(', ')}{' '}
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onInfo()}
              style={{ display: 'inline', padding: 0 }}>
              <InfoOutlined />
            </IconButton>
          </h6>
        }
      />
    </div>
  );
};

export default SchedulingTopCard;
