import axios from 'axios';
import { randomStr } from './utils';
import { FE_URL } from '../config'
import { Api } from './api';
import { LoginRsp, SignupReq, User, PartnerReq } from './dto/Security';
import {
  Appointment,
  DischargeSummary,
  Order,
  OrderPage,
  PatientInfo,
  PatientTodoRsp,
} from './dto/Common';

export const OuraConfig = {
  CLOUD_URL: 'https://cloud.ouraring.com',
  API_URL: 'https://api.ouraring.com',
  CLIENT_ID: 'MWJD3FP2TYXA2JIZ',
  CLIENT_SECRET: 'DEQ275NMADUH2RHUSTEWSCIV5TSCKOU2',
  DEFAULT_SCOPE: ["daily", "heartrate", "session", "spo2", "ring_configuration"],
};

export default class OuraService {

  static getBearerToken() { // Also handles updating token, if necessary
    return Api.get('/oura/token');
  }

  static setUpUser(code: string) {
    return Api.post('/oura/connect', { code, redirect_uri: `${FE_URL}/profile` });
  }

  static revokeAccess() {
    return Api.post('/oura/revoke');
  }

  /**
   * fetches user Oura data (https://cloud.ouraring.com/v2/docs)
   * @param userId 
   * @param path eg, "/v2/usercollection/sleep"
   * @param params eg, { start_date: "2024-01-01", end_date: "2024-02-01" }
   * @returns rsp.data
   */
  static fetchData(userId: string, path: string, params: any) {
    return Api.get(`/oura/fetchData/${userId}`, { 
      params: { path, fetchParams: params }
    });
  }
}
