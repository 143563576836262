import React from 'react';
import YouTube from 'react-youtube';
import { SleepApneaTreatmentData } from '../../../models/PhysicianLandingData';
import './styles.scss';

export interface ISleepApneaTreatmentProps {
  dataList: SleepApneaTreatmentData;
}

export const SleepApneaTreatment: React.FunctionComponent<ISleepApneaTreatmentProps> = (props) => {  
  const { dataList } = props;

  return (
    <div className="sleep-apnea-treatment">
      <h2 className="h2">Sleep Apnea Treatment</h2>
      <div className="pic">
        <YouTube
          videoId={dataList.videoId} opts={{
          height: '100%',
          width: '100%',
          playerVars: {
            autoplay: 0,
            origin: 'http://localhost:3000'
          },
        }} />
      </div>
      
      <div className="txt">
        <p
          dangerouslySetInnerHTML={{ __html: dataList.description}}>
        </p>
      </div>
    </div>
  );
};

export default SleepApneaTreatment;
