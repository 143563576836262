import React, { ReactElement, useState } from 'react';
import './styles.scss';
import { User } from '../../../services/dto/Security';
import { getName } from '../../../services/utils';
import { ProfileIcon } from '../../ProfileIcon';
import { Grid, IconButton, Avatar } from '@mui/material';
import {
  InfoOutlined,
  Schedule,
  LocationOn,
  Event,
  OpenInNew,
  Language,
  Person,
} from '@mui/icons-material';
import SleepSpecialistInfo from '../SleepSpecialistInfo';

export type info = {
  provider?: User | string;
  duration?: string;
  date?: ReactElement | string;
  time?: string;
  timeZone?: ReactElement | string;
  location?: string;
  link?: ReactElement;
};

export interface IAppointmentInfoRowsProps {
  info: info;
  iconWidth?: number;
  spacing?: number;
  color?: string;
}

export const AppointmentInfoRows: React.FunctionComponent<
  IAppointmentInfoRowsProps
> = (props) => {
  const { info } = props;
  const iconWidth = props.iconWidth || 2;
  const spacing = props.spacing || 1;

  const icons = {
    provider:
      info.provider && typeof info.provider !== 'string' ? (
        <Avatar sx={{ width: 24, height: 24 }}>
          <ProfileIcon user={info.provider} color={"blue"} />
        </Avatar>
      ) : (
        <Person />
      ),
    duration: <Schedule />,
    location: <LocationOn />,
    date: <Event />,
    time: <Schedule />,
    timeZone: <Language />,
    link: <OpenInNew />,
  };

  // if info.provider is User
  if (info.provider && typeof info.provider !== 'string') {
    info.provider = `${getName(info.provider)}${['']
      .concat(info.provider?.providerInfo?.qualifications || [])
      .join(', ')}`;
  }

  return (
    <Grid container spacing={spacing} sx={{ maxWidth: '250px' }}>
      {(Object.keys(info) as (keyof typeof info)[]).map((key) => (
        <Grid item xs={12} key={key}>
          <Grid container style={{ color: props.color }}>
            <Grid item xs={iconWidth}>
              <div>{icons[key]}</div>
            </Grid>
            <Grid item xs={12 - iconWidth}>
              <div className="text-details">{info[key]}</div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export interface IAppointmentInfoProps {
  provider: User;
  subject: string;
  info: info;
  iconWidth?: number;
  spacing?: number;
  short?: boolean;
}

export const AppointmentInfo: React.FunctionComponent<IAppointmentInfoProps> = (
  props,
) => {
  const spacing = props.spacing || 1;

  const [showProviderDetails, setShowProviderDetails] =
    useState<boolean>(false);

  return (
    <div className="appointment-info">
      <Grid container spacing={spacing}>
        {/* Provider Info */}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Avatar>
                <ProfileIcon user={props?.provider} color={"blue"}/>
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <div className="text-details">
                <h6 style={{ display: 'inline' }}>
                  {getName(props?.provider)}
                </h6>{' '}
                <h6 style={{ display: 'inline', color: '#CCCCCC' }}>
                  {(props?.provider?.providerInfo?.qualifications || []).join(
                    ', ',
                  )}
                </h6>
              </div>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                edge="end"
                aria-label="info"
                onClick={() => setShowProviderDetails(true)}
                style={{ display: 'inline' }}>
                <InfoOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {/* Appointment Subject */}
        <Grid item xs={12}>
          {props.short ? (
            <h6 style={{ color: '#0879de' }}>{props.subject}</h6>
          ) : (
            <h4>{props.subject}</h4>
          )}
        </Grid>

        {/* Appointment Details */}
        <Grid item xs={12}>
          <AppointmentInfoRows
            info={props.info}
            iconWidth={props.iconWidth}
            spacing={spacing}
          />
        </Grid>
      </Grid>

      {showProviderDetails && (
        <SleepSpecialistInfo
          provider={props?.provider}
          hideScheduleBtn={true}
          onClose={() => setShowProviderDetails(false)}
          onSelectProvider={(p: User) => null}
        />
      )}
    </div>
  );
};

export default AppointmentInfo;
