import React, { useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { FE_URL } from '../../config';
import Modal from '../Modal';
import OuraService from '../../services/ouraService';
import AuthService from '../../services/authService';
import { toast } from 'react-toastify';
import { OuraConfig } from '../../services/ouraService';
import { useHistory } from 'react-router-dom';

export const NoLongerInterested = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();
  const onClickConfirm = () => {
    OuraService.revokeAccess()
      .then(() => {
        AuthService.updateUserInfo({ ouraConnected: false });
        setShowModal(false);
        history.push('/profile?disconnected=true');
        history.go(0);
      })
      .catch((e: Error) => toast.error(e));
  }
  return (<>
    <Grid className="bottom-section" container rowSpacing={2}>
      <Grid item xs={2}>
        <img className="o-icon" src="/assets/oura-o-white.png" alt="oura-o-logo-white" />
      </Grid>
      <Grid item xs={10}>
        <Grid className="left-content" container rowSpacing={1} style={{ marginLeft: 6}}>
          <Grid item xs={12}>
            <h6>No Longer Interested?</h6>
          </Grid>
          <Grid item xs={12}>
            <p>Click here to remove Ognomy access to your Oura Ring data. If you change your mind, you can reconnect at any time.</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <button className="btn-empty" onClick={() => setShowModal(true)}>
          Stop Sharing
        </button>
      </Grid>
    </Grid>
    <Modal 
      open={showModal} 
      onClose={() => setShowModal(false)} 
      buttonActions={[
        { text: "Cancel", style: "cancel-small", action: () => setShowModal(false) },
        { text: "Confirm", style: "confirm-small", action: onClickConfirm },
      ]}>
      Are you sure you want to disconnect your Oura account from Ognomy? You can always reconnect your account later.
    </Modal>
  </>);
}

export const ShareMyData = () => {
  return (
    <Grid className="bottom-section" container rowSpacing={2}>
      <Grid item xs={2}>
        <img className="o-icon" src="/assets/oura-o-white.png" alt="oura-o-logo-white" />
      </Grid>
      <Grid item xs={10}>
        <Grid className="left-content" container rowSpacing={1} style={{ marginLeft: 6}}>
          <Grid item xs={12}>
            <h6>Have an Oura Ring?</h6>
          </Grid>
          <Grid item xs={12}>
            <p>Click here to sign in and share your Oura data with your Ognomy Sleep Specialist</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ConnectButton text="Share My Data" />
      </Grid>
    </Grid>
  );
}

interface ConnectButtonProps { text: string; }
export const ConnectButton = ({ text }: ConnectButtonProps) => {
  const redirect_uri = encodeURI(`${FE_URL}/profile`);
  const scope = OuraConfig.DEFAULT_SCOPE.join("%20");
  const link = `${OuraConfig.CLOUD_URL}/oauth/authorize?response_type=code&client_id=${OuraConfig.CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${scope}`
  return (
    <a href={link}>
      <button className="btn-empty">
        {text}
      </button>
    </a>
  )
}

export const LearnMoreButton = () => {
  return (
    <a href="https://ognomy.com/oura/" target="_blank" rel="noopener noreferrer">
      <button className="btn-filled">
        Learn More
      </button>
    </a>
  );
}

export const OgnomyOuraLogo = () => <img className="ognomy-oura-icon" src="/assets/ognomy-oura-white.png" alt="ognomy-oura" />

export const LoadingElement = () => {
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <CircularProgress color="inherit" />
      </Grid>
      <Grid item xs={12}>
        <p>Loading...</p>
      </Grid>
    </Grid>
  )
}