import React from 'react';

const CompletedRegistration = (props) => {
  return (
    <div className="onboarding-form">
      <div className="header-info complete-view">
        <div className="description">
          <h3>You're All Set</h3>
          <p>
            You can view your information in your profile. The responses to your
            questionnaire have been sent to our team. If any additional
            information is required, we will contact you, otherwise you are all
            set!
          </p>
        </div>
        <a
          href="/patientDashboardPage"
          type="submit"
          className="submit-btn mt-4">
          Enter Dashboard
        </a>
      </div>
    </div>
  );
};

export default CompletedRegistration;
