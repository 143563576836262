import { formatPhoneNumber, getName } from '../../services/utils';
import {
  PlexFont, headerUrl, FooterUrl, PoppinNormal,
} from './pdf';

import { jsPDF } from "jspdf";
import _ from "lodash";


const PDF_MARGIN = 8;

const PATITENT_CODE_REPOSTS = {
  'drowsyDay': 'feel very drowsy or sleepy at any point during the day',
  'hallucinationDreams': 'have strange hallucinations-like dreams while napping',
  'sleepAttacks': 'have “sleep attacks” during the day (i.e., periods when you cannot prevent yourself from falling asleep)',
  'cataplecticAttacks': 'have “cataplectic attacks” (i.e., episodes of weakness in the legs and/or collapse that occur with emotions like laughter or crying)',
  'sleepParalysis': 'have episodes of sleep paralysis (i.e., being awake in bed not able to move or speak)',
  'legRestlessness': 'wake up with “pins and needles” or restlessness in the legs',
}

/**
 * order pdf generator
 */
export class PaperWorkPdfGenerator {

  // initial - user information
  // history - user medical history
  constructor(initial, history) {
    // eslint-disable-next-line new-cap
    this.doc = new jsPDF('p', 'mm', 'letter');
    this.initial = _.cloneDeep(initial);
    this.history = _.cloneDeep(history);
    this.doc.addFileToVFS('Poppins-SemiBold.ttf', PlexFont);
    this.doc.addFont('Poppins-SemiBold.ttf', 'PoppinsBold', 'normal');
    this.doc.addFileToVFS('Poppins-Regular.ttf', PoppinNormal);
    this.doc.addFont('Poppins-Regular.ttf', 'PoppinsNormal', 'normal');
    this.y = 12;
  }

  /**
   * get value from data
   * @param path the path
   * @returns {*}
   */
  get(path) {
    return _.get(this.formData, path);
  }

  /**
   * draw pdf
   */
  draw() {
    this.drawHeader();
    this.y += PDF_MARGIN;

    this.drawTitle('New Patient Forms');
    this.drawRow('Patient Name:', getName(this.initial));
    this.drawRow('DOB:', this.initial.dateOfBirth);
    this.drawRow('Gender:', this.initial.gender);
    this.drawRow('State:', this.initial.state);
    this.drawRow('Email:', this.initial.email);
    this.drawRow('Phone Number:', formatPhoneNumber(_.get(this.initial, 'phone')));
    this.drawFooter();
    this.y += PDF_MARGIN;

    this.drawRow('Objective:');
    const heightString = this.history?.reviewOfSystems.height.toString();
    const height = heightString.split('.')

    this.drawValue(`${this.history?.reviewOfSystems.unit === 'Imperial' ? `Height: ${height[0]}'${height[1] ? height[1] : 0}”` : `Height: ${this.history?.reviewOfSystems.height} cm`}`);
    this.drawValue(`${this.history?.reviewOfSystems.unit === 'Imperial' ? `Weight: ${this.history?.reviewOfSystems.weight} lbs` : `Weight: ${this.history?.reviewOfSystems.weight} kg`}`);

    const height2 = heightString.split('.');
    const heightVal = height2.length === 1 ? parseFloat(height2[0]) * 12  : ( ( parseFloat(height2[0]) * 12 ) + parseFloat(height2[1]) );
    const weightVal = this.history.reviewOfSystems.weight;
    const bmiVal = this.history.reviewOfSystems.unit === 'Imperial' ? ( ( weightVal / (Math.pow(heightVal,2)) ) * 703 ) : this.history.reviewOfSystems.unit === 'Metric' ? ( weightVal / (Math.pow((this.history.reviewOfSystems.height/100),2)) ) : "Failed to Calculate";
    const content = 'BMI: ' + bmiVal.toFixed(1).toString();
    this.drawValue(`${content}`);


    this.y += 5
    this.drawRow('Medications:');
    this.drawValue(`${this.history.medications.activeMedications.length? `The patient is currently taking the following medications:` : `The patient is not currently taking any medications`}`);
    this.history.medications.activeMedications.forEach(element => {
      this.drawValue(`- ${element.medicationName}: ${element.dosage} ${element.frequency}`);
    });

    this.y += 5
    this.drawRow('Allergies:');
    this.drawValue(`${this.history.medications.medicationAllergies.length ? `The patient is allergic to the following medications:` : `NKDA`}`);
    this.history.medications.medicationAllergies.forEach(element => {
      this.drawValue(`- ${element}`);
    });

    this.y += 5
    this.drawRow('Past Medical History:');
    this.drawValue(`${this.history.pastMedicalHistory?.hasPaceMaker ? `The patient has a pacemaker` : `The patient does not have a pacemaker`}`);
    this.y += 3
    
    const hasMedicalProblems =   this.history.pastMedicalHistory?.genericMedicalProblems.length;
    if (hasMedicalProblems === 0) {
      this.drawValue('The patient reports having no preexisting medical conditions');
    } else {
      this.drawValue('The patient reports having the following conditions:');
    }

    this.history?.pastMedicalHistory?.genericMedicalProblems.forEach(element => {
      this.drawValue(`- ${element.name.trim()}${element.type ? ` - ${element.type.trim()}` : ``} ${element.description ? `: '${element.description.trim()}'` : ``}`);
    });

    this.y += 3
    const hasMedicalCare = this.history.pastMedicalHistory?.specificMedicalProblems.length;
    if (hasMedicalCare === 0) {
      this.drawValue('The patient reports receiving no care for any past medical conditions');
    } else {
      this.drawValue('The patient reports having care for the following:');
    }

    this.history.pastMedicalHistory?.specificMedicalProblems.forEach(element => {
      this.drawValue(`- ${element.name.trim()}${element.type ? ` - ${element.type.trim()}` : ``} ${element.description? `: '${element.description.trim()}'` : ``}`);
    });

    this.y += 5
    this.drawRow('Social History:');
    this.drawValue(`Marital Status: ${this.history.socialHistory.maritalStatus}`);
    let socialStatus =  this.history.socialHistory.socialStatuses;
    this.drawValue(`Currently Employed: ${socialStatus.employed.response ? `Yes${ socialStatus.employed.description ? ` - ${socialStatus.employed.description}` : ``}` : `No`}`);
    this.drawValue(`Exercise Regularly: ${socialStatus.exercise.response ? `Yes${socialStatus.exercise.description ? ` - ${socialStatus.exercise.description}` : ``}` : `No`}`);
    this.drawValue(`Consume Caffeinated Beverages: ${socialStatus.caffeine.response ? `Yes${socialStatus.caffeine.description ? ` - ${socialStatus.caffeine.description}` : ``}` : `No`}`);
    this.drawValue(`Consume Alcoholic Beverages: ${socialStatus.alcohol.response ? `Yes${socialStatus.alcohol.description ? ` - ${socialStatus.alcohol.description}` : ``}` : `No`}`);
    this.drawValue(`Consume Tobacco Products: ${socialStatus.tobacco.response ? `Yes${socialStatus.tobacco.description ? ` - ${socialStatus.tobacco.description}` : ``}` : `No`}`);
    this.drawValue(`Substance Abuse History: ${socialStatus.substanceAbuseTreatment.response ? `Yes${socialStatus.substanceAbuseTreatment.description ? ` - ${socialStatus.substanceAbuseTreatment.description}` : ``}` : `No`}`);
    
    this.y += 5
    this.drawRow('Family History:');
    this.drawValue(`${this.history.familyHistory?.badSleep.response ? 'The patient reports having family members with sleep disorders.' : 'The patient reports having no family members with sleep disorders.' }`);
    this.drawValue(`Patient Description: ${this.history.familyHistory?.badSleep.description ? this.history.familyHistory.badSleep.description : `No description given`}`);
    this.history.familyHistory?.familyMedicalConditions?.forEach(element => {
      this.y += 3
      this.drawValue(`${element.relationship}`);
      this.drawValue(`- Deceased: ${element.unknown ? `Unknown` : element.deceased ? `Yes` : `No`}`);
      this.drawValue(`- Age ${element.deceased? 'when deceased' : ''}: ${element.age}`);
      if (element.description) {
        this.drawValue(`- Description: ${element.description}`);
      }
    });
    
    this.y += 5
    this.drawRow('Sleep Medicine History:');
    this.drawValue(`Past Sleep Study: ${this.history.sleepMedicineHistory?.pastSleepStudy?.response ? `Yes${this.history.sleepMedicineHistory?.pastSleepStudy?.description ? ` - ${this.history.sleepMedicineHistory?.pastSleepStudy?.description}` : ``}` : `No`}`);
    this.drawValue(`Currently On CPAP/BiPAP:  ${this.history.sleepMedicineHistory.onPAP ? `Yes ${this.history.sleepMedicineHistory.papVendor ? `(Carrier: ${this.history.sleepMedicineHistory.papVendor})` : ``}` : `No`}`);
    this.drawValue(`Ever had NEAR ACCIDENT or ACCIDENT due to excessive drowsiness: ${this.history.sleepMedicineHistory?.nearAccidentHistoryDescription?.response ? `Yes${this.history.sleepMedicineHistory.nearAccidentHistoryDescription?.description ? ` - ${this.history.sleepMedicineHistory.nearAccidentHistoryDescription?.description}` : ``}` : `No`}`);
    const sleepQuestionare = this.history.sleepMedicineHistory.sleepQuestionare;
    this.drawValue(`Average hours of sleep per night: ${sleepQuestionare ? sleepQuestionare[sleepQuestionare.length-1].averageHoursSlept : ''} hours`);
    this.drawValue(`Average time it takes to fall asleep once in bed: ${sleepQuestionare ? sleepQuestionare[sleepQuestionare.length-1].averageMinToDoze : ''} minutes`);
    
    this.y += 3
    this.drawValue('Patient reports the following:');
    sleepQuestionare && Object.keys(sleepQuestionare[sleepQuestionare.length-1].personalSleepHistory).forEach(key => {
      this.drawValue(`- ${PATITENT_CODE_REPOSTS[key]}`);
    });

    this.y += 5
    this.drawRow('Epworth Sleep Score:');
    const epworthScale = this.history.sleepMedicineHistory.epworthSleepinessScale;
    this.drawValue(`Sitting & reading: ${epworthScale[epworthScale.length-1].reading}`);
    this.drawValue(`Watching TV: ${epworthScale[epworthScale.length-1].watchingTV}`);
    this.drawValue(`Sitting inactive in a public place: ${epworthScale[epworthScale.length-1].inactivePublic}`)
    this.drawValue(`Sitting for an hour as a passenger in a car: ${epworthScale[epworthScale.length-1].passenger}`);
    this.drawValue(`Lying down in the afternoon to rest: ${epworthScale[epworthScale.length-1].afternoonLyeDown}`);
    this.drawValue(`Sitting & talking to another person: ${epworthScale[epworthScale.length-1].talking}`);
    this.drawValue(`Sitting quietly after lunch (no alcohol): ${epworthScale[epworthScale.length-1].afterLunch}`);
    this.drawValue(`Sitting in a car stopped in traffic: ${epworthScale[epworthScale.length-1].inTraffic}`);
    this.drawValue(`SCORE: ${epworthScale[epworthScale.length-1].score}`);
    this.doc.line(PDF_MARGIN, this.y-5, PDF_MARGIN + 13, this.y-5)


    this.y += 5;
    this.drawRow('Provider History:');
    if (this.history.providerHistory.referringProvider !== '') {
      this.drawValue(`Referring Provider: ${this.history.providerHistory.referringProvider}`);
    }
    if (this.history.providerHistory.providers && this.history.providerHistory.providers.length === 0) {
      this.drawValue(`The patient has not provided any provider history`);
    }
    this.y += 3;
    if (this.history.providerHistory.providers && this.history.providerHistory.providers.length) {
      this.history.providerHistory.providers.forEach(element => {
        this.drawValue(`${element.providerName}`);
        this.drawValue(`- ${element.providerCredentials}`);
        this.drawValue(`- ${element.practicingCity}`);
        this.y += 3;
      });
    }

    this.y += 5;
    this.drawRow('Accident Related:');
    if (this.history.reviewOfSystems.accidentRelated && this.history.reviewOfSystems.accidentRelated.accident === "No") {
      this.drawValue(`This visit is not related to an automobile or work accident`);
    }

    if (this.history.reviewOfSystems.accidentRelated && this.history.reviewOfSystems.accidentRelated.accident === "Work Accident") {
      this.drawValue(`This visit is related to a work accident`);
      this.drawValue(`Date of Accident: ${this.history.reviewOfSystems.accidentRelated.date}`);
      this.drawValue(`Insurance Carrier: ${this.history.reviewOfSystems.accidentRelated.accidentInsurance ? this.history.reviewOfSystems.accidentRelated.accidentInsurance : 'None provided'}`);
      this.drawValue(`Employer Name: ${this.history.reviewOfSystems.accidentRelated.employer ? this.history.reviewOfSystems.accidentRelated.employer : 'None provided' }`);
    }

    if (this.history.reviewOfSystems.accidentRelated && this.history.reviewOfSystems.accidentRelated.accident === "Automobile Accident") {
      this.drawValue(`This visit is related to a work accident`);
      this.drawValue(`Date of Accident: ${this.history.reviewOfSystems.accidentRelated.date}`);
      this.drawValue(`No-Fault Carrier: ${this.history.reviewOfSystems.accidentRelated.accidentInsurance ? this.history.reviewOfSystems.accidentRelated.accidentInsurance: 'None Provided'}`);
    }

    this.drawFooter();

  }


  /**
   * draw title
   * @param heading an optional heading
   */
  drawTitle(heading = 'APAP 7 Day Technician Summary Report') {
    this.doc.setFont('PoppinsBold');
    this.doc.setFontSize(16);
    this.doc.text(heading, PDF_MARGIN, this.y);
    this.y += 8;
  }

  /**
   * draw header
   */
  drawHeader() {
    this.doc.addImage(headerUrl, 0, 0, 216, 30, '', 'NONE');
    this.y += 25;
  }

  /**
   * draw title
   */
  drawFooter() {
    this.doc.addImage(FooterUrl, 63, 105, 190, 180, '', 'NONE');
    this.y += 3;
  }


  /**
   * draw row
   * @param name the name
   * @param value the value
   * @param suffix the suffix
   */
  drawRow(name, value = '', suffix = '') {
    const getValueString = () => `${value.toString()} ${suffix}`;
    this.doc.setFont('PoppinsBold');
    this.doc.setFontSize(11);
    this.doc.text(name, PDF_MARGIN, this.y);
    this.doc.setFont('PoppinsNormal');
    const splitTitle = this.doc.splitTextToSize(getValueString(), 80);
    for (let i = 0; i < splitTitle.length; i += 1) {
      
      this.doc.text(splitTitle[i], 90, this.y);
      this.y += 6;
    }
  }

  /**
 * draw Value
 * @param name the name
 * @param value the value
 * @param suffix the suffix
 */
  drawValue(value = '', suffix = '') {
    const getValueString = () => `${value.toString()} ${suffix}`;
    this.doc.setFont('PoppinsNormal');

    const splitTitle = this.doc.splitTextToSize(getValueString(), 200);
    for (let i = 0; i < splitTitle.length; i += 1) {
      if (this.y > 250) {
        this.y = 20;
        this.doc.addPage();
        this.drawHeader();
        this.drawFooter();
      }
      this.doc.text(splitTitle[i], PDF_MARGIN, this.y);
      this.y += 6;
    }
    // this.doc.text(getValueString(), PDF_MARGIN, this.y);
    // this.y += 6;
  }
}
