import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/Formik/FormikControl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { loadProviders } from '../../store/actions/appointments';
import { useHistory } from 'react-router-dom';
import UserService from '../../services/userService';
import AuthService from '../../services/authService';
import AppointmentService from '../../services/appointmentService';
import {
  FORMAT_HOUR_FULL,
  FORMAT_MONTH_SHORT,
  APPOINTMENT_TITLE,
} from '../../config';
import { getName } from '../../services/utils';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import AppointmentScheduler from '../../components/RegistrationProgressComponents/FormScheduleAppointmentSelectDate';
import SelectSleepSpecialist from '../../components/RegistrationProgressComponents/SelectSleepSpecialist';
import SleepSpecialistInfo from '../../components/RegistrationProgressComponents/SleepSpecialistInfo';
import animationData from './confetti.json';
import moment from 'moment';
import './styles.scss';
import {
  borderClass,
  scrollToError,
  textAreaAutoGrow,
} from '../../components/RegistrationComponents/utils';
import dayjs from 'dayjs';

const REASON_OPTION = [
  { key: 'Select Reason', value: '', disabled: true },
  { key: 'Credit Card Issues', value: 'Credit Card Issues' },
  { key: 'Cost is too high', value: 'Cost is too high' },
  { key: 'Found Alternative Care', value: 'Found Alternative Care' },
  { key: 'No Longer Interested', value: 'No Longer Interested' },
  { key: 'Scheduling Conflict', value: 'Scheduling Conflict' },
  {
    key: 'Want to hold off until later date',
    value: 'Want to hold off until later date',
  },
  { key: 'Other', value: 'Other' },
];

const AppointmentConfirmPage = (props) => {
  const [showConfirm, setConfirm] = useState(false);
  const [nearestApt, setNearestApt] = useState();
  const [showBody, setShowBody] = useState(false);
  const [notAppt, setNoAppt] = useState(false);
  const [showModal, setModal] = useState(false);
  const [showReason, setSpecificReason] = useState(false);
  const [showProviderPicker, setShowProviderPicker] = useState(false);
  const [provider, setProvider] = useState(null);
  const [confirmCancelled, setConfirmCancelled] = useState(false);
  const [availData, setAvailData] = useState(null);
  const [providerHasSlots, setProviderHasSlots] = useState(null);
  const [slot, setSlot] = useState(null);
  const [type, setType] = useState('update');
  const [detailsProvider, setDetailsProvider] = useState(null);
  const [timeZone, setTimeZone] = useState('America/New_York');
  const [monthView, setMonthView] = useState(dayjs());
  const { providers } = useSelector((r) => r.appointments);
  const { user } = AuthService.getAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const validationSchema = Yup.object({
    reason: Yup.string(),
    preason: Yup.string(),
  });

  const initialValuesCancel = {
    reason: '',
    preason: '',
  };

  const validationSchemareason = Yup.object({
    reason: Yup.string().required('reason is invalid'),
    preason: showReason ? Yup.string().required() : Yup.string(),
  });

  useEffect(() => {
    if (!nearestApt) {
      getAppointment();
    }
  }, [nearestApt]);

  useEffect(() => {
    let redirectUrl = '/followUp?step=details&view=2';
    if (showConfirm) {
      setTimeout(() => {
        if (nearestApt?.followUpProgress === '' && nearestApt?.medicalId === '') {
          redirectUrl = nearestApt?.followUpProgress || '/followUp?step=details&view=2';
          // window.location = "https://form.jotform.com/210105987675058"
          return;
        } else if (
          nearestApt?.followUpProgress === 'complete' &&
          nearestApt?.medicalId !== ''
        ) {
          redirectUrl = '/patientDashboardPage';
        } else {
          redirectUrl = nearestApt?.followUpProgress || '/followUp?step=details&view=2';
          // window.location = "https://form.jotform.com/210105987675058"
          return;
        }
        history.push(redirectUrl);
      }, 5000);
    }
  }, [showConfirm]);

  const getAppointment = () => {
    UserService.getAppointments({ types: ['upcoming', 'ongoing'] })
      .then((res) => {
        if (res.data.length > 0) {
          setNoAppt(false);
          setNearestApt(getNearestAppointment(res.data));
        } else {
          setNoAppt(true);
        }
      })
      .catch((err) => toast.error(err));
  };

  const onCancel = (value) => {
    const reason = value.reason;
    AppointmentService.deleteAppointment(nearestApt.id, {
      reason,
      cancelDescription: value.preason,
    })
      .then(() => {
        //history.push('/patientDashboardPage')
        setConfirmCancelled(true);
      })
      .catch((err) => toast.error(err.message));
  };

  useEffect(() => {
    dispatch(loadProviders({ withProviderId: true }));
    getRecommendedPhysician(null);
  }, []);

  const getPhysicianSchedule = async (params) => {
    await UserService.getSchedule(params)
      .then((res) => {
        if (!_.isEmpty(res.data)) {
          setProvider(res.data.provider);
          setAvailData(res.data);
          setProviderHasSlots(!_.isEmpty(res.data?.availableSlots));
        } else setProviderHasSlots(false);
      })
      .catch((e) => console.log(e));
  };

  const handleReason = (e, value) => {
    setSpecificReason(true);
  };

  const cancelModal = () => {
    return (
      <div className="cancel-method-modal">
        <div className="cancel-method-content">
          <h3 className="title">Appointment Cancellation</h3>
          {!confirmCancelled && (
            <p className="header">
              We’re sorry to see you go. Please provide a reason for cancelling
              your appointment.
            </p>
          )}
          {confirmCancelled && (
            <p className="header">
              Your appointment on{' '}
              {moment(nearestApt.startTime).format(FORMAT_MONTH_SHORT)}{' '}
              {`${moment(nearestApt.startTime).format('Do')} at ${moment(
                nearestApt.startTime,
              ).format(FORMAT_HOUR_FULL)}`}{' '}
              has been cancelled.
            </p>
          )}
          {!confirmCancelled && (
            <Formik
              initialValues={initialValuesCancel}
              validationSchema={validationSchemareason}
              onSubmit={(values, props) => {
                // console.log
                onCancel(values);
              }}>
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <div className="modal-container">
                    <FormikControl
                      className={borderClass('reason', formik)}
                      control="select"
                      expanding={true}
                      handlers={formik}
                      onClick={(v) => handleReason(v, formik.values)}
                      options={REASON_OPTION}
                      name="reason"
                      showErrorMsg={false}
                    />
                    {showReason && (
                      <FormikControl
                        className={borderClass('preason', formik)}
                        control="textarea"
                        name={`preason`}
                        style={{ resize: 'none' }}
                        maxLength={100}
                        placeholder="Describe"
                        showErrorMsg={false}
                        onChange={(e) =>
                          textAreaAutoGrow(e, formik.setFieldValue)
                        }
                      />
                    )}
                  </div>
                  {!confirmCancelled && (
                    <div className="submit-content">
                      <button
                        type="submit"
                        className="submit-btn"
                        onClick={() => scrollToError()}>
                        Confirm cancellation
                      </button>
                      <button
                        className="submit-btn-white"
                        onClick={() => {
                          setModal(false);
                          setSpecificReason(false);
                        }}>
                        Nevermind, don't cancel
                      </button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          )}
          {confirmCancelled && (
            <div className="submit-content">
              <button
                className="submit-btn new-appt"
                onClick={() => {
                  setModal(false);
                  setType('create');
                  setShowBody(true);
                }}>
                Schedule new appointment
              </button>
              <button
                className="submit-btn-white"
                onClick={() => history.push('/patientDashboardPage')}>
                Go to dashboard
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  const getRecommendedPhysician = async (newProvider) => {
    setAvailData(null);
    setProviderHasSlots(null);
    const providerId = props?.provider?.id || (newProvider && newProvider.id);
    const body = {
      // type: "pseudo",
      // roles: ["Patient"],
      // state: "NY",
      // startTime: moment().startOf("month").format(),
      // endTime: moment().endOf("month").format(),
      // isNewPatient: true,
      // timeZone: "America/New_York",
      // providerId: "",
      // callee: "web",

      patientId: _.get(AuthService.getAuth(), 'user.id'),
      providerId,
      state: _.get(AuthService.getAuth(), 'user.state'),
    };

    await getPhysicianSchedule(body);
  };

  const handleSubmit = (s) => {
    const body = {
      description: APPOINTMENT_TITLE,
      providerId: provider.id,
      patientId: user.id,
      startTime: s.start,
      endTime: s.end,
      on: dayjs(s.start).format('MMM DD, YYYY'),
      at: dayjs(s.start).format('hh:mm a'),
    };
    if (nearestApt.id) {
      UserService.updateAppointment(nearestApt.id, body);
      // .then(showSuccess('Appointment has been updated successfully!'))
      // .catch(e => showApiError(e));
    } else {
      delete body.providerId;
      delete body.patientId;
      body.inviteeEmail = provider.email;
      UserService.createAppointment(body);
      // .then(showSuccess('Appointment has been created successfully!'))
      // .catch(e => showApiError(e));
    }
  };

  // const updateAppointment = (s) => {
  //   let redirectUrl = '';
  //   let body = {
  //     description: APPOINTMENT_TITLE,
  //     providerId: provider.id,
  //     patientId: user.id,
  //     startTime: s.start,
  //     endTime: s.end,
  //     on: moment(s.start).format('MMM DD, YYYY'),
  //     at: moment(s.start).format('hh:mm a'),
  //   };
  //   if (type !== 'create') {
  //     UserService.updateAppointment(nearestApt.id, body)
  //     .then(rsp => {
  //       if (nearestApt?.followUpProgress === '' && nearestApt?.medicalId === '') {
  //         redirectUrl = nearestApt?.followUpProgress || '/followUp?step=details&view=2';
  //         return history.push("https://form.jotform.com/210105987675058")

  //       } else if (nearestApt?.followUpProgress === 'complete' && nearestApt?.medicalId != '') {
  //         redirectUrl =  '/patientDashboardPage';
  //       } else {
  //         redirectUrl = nearestApt?.followUpProgress;
  //       }
  //       history.push(redirectUrl);
  //     }).catch(e => {
  //       toast.error(e.message)
  //     })
  //   } else {
  //       delete body.providerId
  //       delete body.patientId
  //       body.inviteeEmail = provider.email
  //       UserService.createAppointment(body)
  //         .then(rsp => {
  //         }).catch(e => {
  //           toast.error(e.message)
  //       })
  //   }
  // }

  const confirmAppointment = () => {
    const body = {
      participantConfirm: true,
    };
    UserService.updateAppointment(nearestApt.id, body)
      .then(() => {
        setConfirm(true);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const getNearestAppointment = (aptList) => {
    let nearestIndex = 0;
    aptList.forEach((time, i) => {
      if (moment(time).isBefore(time[Number(i) === 0 ? i : i - 1])) {
        nearestIndex = i;
      }
    });

    return aptList[nearestIndex];
  };

  const onFetchData = (changes) => {
    if (changes.timeZone) setTimeZone(changes.timeZone);
    if (changes.startTime) setMonthView(dayjs(changes.startTime));
    getPhysicianSchedule({
      patientId: _.get(AuthService.getAuth(), 'user.id'),
      provider,
      maxFollowupTime: props.defaultMaxFollowupTime,
      state: _.get(AuthService.getAuth(), 'user.state'),
      ...changes,
    });
  };

  const onConfirm = (s) => {
    handleSubmit(s);
    props.onNextStep();
    history.push('/patientDashboardPage');
  };

  return (
    <React.Fragment>
      {showModal && cancelModal()}
      {!showBody && (
        <div className="appointment-form">
          {nearestApt && (
            <div className="header-info">
              <div className="description">
                <h3>Appointment Confirmation</h3>
                <p>Please confirm your upcoming virtual doctor's visit.</p>
              </div>
            </div>
          )}
          {notAppt && (
            <div className="header-info">
              <div className="description">
                <h3>Appointment Confirmation</h3>
                <p className="no-upcoming">No Upcoming Appointment.</p>
              </div>
            </div>
          )}
          <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={(values, props) => {
              // onSubmit(values, props.setSubmitting);
            }}>
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                {!showConfirm && nearestApt && (
                  <div className="form-container-appointment">
                    <img
                      src="/assets/icon-calendar.svg"
                      alt="icon"
                      className="icon-cal"
                    />
                    <h5>
                      {moment(nearestApt.startTime).format('dddd')},{' '}
                      {moment(nearestApt.startTime).format(FORMAT_MONTH_SHORT)}{' '}
                      {`${moment(nearestApt.startTime).format('Do')} - ${moment(
                        nearestApt.startTime,
                      ).format(FORMAT_HOUR_FULL)}`}
                    </h5>
                    <p className="provider-name">
                      {getName(nearestApt.provider)} (
                      {(
                        nearestApt.provider.providerInfo?.qualifications || []
                      ).join(', ')}
                      )
                    </p>
                    <div className="provider-detail">
                      Location: Ognomy Virtual Visit
                      <img
                        src="/assets/Info.svg"
                        className="info-svg"
                        alt="img"
                      />
                    </div>
                    <button
                      type="submit"
                      className="submit-btn"
                      onClick={() => confirmAppointment()}>
                      Confirm
                    </button>
                  </div>
                )}
                {showConfirm && (
                  <div className="form-container-appointment-confirmed">
                    <img
                      src="/assets/icon-calendar-confirm.svg"
                      alt="icon"
                      className="icon-cal"
                    />
                    <h5>Appointment Confirmed</h5>
                    <p className="sub-msg">
                      You are one step closer to better sleep!
                    </p>
                    <div className="appointment-detail">
                      <p>
                        {moment(nearestApt.startTime).format('dddd')},{' '}
                        {moment(nearestApt.startTime).format(
                          FORMAT_MONTH_SHORT,
                        )}{' '}
                        {`${moment(nearestApt.startTime).format(
                          'Do',
                        )} - ${moment(nearestApt.startTime).format(
                          FORMAT_HOUR_FULL,
                        )}`}
                      </p>
                      <p className="provider-name">
                        {getName(nearestApt.provider)} (
                        {(
                          nearestApt.provider.providerInfo?.qualifications || []
                        ).join(', ')}
                        )
                      </p>
                      <div className="provider-detail">
                        Location: Ognomy Virtual Visit
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
          {!showConfirm && nearestApt && (
            <div className="bottom-controller">
              <p>Won't be able to make it?</p>
              <button
                type="submit"
                className="submit-btn"
                style={{ marginTop: '1rem' }}
                onClick={() => setShowBody(true)}>
                Reschedule Appointment
              </button>
              <p className="or"></p>
              <button
                type="submit"
                className="submit-btn"
                onClick={() => setModal(true)}>
                Cancel Appointment
              </button>
            </div>
          )}
        </div>
      )}
      {showConfirm && (
        <div className="confetti-animation">
          <Lottie options={defaultOptions} height={'80%'} width={'80%'} />
        </div>
      )}
      {showBody && (
        <div className="edit-schedule-appointment-page-mains">
          {showBody && provider && !showProviderPicker && availData && (
            <div>
              <div className="top-started flex">
                <div className="blue-back">
                  <div
                    className="btn icons btn-back"
                    onClick={() => setShowBody(false)}></div>
                </div>
              </div>
              <AppointmentScheduler
                data={availData}
                provider={provider}
                providers={[]}
                // existingApt={nearestApt}
                // onClickProvider={() => setDetailsProvider(provider)}
                fetchData={(changes) => onFetchData(changes)}
                title={
                  _.get(AuthService.getAuth(), 'user.isNewPatient', true) 
                    ? "Sleep Medicine Consultation" 
                    : "Follow-Up Visit"
                }
                timeZone={timeZone}
                duration={
                  _.get(AuthService.getAuth(), 'user.isNewPatient', true) 
                    ? 40 
                    : 20
                } // 40 iff isNewPatient === true or undefined
                onConfirm={(slot) => onConfirm(slot)}
                showProviderToggle={_.get(
                  AuthService.getAuth(),
                  'user.isNewPatient',
                  true,
                )}
              />
            </div>
          )}

          {showProviderPicker && providers.data && (
            <SelectSleepSpecialist
              providers={providers.data}
              onClickProvider={(p) => setDetailsProvider(p)}
              onBack={() => setShowProviderPicker(false)}
              onSelectProvider={(p) => {
                setShowProviderPicker(false);
              }}
              provider={provider}
            />
          )}

          {detailsProvider && (
            <SleepSpecialistInfo
              provider={detailsProvider}
              hideScheduleBtn={true}
              onClose={() => setDetailsProvider(undefined)}
              onSelectProvider={(p) => {
                setProvider(p);
                setShowProviderPicker(false);
                setDetailsProvider(undefined);
              }}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AppointmentConfirmPage;