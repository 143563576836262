import React from 'react';

const NoProviders = (props) => {
  return (
    <div className="onboarding-form">
      <div className="header-info complete-view">
        <div className="description pb-4">
          <h3>Coming Soon to Your State!</h3>
          <p>
            It appears we don't have a sleep specialist licensed in your state,
            which means <b>we can't schedule an appointment for you today.</b>
          </p>

          <p>
            While we don't have one in our network right now, we are adding
            states quickly.{' '}
            <b>We recommend you complete your registration today</b> and we will
            notify you by email once we are live in your state.
          </p>

          <p>
            If you need to see a sleep specialist sooner, please contact our
            office at{' '}
            <a href="tel:1-877-664-6669" class="blue-link">
              1-877-664-6669
            </a>{' '}
            and we will help you find one available near you.
          </p>
        </div>
        <div
          className="submit"
          style={{ position: 'relative', paddingTop: 15 }}>
          <a
            href="/patientDashboardPage"
            type="submit"
            className="submit-btn">
            Continue
          </a>
        </div>
      </div>
    </div>
  );
};

export default NoProviders;
