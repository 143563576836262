import React, {createRef, useState} from 'react';
import './styles.scss';
import {ordinal, showApiError} from "../../../services/utils";
import UserService from "../../../services/userService";
import {Order} from "../../../services/dto/Common";
import {FORMAT_MONTH_SHORT, FORMAT_YEAR_MONTH} from "../../../config";
import moment from "moment";


export interface IScheduleHSTProps {
  order?: Order;
  onDone: (hasNext: boolean) => void;
}

export const ScheduleHST: React.FunctionComponent<IScheduleHSTProps> = (props) => {
  const [currentMonthIndex, setCurrentMonthIndex] = useState<number>(0);
  const [currentDateIndex, setCurrentDateIndex] = useState<number>(-1);
  const [loading, setLoading] = useState(false);

  // enabled Form
  const enabledForm = () => currentDateIndex >= 0 && !loading;
  const ref = createRef<HTMLUListElement>();
  const clickScroll = (r: any) => {
    if (!!r && r.current) {
      r.current.scrollLeft += 130;
    }
  };
  const newMonths = new Array(12).fill(0).flatMap((v, i) => {
    const date = moment().add(i, 'months');
    const currentMonth = moment().month();
    if (date.month() === currentMonth) {
      const sevenDay = date.add(7, 'days');

      return sevenDay.month() !== currentMonth ? [] : sevenDay;
    }

    return date.format(FORMAT_YEAR_MONTH);
  });

  const curMonth = moment(newMonths[currentMonthIndex], FORMAT_YEAR_MONTH);
  const leadTime = moment().add(7, "days")
  const dates = new Array(curMonth.daysInMonth())
    .fill(0)
    .flatMap((v, i) => {
      const date = moment(curMonth.date(i + 1));
      if(date < leadTime) return []
      return date;
    })
    .filter((i) => i.diff(moment(), 'days') > 0)
    .filter((i) => {
      return i.format('ddd') !== 'Sat' && i.format('ddd') !== 'Sun';
    })
    .map((i) => i.toDate());
  /**
   * on HST next button clicked
   */
  const onShippingDateNext = () => {
    const {order} = props;
    setLoading(true)
    if (!order) {
      props.onDone(Math.random() > 0.5)
      return;
    }
    UserService.updateOrder(order.id, {
      scheduledDate: moment(dates[currentDateIndex]).toISOString(),
    })
      .then(() => {
        UserService.upcomingAppointment(order.patientId)
          .then(rsp => {
            // https://gitlab.com/tc-ognomy/ognomy-mobile/-/issues/130
            // Appointment was Scheduled Outside HST Order Flow
            // Bring User back to Order list
            if (rsp.data && rsp.data.length > 0) {
              props.onDone(false)
            } else {
              // Bring User to Follow Up Schedule page
              props.onDone(true)
            }
          })
          .catch(e => {
            showApiError(e);
            setLoading(false);
          });
      })
      .catch(e => {
        setLoading(false);
        showApiError(e);
      });
  };
  const renderDatePart = () => {
    return (
      <>
        <div className="select-date-area">
          <div className="bold-title" style={{color: "white"}}>
            Select Home Sleep Test Shipping Date
          </div>
          <div className="date-letter">
            <ul>
              {
                newMonths.map((item, index) => (
                  <li key={index}>
                    <div
                      className={`btn date-word ${currentMonthIndex === index ? 'current' : ''}`}
                      onClick={() => {
                        setCurrentMonthIndex(index)
                        setCurrentDateIndex(-1)
                      }}>
                      {moment(item, FORMAT_YEAR_MONTH).format(FORMAT_MONTH_SHORT)}
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="some-date-panel">
            <ul ref={ref}>
              {
                dates.map((dayItem, dayIndex) => (
                  <li key={dayIndex}>
                    <div className={`btn date-panel ${currentDateIndex === dayIndex ? 'current' : ''}`}
                         onClick={(event) => {
                           setCurrentDateIndex(dayIndex)
                         }}>
                      <div className="top-num">
                        <div className="week-txt">
                          {moment(dayItem).format('ddd')}
                        </div>
                        <div className="num">
                          {moment(dayItem).format('DD')}
                          <div className="num-ori">
                            {ordinal(moment(dayItem).format('Do'))}
                          </div>
                        </div>
                      </div>
                      <div className="bottom-area">

                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
            <a href="#javascript" className="icons btn-scroll"
               onClick={(event) => {
                 clickScroll(ref);
                 event.preventDefault()
               }}>
              &nbsp;
            </a>
          </div>
        </div>
      </>
    )
  }
  return (
    <div className="form-schedule-appointment-selectdate">
      {renderDatePart()}
      <div className="hst-tips">
        This is when your Home Sleep Test will be shipped from our office, please allow 2-5 days for arrival.
      </div>

      <div className="bottom-btns flex-grid">
        <div className="left-txt">
        </div>
        <div className="right-btn">
          <div
            className={`btn btn-blue ${enabledForm() ? '' : 'disabled'}`}
            onClick={onShippingDateNext}>
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleHST;
