import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import PropTypes from 'prop-types';

const Textarea = (props) => {
  const { label, name, icon, showErrorMsg, ...rest } = props
  return (
    <div className="formik-control">
      <label htmlFor={name}>{label}</label>
      <Field as='textarea' id={name} name={name} {...rest} />
      {!!showErrorMsg !== false && <ErrorMessage component={TextError} name={name} />}
    </div>
  )
}

/** Can take all other textarea element attributes */
Textarea.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  showErrorMsg: PropTypes.bool,
  value: PropTypes.string,
};

export default Textarea
