import React from 'react';
import './OuraSetup.scss';
import { Grid, Paper } from '@mui/material';
import { ConnectButton, OgnomyOuraLogo } from './helper';

const OuraDisconnected = () => {
  return (
    <Paper className="layer-0">
      <Paper className="layer-1">
        <Grid container spacing={2}>
          <Grid item>
            <OgnomyOuraLogo />
          </Grid>
          <Grid item xs={12}>
            <p><b>Disconnected!</b> Your Oura account has been disconnected from Ognomy, and we will no longer be able to access your data. You can reconnect your account at any time.</p>
          </Grid>
          <Grid item xs={12}>
            <ConnectButton text={"Reconnect"} />
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default OuraDisconnected;
