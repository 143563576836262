import React from 'react';
import Input from './Input';
import Textarea from './Textarea';
import Select from './Select';
import RadioButtons from './RadioButtons';
import DateInput from './DateInput';
import CustomBtnSelect from './CustomBtnSelect';
import PropTypes from 'prop-types'

const FormikControl = (props) => {
  const { control, ...rest } = props;

  switch (control) {
    case 'input':
      return <Input {...rest} />;
    case 'textarea':
      return <Textarea {...rest} />;
    case 'select':
      return <Select {...rest} />;
    case 'radio':
      return <RadioButtons {...rest} />;
    case 'date':
      return <DateInput {...rest} />;
    case 'custom-btn':
      return <CustomBtnSelect {...rest} />;
    default:
      return null;
  }
}

FormikControl.propTypes = {
   /** Type of input element or selector */
  control: PropTypes.string.isRequired
}

export default FormikControl;
