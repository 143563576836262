import axios from 'axios';
import { S_NEW_ZZZ_URI } from '../config';
import { Api } from './api';
import {
  Appointment,
  DischargeSummary,
  Order,
  OrderPage,
  PatientInfo,
  PatientTodoRsp,
  SNewZzzRow,
} from './dto/Common';
import { LoginRsp, PartnerReq, SignupReq, User } from './dto/Security';

export default class UserService {
  /**
   * get state
   */
  static getStates() {
    return Api.get<string[]>('/lookup/states');
  }

  static getSetupIntent(email?: string) {
    return Api.post<string[]>('/payment/createSetupIntent', { email });
  }

  /**
   * Get insurance limited coverage state list
   */
  static getLimitedCoverageStates() {
    return Api.get<string[]>('/lookup/states/limited-coverage');
  }

  /**
   * send email
   * @param email the email address
   * @param type the email type
   */
  static sendVerificationCode(
    email: string,
    type = 'signUp',
    messageType = 'email',
  ) {
    return Api.get('sendVerificationCode', {
      params: { type, email, messageType },
    });
  }

  static async createForm(file: any, body: any) {
    const uploadBody = new FormData();
    uploadBody.append('1', file);
    if (body.attachFile) uploadBody.append('attach', body.attachFile);
    return await this.uploadFile(uploadBody)
      .then(async (rsp0: any) => {
        const key = rsp0.data['1'];
        return await Api.post('/forms', { ...body, key }).catch((e) =>
          console.log(e.message),
        );
      })
      .catch((e) => console.log(e.message));
  }

  /**
   * checks verification code
   * @param params email, code, messageType
   */
  static checkVerificationCode(params: any) {
    return Api.post('verifycode', { params });
  }

  /**
   * checks verification code, execute method, and return data
   * @param params email, code, messageType, returnData (string[] of models)
   */
  static verifyAndExecute(params: any) {
    return Api.post('verifyAndExecute', { params });
  }

  static verifyIdentity(values: any) {
    return Api.post('verifyIdentity', values)
  }

  /**
   * forgot password
   * @param body the request body
   * @returns {Promise}
   */
  static createFeedback(body: any) {
    return Api.post('feedback', body);
  }

  /**
   * forgot password
   * @param body the request body
   * @returns {Promise}
   */
  static forgotPassword(body: SignupReq) {
    return Api.post('forgotPassword', body);
  }

  /**
   * update password
   * @param body the request body
   * @returns {Promise}
   */
  static updatePassword(body: SignupReq) {
    return Api.post('updatePassword', body);
  }

  /**
   * forgot password
   * @param body the request body
   * @returns {Promise}
   */
  static reEnterPassword(body: any) {
    return Api.post('users/reenterpassword', body);
  }

  /**
   * get user forms
   */
  static getForms() {
    return Api.get('forms');
  }

  /**
   * sign up
   * @param body the request body
   * @returns {Promise}
   */
  static signUp(body: SignupReq) {
    return Api.post<LoginRsp>('signup', body);
  }

  /**
   * sign up
   * @param body the request body
   * @returns {Promise}
   */
  static refer(body: SignupReq) {
    return Api.post('refer', body);
  }

  /**
   * enroll partner
   * @param body the request body
   * @returns {Promise}
   */
  static enrollPartner(body: PartnerReq) {
    return Api.post('/partner/enroll', body);
  }

  /**
   * update user insurance
   * @param entity the entity
   * @param userId the user id
   */
  static updateUserPayment(entity: any, userId: string) {
    return Api.put<User>('user/payment/' + userId, entity);
  }

  /**
   * get user insurance
   * @param entity the entity
   * @param userId the user id
   */
  static getUserPayment(userId: string) {
    return Api.get<User>('user/payment/' + userId);
  }

  /**
   * update profile
   * @param entity the entity
   * @param userId the user id
   */
  static updateProfile(entity: any, userId: string) {
    return Api.put<User>('users/' + userId, entity);
  }

  /**
   * update profile
   * @param entity { onboardingProgress }
   * @param userId the user id
   */
  static updateOnboardingProgress(entity: any, userId: string) {
    return Api.put<User>('users/onboardingProgress/' + userId, entity);
  }

  /**
   * upload file
   * @param body the body
   */
  static uploadFile(body: any) {
    return Api.post('/files', body);
  }

  /**
   * Get file list
   */
  static getFileList(query: any) {
    return Api.get(`/files?${query}`);
  }

  /**
   * Get file list
   */
  static deleteFile(query: any) {
    return Api.delete(`/files?${query}`);
  }

  static updateChatbotProgress(userId: string) {
    return Api.put(`users/${userId}/updateChatbotProgress`);
  }

  /**
   * Check whether patient exists
   * @param query
   * @returns Boolean
   */
  static checkPatientExistsByEmail(email: string) {
    return Api.get(`patient/check/${email}`);
  }

  /**
   * get provider
   * @returns {Promise}
   */
  static getProviders = (params: any, withCredentials?: boolean) => {
    if (withCredentials) {
      Api.defaults.withCredentials = true;
    }
    const response = Api.get<User[]>('providers', { params });
    Api.defaults.withCredentials = false;
    return response;
  };

  /**
   * get provider
   * @returns {Promise}
   */
  static getProvidersTmp = (params: any) => {
    return Api.get<User[]>('tmp/providers', { params });
  };

  /**
   * get schedules
   */
  static getSchedule(params: any, withCredentials?: boolean) {
    if (withCredentials) {
      Api.defaults.withCredentials = true;
    }
    const response = Api.get<any>('v2/availability', { params });
    Api.defaults.withCredentials = false;
    return response;
  }

  /**
   * create appointment
   * @param body the request body
   */
  static createAppointment(body: any) {
    return Api.post('appointments', body);
  }

  /**
   * get simple user
   * @param userId the user id
   */
  static getSimpleUser(userId: string) {
    return Api.get<User>(`/simpleUsers/${userId}`);
  }

  /**
   * get physician user
   * @param userId the user id
   */
  static getPhysician(userId: string) {
    return Api.get<User>(`/physician/${userId}`);
  }

  /**
   * get physician user
   * @param userId the user id
   */
  static getPhysicianRoute(params: any) {
    return Api.get<User>(`/doctor`, { params });
  }

  /**
   * get physician patient info
   * @param userId the user id
   */
  static getPhysicianPatientInfo(userId: string) {
    return Api.get<PatientInfo>(`/physician/${userId}/patient-info`);
  }

  /**
   * reset password
   * @param id the user id
   */
  static resetPassword(body: any) {
    return Api.put(`users/resetpassword`, body);
  }

  /**
   * get user progress
   * @param userId the user id
   */
  static getUserProgress(userId: string) {
    return Api.get(`/patient/progress`);
  }

  /**
   * get user progress
   * @param userId the user id
   */
  static getUser(userId: string) {
    return Api.get<User>(`/users/${userId}`);
  }

  /**
   * get appointments(upcoming and past)
   * @returns {Promise}
   */
  static getAppointments(params: any) {
    return Api.get<Appointment[]>('appointments', { params });
  }

  /**
   * update appointment
   * @param id the id
   * @param body the body
   */
  static updateAppointment(id: string, body: any) {
    return Api.put('appointments/' + id, body);
  }

  /**
   * generate token
   */
  static generateToken(id: string) {
    return Api.get('generate/token/' + id);
  }

  /**
   * update appointment progress
   * @param id the id
   * @param body the body
   */
  static updateAppointmentProgress(id: string, body: any) {
    return Api.put('/appointments/updateprogress/' + id, body);
  }

  /**
   * get appointment
   * @param id the appointment id
   */
  static getAppointment(id: string) {
    return Api.get<Appointment>(`/appointments/${id}`);
  }

  /**
   * create payment
   */
  static createPayment(entity: any) {
    return Api.post('payments', entity);
  }

  /**
   * pay with new credit card
   */
  static newCardPayment(entity: any) {
    return Api.post('card', entity);
  }

  /**
   * create ref
   * @param body the body
   */
  static createReferral(body: any) {
    return Api.post('referral', body);
  }

  /**
   * get ref logo
   * @param body the body
   */
  static getPartnerLogo(refId: string) {
    return Api.get(`/referralUsers?refId=${refId}`).then(async (res) => {
      let data = res.data[0];
      const logoBuffer = data?.logoBuffer?.data;
      const base64 = btoa(
        new Uint8Array(logoBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          '',
        ),
      );
      return {
        partner: res.data[0],
        base64: 'data:;base64,' + base64,
      };
    });
  }

  /**
   * get payment history
   */
  static getPaymentHistory(params: any) {
    return Api.get('payments', { params });
  }

  static getOrders(params: any) {
    return Api.get<Order[]>('/orders', { params });
  }

  static getOrdersWithPage(params: any) {
    return Api.get<OrderPage>('/orders', { params });
  }

  /**
   * get discharge summary
   * @param appointmentId the appointment id
   */
  static getDischargeSummary(appointmentId: string) {
    return Api.get<DischargeSummary>(
      '/dischargeSummary/info?checkExist=true&appointmentId=' + appointmentId,
    );
  }

  /**
   * update order
   * @param orderId the order id
   * @param body the body
   */
  static updateOrder(orderId: string, body: any) {
    return Api.put<Order>(`/orders/${orderId}`, body);
  }

  /**
   * pay order
   * @param orderId the order id
   * @param body the body
   */
  static payOrder(orderId: string, body: any) {
    return Api.post<Order>(`/orders/${orderId}/pay`, body);
  }

  /**
   * get upcoming events
   * @param patientId the patient id
   */
  static upcomingAppointment(patientId: string) {
    return Api.get<Appointment[]>('appointments/upcoming', {
      params: { patientId, simple: true },
    });
  }

  static getPatientTodo() {
    return Api.get<PatientTodoRsp>('patient/todo');
  }

  static getSNewZzz() {
    return axios.get<SNewZzzRow[]>(S_NEW_ZZZ_URI);
  }

  static getMedicalHistory(userId: string) {
    return Api.get(`medical-history/${userId}`);
  }

  static updateMedicalHistory(userId: string, data: object) {
    return Api.post(`medical-history/${userId}`, data);
  }

  static getMedicalHistoryById(id: string) {
    return Api.get(`medical-history-followup?id=${id}`);
  }

  static updateMedicalHistoryById(id: string, data: object) {
    return Api.post(`medical-history-followup?${id}`, data);
  }

  static getPatientIP() {
    // Use axios, otherwise will be blocked by cors
    return axios.get('https://api64.ipify.org?format=json');
  }

  static createSignatures(data: object, type = 'default') {
    return Api.post(`signatures?type=${type}`, data);
  }
}
