import React, { lazy, Suspense, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header';
import { Api } from '../../services/api';
import './styles.scss';

const RegistrationLandingPage: React.FunctionComponent = (props: any) => {
  const partner = props.routeParams?.partner;
  const [{ loading, data, base64 }, setState] = useState<any>({
    loading: true,
    data: {},
  });
  useEffect(() => {
    if (partner) {
      Api.get(`/referralUsers?landingRoute=${partner}`)
        .then(async (res) => {
          let data = res.data[0]
          const logoBuffer = data?.logoBuffer?.data
          const base64 = btoa(
            new Uint8Array(logoBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          );
          setState({ loading: false, data: res.data[0], base64: "data:;base64," + base64});
        })
        .catch((error) => {
          setState({ loading: false, data: {} });
        });
    } else setState({ loading: false, data: {} });
  }, []);

  const RegistrationLandingBanner = lazy(() => import('../../components/RegistrationLandingBanner'));
  if (partner && loading) return <div></div>;
  return (
    <React.Fragment>
      <Suspense fallback={<div></div>}>
        <div className="padding-wraper">
          <Header
            currentPage=""
            headerType={partner ? 'Referred' : 'Non-LoggedIn'}
            partnerLogo={base64}
            partnerName={data && data.name || ''}
          />
          <RegistrationLandingBanner  data={data} />
        </div>
      </Suspense>
    </React.Fragment>
  );
};

export default withRouter(RegistrationLandingPage);
