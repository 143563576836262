import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Error } from '@mui/icons-material';
import { PayModalType, PayModules } from "./PayModules";
import { Appointment } from "../../services/dto/Common";
import _ from "lodash";
import { Timeout } from "react-number-format/types/types";

type WaitingRoomActionsProps = {
  apt: Appointment;
  cardDim: number;
  loadApt: () => void;
  onEnter: () => void;
  onReschedule: () => void;
}

const WaitingRoomActions = (props: WaitingRoomActionsProps) => {
  const { apt, cardDim, loadApt, onEnter, onReschedule } = props;
  const [payModalType, setPayModalType] = useState<PayModalType>();
  const [waitingText, setWaitingText] = useState<string>();
  const [showRescheduleButton, setShowRescheduleButton] = useState<boolean>(false);
  let timeoutRefs: Timeout[] = [];

  const waitingTextOptions = [ // duration: number (in seconds)
    { duration: 30, text: "Sit tight! Your sleep specialist will be joining shortly."},
    { duration: 2 * 60, text: "Thank you for your patience! Seems the specialist is running a bit late."},
    { duration: 4 * 60, text: "Hang on just a bit longer! Your specialist is running long with their previous patient."},
    { duration: 4 * 60, text: "Sorry for the delay. Just hang on a few more minutes."},
    { duration: 0, text: "Seems your specialist is running quite late. We know your time is valuable, so feel free to reschedule your visit."},
  ];

  const setWaitTextTimers = () => {
    const elapsedTime = new Date().getTime() - new Date(apt.startTime).getTime();
    let cumTime = 0;
    let currText = waitingTextOptions[0].text;
    for (const textOption of waitingTextOptions) {
      if (elapsedTime > cumTime) {
        currText = textOption.text;
        if (textOption.duration === 0) setShowRescheduleButton(true);
      } else {
        timeoutRefs.push(setTimeout(() => {
          setWaitingText(textOption.text);
          if (textOption.duration === 0) setShowRescheduleButton(true);
        }, cumTime - elapsedTime))
      }
      cumTime += textOption.duration * 1000;
    }
    setWaitingText(currText);
  }
  
  const bypassCopay = !!_.get(apt, "patientCheck.items.bypass");
  const patientPaid = !!_.get(apt, "patientPaid");
  const copayAmount = _.get(apt, "copayAmount");
  const canJoin = !!_.get(apt, "meetingId");
  const isCopayOk = bypassCopay || patientPaid || (copayAmount && copayAmount <= 0);
  
  useEffect(() => {
    if (isCopayOk && !canJoin) setWaitTextTimers();
    return () => { _.forEach(timeoutRefs, (timeoutRef) => clearTimeout(timeoutRef)); }
  }, []);

  useEffect(() => {
    if (canJoin) _.forEach(timeoutRefs, (timeoutRef) => clearTimeout(timeoutRef));
  }, [canJoin]);

  return (<>
    {/* Action Display */}
    {(isCopayOk)
      ? (canJoin) 
        ? <Button variant='contained' className="enter-btn" style={{width: "100%", aspectRatio: "2", borderRadius: `${cardDim/20}px`}} onClick={onEnter}>
            Click here to enter the virtual visit
          </Button>
        : <>
            <h3 style={{fontWeight: "600", textAlign: "center"}}>{waitingText}</h3>
            {showRescheduleButton && <>
              <div className="pay-btn" onClick={onReschedule}>Reschedule</div>
              <p style={{textAlign: "center"}}>NOTE: Any payment towards this visit will be automatically refunded.</p>
            </>}
          </>
      : (_.isNil(copayAmount))
        ? <h3 style={{fontWeight: "600", textAlign: "center"}}>Awaiting copay information</h3>
        : <>
          <span>
            <Error style={{color: "#54b7ff", display: "inline", marginRight: "5px"}}/>
            <p style={{display: "inline"}}>Payment required to continue.</p>
          </span>
            <h3 style={{fontSize: "24px", fontWeight: "600", textAlign: "center"}}>Your copay for this visit is:</h3>
            <h2 style={{fontSize: "36px", fontWeight: "700", color: "#20c997"}}><ul>${copayAmount}*</ul></h2>
            <div className="pay-btn" onClick={() => setPayModalType('exist')}>
              Pay Now
            </div>
            <p style={{textAlign: "center"}}>*The full payment required for your visit may change based on insurance response.</p>
          </>}

    {/* Pay Modal */}
    {payModalType && apt && copayAmount && (
      <PayModules
        amount={(typeof copayAmount === 'string') ? parseInt(copayAmount) : copayAmount}
        entityId={apt.id}
        description={apt.description}
        showModal={payModalType}
        type={'apt'}
        onClose={(card) => {
          if (card) loadApt();
          setPayModalType(undefined);
          setWaitTextTimers();
        }}
      />
    )}
  </>)
}
export default WaitingRoomActions;