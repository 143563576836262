import React from 'react';

const CompletedForms = (props) => {
  const { inOffice } = props;
  return (
    <div className="onboarding-form">
      <div className="header-info complete-view">
        <div className="description">
          <h3>Forms Completed!</h3>
          <p>
          You're all set! {
            (inOffice) 
              ? "Please hand this device back to the staff member in order to complete your pre-appointment check in." 
              : "You may close out of this tab now."
          }
          </p>
        </div>
        {/* <a
          href="/patientDashboardPage"
          type="submit"
          className="submit-btn mt-4">
          Return To Dashboard
        </a> */}
      </div>
    </div>
  );
};

export default CompletedForms;
