import React from 'react';

const CompletedForms = (props) => {
  return (
    <div className="onboarding-form">
      <div className="header-info complete-view">
        <div className="description">
          <h3>Forms Completed!</h3>
          <p>
          That's another step on your path to better sleep. Let's return to your dashboard to see what else there is to do.
          </p>
        </div>
        <a
          href="/patientDashboardPage"
          type="submit"
          className="submit-btn mt-4">
          Return To Dashboard
        </a>
      </div>
    </div>
  );
};

export default CompletedForms;
