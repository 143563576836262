import {User} from "../../services/dto/Security";
import {Appointment, MyProgressItem, PatientTodoRsp, StateFullData} from "../../services/dto/Common";
import * as redux from 'redux'
import {commonApiAction} from "../helper";
import UserService from "../../services/userService";

export const API_LOAD_PROVIDERS = 'API_LOAD_PROVIDERS';
export const API_LOAD_APPOINTMENTS = 'API_LOAD_APPOINTMENTS';
export const API_LOAD_MY_PROGRESS = 'API_LOAD_MY_PROGRESS';
export const API_LOAD_TODO = 'API_LOAD_TODO'

export interface ApiLoadProviders {
  type: typeof API_LOAD_PROVIDERS,
  payload: StateFullData<User[]>
}

export interface ApiLoadAppointments {
  type: typeof API_LOAD_APPOINTMENTS,
  payload: StateFullData<Appointment[]>
}

export interface ApiLoadMyProgress {
  type: typeof API_LOAD_MY_PROGRESS,
  payload: StateFullData<MyProgressItem[]>
}

export interface ApiLoadTodo {
  type: typeof API_LOAD_TODO,
  payload: StateFullData<PatientTodoRsp>
}

export const loadProviders = (params: any) => {
  return (dispatch: redux.Dispatch) => {
    commonApiAction(
      dispatch,
      API_LOAD_PROVIDERS,
      UserService.getProviders(params)
    )
  }
}

export const loadAppointments = (params: any) => {
  return (dispatch: redux.Dispatch) => {
    commonApiAction(
      dispatch,
      API_LOAD_APPOINTMENTS,
      UserService.getAppointments(params)
    )
  }
}

export const loadMyProgress = (userId: string) => {
  return (dispatch: redux.Dispatch) => {
    commonApiAction(
      dispatch,
      API_LOAD_MY_PROGRESS,
      UserService.getUserProgress(userId)
    )
  }
}

export const loadMyTodo = () => {
  return (dispatch: redux.Dispatch) => {
    commonApiAction(
      dispatch,
      API_LOAD_TODO,
      UserService.getPatientTodo()
    )
  }
}

export type AppointmentTypes = ApiLoadProviders | ApiLoadAppointments | ApiLoadMyProgress | ApiLoadTodo