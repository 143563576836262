import Close from '@mui/icons-material/Close';
import { Dialog, DialogActions } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  open: boolean;
  noCloseBtn?: boolean;
  onClose: () => void;
  children: ReactNode;
  buttonActions?: {
    text: string;
    style: 'confirm' | 'cancel' | 'confirm-small' | 'cancel-small' | 'link';
    action: () => void;
  }[];
}

const Modal = ({
  open,
  noCloseBtn,
  onClose,
  children,
  buttonActions,
}: Props) => {
  return (
    <Dialog
      open={open}
      sx={{
        '.MuiPaper-root': {
          padding: '2rem 2rem 1rem',
          borderRadius: '18px',
        },
        '.--close-modal': {
          cursor: 'pointer',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          right: '.5rem',
          top: '.5rem',
          '&:hover': {
            background: 'rgba(0, 0, 0, .15)',
          },
        },
        svg: {
          height: 25,
          width: 25,
        },
        '.btn.btn-blue. .btn.text-btn': {
          fontWeight: 400,
          fontSize: '1rem',
        },
      }}>
      {!noCloseBtn && (
        <button className="btn --close-modal" onClick={onClose}>
          <Close />
        </button>
      )}
      {children}

      <DialogActions style={{justifyContent: "center", paddingTop: "1rem"}}>
        {buttonActions &&
          buttonActions.map((action) => {
            const buttonClassNames = {
              link: 'text-btn --link',
              confirm: 'btn btn-blue',
              cancel: 'btn btn-blue-border',
              'confirm-small': 'btn btn-blue small',
              'cancel-small': 'btn btn-blue-border small',
            }
            return (
              <button onClick={action.action} className={buttonClassNames[action.style]}>
                {action.text}
              </button>
            );
          })}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
