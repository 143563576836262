import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import {CreditCard} from "../../../services/dto/Security";
import {TodoItem} from "../LeftStepList";

export interface ISessionSummaryProps {
  copayValue: number
  card?: CreditCard,
  steps: TodoItem[]
}

export const SessionSummary: React.FunctionComponent<ISessionSummaryProps> = (props) => {  
  const { copayValue ,card,steps} = props;
  const displayCopay = typeof copayValue === "string" ? copayValue : (copayValue || 0).toFixed(2)
  
  return (
    <div className="session-summary">
      <div className="white-box">
        <div className="bold-title">Here is summary of your consultation</div>
        <div className="check-list">
          {steps.map((item, index) => (
            <div className="check-row" key={index}>
              <div className="checkbox-wrap-round">
                <input
                  type="checkbox"
                  id={`session-summary-check-${index}`}
                  checked={item.completed}
                  disabled
                />
                <label htmlFor={`session-summary-check-${index}`}>
                  {item.title}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="bottom-area">
          <div className="left-area">
            <div className="copay-txt">Copay</div>
            <div className="price-txt">${displayCopay}</div>
            {card && <div className="visa-txt">Billed to {card.number}</div>}
          </div>
          <a href="#javascript" className="btn btn-blue-border">
            Request a receipt
          </a>
        </div>
      </div>
      <div className="bottom-link">
        <NavLink to="/patientDashboardPage" className="blue-link">
          Go to Dashboard
        </NavLink>
      </div>
    </div>
  );
};

export default SessionSummary;
