import React, { useEffect, useState } from 'react';
import './styles.scss';
import { LStorage, useQuery } from '../../../services/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store/reducers';
import { AuthState } from '../../../store/reducers/auth';
import { useHistory } from 'react-router-dom';
import TabMenu from '../../TabMenu';
import UserService from '../../../services/userService';
import { updateAuthUser } from '../../../store/actions/auth';
import AuthService, { TAB_KEY } from '../../../services/authService';
import InsuranceInfo from '../../RegistrationComponents/InsuranceInfo';
import PersonalInfo from '../../RegistrationComponents/PersonalInfo';
import PaymentInfo from '../../RegistrationComponents/PaymentInfo';
import OuraInfo from '../../RegistrationComponents/OuraInfo';

export const FormReviewDetails = () => {
  const { authUser } = useSelector<IAppState, AuthState>((s) => s.auth);

  const getTab = () => LStorage.getItem(TAB_KEY, true).tab;
  const saveTab = (newTab: string) => LStorage.setItem(TAB_KEY, { tab: newTab });

  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  
  const [step, setStep] = useState<string>(query.get('step') || getTab() || 'info');

  useEffect(() => {
    if (query.get('step')) {
      history.push('/profile'); // remove step=___ from query
    }

    if (authUser) {
      UserService.getSimpleUser(authUser.id)
        .then((user) => {
          AuthService.updateUserInfo(user.data);
          dispatch(updateAuthUser(user.data));
        })
        .catch((e) => console.log(e.message));
    }
  }, []);

  useEffect(() => {
    saveTab(step);
  }, [step])

  const navItems = {
    info: 'Personal',
    insurance: 'Insurance',
    payment: 'Payment',
    oura: 'Oura Sync',
  };

  const handleClick = (key: any) => setStep(key);

  useEffect(() => {
    if (step && !Object.keys(navItems).includes(step)) {
      return history.push('patientDashboardPage')
    }
  }, [step]);

  return (
    <div className="form-review-details">
      <div className="menu-container">
        <div className="top-started">
          <div
            onClick={() => history.push('patientDashboardPage')}
            className="btn back-link">
            <i className="icons icon-back-arrow" />
          </div>
        </div>
        <TabMenu active={step} navItems={navItems} handleClick={handleClick} />
      </div>

      <div>
        {step === 'info' && <PersonalInfo onContinue={() => {}} profile />}
        {step === 'insurance' && <InsuranceInfo onContinue={() => {}} profile />}
        {step === 'payment' && <PaymentInfo onContinue={() => {}} profile />}
        {step === 'oura' && <OuraInfo onContinue={() => {}} profile />}
      </div>
    </div>
  );
};

export default FormReviewDetails;
