import React, { useEffect, useState } from 'react';
import _, { constant } from 'lodash';
import AppointmentScheduler from '../FormScheduleAppointmentSelectDate';
import SleepSpecialistInfo from '../SleepSpecialistInfo';
import './styles.scss';
import { User } from '../../../services/dto/Security';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store/reducers';
import { AppointmentsState } from '../../../store/reducers/appointments';
import { loadProviders } from '../../../store/actions/appointments';
import { Appointment, Slot } from '../../../services/dto/Common';
import { AuthState } from '../../../store/reducers/auth';
import UserService from '../../../services/userService';
import AuthService from '../../../services/authService';
import { updateAuthUser } from '../../../store/actions/auth';
import PolicyModal from '../Modals/PolicyModal';
import { useHistory } from 'react-router-dom';
import ContinueOnboardingModal from '../Modals/ContinueOnboardingModal';
import moment from 'moment';
import { FetchDataType } from '../../RegistrationProgressComponents/FormScheduleAppointmentSelectDate';
import { useQuery } from '../../../services/utils';
import dayjs from 'dayjs';

export interface IFormScheduleAppointmentProps {
  onPreviousStep?: () => void;
  onNextStep: () => void;
  onConfirmed?: (p: User, slot: Slot) => void;
  hideProvider?: boolean;
  loading?: boolean;
  provider?: User;
  aptId?: string;
  aptCount: number;
  aptSaved?: boolean;
  savingAppointment?: boolean;
}

export const FormScheduleAppointment: React.FunctionComponent<
  IFormScheduleAppointmentProps
> = (props) => {
  const queryParams = useQuery();
  const [hasCreditcard, setHasCreditcard] = useState<boolean>(false);
  const [isCCRequired, setCCRequired] = useState<boolean>(false);
  const [showProviderPicker, setShowProviderPicker] = useState<boolean>(false);
  const [provider, setProvider] = useState<User>();
  const [availData, setAvailData] = useState<any>();
  const [providerHasSlots, setProviderHasSlots] = useState<any>(null);
  const [slot, setSlot] = useState<Slot>();
  const [detailsProvider, setDetailsProvider] = useState<User>();
  const [timeZone, setTimeZone] = useState<string>(_.get(AuthService.getAuth(), 'user.timeZone', 'America/New_York'));
  const [existingApt, setExistingApt] = useState<Appointment>();

  const { providers } = useSelector<IAppState, AppointmentsState>(
    (r) => r.appointments,
  );
  const [times, setTimes] = useState<{ endTime?: any; startTime?: any }>({
    startTime: moment().format(),
    endTime: moment().add(4, 'month').format(),
  });
  const dispatch = useDispatch();
  const { authUser } = useSelector<IAppState, AuthState>((s) => s.auth);
  const history = useHistory();

  useEffect(() => {
    if (authUser) {
      UserService.getSimpleUser(authUser.id)
        .then((r1) => {
          dispatch(
            updateAuthUser({
              creditCards: r1.data.creditCards || [],
              providerId: r1.data.providerId,
              isNewPatient: r1.data.isNewPatient,
              bypassPlatformFees: r1.data.bypassPlatformFees,
            }),
          );
          setHasCreditcard(
            !!(r1.data.creditCards && r1.data.creditCards.length),
          );
          setCCRequired(r1.data.ccRequired || false);
        })
        .catch((e) => console.log(e.message));
    }
  }, [authUser?.id]);

  useEffect(() => {
    if ((authUser?.bypassPlatformFees || hasCreditcard) && props.aptSaved)
      history.push('/patientDashboardPage');
  }, [props.aptSaved]);

  useEffect(() => {
    dispatch(loadProviders({ withProviderId: true }));
    getRecommendedPhysician(existingApt?.providerId);
    if (props.aptId) getExistingApt(props.aptId);
  }, []);

  const getRecommendedPhysician = async (queryProviderId?: string) => {
    setAvailData(null);
    setProviderHasSlots(null);
    const providerId = props?.provider?.id || queryProviderId;

    const body = {
      patientId: _.get(AuthService.getAuth(), 'user.id'),
      providerId,
      timeZone,
      maxFollowupTime: _.get(AuthService.getAuth(), 'user.maxFollowupTime'),
      state: _.get(AuthService.getAuth(), 'user.state')

      // ~~EXAMPLE BODY FOR UNREGISTERED PATIENT~~
      // type: "pseudo",
      // roles: ["Patient"],
      // state: "NY",
      // startTime: moment().startOf("month").format(),
      // endTime: moment().endOf("month").format(),
      // isNewPatient: true,
      // timeZone: "America/New_York",
      // providerId: "",
      // callee: "web",
    };
    await getPhysicianSchedule(body);
  };

  const getPhysicianSchedule = async (params: any) => {
    const data = {
      startTime: times.startTime,
      endTime: times.endTime,
      providerId: params?.providerId || provider?.id,
      ...params,
    };
    await UserService.getSchedule(data)
      .then((res) => {
        if (!_.isEmpty(res.data)) {
          setProvider(res.data.provider);
          setAvailData(res.data);
          setProviderHasSlots(!_.isEmpty(res.data?.availableSlots));
        } else {
          setProviderHasSlots(false);
        }
      })
      .catch((e) => console.log(e.message));
  };

  const getExistingApt = async (aptId: string) => {
    UserService.getAppointment(aptId)
      .then((rsp) => rsp.data && setExistingApt(rsp.data))
      .catch(console.error);
  };

  const notProvider = () => {
    return (
      <div className="container-appointment_page">
        <div className="blue-box_container">
          <img
            src="/assets/deadline.svg"
            alt="icon"
            className="icon-calendar"
          />
          <p>
            Sorry, we currently do not have a physician licensed for your state.
            We will notify you when we expand to include your state and are able
            to assist you.
          </p>
          <br/>
          <button className="btn btn-blue-border" onClick={() => history.push('/patientDashboardPage')} style={{margin: 0}}>Go To Dashboard</button>
        </div>
      </div>
    );
  };

  if (providers?.loading || providerHasSlots === null) {
    return <div></div>;
  } else if (
    !providers?.data ||
    !providers?.data.length ||
    providers?.err ||
    providerHasSlots === false
  ) {
    return <div>{_.get(providers, 'err.message') || notProvider()}</div>;
  }

  const showPolicyModal =
    provider && !showProviderPicker && !authUser?.termsConsent;

  const requirePayModal =
    !hasCreditcard &&
    isCCRequired &&
    props.aptSaved &&
    !authUser?.bypassPlatformFees;

  const formsCompleted = authUser?.onboardingProgress !== 'complete';
  const showFormsModal = props.aptSaved && !requirePayModal && formsCompleted;

  const onConfirm = async (s: Slot) => {
    setSlot(s);
    if (props.onConfirmed && provider) props.onConfirmed(provider, s);
    props.onNextStep();
  };

  const onFetchData = (changes: FetchDataType) => {
    if (changes.timeZone) setTimeZone(changes.timeZone);
    if (changes.startTime && changes.endTime)
      setTimes({ startTime: changes.startTime, endTime: changes.endTime });
    getPhysicianSchedule({
      patientId: _.get(AuthService.getAuth(), 'user.id'),
      maxFollowupTime: _.get(AuthService.getAuth(), 'user.maxFollowupTime'),
      state: _.get(AuthService.getAuth(), 'user.state'),
      ...changes,
    });
  };

  const getAppointmentDetails = () => {
    const isNewPatient = _.get(
      AuthService.getAuth(),
      'user.isNewPatient',
      true,
    );

    let details: any = {};
    const existingAptTitle = _.get(existingApt, 'description', '');
    if (!existingAptTitle.toLocaleLowerCase().includes('consultation')) {
      details.title = existingAptTitle;
    } else if (isNewPatient) details.title = 'Sleep Medicine Consultation';
    else details.title = 'Follow-Up Visit';

    details.duration = existingApt
      ? dayjs(existingApt?.endTime).diff(
          dayjs(existingApt?.startTime),
          'minutes',
        )
      : isNewPatient
      ? 40
      : 20;

    return details;
  };

  const { title, duration } = getAppointmentDetails();
  return (
    <>
      {showPolicyModal && (
        <PolicyModal
          userId={authUser?.id}
          termsConsent={authUser?.termsConsent}
          updateAuthUser={(data: object) => {
            AuthService.updateUserInfo(data);
            dispatch(updateAuthUser(data));
          }}
        />
      )}

      {showFormsModal && <ContinueOnboardingModal />}

      {provider && !showProviderPicker && availData && (
        <div>
          {queryParams.get('aptCount') && (
            <div className="top-started flex">
              {props.onPreviousStep && <div
                className="blue-back"
                style={{ position: 'relative', left: '10px' }}>
                <div
                  className="btn icons btn-back"
                  onClick={props.onPreviousStep}></div>
              </div>}
            </div>
          )}
          <AppointmentScheduler
            data={availData}
            provider={provider}
            providers={providers.data}
            existingApt={existingApt}
            // onClickProvider={() => setDetailsProvider(provider)}
            fetchData={(changes: FetchDataType) => onFetchData(changes)}
            title={title}
            timeZone={timeZone}
            duration={duration} // 40 iff isNewPatient === true or undefined
            onConfirm={(s: Slot) => onConfirm(s)}
            submitting={!!props.savingAppointment}
            showProviderToggle={_.get(
              AuthService.getAuth(),
              'user.isNewPatient',
              true,
            )}
          />
        </div>
      )}

      {detailsProvider && (
        <SleepSpecialistInfo
          provider={detailsProvider}
          hideScheduleBtn={true}
          onClose={() => setDetailsProvider(undefined)}
          onSelectProvider={(p: User) => {
            setProvider(p);
            setShowProviderPicker(false);
            setDetailsProvider(undefined);
          }}
        />
      )}
    </>
  );
};

export default FormScheduleAppointment;
