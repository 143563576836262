import React, { useState } from "react";
import { Card, CardContent, CardMedia, IconButton } from "@mui/material";
import { ProfileIcon } from "../../components/ProfileIcon";
import { InfoOutlined } from '@mui/icons-material';
import SleepSpecialistInfo from "../../components/RegistrationProgressComponents/SleepSpecialistInfo";
import { User } from "../../services/dto/Security";
import { getCredentials, getName } from "../../services/utils";

type ProviderCardProps = {
  provider: User;
  cardDim: number;
}

const ProviderCard = (props: ProviderCardProps) => {
  const { provider, cardDim } = props;
  const [showProviderDetails, setShowProviderDetails] = useState<boolean>(false);

  return (<>
    <Card elevation={0} style={{marginTop: '8px', alignItems: "center", display: "flex", backgroundColor: "#90AEE8", width: "100%", borderRadius: `${cardDim/20}px`, padding: "4%"}}>
      <CardMedia>
        <div style={{height: `${cardDim/4}px`, width: `${cardDim/4}px`, maxHeight: "100%", borderRadius: "50%", overflow: "hidden", margin: "auto"}}>
          <ProfileIcon user={provider} fontSize={32} />
        </div>
      </CardMedia>
      <CardContent style={{ padding: "0 0 0 16px", alignItems: "center", width: '100%'}}>
        <h2 style={{textWrap: 'wrap', color: "white", fontSize: 28, fontWeight: 600}}>{getName(provider)}</h2>
        <h3 style={{display: 'inline', textWrap: 'wrap', fontSize: 26, color: "white", fontWeight: 200}}>{getCredentials(provider)}</h3>
        <IconButton
          edge="end"
          aria-label="info"
          onClick={() => setShowProviderDetails(true)}
          sx={{float: 'right'}}
        >
          <InfoOutlined />
        </IconButton>
      </CardContent>
    </Card>

    {showProviderDetails && (
      <SleepSpecialistInfo
        provider={provider}
        hideScheduleBtn={true}
        onClose={() => setShowProviderDetails(false)}
        onSelectProvider={(p: User) => null}
      />
    )}
  </>)
}

export default ProviderCard;