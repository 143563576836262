import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  useElements,
  useStripe,
  Elements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateAuthUser } from '../../store/actions/auth';
import AuthService from '../../services/authService';
import UserService from '../../services/userService';

import service from '../../services/userService';
import Ripple from '../Spinners/Ring';
import './PaymentForm.scss';

const STRIP_PUBLISHABLE_KEY =
  process.env.BUILD_ENV !== 'prod'
    ? 'pk_test_tkQbOvhM2npIL7hfiglt3Fth0055JdKmz6'
    : 'pk_live_Z6fjkFekaFaXGMtxhNdcFMzM00AGmRgdvk';

const stripePromise = loadStripe(STRIP_PUBLISHABLE_KEY);

interface Props {
  onSubmit?: () => void;
  noBottomText?: boolean;
  userId?: string;
}

const PaymentForm = ({ onSubmit, noBottomText = false, userId }: Props) => {
  const [clientSecret, setClientSecret] = useState('');
  const [stripeUnableToConnect, setStripeUnableToConnect] = useState(false);
  const { user } = AuthService.getAuth();

  useEffect(() => {
    if (!user?.email) setStripeUnableToConnect(true);
    else {
      (async () => {
        try {
          const { data } = await service.getSetupIntent(user?.email);
          // @ts-ignore
          const { client_secret } = data;
          setClientSecret(client_secret);
        } catch (err) {
          console.error(err);
          setStripeUnableToConnect(true);
        }
      })();
    }
  }, []);

  return (
    <div className="payment-form">
      {stripeUnableToConnect && (
        <div className="py-2">
          <h4 className='mb-3' style={{color: "#3f5c76"}}>Connection Failed</h4>
          <p style={{ textAlign: 'center', fontSize: '16px' }}>
            We were unable to connect to our payment service, please try again
            later!
          </p>
          <button className="btn btn-blue small mt-4" style={{ fontSize: '16px' }} onClick={onSubmit}>
            {' '}
            Continue
          </button>
        </div>
      )}
      {!stripeUnableToConnect && (
        <>
          <img
            src="./assets/stripe-badge-transparent.png"
            alt="stripe-badge"
            style={{ width: '100%' }}
          />
          {clientSecret ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret,
              }}>
              <StripeFormBody
                onSubmit={onSubmit}
                noBottomText={noBottomText}
                userId={userId}
              />
            </Elements>
          ) : (
            <div
              className="payment-form_loader"
              style={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: 10,
              }}>
              <Ripple />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const StripeFormBody = ({ onSubmit, noBottomText, userId }: Props) => {
  const { user } = userId ? { user: { id: userId } } : AuthService.getAuth();
  const { updateProfile, updateUserPayment } = UserService;
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();

  const handleOnNameChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setName(value);
  };

  const handleOnSubmit = async (ev: FormEvent) => {
    setLoading(true);
    ev.preventDefault();
    name.trim();
    if (name === '') {
      toast.error('Must include full name as it appears on card.');
      return;
    }
    try {
      if (!stripe || !elements) {
        return null;
      }
      const response = await stripe.confirmSetup({
        // @ts-ignore
        elements,
        // @ts-ignore
        redirect: 'if_required',
      });
      const { error, setupIntent } = response;
      const { payment_method } = setupIntent || {};
      if (userId) {
        await updateUserPayment(
          {
            paymentMethodId: payment_method,
            paymentMethodName: name,
            verified: true,
          },
          user.id,
        );
      } else {
        const { data } = await updateProfile(
          { paymentMethodId: payment_method, paymentMethodName: name },
          user.id,
        );
        AuthService.updateUserInfo(data || {});
        dispatch(updateAuthUser(data || {}));
      }

      if (error) {
        toast.error(`${error.message} Please try again.`);
        console.error(error);
      } else {
        if (onSubmit) {
          onSubmit();
        }
        toast.success('Successfully added card to account.');
      }
    } catch (err) {
      toast.error("There was an issue saving your card. Please try again.")
      console.error(err);
    }
  };

  return (
    <form onSubmit={async (e) => await handleOnSubmit(e).finally(() => setLoading(false))} className="payment-method_form">
      <div style={{ marginBottom: 7 }}>
        <div className="p-GridCell p-GridCell--12 ">
          <label className="p-FieldLabel Label Label--empty">Name</label>
          <div>
            <input
              type="text"
              id="Field-nameInput"
              name="name"
              placeholder="Full Name"
              className="p-Input-input Input Input--empty"
              onChange={handleOnNameChange}
            />
          </div>
        </div>
      </div>
      <PaymentElement />
      {!noBottomText && (
        <p className="header footer">
          Next, you will be taken to our onboarding questionnaire
        </p>
      )}

      <button
        className="submit-btn"
        disabled={loading || !stripe || !elements}
        type="submit">
        Save
      </button>
    </form>
  );
};

export default PaymentForm;
