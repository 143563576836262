import { Form, Formik } from 'formik';
import { isNil, omit } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Dropzone from '../../components/Dropzone';
import FormikControl from '../../components/Formik/FormikControl';
import { LOAD_LOADER } from '../../constants/actionTypes';
import { Api } from '../../services/api';
import AuthService from '../../services/authService';
import UserService from '../../services/userService';
import { useQuery } from '../../services/utils';
import { borderClass, manualFormValidation, scrollToError, textAreaAutoGrow } from './utils';

const SleepHistory = (props) => {
  const {
    sleepQuestionare = [],
    epworthSleepinessScale: epworth = [],
    nearAccidentHistoryDescription: nearAccident = { response: null },
    pastSleepStudy,
    ...sleepHistory
  } = props.sleepHistory;
  const lastQuestionare = sleepQuestionare[sleepQuestionare.length - 1];
  const lastEpworth = epworth[epworth.length - 1];
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const view = query.get('view') || '1';
  const { user } = AuthService.getAuth();
  const [state, setState] = useState({ sleepStudyFiles: {}, hasFiles: false });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  useEffect(() => {
    if (!parseInt(view) || parseInt(view) > 4) {
      history.push('/');
    } else if (view === '1') getFileList();
  }, []);

  let validationSchema;
  switch (view) {
    case '1':
      validationSchema = {
        pastSleepStudy: Yup.object({
          response: Yup.boolean().required(),
          description: Yup.string(),
        }),
        onPAP: Yup.boolean().required(),
        papVendor: Yup.string().when('onPAP', {
          is: true,
          then: Yup.string().required(),
        }),
        nearAccidentHistoryDescription: Yup.object({
          response: Yup.boolean().required(),
          description: Yup.string(),
        }),
      };
      break;
    case '2':
      validationSchema = {
        sleepQuestionare: Yup.object({
          dateSaved: Yup.date().required(),
          averageHoursSlept: Yup.string().required(),
          averageMinToDoze: Yup.string().required(),
          personalSleepHistory: Yup.object({
            drowsyDay: Yup.boolean(),
            hallucinationDreams: Yup.boolean(),
            sleepAttacks: Yup.boolean(),
            cataplecticAttacks: Yup.boolean(),
            sleepParalysis: Yup.boolean(),
            legRestlessness: Yup.boolean(),
          }),
        }),
      };
      break;
    case '3':
      validationSchema = {
        sleepQuestionare: Yup.object({
          dateSaved: Yup.date().required(),
          sleepBehaviors: Yup.array().of(Yup.string()),
        }),
      };
      break;
    case '4':
      validationSchema = {
        epworthSleepinessScale: Yup.object({
          reading: Yup.string().required(),
          watchingTV: Yup.string().required(),
          inactivePublic: Yup.string().required(),
          passenger: Yup.string().required(),
          afternoonLyeDown: Yup.string().required(),
          talking: Yup.string().required(),
          afterLunch: Yup.string().required(),
          inTraffic: Yup.string().required(),
          score: Yup.number().required(),
          dateSaved: Yup.date().required(),
        }),
      };
      break;
  }

  const fileLength = Object.keys(state.sleepStudyFiles).length;
  const initialValues = {
    // view one
    pastSleepStudy:
      typeof pastSleepStudy?.response === 'boolean'
        ? pastSleepStudy
        : {
            response: !!fileLength || null,
            description: '',
          },
    onPAP: sleepHistory?.onPAP,
    papVendor: sleepHistory?.papVendor || '',
    nearAccidentHistoryDescription: nearAccident === null ? null : nearAccident,
    // view 2 and 3
    sleepQuestionare: {
      dateSaved: new Date(),
      sleepBehaviors: lastQuestionare?.sleepBehaviors || [],
      averageHoursSlept: lastQuestionare?.averageHoursSlept || '',
      averageMinToDoze: lastQuestionare?.averageMinToDoze || '',
      personalSleepHistory: lastQuestionare?.personalSleepHistory || {},
    },
    // view 4
    epworthSleepinessScale: {
      reading: lastEpworth?.reading || '',
      watchingTV: lastEpworth?.watchingTV || '',
      inactivePublic: lastEpworth?.inactivePublic || '',
      passenger: lastEpworth?.passenger || '',
      afternoonLyeDown: lastEpworth?.afternoonLyeDown || '',
      talking: lastEpworth?.talking || '',
      afterLunch: lastEpworth?.afterLunch || '',
      inTraffic: lastEpworth?.inTraffic || '',
      score: lastEpworth?.score || 0,
      dateSaved: new Date(),
    },
  };
  validationSchema = Yup.object({
    ...validationSchema,
  });

  const getFileList = () => {
    user.id &&
      UserService.getFileList(`Key=patients/${user.id}/sleep-study`)
        .then((res) => {
          let sleepStudyFiles = {};
          let hasFiles = false;
          if (res?.data?.length) {
            res.data.forEach((file) => {
              hasFiles = true;
              sleepStudyFiles[file.name] = {
                name: file.name,
                loading: false,
                uploaded: true,
              };
            });

            setGenericState({ sleepStudyFiles, hasFiles });
          }
        })
        .catch((error) => toast.error('Unable to retrieve file list!'));
  };

  const nextView = (v) =>
    history.push(`/newPatientForms?step=sleep-history&view=${parseInt(view) + 1}`);

  const deleteFile = (name) => {
    let sleepStudyFiles = { ...state.sleepStudyFiles };
    sleepStudyFiles[name].loading = true;
    setGenericState({ sleepStudyFiles });

    UserService.deleteFile(`Key=patients/${user.id}/sleep-study/${name}`)
      .then((res) => {
        delete sleepStudyFiles[name];
        setGenericState({ sleepStudyFiles });
      })
      .catch((error) => {
        sleepStudyFiles[name].loading = false;
        toast.error('Unable to delete this file. Please try again!');
      });
  };

  const handleNumberChange = (e, formik, i) => {
    const value = e.target.value.split("-");
    const maxValue = i === 1 ? 24 : 300;
    const maxLength = i === 1 ? 2 : 3;
    let valid = true;
    if (value.length > 2) valid = false;
    value.forEach(x => {
      if (parseInt(x) > maxValue) valid = false;
      if (x.length > maxLength) valid = false;
    })
    if (valid) formik.handleChange(e);
  };
  const handleFileChange = (file) => {
    const parts = file.name.split('.');
    const ext = parts.pop();
    const fileName = `PastHSTReport-${parts.join('.')}.${ext}`;

    setState((state) => {
      const newState = { ...state };
      newState.sleepStudyFiles[fileName] = {
        name: fileName,
        loading: true,
        uploaded: false,
      };
      return newState;
    });
    const data = new FormData();
    dispatch({ type: LOAD_LOADER, data: false });
    data.append('file', file, fileName);
    data.append('keyPath', `patients/${user.id}/sleep-study/`);
    UserService.uploadFile(data)
      .then((res) => {
        dispatch({ type: LOAD_LOADER, data: true });
        const uploadedFileName = res?.data?.file;

        Api.post('/forms', {
          category: 'OTHER',
          name: uploadedFileName,
          key: `patients/${user.id}/sleep-study/${uploadedFileName}`,
          patientId: user.id,
          usedFor: 'Patient Onboarding',
        }).catch(() => {});

        setState((state) => {
          const newState = { ...state };
          delete newState.sleepStudyFiles[fileName];
          newState.sleepStudyFiles[uploadedFileName] = {
            name: uploadedFileName,
            loading: false,
            uploaded: true,
          };
          return newState;
        });
      })
      .catch((error) =>
        toast.error(`Unable to upload ${file.name}. Please try again!`),
      );
  };

  const getEpworth = (epworth) => {
    const score = Object.keys(epworth)
      .map((v) => {
        const index = scale.indexOf(epworth[v]);
        return index !== -1 ? index : 0;
      })
      .reduce((prev, curr) => prev + curr);

    epworth.score = score;
    return epworth;
  };

  const constructData = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const keys = ['sleepQuestionare', 'epworthSleepinessScale'];

    const questionnaire = [...sleepQuestionare];
    questionnaire.splice(questionnaire.length - 1, 1, newValues[keys[0]]);

    const epworthVal = [...epworth];
    epworthVal.splice(epworthVal.length - 1, 1, getEpworth(newValues[keys[1]]));
    return view === '1'
      ? omit(newValues, keys)
      : view === '4'
      ? {
          [keys[1]]: epworthVal,
        }
      : {
          [keys[0]]: questionnaire,
        };
  };

  const onSubmit = async (values, setSubmitting) => {
    setSubmitting(false);
    const newValues = constructData(values);
    let onboardingProgress =
      parseInt(view) <= 4
        ? `sleep-history&view=${parseInt(view) + 1}`
        : 'details';

    const data = {
      userId: user.id,
      type: 'New Patient',
      form: { sleepMedicineHistory: newValues },
    };

    try {
      const { updateMedicalHistory, updateProfile } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);

      await updateProfile({ onboardingProgress }, user.id);
      AuthService.updateUserInfo({ onboardingProgress });
      props.updateHistory(formDataRecord?.data?.form || {});
      parseInt(view) < 4
        ? nextView()
        : props.onContinue({ onboardingProgress: 'details' });
      setSubmitting(false);
    } catch (error) {
      toast.error('Unable to save social history. Please try again.');
      setSubmitting(false);
    }
  };

  const getDisableBtn = () => {
    const uploading = Object.values(state.sleepStudyFiles).find(
      (file) => file.loading && !file.uploaded,
    );

    return !!uploading;
  };

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Sleep Medicine History</h3>
          <p>
            Please provide some key details about your sleep and history of
            sleep care.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={view === '2' || view === '4'}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          let {
            pastSleepStudy,
            onPAP,
            nearAccidentHistoryDescription: newAccident,
            sleepQuestionare,
            epworthSleepinessScale: epworth,
          } = formik.values;
          const disableBtn =
            getDisableBtn(formik.values) || formik.isSubmitting;
          const fileLength = Object.keys(state.sleepStudyFiles)?.length;
          const hasFiles = fileLength || pastSleepStudy?.response;

          if (fileLength && typeof pastSleepStudy?.response !== 'boolean') {
            formik.setFieldValue('pastSleepStudy', {
              response: true,
              description: '',
            });
          }

          const onPAPBorder = borderClass('onPAP', formik);
          const sleepStudyBorder = borderClass(
            'pastSleepStudy.response',
            formik,
          );
          const nearAccidentBorder = borderClass(
            'nearAccidentHistoryDescription.response',
            formik,
          );
          const hoursSleptBorder = borderClass(
            'sleepQuestionare.averageHoursSlept',
            formik,
          );
          const hoursToDozeBorder = borderClass(
            'sleepQuestionare.averageMinToDoze',
            formik,
          );
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                manualFormValidation(formik);
                formik.handleSubmit();
              }}>
              {/* VIEW ONE */}
              {view === '1' && (
                <div className="form-container">
                  {view === '1' && (
                    <Fragment>
                      <FormikControl
                        className={sleepStudyBorder}
                        control="custom-btn"
                        onClick={async (v) => {
                          await formik.setFieldValue('pastSleepStudy', {
                            response: v === 'Yes',
                            description: '',
                          });
                          v === 'Yes' &&
                            formik.setFieldTouched(
                              'pastSleepStudy.description',
                              false,
                            );
                        }}
                        label="Have you ever had a sleep study?"
                        labelError={sleepStudyBorder === 'error-border'}
                        options={['Yes', 'No']}
                        persist={true}
                        selected={
                          pastSleepStudy?.response === false
                            ? 'No'
                            : hasFiles || pastSleepStudy?.response
                            ? 'Yes'
                            : null
                        }
                      />

                      {hasFiles && pastSleepStudy?.response !== false && (
                        <FormikControl
                          className={borderClass(
                            'pastSleepStudy.description',
                            formik,
                          )}
                          control="textarea"
                          style={{ resize: 'none' }}
                          maxLength={100}
                          name={'pastSleepStudy.description'}
                          id={'pastSleepStudy.description'}
                          placeholder="Describe"
                          showErrorMsg={false}
                          onChange={(e) =>
                            textAreaAutoGrow(e, formik.setFieldValue)
                          }
                        />
                      )}
                      {hasFiles && pastSleepStudy?.response !== false && (
                        <Dropzone
                          files={state.sleepStudyFiles}
                          handleFileChange={handleFileChange}
                          handleRemoveFile={deleteFile}
                        />
                      )}

                      <FormikControl
                        className={onPAPBorder}
                        control="custom-btn"
                        onClick={async (v) => {
                          await formik.setFieldValue('onPAP', v === 'Yes');
                          v === 'Yes'
                            ? formik.setFieldTouched('papVendor', false)
                            : formik.setFieldValue('papVendor', '');
                        }}
                        label="Are you currently on CPAP/BiPAP?"
                        labelError={onPAPBorder === 'error-border'}
                        options={['Yes', 'No']}
                        selected={onPAP ? 'Yes' : onPAP === false ? 'No' : null}
                      />
                      {onPAP && (
                        <FormikControl
                          className={borderClass('papVendor', formik)}
                          control="input"
                          name={'papVendor'}
                          id={'papVendor'}
                          placeholder="Vendor Name"
                          showErrorMsg={false}
                        />
                      )}

                      <FormikControl
                        className={nearAccidentBorder}
                        control="custom-btn"
                        onClick={async (v) => {
                          await formik.setFieldValue(
                            'nearAccidentHistoryDescription',
                            {
                              response: v === 'Yes',
                              description: '',
                            },
                          );
                          v === 'Yes' &&
                            formik.setFieldTouched(
                              'nearAccidentHistoryDescription.description',
                              false,
                            );
                        }}
                        label="Have you ever had an accident or near accident due to excessive drowsiness?"
                        labelError={nearAccidentBorder === 'error-border'}
                        options={['Yes', 'No']}
                        selected={
                          isNil(newAccident?.response)
                            ? null
                            : newAccident?.response
                            ? 'Yes'
                            : 'No'
                        }
                      />
                      {newAccident?.response && (
                        <FormikControl
                          className={borderClass(
                            'nearAccidentHistoryDescription.description',
                            formik,
                          )}
                          control="textarea"
                          style={{ resize: 'none' }}
                          maxLength={100}
                          name={'nearAccidentHistoryDescription.description'}
                          id={'nearAccidentHistoryDescription.description'}
                          placeholder="Describe"
                          showErrorMsg={false}
                          onChange={(e) =>
                            textAreaAutoGrow(e, formik.setFieldValue)
                          }
                        />
                      )}
                    </Fragment>
                  )}
                </div>
              )}
              {/* VIEW TWO */}
              {view === '2' && (
                <Fragment>
                  <div className="form-container sleep-history">
                    <div className="mb-2 mt-2">
                      <FormikControl
                        className={hoursSleptBorder}
                        control="input"
                        type="text"
                        onChange={(e) => handleNumberChange(e, formik, 1)}
                        name="sleepQuestionare.averageHoursSlept"
                        id="sleepQuestionare.averageHoursSlept"
                        label="How many hours of sleep do you get on a typical night?"
                        labelError={hoursSleptBorder === 'error-border'}
                        placeholder="Average Hours Slept"
                        showErrorMsg={false}
                      />
                    </div>

                    <div className="mb-2 mt-2">
                      <FormikControl
                        className={hoursToDozeBorder}
                        control="input"
                        type="text"
                        onChange={(e) => handleNumberChange(e, formik, 2)}
                        name="sleepQuestionare.averageMinToDoze"
                        id="sleepQuestionare.averageMinToDoze"
                        label="How long does it take you to fall asleep once in bed?"
                        labelError={hoursToDozeBorder === 'error-border'}
                        placeholder="Minutes in Bed"
                        showErrorMsg={false}
                      />
                    </div>
                  </div>

                  <div className="form-container sleep-history">
                    {Object.keys(personalSleep).map((key) => {
                      const displayValue = personalSleep?.[key];
                      const name = `sleepQuestionare.personalSleepHistory.${key}`;
                      const value =
                        sleepQuestionare?.personalSleepHistory?.[key];
                      return (
                        <FormikControl
                          key={name}
                          control="custom-btn"
                          className="mb-0 mt-0"
                          onClick={async (v) => {
                            formik.setFieldValue(name, value ? false : true);
                          }}
                          options={[displayValue]}
                          selected={value && displayValue}
                        />
                      );
                    })}
                  </div>
                </Fragment>
              )}
              {/* VIEW THREE */}
              {view === '3' && (
                <div className="form-container sleep-history">
                  <p className="mb-3 mt-1">
                    Select all of the following sleep bahaviors you or someone
                    has noted about you in the past year.
                  </p>
                  {sleepBehaviors.map((item, i) => {
                    const sleepBehaviors = sleepQuestionare?.sleepBehaviors;
                    const value = sleepBehaviors?.includes(item);

                    return (
                      <FormikControl
                        key={item}
                        control="custom-btn"
                        className="mb-0 mt-0"
                        style={{ width: '95%' }}
                        onClick={(v) => {
                          formik.setFormikState((state) => {
                            let newState = { ...state };
                            let values =
                              newState.values.sleepQuestionare.sleepBehaviors;
                            const index = values.indexOf(v);
                            index !== -1
                              ? values.splice(index, 1)
                              : values.push(v);
                            return newState;
                          });
                        }}
                        options={[item]}
                        selected={value && item}
                      />
                    );
                  })}
                </div>
              )}
              {/* VIEW FOUR */}

              {view === '4' && (
                <div className="form-container sleep-history epworth">
                  <p className="mb-3 mt-1">
                    How likely are you to doze off in the situations below?
                  </p>
                  {Object.keys(epworthItems).map((key, i) => {
                    const label = epworthItems[key];
                    const value = epworth[key];
                    const name = `epworthSleepinessScale.${key}`;
                    const borderClassStr = borderClass(name, formik);
                    return (
                      <Fragment key={key}>
                        <p
                          className="title"
                          style={
                            borderClassStr === 'error-border'
                              ? { color: '#FF0404', fontWeight: 500 }
                              : {}
                          }>
                          {label}
                        </p>
                        <FormikControl
                          control="custom-btn"
                          className={borderClassStr}
                          onClick={async (v) => {
                            await formik.setFieldValue(name, v, false);
                            formik.setFieldTouched(name);
                          }}
                          persist={true}
                          options={scale}
                          selected={value}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              )}
              <button
                type="submit"
                className={`submit-btn ${disableBtn ? 'disabled' : ''}`}
                onClick={() => scrollToError()}
                disabled={disableBtn}
                ref={props.continueRef}>
                Continue
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SleepHistory;

const scale = ['None', 'Slight', 'Moderate', 'High'];
const personalSleep = {
  drowsyDay: 'Feel very drowsy or sleepy at any point during the day',
  hallucinationDreams: 'Have strange hallucination-like dreams while napping',
  sleepAttacks:
    'Have "sleep attacks" during the day (i.e., periods when you cannot prevent yourself from falling asleep)',
  cataplecticAttacks:
    'Have "cataplectic attacks" (i.e., episodes of weakness in the legs and/or collapse that occur with emotions like laughter or crying)',
  sleepParalysis:
    'Have episodes of sleep paralysis (i.e., being awake in bed not able to move or speak)',
  legRestlessness:
    'Wake up with “pins and needles” or restlessness in the legs',
};

const sleepBehaviors = [
  'Snoring',
  'Sleepwalking',
  'Sleep talking',
  'Heart Palpitations',
  'Grinding your teeth',
  'Twitching of the legs or arms',
  'Waking up with frequent urge to urinate',
  'Rolling or rocking movement',
  'Shouting, screaming, or swearing',
  'Violent movements',
  'Waking up with anxiety or tension',
  'Excessive sweating',
  'Waking up with chest pain',
  'Waking up with jaw pain',
  'Waking up gasping or choking',
  'Waking up with headache',
  'Waking up with heartburn',
  'Waking up with frightening images',
  'Waking up with air hunger (shortness of breath)',
  'Waking up with the feeling of weight on chest',
  'Apnea (i.e. lapses in breathing, periods of no breathing)',
  'Frequent coughing',
  'Falling out of bed',
  'Bed-wetting',
  'Large body jerks',
  'Loud snoring',
  'Restless sleep',
  'Waking up with terror',
];

const epworthItems = {
  reading: 'Sitting and reading',
  watchingTV: 'Watching TV',
  inactivePublic: 'Sitting inactive in a public place',
  passenger: 'Sitting for an hour as a passenger in a car',
  afternoonLyeDown: 'Lying down in the afternoon to rest',
  talking: 'Sitting & talking to another person',
  afterLunch: 'Sitting quietly after lunch (no alcohol)',
  inTraffic: 'Sitting in a car stopped in traffic',
};
