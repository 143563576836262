import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../services/authService';
import './styles.scss';

const ContinueOnboardingModal = (props) => {
  const [height, setHeight] = useState(1200);
  const body = document.body;
  const { user } = AuthService.getAuth();
  const html = document.documentElement;
  const history = useHistory();
  useEffect(() => {
    setHeight(Math.max(body.scrollHeight, html.scrollHeight));
  }, [html.scrollHeight, body.scrollHeight]);

  const onSubmit = () => {
    history.push(`/newPatientForms`);
  };
 

  return (
    <div
      className="base-modal continue-onboarding"
      style={{ height: height + 'px' }}>
      <div className="base-modal-content">
        <h1 className="terms-title">Complete Paperwork</h1>
        <p className="terms-header">
          Before your appointment, you need to answer a few more questions.
        </p>

        <p className="terms-header">
          These questions are just like those you would answer when visiting an
          in-person doctors office (the forms on the clipboard!).
        </p>

        <div className="submit-btn">
          <button className="btn btn-blue" onClick={() => onSubmit()}>
            Continue to forms
          </button>
        </div>
        <p className="open-upload">
          <button
            type="button"
            onClick={props.onClose}
            className="text-btn  --link"
            style={{ margin: '30px auto 0' }}>
            Finish later
          </button>
        </p>
      </div>
    </div>
  );
};

export default ContinueOnboardingModal;
