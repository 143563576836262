// form Validation service
export default class FormValidationSvc {
  // validate the pattern input entering
  static validateInputEnteringPattern(event: any, fieldNameValueCurrent?: string) {
    return event.target.validity.valid
      ? event.target.value.toString()
      : (fieldNameValueCurrent || '').toString()
  }

  static validateEmail(mail: string) {
    if (mail === '') {
      return true
    }
    const filter = /(.+)@(.+){2,}\.(.+){2,}/
    return filter.test(mail);
  }

  static validatePassword(value: string) {
    let passed = true;
    if (value === '') {
      return true
    }
    [0, 1, 2, 3, 4].forEach((item, ruleIndex) => {
      passed = passed && FormValidationSvc.validatePasswordRuleByIndex(ruleIndex, value)
    });
    return passed;
  }

  // enabled Form
  static validatePasswordRuleByIndex(ruleIndex: number, value: string) {
    let filter;
    switch (ruleIndex) {
      case 0:
        return value.length >= 8;
      case 1:
        filter = /[A-Z]+/;
        return filter.test(value);
      case 2:
        filter = /[a-z]+/;
        return filter.test(value);
      case 3:
        filter = /[0-9]+/;
        return filter.test(value);
      case 4:
        filter = /[`~!@#$%^&*()_\-+=<>?:"{}|,.;'\\[\]·！￥…（）—《》？：“”【】、；‘’，。]/;
        return filter.test(value);
      default:
        return true;
    }
  };


  static normalizePhoneNumberInput = (value: string, previousValue?: string): string => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;


    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)} ${currentValue.slice(6, 10)}`;
  };

  static validatePhone(v: string) {
    if (!v) {
      return true;
    }
    return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v)
  }

  static rawPhone = (value: string) => value.replace(/[^\d]/g, '')
}