// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  verifyUploadCode,
  uploadImageToOgnomyServer,
  updateInsurancePhoto,
} from '../../services/orbitzService';
import userService from '../../services/userService';
import { toast } from 'react-toastify';
import './mobileInsuranceUpload.scss';
import AuthService from '../../services/authService';
import { get } from 'lodash';
import UploadCards from './UploadCards';
import CardList from './CardList';
import ConfirmDetails from './ConfirmDetails';
import { useHistory } from 'react-router-dom';
import CreditForm from './CreditForm';

export const defaultInsuranceState = {
  insuranceCarrier: '',
  insuredID: '',
  insuredName: '',
  effectiveDate: '',
  relationship: '',
  subscriberName: '',
  subscriberDOB: '',
  subscriberId: '',
  groupNumber: '',
  frontPhoto: '',
  backPhoto: '',
  frontPresign: '',
  backPresign: '',
  frontFileId: '',
  backFileId: '',
  frontFilePath: '',
  backFilePath: '',
};

export enum StepName {
  verified = 'verified',
  list = 'list',
  front = 'front',
  back = 'back',
  finalupload = 'finalupload',
  confirmDetails = 'confirmDetails',
  success = 'success',
  credit = 'credit',
}

const UploadInsuranceImage = () => {
  const history = useHistory();
  const params = new URL(window.location).searchParams;
  let userId = params.get('u') || '';
  let alreadyVerified = params.get('v') || '';
  const mobileVerfication = sessionStorage.getItem('mobileVerified');

  const email = get(
    window.location.search.match(new RegExp('e=(.*)')),
    '[1]',
    '',
  );

  const { user } = AuthService.getAuth();

  const [step, setStep] = useState<StepName>(
    Boolean(mobileVerfication) ? StepName.list : StepName.verified,
  );
  const [code, setCode] = useState('');
  const [verified, setVerified] = useState(Boolean(mobileVerfication));
  const [insuranceInfo, setInsuranceInfo] = useState({
    ...defaultInsuranceState,
  });
  useEffect(() => {
    console.log(insuranceInfo)
  }, [insuranceInfo])
  const [loadingState, setLoadingState] = useState(null);
  const [uploadRequest, setUploadRequest] = useState(null);
  const [cardList, setCardList] = useState<any[]>([]);
  const [creditCards, setCreditCards] = useState([]);

  useEffect(async () => {
    if (alreadyVerified) {
      setCardList(user.insuranceCards);
      setStep(StepName.list);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (userId) {
        const { data } = await userService.getUserPayment(userId).catch(() => {});
        const { insuranceCards, creditCards } = data;
        setCardList(insuranceCards);
        setCreditCards(creditCards);
      }
    })();
  }, [verified]);

  const handleChangeCode = (e) => {
    let value = e.target.value;
    if (value.length === 5) return;
    setCode(value);
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      await verifyUploadCode(email, userId, code);
      setStep(StepName.list);
      setVerified(true);
      sessionStorage.setItem('mobileVerified', true);
    } catch (err) {
      console.log(err);
      toast.error('Error code invalid.');
    }
  };

  const handleConfirmBothImages = async () => {
    setUploadRequest('Uploading Images');
    let frontImage, backImage;
    try {
      // Image Uploads //
      // Upload images to Orbtiz
      await updateInsurancePhoto(
        insuranceInfo.frontPresign,
        insuranceInfo.frontFilePath,
      );
      await updateInsurancePhoto(
        insuranceInfo.backPresign,
        insuranceInfo.backFilePath,
      );

      // Create new form data to attach photos to.
      const bodyFront = new FormData();
      const bodyBack = new FormData();

      // Attach photos to body data and check if verfied
      bodyFront.append('1', insuranceInfo.frontFilePath);
      bodyBack.append('2', insuranceInfo.backFilePath);

      if (verified || Boolean(user)) {
        bodyFront.append('verified', true);
        bodyBack.append('verified', true);
      }

      // Upload copy of images to Ognomy
      frontImage = await uploadImageToOgnomyServer(bodyFront);
      backImage = await uploadImageToOgnomyServer(bodyBack);
    } catch (err) {
      toast.error(
        'Unable to upload the images to the Ognomy Server. Please try again.',
      );
    }

    const frontImagePath = frontImage?.data['1'] || '';
    const backImagePath = backImage?.data['2'] || '';

    let cardUpdate = {
      ...insuranceInfo,
      frontPhoto: frontImagePath,
      backPhoto: backImagePath,
      frontPresign: insuranceInfo.frontPresign,
      frontFileId: insuranceInfo.frontFileId,
      backPresign: insuranceInfo.backPresign,
      backFileId: insuranceInfo.backFileId,
    };

    try {
      setUploadRequest('Processing Images');

      // const ocrResponse = await getOrtbitzOCR(
      //   insuranceInfo.frontFileId,
      //   insuranceInfo.backFileId,
      // );

      // const { data } = ocrResponse;

      // const {
      //   id,
      //   externalId,
      //   subscriberId,
      //   payerName,
      //   orbitPayerId,
      //   payerId,
      //   groupNumber,
      //   effectiveDate,
      // } = data;

      cardUpdate = {
        ...cardUpdate,
        insuranceCarrier: insuranceInfo.insuranceCarrier,
        relationship: 'Self',
      }
      //   insuranceCarrier: insuranceInfo.insuranceCarrier || payerName,
      //   relationship: 'Self',
      //   insuredID: payerId,
      //   effectiveDate,
      //   planNumber: groupNumber,
      //   orbitzId: id,
      //   externalId,
      //   subscriberId,
      //   orbitPayerId,
      // };
    } catch (err) {
      console.log(err);
    }

    if (user && alreadyVerified !== '') {
      cardUpdate = {
        ...cardUpdate,
        insuredName: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.firstName,
        dob: user.dateOfBirth,
        gender: user.gender,
        address: user.address,
        city: user.city,
        state: user.state,
        zip: user.zip,
        subscriberName: `${user.firstName} ${user.lastName}`,
      };
    }

    setInsuranceInfo(cardUpdate);
    setUploadRequest(null);
    setStep(StepName.confirmDetails);
  };

  const handleConfirmDetails = async (finalDetails) => {
    const cardUpdate = {
      ...insuranceInfo,
      ...finalDetails,
    };

    const cardListCopy = [...cardList].filter((card) => {
      return card._id !== cardUpdate._id;
    });

    const updatedInsuranceCards = [...cardListCopy, cardUpdate];

    const userIdCheck = user && alreadyVerified !== '';

    try {
      const { data } = await userService.updateUserPayment(
        {
          insuranceCards: updatedInsuranceCards,
          verified: Boolean(user && alreadyVerified) || verified,
        },
        userIdCheck ? user.id : userId,
      );
      setCardList(data);
      setStep(StepName.list);
      setInsuranceInfo(defaultInsuranceState);
      if (alreadyVerified) {
        const authStorage = JSON.parse(localStorage.getItem('O_AUTH_KEY')) || {
          user: {},
        };
        const updatedStorage = {
          ...authStorage,
          user: {
            ...authStorage.user,
            insuranceCards: updatedInsuranceCards,
          },
        };
        localStorage.setItem('O_AUTH_KEY', JSON.stringify(updatedStorage));
      }
    } catch (err) {
      setStep(StepName.list);
      console.error('Images saved, but failed to confirm card details.');
    }
  };

  const handleInitiateNewCard = async (currentCard = {}) => {
    // setLoadingState('requesting');
    try {
      // const { front, back } = await createOrbitzPresigns();
      // const cardData = {
      //   frontPresign: front.presign,
      //   frontFileId: front.fileId,
      //   backPresign: back.presign,
      //   backFileId: back.fileId,
      //   locationId: '',
      //   inProgress: false,
      // };
      // setLoadingState(null);
      setInsuranceInfo((state) => ({
        ...state,
        ...currentCard,
        // ...cardData,
      }));
      setStep(StepName.front);
    } catch (err) {
      toast.error('Could not connect to insurance upload. Please try again.');
    }
  };

  const handleEndUpload = () => {
    if (alreadyVerified) {
      let continueToRegister = params.get('r') || '';
      let continueToSchedule = params.get('s') || '';
      history.push(
        `${
          continueToRegister
            ? '/registration?step=payment'
            : continueToSchedule
            ? '/scheduling?step=payment'
            : 'profile?step=insurance'
        }`,
      );
    } else {
      setStep(StepName.success);
    }
  };

  const stepRender = () => {
    const title = (
      <div>
        <h3 className="--bold">Insurance Card Upload</h3>
      </div>
    );

    switch (step) {
      case StepName.verified:
        return (
          <>
            <div className="form-container">
              <div className="verification">
                {title}
                <p className="center-txt">
                  Please verify your identity by providing the verification code
                  on your computer/tablet screen.
                </p>
                <input
                  className="verification-code"
                  type="text"
                  placeholder="Verification Code"
                  value={code}
                  onChange={handleChangeCode}
                />
              </div>
            </div>
            <button className="btn" onClick={handleVerifyCode}>
              Submit
            </button>
          </>
        );

      case StepName.list:
        return (
          <CardList
            cardList={cardList}
            onInitiateNewCard={handleInitiateNewCard}
            onSetStep={setStep}
            loadingState={loadingState}
            title={title}
            onEnd={handleEndUpload}
          />
        );
      case StepName.front:
      case StepName.back:
      case StepName.finalupload:
        return (
          <UploadCards
            insuranceInfo={insuranceInfo}
            loadingState={loadingState}
            step={step}
            handleSetInfo={setInsuranceInfo}
            title={title}
            onConfirmImages={handleConfirmBothImages}
            onSetStep={setStep}
          />
        );
      case StepName.confirmDetails:
        return (
          <ConfirmDetails
            insuranceInfo={insuranceInfo}
            onConfirmDetails={handleConfirmDetails}
          />
        );
      case StepName.success:
        return (
          <>
            <div className="form-container p-4">
              {title}
              <p>
                Thank you, to continue your journey you may now return to your
                computer.
              </p>
            </div>
          </>
        );
      case StepName.credit:
        return (
          <>
            <CreditForm
              onSkip={() => setStep(StepName.success)}
              onSaveCredit={() => {
                setStep(StepName.success);
              }}
              userId={userId}
              email={email}
            />
          </>
        );
      default: {
        return <></>;
      }
    }
  };

  return (
    <div className="upload-insurance">
      <img className="logo" src="/assets/logo.svg" alt="logo" />
      {uploadRequest ? (
        <div className="form-container">
          <h3 className="--bold" style={{ marginBottom: 0 }}>
            {uploadRequest}...
          </h3>
        </div>
      ) : (
        <>{stepRender()}</>
      )}
    </div>
  );
};

export default UploadInsuranceImage;
