import React, { useState } from 'react';
import './styles.scss';
import dayjs from 'dayjs';
import { Card } from "@mui/material"
import { User } from "../../../services/dto/Security";
import { Appointment } from '../../../services/dto/Common';
import TopCardHeader from './TopCardHeader';
import TopCardMedia from './TopCardMedia';
import SleepSpecialistInfo from '../SleepSpecialistInfo';

export interface ISchedulingTopCardProps {
  provider: User,
  existingApt?: Appointment,
  showHeader?: Boolean,
  showMedia?: Boolean,
  timezone?: string,
}

const SchedulingTopCard: React.FunctionComponent<ISchedulingTopCardProps> = ({
  provider,
  existingApt,
  timezone,
  showHeader=true,
  showMedia=true,
}) => {
  const [showProviderDetails, setShowProviderDetails] = useState<boolean>(false);
  return (
    <div>
      <Card className="top-card center">
        {showHeader && <TopCardHeader 
          provider={provider} 
          onInfo={() => setShowProviderDetails(true)} />}
        {showMedia && <TopCardMedia
          startTime={dayjs(existingApt?.startTime).tz(timezone)} 
          endTime={dayjs(existingApt?.endTime).tz(timezone)} 
          location="Video Meeting" />}
      </Card>
      {showProviderDetails && (
        <SleepSpecialistInfo
          provider={provider}
          hideScheduleBtn={true}
          onClose={() => setShowProviderDetails(false)}
          onSelectProvider={(p: User) => null}
        />
      )}
    </div>
  );
};

export default SchedulingTopCard;
