import React, { Fragment, useEffect, useState } from 'react';
import FormikControl from '../../../components/Formik/FormikControl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { borderClass, manualFormValidation, scrollToError, yupRegex } from '../utils';
import { useQuery } from '../../../services/utils';
import { useHistory } from 'react-router-dom';
import UserService from '../../../services/userService';
import { toast } from 'react-toastify';

const SLEEP_STATUS = ['Better', 'Worse', 'About the same'];

let AMs = []; // ['12:00 AM', '12:30 AM', '1:00 AM', ...]
let PMs = []; // ['12:00 PM', '12:30 PM', '1:00 PM', ...]
for (const hr of Array(12).fill().map((_, i) => (((i - 12) % 12) + 12).toString())) {
  for (const min of ['00', '30']) {
    const timeAM = `${hr}:${min} AM`;
    AMs.push({ key: timeAM, value: timeAM });
    const timePM = `${hr}:${min} PM`;
    PMs.push({ key: timePM, value: timePM });
  }
}
const hourAM = AMs.concat(PMs);
const hourPM = PMs.concat(AMs);

const SleepHistory = (props) => {
  const { apptId, userId, token, sleepHistory } = props;
  const epworth = sleepHistory.epworthSleepinessScale || [];
  const lastEpworth = epworth[epworth.length - 1];

  const history = useHistory();
  const query = useQuery();
  const step = query.get('step');
  const view = query.get('view') || '1';
  const [state, setState] = useState({ sleepStudyFiles: {}, hasFiles: false });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  useEffect(() => {
    if (step === 'sleep-history' && parseInt(view) && parseInt(view) > 2) {
      history.push('/');
    } else if (view === '1') getFileList();
  }, []);

  let validationSchema;
  switch (view) {
    case '1':
      validationSchema = {
        epworthSleepinessScale: Yup.object({
          averageBedtime: Yup.string().required(),
          averageRiseTime: Yup.string().required(),
          numberOfAwakenings: Yup.string().matches(yupRegex.numberDash).required(),
          averageHourSlept: Yup.string().matches(yupRegex.numberDash).required(),
          amountDoseTime: Yup.string().matches(yupRegex.numberDash).required(),
          mySleepStatus: Yup.string().required(),
        }),
      };
      break;
    case '2':
      validationSchema = {
        epworthSleepinessScale: Yup.object({
          reading: Yup.string().required(),
          watchingTV: Yup.string().required(),
          inactivePublic: Yup.string().required(),
          passenger: Yup.string().required(),
          afternoonLyeDown: Yup.string().required(),
          talking: Yup.string().required(),
          afterLunch: Yup.string().required(),
          inTraffic: Yup.string().required(),
          score: Yup.number().required(),
          dateSaved: Yup.date().required(),
        }),
      };
      break;
    default:
      break;
  }

  const initialValues = {
    // view 4
    epworthSleepinessScale: {
      reading: '',
      watchingTV: '',
      inactivePublic: '',
      passenger:  '',
      afternoonLyeDown: '',
      talking:'',
      afterLunch:'',
      inTraffic:'',
      score: lastEpworth?.score || 0,
      averageBedtime: lastEpworth?.averageBedtime || '9:00 PM',
      averageRiseTime: lastEpworth?.averageRiseTime || '7:00 AM',
      numberOfAwakenings: lastEpworth?.numberOfAwakenings || '',
      averageHourSlept: lastEpworth?.averageHourSlept || '',
      amountDoseTime: lastEpworth?.amountDoseTime || '',
      mySleepStatus: '',
      dateSaved: new Date(),
    },
  };
  validationSchema = Yup.object({
    ...validationSchema,
  });

  const getFileList = async () => {
    userId && await UserService.verifyAndExecute({ 
      verificationCode: token,
      tasks: [{ key: 'getFileList', body: `Key=patients/${userId}/sleep-study` }]
    }).then((resData) => {
      const res = resData.getFileList
          let sleepStudyFiles = {};
          let hasFiles = false;
          if (res?.data?.length) {
            res.data.forEach((file) => {
              hasFiles = true;
              sleepStudyFiles[file.name] = {
                name: file.name,
                loading: false,
                uploaded: true,
              };
            });

            setGenericState({ sleepStudyFiles, hasFiles });
          }
        })
        .catch((error) => toast.error('Unable to retrieve file list!'));
  };

  const onSubmit = async (values, setSubmitting) => {
    setSubmitting(false);

    const newValue = values.epworthSleepinessScale;
    let mergedObj2 = {};

    if (view === '2') {
      mergedObj2 = newValue;
      mergedObj2.mySleepStatus = _.get(sleepHistory?.epworthSleepinessScale, '0.mySleepStatus');
    } else {
      mergedObj2 = { ...newValue, ...lastEpworth };
    }

    const newValues = {
      epworthSleepinessScale: [
        mergedObj2
      ]
    }

    const data = {
      userId,
      type: 'Follow-up',
      appointmentId: apptId,
      form: {
        sleepMedicineHistory: {
          ...newValues,
        },
      },
    };

    try {
      (parseInt(view) === 2) ? props.onContinue(data) : props.onContinue(data, `sleep-history&view=${parseInt(view) + 1}`);
      setSubmitting(false);
    } catch (error) {
      toast.error('Unable to save social history. Please try again.');
      setSubmitting(false);
    }
  };

  const getDisableBtn = () => {
    const uploading = Object.values(state.sleepStudyFiles).find(
      (file) => file.loading && !file.uploaded,
    );

    return !!uploading;
  };

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Sleep Update</h3>
          <p>
            Please provide some key details about your body and any recent
            health issues.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={view === "2" ? validationSchema : null}
        enableReinitialize={view === '2' || view === '4'}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          let {
            pastSleepStudy,
            onPAP,
            nearAccidentHistoryDescription: newAccident,
            sleepQuestionare,
            epworthSleepinessScale,
          } = formik.values;
          const disableBtn =
            getDisableBtn(formik.values) || formik.isSubmitting;
          const fileLength = Object.keys(state.sleepStudyFiles)?.length;

          if (fileLength && typeof pastSleepStudy?.response !== 'boolean') {
            formik.setFieldValue('pastSleepStudy', {
              response: true,
              description: 'test',
            });
          }

          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                manualFormValidation(formik);
                formik.handleSubmit();
              }}>
              {/* VIEW ONE */}
              {view === '1' && (
                <div className="form-container sleep-container">
                  {view === '1' && (
                    <Fragment>
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.averageHourSlept',
                          formik,
                        )}
                        control="input"
                        type="text"
                        maxLength={5}
                        label="How many hours of sleep do you get on a typical night?"
                        name="epworthSleepinessScale.averageHourSlept"
                        placeholder="Average Hours Slept"
                        showErrorMsg={false}
                      />

                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.averageBedtime',
                          formik,
                        )}
                        control="select"
                        label="What time do you usually fall asleep?"
                        name="epworthSleepinessScale.averageBedtime"
                        placeholder="Average Bedtime"
                        handlers={formik}
                        value={
                          formik.values['epworthSleepinessScale'].averageBedtime
                        }
                        options={hourPM}
                        showErrorMsg={false}
                      />
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.averageRiseTime',
                          formik,
                        )}
                        control="select"
                        label="What time do you usually wake up?"
                        name="epworthSleepinessScale.averageRiseTime"
                        placeholder="Average Rise Time"
                        handlers={formik}
                        options={hourAM}
                        value={
                          formik.values['epworthSleepinessScale']
                            .averageRiseTime
                        }
                        showErrorMsg={false}
                      />
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.amountDoseTime',
                          formik,
                        )}
                        control="input"
                        type="text"
                        maxLength={5}
                        name="epworthSleepinessScale.amountDoseTime"
                        id="epworthSleepinessScale.amountDoseTime"
                        label="How long does it take to fall asleep on a typical night?  "
                        placeholder="Average Dose Time"
                        showErrorMsg={false}
                      />
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.numberOfAwakenings',
                          formik,
                        )}
                        control="input"
                        type="text"
                        maxLength={5}
                        name="epworthSleepinessScale.numberOfAwakenings"
                        id="epworthSleepinessScale.numberOfAwakenings"
                        label="How many times do you wake up thoughout a typical night? "
                        placeholder="Number of Awakenings"
                        showErrorMsg={false}
                      />
                      <FormikControl
                        className={borderClass(
                          'epworthSleepinessScale.mySleepStatus',
                          formik,
                        )}
                        control="custom-btn"
                        label="Compared to before your last visit, how would you rate your sleep?"
                        name={'epworthSleepinessScale.mySleepStatus'}
                        onClick={async (v) => {
                          await formik.setFieldValue(
                            'epworthSleepinessScale.mySleepStatus',
                            v,
                            false,
                          );
                          formik.setFieldTouched(
                            'epworthSleepinessScale.mySleepStatus',
                          );
                        }}
                        persist={true}
                        options={SLEEP_STATUS}
                      />
                    </Fragment>
                  )}
                </div>
              )}
              {view === '2' && (
                <div className="form-container sleep-history epworth">
                  <p className="mb-3 mt-1">
                    How likely are you to doze off in the situations below?
                  </p>
                  {Object.keys(epworthItems).map((key, i) => {
                    const label = epworthItems[key];
                    const value = epworth[key];
                    const name = `epworthSleepinessScale.${key}`;
                    const borderClassStr = borderClass(name, formik);
                    return (
                      <Fragment key={key}>
                        <p
                          className="title"
                          style={
                            borderClassStr === 'error-border'
                              ? { color: '#FF0404', fontWeight: 500 }
                              : {}
                          }>
                          {label}
                        </p>
                        <FormikControl
                          control="custom-btn"
                          className={borderClassStr}
                          onClick={async (v) => {
                            await formik.setFieldValue(name, v, false);
                            formik.setFieldTouched(name);
                          }}
                          persist={true}
                          options={scale}
                          selected={value}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              )}
              <button
                type="submit"
                className={`submit-btn ${disableBtn ? 'disabled' : ''}`}
                onClick={() => scrollToError()}
                disabled={disableBtn}
                ref={props.continueRef}>
                Continue
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SleepHistory;

const scale = ['None', 'Slight', 'Moderate', 'High'];

const epworthItems = {
  reading: 'Sitting and reading',
  watchingTV: 'Watching TV',
  inactivePublic: 'Sitting inactive in a public place',
  passenger: 'Sitting for an hour as a passenger in a car',
  afternoonLyeDown: 'Lying down in the afternoon to rest',
  talking: 'Sitting & talking to another person',
  afterLunch: 'Sitting quietly after lunch (no alcohol)',
  inTraffic: 'Sitting in a car stopped in traffic',
};
