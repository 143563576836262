import React from 'react';
import { StepName } from './MobileInsuranceUpload';
import { FileUploader } from 'react-drag-drop-files';

type Props = {
  insuranceInfo: any;
  handleSetInfo: any;
  loadingState: null | string;
  step: StepName;
  onSetStep: any;
  title: string;
  onConfirmImages: any;
};

const UploadCards = ({
  insuranceInfo,
  loadingState,
  step,
  handleSetInfo,
  onSetStep,
  title,
  onConfirmImages,
}: Props) => {
  const handleFileChange = async (event: any) => {
    handleSetInfo((state: any) => {
      const newState = { ...state };
      newState[`${StepName[step]}FilePath`] = event;
      return newState;
    });
  };

  const handleConfirmImage = async () => {
    if (insuranceInfo[`${StepName[step]}FilePath`] === '') return;
    if (step === 'front') {
      onSetStep(StepName.back);
    } else {
      onSetStep(StepName.finalupload);
    }
  };

  const front = insuranceInfo[`frontFilePath`];
  const back = insuranceInfo[`backFilePath`];
  const text = (
    <>
      {step === 'front' ? "First" : "Next"} we will need a photo of the{' '}
      <strong>{StepName[step].toUpperCase()}</strong> of your insurance card.
      You can take a photo or use one you already have saved.
    </>
  );
  switch (step) {
    case StepName.front:
      return (
        <>
          <div className="form-container">
            {title}
            <p className="center-txt">{text}</p>
            <div
              className={`upload-insurance_file-upload ${
                front !== '' ? '--contains-photo' : ''
              }`}>
              <FileUploader
                handleChange={handleFileChange}
                value={insuranceInfo?.frontImage}
                children={
                  front !== '' ? (
                    <div className="insurance-upload_preview">
                      <img
                        alt="front-image"
                        className="img insurance-upload_image"
                        src={URL.createObjectURL(front)}
                      />
                    </div>
                  ) : (
                    <span>Tap here to upload a photo of the FRONT of your insurance card.</span>
                  )
                }
                name="file"
                types={['JPG', 'JPEG', 'PNG']}
              />
            </div>
          </div>
          <button
            style={{ lineHeight: 'inherit' }}
            type="button"
            className="btn"
            onClick={handleConfirmImage}
            disabled={
              insuranceInfo[`frontFilePath`] === '' ||
              loadingState === 'requesting'
            }>
            Confirm
          </button>
        </>
      );
    case StepName.back:
      return (
        <>
          <div className="form-container">
            {title}
            <p className="center-txt">{text}</p>
            <div
              className={`upload-insurance_file-upload ${
                back !== '' ? '--contains-photo' : ''
              }`}>
              <FileUploader
                handleChange={handleFileChange}
                className={back !== '' && '--contains-photo'}
                children={
                  back ? (
                    <div className="insurance-upload_preview">
                      <img
                        alt="back-image"
                        className="img insurance-upload_image"
                        src={URL.createObjectURL(back)}
                      />
                    </div>
                  ) : (
                    <span>
                      Tap here to upload a photo of the BACK of your insurance card.
                    </span>
                  )
                }
                name="file"
                value={insuranceInfo?.backImage}
                types={['JPG', 'JPEG', 'PNG']}
              />
            </div>
          </div>
          <button
            style={{ lineHeight: 'inherit' }}
            type="button"
            className="btn"
            onClick={handleConfirmImage}
            disabled={
              insuranceInfo[`backFilePath`] === '' ||
              loadingState === 'requesting'
            }>
            Confirm
          </button>
        </>
      );
    case StepName.finalupload:
      return (
        <>
          <div className="form-container">
            {title}
            <p className="center-txt">
              Please confirm that both insurance card images are correct. If you
              need to retake either one, simply tap on the image.
            </p>
            <div
              className={`upload-insurance_file-upload ${
                front !== '' ? '--contains-photo' : ''
              }`}>
              <FileUploader
                handleChange={handleFileChange}
                value={insuranceInfo?.frontImage}
                children={
                  front !== '' ? (
                    <div className="insurance-upload_preview">
                      <img
                        alt="front-image"
                        className="img insurance-upload_image"
                        src={URL.createObjectURL(front)}
                      />
                    </div>
                  ) : (
                    <span>Tap here to upload a photo of the FRONT of your insurance card.</span>
                  )
                }
                name="file"
                types={['JPG', 'JPEG', 'PNG']}
              />
            </div>
            <div
              className={`upload-insurance_file-upload ${
                back !== '' ? '--contains-photo' : ''
              }`}>
              <FileUploader
                handleChange={handleFileChange}
                value={insuranceInfo?.backImage}
                className={back !== '' && '--contains-photo'}
                children={
                  back ? (
                    <div className="insurance-upload_preview">
                      <img
                        alt="back-image"
                        className="img insurance-upload_image"
                        src={URL.createObjectURL(back)}
                      />
                    </div>
                  ) : (
                    <span>
                      Tap here to upload a photo of the BACK of your insurance card.
                    </span>
                  )
                }
                name="file"
                types={['JPG', 'JPEG', 'PNG']}
              />
            </div>{' '}
          </div>
          <button
            style={{ lineHeight: 'inherit' }}
            type="button"
            className="btn"
            onClick={onConfirmImages}
            disabled={loadingState === 'requesting'}>
            Confirm Images
          </button>
        </>
      );
    default: {
      return <></>;
    }
  }
};

export default UploadCards;
