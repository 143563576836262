import { useRef, useEffect } from 'react';
import Medications from './Medications';
import ProviderHistory from './ProviderHistory';
import SocialHistory from './SocialHistory';
import SleepHistory from './SleepHistory';
import SystemsReview from './SystemsReview';


const FormSelect = (props) => {
  const { apptId, userId, token, medicalHistory, onContinue } = props;
  const elementRef = useRef(null);
  const formRef = useRef(null);
  const handleClick = () => {
    elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    // document.getElementById("ScrollToBottom").style.display = "none";
  };

  const handleScroll = () => {
    if (elementRef && elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();

      const onScreen = (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );

      document.getElementById("ScrollToBottom").style.display = (onScreen) ? "none" : "block";
    }
  };

  useEffect(() => {
    handleScroll();
    let scrollInterval = setInterval(() => handleScroll(), 1000)
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(scrollInterval);
    };
  }, []);    

  const FormElement = (props) => {
    switch (props.step) {
      case 'details':
        return <SystemsReview
          apptId={apptId}
          userId={userId}
          reviewOfSystems={medicalHistory?.reviewOfSystems || {}}
          onContinue={onContinue}
          token={token}
          continueRef={elementRef}
        />
      case 'sleep-history':
        return <SleepHistory
          apptId={apptId}
          userId={userId}
          sleepHistory={medicalHistory?.sleepMedicineHistory || {}}
          onContinue={onContinue}
          token={token}
          continueRef={elementRef}
        />
      case 'social-history':
        return <SocialHistory
          apptId={apptId}
          userId={userId}
          socialHistory={medicalHistory?.socialHistory}
          onContinue={onContinue}
          token={token}
          continueRef={elementRef}
        />
      case 'medications':
        return <Medications
          apptId={apptId}
          userId={userId}
          medications={medicalHistory?.medications || {}}
          onContinue={onContinue}
          token={token}
          continueRef={elementRef}
        />
      case 'provider':
        return <ProviderHistory
          apptId={apptId}
          userId={userId}
          providerHistory={medicalHistory?.providerHistory}
          onContinue={onContinue}
          token={token}
          continueRef={elementRef}
        />
      default:
        return null;
    }
  }

  return <>
    <div id="ScrollToBottom" className="fixed-btn" onClick={() => handleClick()}>
      <h5 style={{fontWeight: 600, width: "fit-content", margin: "auto"}}>Jump to Bottom</h5>
    </div>
    <FormElement step={props.step} ref={formRef} />
  </>
}

export default FormSelect