import React, { useState, useEffect } from 'react';
import AuthService from '../../services/authService';
import CustomBtnSelect from '../../components/Formik/CustomBtnSelect';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import CardList from './CardList';
import SMS from './SMS';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import './insurance.scss';
import ConfirmDetails from './ConfirmDetails';
import { get, isEmpty } from 'lodash';
import DeleteModal from './DeleteModal';

const defaultDetails = {
  insuranceCarrier: '',
  insuredID: '',
  planNumber: '',
  relationship: '',
  subscriberName: '',
  effectiveDate: '',
};

export const useObjectState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const updateProperties = (update: any) => {
    if (typeof update === 'function') {
      setState((prevState: any) => ({
        ...prevState,
        ...update(prevState),
      }));
    } else {
      setState((prevState: any) => ({
        ...prevState,
        ...update,
      }));
    }
  };

  return [state, updateProperties];
};

enum Screens {
  INIT,
  DEFAULT,
  EDIT,
  MANUALADD,
  MOBILEUPLOAD,
  SELFPAY,
}

const Insurance = ({
  onContinue,
  patientInLimitedState,
  defaultCards = [],
}: any) => {
  const { user } = AuthService.getAuth();

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  if (user.insuranceCards.length && pathname === 'registration') {
    onContinue(user);
  }

  const initialScreen =
    user?.selfPay
      ? Screens.SELFPAY
      : user?.insuranceCards?.length || defaultCards.length
        ? Screens.DEFAULT
        : Screens.INIT;
  
  const [state, setState] = useObjectState({
    limitedCoverageStates: [],
    screen: initialScreen,
    medicarePartB: !!user?.medicarePartB,
    medicaid: !!user?.medicaid,
    hasInsurance: !!user?.insuranceCards.length,
    selfPay: !!user?.selfPay,
    insuranceCards: user?.insuranceCards.length
      ? user?.insuranceCards
      : defaultCards.map((card: any) => ({ ...defaultDetails, ...card })),
    openSMS: false,
    verificationCode: '',
    edittedCard: null,
    openDelete: null,
  });

  const [loadingState, setLoadingState] = useObjectState({
    observing: false,
    submittingCard: false,
    updatingUser: false,
  });

  useEffect(() => {
    let setInt: any;
    if (loadingState.observing) {
      setInt = setInterval(async () => {
        const { data }: any = await UserService.getUserPayment(user.id).catch(() => {});
        const { insuranceCards = [] } = data;
        setState({ insuranceCards, hasInsurance: !!insuranceCards.length, screen: Screens.DEFAULT });
      }, 5000);
    } else {
      clearInterval(setInt);
    }
    return () => {
      clearInterval(setInt);
    };
  }, [loadingState]);

  const handleUpdateUser = async (updates: { [key: string]: any }) => {
    setLoadingState({ updatingUser: true });
    try {
      const response = await UserService.updateProfile(updates, user.id);
      const { data } = response;
      AuthService.updateUserInfo(data);
      setState(updates);
      return response;
    } catch (err) {
      toast.error('Unable to update profile.');
    }
    setLoadingState({ updatingUser: false });
  };

  const handleInitiateNewCard = () => {
    // Need to do check for mobile
    if (
      /iPhone/i.test(navigator.userAgent) ||
      /Android/i.test(navigator.userAgent)
    ) {
      history.push(
        `/upload-insurance?v=1${
          pathname === '/registration' 
            ? '&r=1' 
            :
            pathname === '/scheduling' 
            ? '&s=1' 
            : ''
        }`,
      );
    } else {
      setState({ openSMS: true });
    }
  };

  const handleEditCard = (cardId: any) => {
    const card = state.insuranceCards.filter(
      (card: any) => card._id === cardId,
    )[0];
    if (card) {
      setState({ screen: Screens.EDIT, edittedCard: card });
    }
  };

  const handleSubmitCard = async (details: any) => {
    setLoadingState({ submittingCard: true });
    let cards = state.insuranceCards;
    if (details._id) {
      cards = cards.filter((card: any) => card._id !== details._id);
    }
    cards.push(details);
    //Check if card has medicaid or medicare
    let medicaid, medicare;
    if (details.medicaid) {
      medicaid = true;
    }
    if (details.medicarePartB) {
      medicare = true;
    }

    // TODO: Run check on medicare and medicaid and have prompt that asks them to identify which card has which
    // TODO: Run check and see if they have a card that has one option already

    try {
      const response = await handleUpdateUser({
        insuranceCards: cards,
        medicarePartB: medicaid,
        medicaid: medicare,
        chatbotProgress: {
          sheepa: 54,
          sheepaFinished: true,
          sheepaTime: moment().utc(),
        },
      });

      setState({
        edittedCard: null,
        screen: Screens.DEFAULT,
        insuranceCards: get(response, 'data.insuranceCards', []),
      });
    } catch (err) {
      console.log(err);
    }
    setLoadingState({ submittingCard: false });
  };

  const handleDeleteCard = async () => {
    let insuranceCards = state.insuranceCards.filter(
      (card: any, index: any) => index !== state.openDelete,
    );
    await handleUpdateUser({ insuranceCards });
    setState({ openDelete: null });
  };

  const cardCheck =
    user.insuranceCards.filter(
      (card: any) => !Boolean(card.frontPhoto) && !Boolean(card.backPhoto),
    ).length !== 0;

  const screen = () => {
    switch (state.screen) {
      case Screens.INIT:
        return (
          <div className="form-container">
            <CustomBtnSelect
              label="Do you have health insurance?"
              persist={true}
              options={['Yes', 'No']}
              onClick={(e) => {
                const bool = e === 'Yes' ? true : false;
                handleUpdateUser({ hasInsurance: bool });
                if (!bool) {
                  setState({ screen: Screens.SELFPAY });
                } else {
                  setState({ screen: Screens.DEFAULT });
                }
              }}
            />
          </div>
        );
      case Screens.DEFAULT: {
        return (
          <>
            <div className="form-container">
              {!state.openSMS && (
                <p style={{ marginBottom: '1rem' }}>
                  {state.insuranceCards.length === 0
                    ? 'Seems we have no insurance plans on record for you. Tap ”Add Insurance Card” below to add a new plan.'
                    : cardCheck
                      ? `${
                          !cardCheck
                            ? 'You’re all set!'
                            : 'Below are all of the insurance cards you have on file. Plans with missing information are highlighted in red.'
                        }`
                      : 'Below are all of the insurance cards you have on file. Plans with missing information are highlighted in red.'}
                </p>
              )}

              <CardList
                cardList={state.insuranceCards}
                onInitiateNewCard={handleInitiateNewCard}
                onEditCard={handleEditCard}
                onDeleteCard={(index) => setState({ openDelete: index })}
                onUpload={handleInitiateNewCard}
                openSMS={state.openSMS}
                cardCheck={cardCheck}
              />
              {state.openSMS && (
                <SMS
                  observing={loadingState.observing}
                  user={user}
                  onSubmit={() => setLoadingState({ observing: true })}
                  onSetManual={() => {
                    setLoadingState({ observing: false });
                    setState({ screen: Screens.MANUALADD, openSMS: false });
                  }}
                />
              )}

              {state.openSMS ? (
                <p style={{ marginTop: '1rem' }}>
                  <button
                    className="text-btn --link"
                    onClick={() => {
                      setLoadingState({ observing: false });
                      setState({ openSMS: false });
                    }}>
                    Back To List
                  </button>
                </p>
              ) : (
                <p style={{ marginTop: '1rem' }}>
                  Don't have insurance?{' '}
                  <button
                    className="text-btn --link"
                    onClick={() =>
                      setState({ screen: Screens.INIT, hasInsurance: null })
                    }>
                    Click here to update.
                  </button>
                </p>
              )}
            </div>
            {(pathname === '/registration' || pathname === '/scheduling') && (
              <button
                style={{ lineHeight: 'inherit' }}
                type="button"
                className="btn"
                onClick={() => onContinue(user)}>
                  Done
              </button>
            )}
          </>
        );
      }
      case Screens.SELFPAY: {
        return (
          <>
            <div className="form-container">
              <p>
                <b>No problem!</b> We have a terrific self pay option. Here is a
                document explaining how Ognomy self pay works.
              </p>
              <p className="self-pay-doc">
                <a
                  href={`${SERVER_URL}/conditions/${
                    patientInLimitedState ? 'limited-self-pay' : 'self-pay'
                  }`}
                  target="_blank"
                  rel="noreferrer">
                    Self Pay Document
                </a>
              </p>
              <p>
                We will also send a copy of this to your email for future
                reference.
              </p>
              <CustomBtnSelect
                onClick={(e) => {
                  const values = { selfPay: e === 'Elect to self-pay' };
                  setState(values);
                  handleUpdateUser(values);
                }}
                persist={true}
                options={['Elect to self-pay', 'Need more time']}
                selected={user.selfPay ? 'Elect to self-pay' : 'Need more time'}
              />
              <p>
                Have insurance?{' '}
                <button
                  className="text-btn --link"
                  onClick={() => setState({ screen: Screens.INIT })}>
                    Click here to update.
                </button>
              </p>
            </div>
            {(pathname === '/registration' || pathname === '/scheduling') && (
              <button
                style={{ lineHeight: 'inherit' }}
                type="button"
                className="btn"
                onClick={() => onContinue(user)}>
                  Done
              </button>
            )}
          </>
        );
      }
      case Screens.EDIT: {
        return (
          <ConfirmDetails
            insuranceInfo={state.edittedCard}
            loadingState={loadingState.submittingCard}
            onConfirmDetails={handleSubmitCard}
          />
        );
      }
      case Screens.MANUALADD: {
        return (
          <ConfirmDetails
            insuranceInfo={{}}
            loadingState={loadingState.submittingCard}
            onConfirmDetails={handleSubmitCard}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <div className="insurance">
      {screen()}
      {state.openDelete !== null && (
        <DeleteModal
          onDelete={handleDeleteCard}
          onClose={() => setState({ openDelete: null })}
        />
      )}
    </div>
  );
};

export default Insurance;
