import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import { PatternFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import './styles.scss';
import { yupRegex } from '../RegistrationComponents/utils';

const FormattedInput = (props) => {
  const {
    onChange,
    formatType,
    watchYear,
    minYear = 1920,
    maxYear,
    ...rest
  } = props;
  const dateFormat = (v) => {
    let value = v.formattedValue.split('/');
    if (value[0] > 12) {
      const changes = value[0];
      value[0] = '0' + changes.charAt(0);
      value[1] = '' + changes.charAt(1);
    }

    if (!watchYear && value[1] > 31) {
      value[1] = '31';
    }

    if (minYear && value[2] < minYear) {
      value[2] = minYear;
    }

    if (maxYear && value[2] > maxYear) {
      value[2] = maxYear;
    }

    v.formattedValue = value.join('/');
    return v;
  };

  return (
    <PatternFormat
      {...rest}
      mask="_"
      onValueChange={(v) =>
        formatType === 'date' ? onChange(dateFormat(v)) : onChange(v)
      }
    />
  );
};

const Input = (props) => {
  const {
    label,
    name,
    icon,
    showErrorMsg,
    containerStyle,
    labelError = false,
    labelStyle = {},
    ...rest
  } = props;
  
  const labelStyles = labelError
    ? {
        color: '#FF0404',
        fontWeight: 500,
        ...labelStyle,
      }
    : {};

  const onKeyDownHandler = (e) => {
    if (rest.onKeyDown) {
      rest.onKeyDown(e);
    } else if (
      (rest.type === 'number' && e.key === 'e') ||
      (rest.type === 'char' && !yupRegex.letters.test(e.key))
    ) {
      e.preventDefault();
    }
  };
  return (
    <div className="formik-control" style={containerStyle}>
      {label && <label style={labelStyles} htmlFor={name}>{label}</label>}
      {icon && <div className="icon">{icon}</div>}
      {rest.format ? (
        <FormattedInput id={name} name={name} {...rest} />
      ) : (
        <Field id={name} name={name} {...rest} onKeyDown={onKeyDownHandler} />
      )}
      {!!showErrorMsg !== false && (
        <ErrorMessage component={TextError} name={name} />
      )}
    </div>
  );
};

/** Can take all other input element attributes */
Input.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  showErrorMsg: PropTypes.bool,
  format: PropTypes.string,
  formatType: PropTypes.string,
  value: PropTypes.any,
};

FormattedInput.propTypes = {
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
};

export default Input;
