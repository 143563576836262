import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Header from '../../components/Header';
import RegistrationDoneBanner from '../../components/RegistrationDoneBanner';
import './styles.scss';

interface IRegistrationDonePageProps extends RouteComponentProps<any> {
}

const RegistrationDonePage: React.FunctionComponent<IRegistrationDonePageProps> = (props) => {  

  return (
    <React.Fragment>     
      <div className="padding-wraper">
      
        <Header
          currentPage="Dashboard"
          headerType="PatientDashboard" />
        
        <RegistrationDoneBanner />
      </div> 
    </React.Fragment>
  );
};

export default withRouter(RegistrationDonePage);
