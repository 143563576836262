import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserService from '../../services/userService';
import { PaperWorkPdfGenerator }  from './PaperWorkPdfGenerator';
import Header from '../../components/Header';
import FormSelect from './FollowUpPages/FormSelect';
import { get } from 'lodash';
import CompletedForms from './CompletedForms';
import ProgressBar from '../../components/ProgressBar';

const NoAuthNewPatientForms = (props) => {
  const { user, appointment, goTo, inOffice } = props;
  const { token } = useParams();

  const [currStep, setCurrStep] = useState(null);
  const [medicalHistory, setMedicalHistory] = useState(props.medicalHistory);
  useEffect(() => setMedicalHistory(props.medicalHistory), [props.medicalHistory])

  const progressSteps = [
    'details',
    'sleep-history',
    'social-history',
    'medications',
    'provider',
    'complete'
  ];

  const getNextStep = (step) => get(progressSteps, progressSteps.findIndex(s => s === step) + 1, 'complete');

  useEffect(async () => {
    const tmp = (appointment.followUpProgress || '').split('&')[0];
    if (!progressSteps.includes(tmp)) {
      setCurrStep(progressSteps[0]);
      goTo(progressSteps[0]);
    } else {
      setCurrStep(tmp);
      goTo(appointment.followUpProgress);
    }
  }, []);

  const updateHistory = (v) => setMedicalHistory({ ...medicalHistory, ...v });

  const onContinue = async (step, data) => {
    const formDataRecord = await UserService.verifyAndExecute({ 
      verificationCode: token,
      tasks: [{ key: 'updateMedicalHistory', body: data }]
    }).then(res => res.data.updateMedicalHistory);

    updateHistory(formDataRecord?.data?.form || {});
    await UserService.verifyAndExecute({ 
      verificationCode: token,
      tasks: [{ key: 'updateAppointmentProgress', body: { followUpProgress: step, medicalId: formDataRecord?.data?.id } }]
    });

    // Create PDF upon completion
    if (step === 'complete') {
      try {
        const generator = new PaperWorkPdfGenerator(user, {
          ...formDataRecord.form,
          ...medicalHistory,
        });
        generator.draw();
        const file = new File(
          [generator.doc.output('blob')],
          `Follow-up Patient Form.pdf`,
          { type: 'application/pdf' },
        );
        const body = {
          category: 'OTHER',
          patientId: user.id,
          usedFor: 'patient form',
        };
        await UserService.createForm(file, body).catch(err => toast.error(err.message));
      } catch (error) {
        console.log(error.message);
      }
    }

    if (step && !step.includes('&')) setCurrStep(step);
    return goTo(step);
  };

  return (!user) ? <div></div> : (
    <Fragment>
      <Header
        currentPage=""
        headerType="FollowUpForms"
        customClass="registeration-header"
      />
      {currStep && <ProgressBar
        numberOfSteps={progressSteps.length}
        activeStep={progressSteps.findIndex(s => s === currStep)}
        complete={currStep === 'complete'}
      />}
      <Fragment>
        <FormSelect
          step={currStep}
          medicalHistory={medicalHistory}
          apptId={appointment.id}
          userId={user.id}
          token={token}
          onContinue={(data, nextStep) => onContinue(nextStep || getNextStep(currStep), data)}
        />
        {currStep === 'complete' && <CompletedForms inOffice={inOffice} />}
      </Fragment>
    </Fragment>
  );
};

export default NoAuthNewPatientForms;