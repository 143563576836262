import React, { useState } from 'react';
import './styles.scss';
import { Appointment } from "../../../services/dto/Common";
import UserService from '../../../services/userService';
import { getName , showApiError} from "../../../services/utils";
import _ from 'lodash';

export interface TodoItem {
  title?: string
  completed: boolean
  type: string
  defaultTitle?: string,
}

export interface ILeftStepListProps {
  apt: Appointment;
  steps: TodoItem[]
  waiting: boolean;
}


const MESSAGE_01 =
  'Hold on, your physician is finalizing all the information from your consult, your follow up steps will be in shortly.';
const MESSAGE_02 =
  'Thank you for your visit, we hope you enjoyed your teleconsult experience. The office will be in contact with you regarding next steps.';

const MESSAGE_03 = 'How happy were you with your appointment experience today?';

const MESSAGE_DONE = 'Thank you for your feedback! Your Physician is finalizing your consultation report, the follow up steps will be in shortly!';

export const LeftStepList: React.FunctionComponent<ILeftStepListProps> = (props) => {

  const { apt } = props;
  const [feedback, setFeedback] = useState(false)
  const scale = [1, 2, 3, 4, 5];

  const onRate = (e:any) => {
    const body = {
      patientId: apt.patientId,
      appointmentId: apt.providerId,
      patientResponse: e,
    }
    UserService.createFeedback(body)
      .then(() => {
        setFeedback(true);
      })
      .catch(e => {
        showApiError(e)
      })
  }


  const renderContent = () => {
    return <>
      {props.steps.map((item, i) => (
        <div className="check-list" key={i}>
          <div className="check-row">
            <div className="checkbox-wrap-round">
              <input type="checkbox" id={`left-step-list-check-${i}`}
                checked={item.completed} disabled />
              <label htmlFor={`left-step-list-check-${i}`}>
                {item.title}
              </label>
            </div>
          </div>
        </div>
      ))}
    </>
  }
  return (
    <div className="left-step-list">
      <div className="white-box mt-0 pt-5" >
        {!feedback && (
          <div className="scale-container">
            <div className="scale-question">{MESSAGE_03}</div>
            <div className="scale-box-line">
              {_.map(scale, (e) => (
                <div
                  key={'scale' + e}
                  className="scale-box_container"
                  onClick={() => onRate(e)}>
                  <div className="scale-box">
                    <div className="scale-circle">{e}</div>
                  </div>
                  {scale.length !== e && <hr className="dash" />}
                </div>
              ))}
            </div>
            <div className="scale-label">
              <div className="scale-txt">Unsatisfied</div>
              <div className="scale-txt mg-right">Acceptable</div>
              <div className="scale-txt mg-right_extactic">Great</div>
            </div>
          </div>
        )}
        {/* {skip && !feedback && <div className="thin-title">{props.waiting ? MESSAGE_01 : MESSAGE_02}</div>} */}
        {feedback && (
          <div className="thin-title">
            {props.steps.length
              ? MESSAGE_DONE
              : 'You are all set! At the moment your sleep specialist has not required any next steps. If any additional information is needed, our team will reach out via phone or email. Have a great day!'}
          </div>
        )}
        <h4 style={{marginBottom: 10, color: '#3f5c76', fontWeight: 500}}>Next Steps:</h4>
        {!props.waiting && renderContent()}
      </div>
    </div>
  );
};

export default LeftStepList;
