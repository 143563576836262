import React, { useState, useEffect, Fragment } from 'react';
import { SERVER_URL } from '../../../config';
import service from '../../../services/userService';
import './styles.scss';

const PolicyModal = (props) => {
  const [height, setHeight] = useState(1200);
  const [termsChecked, setTermsChecked] = useState(false);
  const body = document.body;
  const html = document.documentElement;

  useEffect(() => {
    setHeight(Math.max(body.scrollHeight, html.scrollHeight));
  }, [html.scrollHeight, body.scrollHeight]);

  const onSubmit = async () => {
    const data = {
      noShowConsent: true,
      termsConsent: true,
    };
    try {
      await service.updateProfile(data, props.userId);
      props.updateAuthUser(data);
    } catch (error) {
      props.updateAuthUser(data);
      console.log(error);
    }
  };

  return (
    <div className="base-modal no-show-modal">
      <div className="base-modal-content no-show-modal-content">
        {!props.termsConsent && (
          <Fragment>
            <h1 className="terms-title">Updated Terms</h1>
            <p className="terms-header">
              We have recently updated our terms and conditions, including a new
              no-show policy. Please read and agree to the following:
            </p>

            <div className="input-wrap">
              <input
                type="checkbox"
                id="checkIsAgreedToTermsAndConditions"
                checked={termsChecked}
                onChange={(e) => setTermsChecked(e.target.checked)}
              />
              <label htmlFor="checkIsAgreedToTermsAndConditions">
                I've read and agreed to the following:
              </label>
              <div className="policyLinks">
                <a
                  href={`${SERVER_URL}/conditions/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                  className="blue-txt">
                  Privacy Policy
                </a>
                <a
                  href={`${SERVER_URL}/conditions/notice-of-privacy-practices`}
                  target="_blank"
                  rel="noreferrer"
                  className="blue-txt">
                  Notice of Privacy Practices
                </a>
                <a
                  href={`${SERVER_URL}/conditions/terms-of-use`}
                  target="_blank"
                  rel="noreferrer"
                  className="blue-txt">
                  Terms of Use
                </a>
                <a
                  href={`${SERVER_URL}/conditions/end-user-agreement`}
                  target="_blank"
                  rel="noreferrer"
                  className="blue-txt">
                  End User License Agreement
                </a>
              </div>
            </div>
          </Fragment>
        )}

        <div className="submit-btn">
          <button
            className="btn btn-blue"
            disabled={!props.termsConsent && !termsChecked}
            onClick={() => onSubmit()}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyModal;
