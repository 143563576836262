import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import {User} from "../../../services/dto/Security";
import {getName} from "../../../services/utils";

export interface IScheduleAnAppointmentProps {
  provider: User
}

export const ScheduleAnAppointment = (props:IScheduleAnAppointmentProps) => {
  const { provider } = props;
  const [showSimpleMode, setShowSimpleMode] = useState<boolean>(false);

  return (
    <div
      className={`schedule-an-appointment ${
        showSimpleMode ? 'simple-status' : ''
      }`}
      onClick={(event) => {
        setShowSimpleMode(false);
        event.preventDefault();
      }}>
      <a
        href="#javascript"
        className="icons mobile-close"
        onClick={(event) => {
          setShowSimpleMode(true);
          event.preventDefault();
          event.stopPropagation();
        }}>
        &nbsp;
      </a>
      <h3 className="h3">Schedule An Appointment</h3>
      <h2 className="h2">With Dr. {getName(provider)}</h2>
      <NavLink
        to={`/registration?step=register&refType=physician&refId=${provider.id}`}
        className="btn btn-blue">
        Register
        <i className="icons icon-right-arrow" />
      </NavLink>
      <div className="txt">
        Already have account?{' '}
        <NavLink to="/signInPage" className="login-link">
          Login
        </NavLink>
      </div>
    </div>
  );
};

export default ScheduleAnAppointment;
