import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/Formik/FormikControl';
import formValidationSvc from '../../services/formValidationSvc';
import './styles.scss';
import { showApiError, showSuccess, useIsMounted } from "../../services/utils";
import { V_CODE_LENGTH } from "../../components/RegistrationProgressComponents/FormAccountVerification";
import UserService from "../../services/userService";
import { yupRegex } from './utils';

import { borderClass, scrollToError } from '../../components/RegistrationComponents/utils';
import { useDispatch } from "react-redux";
import RadioButtons from '../../components/Formik/RadioButtons';


export const ResetPasswordBody = (props) => {
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Must be a valid email'),
    phone: Yup.string().matches(yupRegex.phone)
  });
  const [emailAddress, setEmailAddress] = useState('');
  const [sendType, setSendType] = useState('email');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const [resetpassword, setResetpassword] = useState(false);
  const isMounted = useIsMounted();
  const history = useHistory();

  const isPwdOk = !!password && formValidationSvc.validatePassword(password);
  const isConfirmPwdOk = !!confirmPwd && formValidationSvc.validatePassword(confirmPwd) && confirmPwd === password;
  const isEmailOk = !!emailAddress && formValidationSvc.validateEmail(emailAddress)
  const isCodeOk = code.length === V_CODE_LENGTH
  const isStep2 = step === 1;

  useEffect(() => {
    const query = new URLSearchParams(history.location.search)
    if (query.get('reset')) {
      setStep(1)
      setResetpassword(true);
    }
  })

  const sendEmail = (values) => {
    setLoading(true);
    UserService.sendVerificationCode(values.email, 'resetPassword', sendType) // always 'email'
      .then(() => {
        setComplete(true);
        if (isMounted.current) {
          showSuccess(
            `Password reset link sent, please check your ${sendType}.`,
          );
          setComplete(true);
        }
      })
      .catch((e) => {
        if (isMounted.current) {
          showApiError(e);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  const resetForm = () => {
    setStep(0);
    setEmailAddress('');
    setPassword('');
    setConfirmPwd('');
    setCode('');
  };

  return (
    <div className="signin-banner">
      <div className="header-info">
        <div className="description">
          <h3>Forgot Password</h3>
          {!complete && (
            <>
              <p>Please enter the email address associated with</p>
              <p>your account. If an account exists, a verification code</p>
              <p>will be sent to your email inbox or phone.</p>
            </>
          )}
          {complete && (
            <>
              <p>An email containing a link to reset your</p>
              <p>password has been sent to your email inbox.</p>
              <p>
                Be sure to reset within the next 10 minutes otherwise the link
                will expire.
              </p>
            </>
          )}
        </div>
      </div>

      {!complete && (
        <div className="form-container">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              sendEmail(values);
            }}>
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>

                <FormikControl
                  className={borderClass('email', formik)}
                  control="input"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                />
                <div className="bottom-controller">
                  <a href="/signInPage" className="submit-btn-white">
                    {' '}
                    Cancel
                  </a>
                  <button
                    type="submit"
                    onClick={() => scrollToError(false)}
                    className="submit-btn">
                    Send Reset Link
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};
