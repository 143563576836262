//@ts-nocheck
import React, { useState } from 'react';
import FormikControl from '../../components/Formik/FormikControl';
import { Form, Formik } from 'formik';
import CustomBtnSelect from '../../components/Formik/CustomBtnSelect';
import PaymentForm from '../../components/PaymentForm';
import PaymentInfoModal from '../../components/RegistrationProgressComponents/Modals/PaymentInfoModal';

type Props = {
  onSaveCredit: () => void;
  userId: string;
  onSkip: () => void;
  email?: string;
};

const CreditForm = ({ userId, onSaveCredit, onSkip, email = '' }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="form-container">
        <p className="--bold">Payment Method</p>
        <p>
          It appears you have no payment method on file, you can add one now or
          click 'Skip' below the form to continue.
          <p onClick={() => setOpen(true)}>Why do we need this?</p>
        </p>
        <PaymentForm
          onSubmit={onSaveCredit}
          noBottomText
          userId={userId}
          email={email}
        />
        <button
          style={{ lineHeight: 'inherit', margin: 'auto', display: 'block' }}
          type="button"
          onClick={onSkip}
          className="text-btn --link">
          Skip
        </button>
        <PaymentInfoModal open={open} onClose={() => setOpen(false)} />
      </div>
    </>
  );
};

export default CreditForm;
