import React, { CSSProperties, useEffect, useState } from 'react';
import './styles.scss';
import AdminUsersService from '../../services/adminUsersService';
import { User } from '../../services/dto/Security';
import { getName, useIsMounted } from '../../services/utils';

export interface ProfileIconProps {
  user: User;
  color?: string;
  fontSize?: number;
}

/**
 * user head component
 */
export const ProfileIcon = (props: ProfileIconProps) => {
  const url = props?.user?.headUrl || '';

  const render = () => {
    const { color, fontSize } = props;
    const initials = getName(props?.user).split(' ').map(word => word.substring(0, 1))
    const text = initials.join('').substring(0, 2)
    const style: CSSProperties = {
      backgroundImage: `url('${url}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    };
    const pStyle: CSSProperties = { fontSize };
    return (
      <div className={`user-header-container ${color}`} style={style}>
        {!url.includes('https://') && <p style={pStyle}>{text}</p>}
      </div>
    );
  };

  return render();
};
