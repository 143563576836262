import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../Formik/FormikControl';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import { borderClass, scrollToError } from './utils';
import './styles.scss';
import { PasswordRule } from '../../config';
import FormValidationSvc from '../../services/formValidationSvc';
import AuthService from '../../services/authService';
import { useDispatch } from 'react-redux';
import { injectAuthUser, updateAuthUser } from '../../store/actions/auth';
import { useQuery } from '../../services/utils';

const Verification = (props) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
    passGuide: false,
  });
  const initialValues = {
    verificationCode: '',
    password: '',
    confirmPassword: '',
  };

  const validatePasswordRule = (ruleIndex, v) =>
    FormValidationSvc.validatePasswordRuleByIndex(ruleIndex, v);

  Yup.addMethod(Yup.string, 'validatePassword', function (errMsg) {
    const msg = errMsg || 'Password is invalid';
    return this.test('test-password', msg, function (v) {
      const valid = v && FormValidationSvc.validatePassword(v);
      const { path, createError } = this;
      return valid ? true : createError({ path, message: msg });
    });
  });

  const validationSchema = Yup.object({
    verificationCode: Yup.string().required(),
    password: Yup.string().validatePassword(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')])
      .required(),
  });

  const createPatientReferral = async (patientId) => {
    const refType = query.get('refType') || props.refType;
    const refId = query.get('refId') || props.refId;

    if (patientId && refType && refId) {
      try {
        const referral = await UserService.createReferral({
          patientId,
          refType,
          refId,
        });
        return referral.data;
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const onSubmit = (values, setSubmitting) => {
    const refId = query.get('refId');

    const credentials = {
      password: values.password,
      email: props.email,
    };

    UserService.signUp({
      ...credentials,
      verificationCode: values.verificationCode,
      platform: 'Web-Patient',
      termsConsent: true,
      existingUserId: props.existingUserId,
      partner: refId || '',
    })
      .then(async (res) => {
        const auth = await AuthService.login(credentials);
        const data = auth?.data;

        await AuthService.saveAuth(data);
        dispatch(injectAuthUser(data?.user));

        const referral = await createPatientReferral(data?.user?.id);
        const updatedUser = {
          bypassPlatformFees: referral?.bypassPlatformFees,
        };

        AuthService.updateUserInfo(updatedUser);
        dispatch(updateAuthUser(updatedUser));
        props.onContinue();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setSubmitting(false));
  };

  const sendVerificationCode = () =>
    UserService.sendVerificationCode(props.email).catch((error) =>
      toast.error(
        'We are unable to send the verification code. Please try again!',
      ),
    );

  const PasswordIcon = (name) => (
    <i
      href="#javascript"
      className={`right-eye ${showPassword[name] ? 'no-eye' : ''}`}
      onClick={() =>
        setShowPassword((state) => ({ ...state, [name]: !showPassword[name] }))
      }
    />
  );

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Account Verification</h3>
          <p>
            Enter the verification code sent to your inbox and set a password
            for your account.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <div className="form-container">
              <FormikControl
                className={borderClass('verificationCode', formik)}
                control="input"
                name="verificationCode"
                placeholder="Verification code"
                showErrorMsg={false}
              />
              <div className="breaker"></div>
              <FormikControl
                className={borderClass('password', formik)}
                control="input"
                type={!showPassword.password ? 'password' : 'text'}
                name="password"
                placeholder="Password"
                icon={PasswordIcon('password')}
                onFocus={() =>
                  setShowPassword((state) => ({ ...state, passGuide: true }))
                }
                onBlur={(e) => {
                  formik.handleBlur(e);
                  setShowPassword((state) => ({ ...state, passGuide: false }));
                }}
                showErrorMsg={false}
              />
              {showPassword.passGuide && (
                <div className="password-guide">
                  <div className="bold-title">Password must have</div>
                  <ul>
                    {PasswordRule.map((item, index) => (
                      <li key={index}>
                        <div className="list-item">
                          {validatePasswordRule(
                            index,
                            formik.values.password,
                          ) && <i className="icons icon-done"></i>}
                          <span
                            className={`txt ${
                              validatePasswordRule(
                                index,
                                formik.values.password,
                              )
                                ? 'gray'
                                : ''
                            }`}>
                            {item}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <FormikControl
                className={borderClass('confirmPassword', formik)}
                control="input"
                type={!showPassword.confirmPassword ? 'password' : 'text'}
                name="confirmPassword"
                placeholder="Confirm Password"
                icon={PasswordIcon('confirmPassword')}
                showErrorMsg={false}
              />

              <p className="resend-code">
                Did not receive the verification code?{' '}
                <a
                  href="#"
                  className={`blue-txt`}
                  onClick={sendVerificationCode}>
                  Click here
                </a>{' '}
                to resend.
              </p>
            </div>
            <button
              type="submit"
              className="submit-btn"
              onClick={() => scrollToError(false)}
              disabled={formik.isSubmitting}>
              Continue
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Verification;
