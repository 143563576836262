import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './styles.scss';

export interface DropdownSelectOption {
  label: string,
  value: any
}

export interface DropdownSelectProps {
  label: string;
  value: any;
  items: string[] | DropdownSelectOption[];
  placeholder?: string;
  disabled?: boolean;
  classNameContainer?: string;
  onChange: (value: any) => void;
}

const DropdownSelect: React.FunctionComponent<DropdownSelectProps> = (
  props: DropdownSelectProps,
) => {
  const {label, value, items, placeholder, disabled, classNameContainer} = props;

  /**
   * On click item
   * @param value the item's value
   */
  const onClickItem = (value: any) => {
    props.onChange(value);
  };

  const getLabelByValue = () => {
    const item = (items as any[]).find((i: any) => i === value || i.value === value)
    if (item) {
      return item.label || item
    }
    return undefined
  }

  return (
    <div className="dropdown-gray-block">
      <div className="label-txt">
        {label}
      </div>
      <Dropdown className={`dropdown-select ${classNameContainer ? classNameContainer : ''}`}>
        <Dropdown.Toggle variant="outline"
                         className={`${value ? '' : 'non-value'}`}
                         disabled={disabled}>
          <div className="toggle-label">{getLabelByValue() || (placeholder ? placeholder : 'Select')}</div>
          <span className="icon-drop"/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {(items as any[]).map((item: any, index: number) => (
            <Dropdown.Item
              key={index}
              as="button"
              className={value === item ? 'selected' : ''}
              onClick={() => onClickItem(item)}>
              {item.label || item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownSelect;
