import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { PasswordRule } from '../../config';
import FormikControl from '../../components/Formik/FormikControl';
import FormValidationSvc from '../../services/formValidationSvc';
import './styles.scss';
import { showApiError, showSuccess, useIsMounted } from "../../services/utils";
import { V_CODE_LENGTH } from "../../components/RegistrationProgressComponents/FormAccountVerification";
import UserService from "../../services/userService";
import AuthService from "../../services/authService";
import { injectAuthUser } from "../../store/actions/auth";
import { borderClass, scrollToError } from '../../components/RegistrationComponents/utils';
import { useDispatch } from "react-redux";


export const PasswordForm = (props) => {
  const initialValues = {
    password: '',
    cpassword: '',
  };

  const [showPassword, setShowPassword] = useState({
    passGuide: false,
  });
  
  const validatePasswordRule = (ruleIndex, v) =>
  FormValidationSvc.validatePasswordRuleByIndex(ruleIndex, v);

  Yup.addMethod(Yup.string, 'validatePassword', function (errMsg) {
    const msg = errMsg || 'Password is invalid';
    return this.test('test-password', msg, function (v) {
      const valid = v && FormValidationSvc.validatePassword(v);
      const { path, createError } = this;
      return valid ? true : createError({ path, message: msg });
    });
  });

  const validationSchema = Yup.object({
    password: Yup.string().validatePassword(),
    cpassword: Yup.string().required("This field is required").when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    })
  });

  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    password: false,
    cpassword: false,
  });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const [resetpassword, setResetpassword] = useState(false);
  const isMounted = useIsMounted();
  const history = useHistory();



  useEffect(() => {
    const query = new URLSearchParams(history.location.search)
    if (query.get('reset')) {
      setStep(1)
      setResetpassword(true);
    }
  })

  const onUpdatePassword = (values) => {
    setLoading(true);
    let passwordUpdate = values.skipVerification ? UserService.updatePassword : UserService.forgotPassword;
    passwordUpdate({
      email: values.email,
      password: values.password,
      verificationCode: values.code,
    })
      .then(() => AuthService.login({ email: values.email, password: values.password }))
      .then(rsp => {
        AuthService.saveAuth(rsp.data);
        dispatch(injectAuthUser(rsp.data.user))
        setComplete(true);
      })
      .catch((e) => {
        showApiError(e);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  const showEye = (type = 'password') => {
    return (<a href="#javascript"
    className={`icons right-eye ${state[type] ? 'no-eye' : ''}`}
    onClick={(event) => {
      state[type] =  !state[type];
      setGenericState(state);
      event.preventDefault();
    }}>
  </a>)
  }

  return (
    <div className="signin-banner">
      <div className="header-info">
        <div className="description">
          <h3>Reset Password</h3>
          {!complete && (
            <>
              <p>Please enter your new password, you will need</p>
              <p>to enter it twice to be sure we got it right.</p>
            </>
          )}
          {complete && (
            <>
              <p>Password reset complete! You are all set to</p>
              <p>access your Ognomy account.</p>
            </>
          )}
        </div>
      </div>
      {complete && (
        <div className="complete-container">
          <a
            href={`/patientDashboardPage`}
            className="submit-btn">
            Continue to Dashboard
          </a>
        </div>
      )}
      {!complete && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            //</div>onSubmit(values, props.setSubmitting);
            let { userData } = props;
            userData.password = values.password;
            onUpdatePassword(userData);
            props.onUpdateData(userData);
          }}>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="form-container">
                <FormikControl
                  className={borderClass('password', formik)}
                  control="input"
                  type={state.password ? 'text' : 'password'}
                  name="password"
                  placeholder="New Password"
                  icon={showEye()}
                  onFocus={() =>
                    setShowPassword((state) => ({ ...state, passGuide: true }))
                  }
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setShowPassword((state) => ({
                      ...state,
                      passGuide: false,
                    }));
                  }}
                />
                {showPassword.passGuide && (
                  <div className="password-guide">
                    <div className="bold-title">Password must have</div>
                    <ul>
                      {PasswordRule.map((item, index) => (
                        <li key={index}>
                          <div className="list-item">
                            {validatePasswordRule(
                              index,
                              formik.values.password,
                            ) && <i className="icons icon-done"></i>}
                            <span
                              className={`txt ${
                                validatePasswordRule(
                                  index,
                                  formik.values.password,
                                )
                                  ? 'gray'
                                  : ''
                              }`}>
                              {item}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <FormikControl
                  className={borderClass('cpassword', formik)}
                  control="input"
                  type={state.cpassword ? 'text' : 'password'}
                  name="cpassword"
                  icon={showEye('cpassword')}
                  placeholder="Confirm New Password"
                />
              </div>
              <div className="bottom-controller">
                <a href="/signInPage" className="submit-btn-white">
                  Cancel
                </a>
                <button
                  type="submit"
                  onClick={() => scrollToError()}
                  className="submit-btn">
                  Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
