import React from 'react';
import YouTube from 'react-youtube';
import { NavLink } from 'react-router-dom';
import { RegistrationDoneBannerVideoId } from '../../config';
import './styles.scss';

export interface IRegistrationDoneBannerProps {
}

export const RegistrationDoneBanner: React.FunctionComponent<IRegistrationDoneBannerProps> = (props) => {  

  return (
    <div className="complete-box">
      <div className="com-top">
        <div className="left">
          <div className="txt-box">
            <h1 className="h1">Awesome!</h1>
            <h3 className="h3">Thank you for your registration</h3>
          </div>
        </div>
        <NavLink to="/patientDashboardPage" className="btn btn-blue">
          My Dashboard <i className="icons icon-right-arrow"></i>
        </NavLink>
      </div>
      <div className="com-body">
        <YouTube
          videoId={RegistrationDoneBannerVideoId}
          opts={{
            height: '351px',
            width: '100%',
            playerVars: {
              autoplay: 0,
              origin: 'http://localhost:3000',
            },
          }}
        />
      </div>
    </div>
  );
};

export default RegistrationDoneBanner;
