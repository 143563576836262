import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import './styles.scss';

const TabMenu = (props) => {
  const [active, setActive] = useState(props.active);

  return (
    <div className="tab-menu-container">
        <Nav activeKey={active} variant="pills" className="nav-list">
          {Object.keys(props.navItems).map((key) => (
            <Nav.Item key={key}>
              <Nav.Link eventKey={key} onClick={(e) => {
                setActive(key)
                props.handleClick(key)
              }}>
                {props.navItems[key]}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
    </div>
  );
};

export default TabMenu;
