// @ts-nocheck
import React, { useState } from 'react';
import FormikControl from '../../components/Formik/FormikControl';
import { Form, Formik } from 'formik';
import CustomBtnSelect from '../../components/Formik/CustomBtnSelect';
import moment from 'moment';

type Props = {
  insuranceInfo: any;
  loadingState: boolean;
  onConfirmDetails: any;
};

const relationships = ['Self', 'Spouse', 'Parent', 'Child', 'Other'];
const relationshipOpts = relationships.map((option) => ({
  key: option,
  value: option,
}));

const ConfirmDetails = ({
  insuranceInfo = {},
  loadingState,
  onConfirmDetails,
}: Props) => {
  const [details, setDetails] = useState(insuranceInfo);
  console.log("insuranceInfo", insuranceInfo)
  const handleDetailsChange = (e: any, key: any) => {
    e = key === 'effectiveDate' ? e.formattedValue : e.target.value;
    setDetails((state: any) => ({
      ...state,
      [key]: e,
    }));
  };
  const handleChangeType = (value) => {
    if (value === 'Medicaid') {
      setDetails((state: any) => ({
        ...state,
        medicaid: true,
        medicarePartB: false,
      }));
    } else if (value === 'Medicare Part B') {
      setDetails((state: any) => ({
        ...state,
        medicaid: false,
        medicarePartB: true,
      }));
    } else {
      setDetails((state: any) => ({
        ...state,
        medicaid: false,
        medicarePartB: false,
      }));
    }
  };
  return (
    <>
      <div className="form-container">
        <p className="--bold">Detail Confirmation</p>
        <p className="center-text" style={{ marginBottom: 16 }}>
          Please confirm all fields are correct and edit those that are
          missing/incorrect
        </p>
        {details && <Formik initialValues={{}}>
          <Form>
            <FormikControl
              className={'confirm-details-input'}
              control="input"
              type="text"
              name="insuranceCarrier"
              placeholder="Insurance Carrier"
              value={details.insuranceCarrier}
              onChange={(e) => {
                handleDetailsChange(e, 'insuranceCarrier');
              }}
              showErrorMsg={false}
              label="Insurance Carrier"
            />
            <FormikControl
              className={'confirm-details-input'}
              control="input"
              type="text"
              name="subscriberId"
              placeholder="Insurance ID Number"
              value={details.subscriberId}
              onChange={(e) => {
                handleDetailsChange(e, 'subscriberId');
              }}
              showErrorMsg={false}
              label="Insurance ID Number"
            />
            <FormikControl
              className={'confirm-details-input'}
              control="input"
              type="text"
              name="planNumber"
              placeholder="Group Number"
              value={details.planNumber}
              onChange={(e) => {
                handleDetailsChange(e, 'planNumber');
              }}
              showErrorMsg={false}
              label="Group Number"
            />
            <FormikControl
              className={'confirm-details-input'}
              control="input"
              type="text"
              name="subscriberName"
              placeholder="Policy Holder Name"
              value={details.subscriberName}
              onChange={(e) => {
                handleDetailsChange(e, 'subscriberName');
              }}
              showErrorMsg={false}
              label="Policy Holder Name"
            />
            <FormikControl
              className={'confirm-details-input'}
              control="select"
              name={`relationship`}
              expanding={true}
              showErrorMsg={false}
              onChange={(e) => handleDetailsChange(e, 'relationship')}
              label="Relationship to Policy Holder"
              options={[
                {
                  key: 'Relation to Policy Holder',
                  value: '',
                  disabled: true,
                },
                ...relationshipOpts,
              ]}
            />
            <FormikControl
              className="confirm-details-input"
              control="input"
              format="##/##/####"
              formatType="date"
              value={details?.effectiveDate}
              onChange={(v) => handleDetailsChange(v, 'effectiveDate')}
              name={`effectiveDate`}
              placeholder={moment().startOf('year').format('MM/DD/YYYY')}
              label="Effective Date of Coverage"
              showErrorMsg={false}
            />
            <CustomBtnSelect
              label="Is this card Medicare Part B or Medicaid?"
              persist={true}
              options={['Medicaid', 'Medicare Part B', 'Neither']}
              selected={
                details.medicaid
                  ? 'Medicaid'
                  : details.medicarePartB
                  ? 'Medicare Part B'
                  : 'Neither'
              }
              onClick={(e) => {
                handleChangeType(e);
              }}
            />
          </Form>
        </Formik>}
      </div>
      <button
        style={{ lineHeight: 'inherit' }}
        type="button"
        className="btn"
        onClick={() => onConfirmDetails(details)}>
        Save
      </button>
    </>
  );
};

export default ConfirmDetails;
