import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import _ from 'lodash';
import './styles.scss';
import {User} from "../../services/dto/Security";
import {Appointment, Slot} from "../../services/dto/Common";
import UserService from "../../services/userService";
import moment from "moment";
import {showApiError, showError, showSuccess, useQuery} from "../../services/utils";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/reducers";
import {AuthState} from "../../store/reducers/auth";
import { APPOINTMENT_TITLE, FE_URL } from '../../config';
import AppointmentScheduler, { FetchDataType } from '../../components/RegistrationProgressComponents/FormScheduleAppointmentSelectDate';
import dayjs from 'dayjs';

const WINDOW_LENGTH = 7;

const MoveUpAppointmentPage = (props: any) => {
  const [user, setUser] = useState<User>();
  const [timeZone, setTimeZone] = useState<string>();
  const [appointment, setAppointment] = useState<Appointment>();
  const [provider, setProvider] = useState<User>();
  const [aptSaved, setAptSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedSlot, setSelectedSlot] = useState<Slot>();
  const [savingAppointment, setSavingAppointment] = useState(false);
  const [finished, setFinished] = useState(false);
  const token = useQuery().get('token');
  const { authUser } = useSelector<IAppState, AuthState>((s) => s.auth);
  const history = useHistory();
  const queryParams = useQuery();

  const [availData, setAvailData] = useState<any>();
  const [providerHasSlots, setProviderHasSlots] = useState<boolean>();

  useEffect(() => {
    const tasks = [
      { key: 'getUser' },
      { key: 'getProvider' },
      { key: 'getAppointment' },
    ]
    UserService.verifyAndExecute({ verificationCode: token, tasks })
      .then((rsp) => {
        setUser(rsp.data.getUser)
        setTimeZone(rsp.data.getUser.timeZone)
        setProvider(rsp.data.getProvider)
        setAppointment(rsp.data.getAppointment)
      })
      .catch((e) => {
        history.push('/');
      });
  }, []);

  useEffect(() => {
    getPhysicianSchedule();
  }, [user, provider, timeZone, appointment])

  const getPhysicianSchedule = async (params: any = {}) => {
    if (!user || !provider || !appointment || !timeZone) return;
    setLoading(true)
    const data = {
      // patientId: user.id, // *** this adds unwanted lead time, not sure if necessary
      providerId: provider.id,
      startTime: moment().add(1, "days").startOf("day"),
      endTime: moment().add(1 + WINDOW_LENGTH, "days").endOf("day"),
      timeZone,
      leadTime: 1,
      state: user.state,
      isNewPatient: !appointment.followUp,
      type: "pseudo",
      withSchedule: true,
      ...params,
    };

    const tasks = [{ key: 'getSchedule', body: data }];
    UserService.verifyAndExecute({ verificationCode: token, tasks })
      .then((rsp) => {
        setAvailData(rsp.data.getSchedule)
        setProviderHasSlots(!_.isEmpty(rsp.data.getSchedule?.availableSlots))
        setLoading(false);
      })
      .catch((e) => {
        history.push('/');
      });
  };

  const onFetchData = (changes: FetchDataType) => {
    if (changes.timeZone) setTimeZone(changes.timeZone);
    // if (changes.startTime && changes.endTime)
    //   setTimes({ startTime: changes.startTime, endTime: changes.endTime });
    getPhysicianSchedule(changes);
  };

  const onSaveAppointment = async (slot?: Slot) => {
    if (!provider || !slot || !user || !appointment) {
      showError('Invalid context');
      return;
    }

    setSavingAppointment(true)
    const body: any = {
      pseudoAuth: user,
      data: {
        appointmentId: appointment.id,
        description: APPOINTMENT_TITLE,
        providerId: provider.id,
        patientId: user.id,
        startTime: slot.start,
        endTime: slot.end,
        on: moment(slot.start).format('MMM DD, YYYY'),
        at: moment(slot.start).format('hh:mm a'),
      }
    };

    const tasks = [];
    if (appointment) {
      tasks.push({ key: 'updateAppointment', body })
    } else {
      delete body.data.providerId;
      delete body.data.patientId;
      body.data.inviteeEmail = provider.email;
      tasks.push({ key: 'createAppointment', body })
    }
    tasks.push({ key: 'deleteToken' });
    await UserService.verifyAndExecute({ verificationCode: token, tasks })
      .then((rsp) => {
        showSuccess('Appointment has been updated successfully!');
        setAptSaved(true);
      })
      .catch((e) => {
        showApiError(e);
      })
      .finally(() => {
        setSavingAppointment(false)
        setFinished(true)
      });
  };

  return (
    <React.Fragment>
      <div className="padding-wraper ">
        <Header currentPage="Dashboard" headerType="Non-LoggedIn" title="Edit Appointment" />
        {finished && <div className="header-info">
          <div className="description" style={{marginBottom: "16px"}}>
            <h3 style={{marginBottom: "8px"}}>Appointment Rescheduled!</h3>
            <p>You are all set. You can close this tab now.</p>
          </div>
        </div>}
        <div className="edit-schedule-appointment-page-mains">
          <div className="tab-right-content">
            {!loading && user && provider && appointment && timeZone && <AppointmentScheduler
              data={availData}
              provider={provider}
              existingApt={appointment}
              fetchData={(changes: FetchDataType) => onFetchData(changes)}
              title={appointment.followUp ? "Follow-Up Visit" : "New Patient Consultation"}
              timeZone={timeZone}
              duration={appointment.followUp ? 20 : 40}
              onSelect={(s?: Slot) => setSelectedSlot(s)}
              onConfirm={(s: Slot) => onSaveAppointment(s)}
              submitting={!!savingAppointment}
              showProviderToggle={false}
              defaultDate={dayjs().add(1, "days")}
              hideTopCard={finished}
              hideButtons={finished}
            />}
          </div>
        </div>
        {!loading && !selectedSlot && !finished && <p style={{
          margin: '2rem auto',
          color: 'white',
          textAlign: 'center',
          fontSize: '16px',
        }}>
          To reschedule to a date beyond this range, you will need to <a style={{textDecoration: 'underline'}} href={`${FE_URL}/signInPage`}>login</a> and edit your existing appointment.
        </p>}
      </div>
    </React.Fragment>
  );
};

export default MoveUpAppointmentPage;
