import React, { Fragment, useEffect, useState } from 'react';
import UserService from '../../services/userService';
import './styles.scss';
import AuthService from '../../services/authService';
import Insurance from '../../containers/Insurance';

const InsuranceInfo = (props) => {
  const { user } = AuthService.getAuth();
  const insuranceCardCheck = user?.insuranceCards.filter(
    (card) => !card.inProgress,
  );
  const [state, setState] = useState({
    hasInsurance: insuranceCardCheck?.length ? true : null,
    medicarePartB: !!user?.medicarePartB,
    medicaid: !!user?.medicaid,
    edit: false,
    addNew: false,
    upload: false,
    viewIndex: null,
    limitedCoverageStates: [],
    loading: true,
  });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  useEffect(() => {
    UserService.getLimitedCoverageStates()
      .then((res) => {
        setGenericState({ limitedCoverageStates: res?.data || [] });
      })
      .finally(() => setGenericState({ loading: false }));
  }, []);

  const patientInLimitedState = state.limitedCoverageStates.includes(
    user.state,
  );

  if (state.loading) return null;
  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>
            {state.upload
              ? 'Insurance Card Upload'
              : props.title || 'Health Insurance Info'}
          </h3>
          {!state.upload && !state.edit && (
            <>
              {patientInLimitedState ? (
                <p>
                  We accept insurance in your area including Medicare Part B and
                  are adding new insurance coverage soon!
                </p>
              ) : (
                <p>
                  {props.subtitle ||
                    `We accept most national plans and some regional plans
                  depending on the area. If valid, this can cover most if not
                  all of the cost of Ognomy.`}
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <Insurance
        onContinue={props.onContinue}
        defaultCards={props.defaultCards}
      />
    </div>
  );
};

export default InsuranceInfo;
