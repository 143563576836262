import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import _ from 'lodash';
import './styles.scss';
import FormScheduleAppointment from "../../components/RegistrationProgressComponents/FormScheduleAppointment";
import {User} from "../../services/dto/Security";
import {Slot} from "../../services/dto/Common";
import UserService from "../../services/userService";
import moment from "moment";
import {showApiError, showError, showSuccess, useQuery} from "../../services/utils";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/reducers";
import {AuthState} from "../../store/reducers/auth";
import { APPOINTMENT_TITLE } from '../../config';
import AuthService from '../../services/authService';
import { AxiosResponse } from 'axios';

const EditScheduleAppointmentPage = (props: any) => {
  const [provider, setProvider] = useState<User>();
  // const [slot, setSlot] = useState<Slot>();
  const [aptSaved, setAptSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [savingAppointment, setSavingAppointment] = useState(false);
  const { id } = useParams() as any;
  const { authUser } = useSelector<IAppState, AuthState>((s) => s.auth);
  const history = useHistory();
  const queryParams = useQuery();

  useEffect(() => {
    UserService.getUser(AuthService.getAuth().user.id)
      .then((user: AxiosResponse<User>) => {
        console.log("updated!", user.data)
        AuthService.updateUserInfo(user.data);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  }, [])

  const onSaveAppointment = async (provider?: User, slot?: Slot) => {
    if (!provider || !slot || !authUser) {
      showError('Invalid context');
      return;
    }

    // setLoading(true)
    setSavingAppointment(true)
    const body: any = {
      description: APPOINTMENT_TITLE,
      providerId: provider?.id,
      patientId: authUser?.id,
      startTime: slot?.start,
      endTime: slot?.end,
      on: moment(slot.start).format('MMM DD, YYYY'),
      at: moment(slot.start).format('hh:mm a'),
    };
    if (props.onContinue) {
      props.onContinue(body);
      return;
    }
    // const creditCards = await AuthService.getAuth()?.user?.creditCards || []

    if (id) {
      UserService.updateAppointment(id, body)
        .then((rsp) => {
          showSuccess('Appointment has been updated successfully!');
          setAptSaved(true);
          history.push('/patientDashboardPage');
        })
        .catch((e) => {
          showApiError(e);
          // setLoading(false)
        })
        .finally(() => setSavingAppointment(false));
    } else {
      delete body.providerId;
      delete body.patientId;
      body.inviteeEmail = provider.email;
      UserService.createAppointment(body)
        .then((rsp) => {
          setAptSaved(true);
          showSuccess('Appointment has been created successfully!');
          if (queryParams.get('registration')) {
            history.push('/registration?step=complete');
          } else {
            history.push('/patientDashboardPage');
          }
        })
        .catch((e) => {
          showApiError(e);
          // setLoading(false)
        })
        .finally(() => setSavingAppointment(false));
    }
  };

  // const isFinalStep = provider && slot?.start && !loading;

  return (
    <React.Fragment>
      <div className="padding-wraper ">
        {!props.onContinue && (
          <Header currentPage="Dashboard" headerType="PatientDashboard" />
        )}
        {queryParams.get('registration') && (
          <div className="description">
            <h2>You Did It!</h2>
            <p>Let's get you scheduled with one of our board certified sleep specialists.</p>
          </div>
        )}

        <div className="edit-schedule-appointment-page-mains">
          <div className="tab-right-content">
            {!loading && <FormScheduleAppointment
              onPreviousStep={() => history.push('/patientDashboardPage')}
              onNextStep={_.noop}
              provider={provider}
              aptCount={parseInt(queryParams.get('aptCount') || '0', 10)}
              aptId={id}
              aptSaved={aptSaved}
              savingAppointment={savingAppointment}
              onConfirmed={(p, s) => {
                setProvider(p);
                // setSlot(s);
                onSaveAppointment(p, s);
              }}
            />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditScheduleAppointmentPage;