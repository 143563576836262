import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import './styles.scss';
import { getName } from '../../services/utils';
import { ProfileIcon } from '../ProfileIcon';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store/reducers';
import { AuthState } from '../../store/reducers/auth';
import { User } from '../../services/dto/Security';
import AuthService from '../../services/authService';

export interface IHeaderProps {
  currentPage?: string;
  headerType?: string;
  phy?: User;
  title?: string;
  partnerLogo?: string;
  partnerName?: string;
  customClass?: string;
}

export const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const [showDesktopMenu, setShowDesktopMenu] = useState<boolean>(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [showMobileContactInfo, setShowMobileContactInfo] =
    useState<boolean>(false);

  const history = useHistory();

  // click Outside
  const clickOutside = () => {
    setShowDesktopMenu(false);
  };

  // get Header Title
  const getHeaderTitle = () => {
    if (props.title) {
      return props.title;
    }
    switch (headerType) {
      case 'PhysicianLanding':
        return '';
      case 'PatientDashboard':
        return 'Patient Dashboard';
      case 'NewPatientForms':
        return 'New Patient Forms';
      case 'FollowUpForms':
        return 'Follow-Up Forms';
      case 'AppointmentConfirm':
        return 'Appointment Confirmation';  
      case 'Non-LoggedIn':
        return 'Patient Registration';
      case 'Referred':
        return 'Consult a Doctor';
      default:
        return '';
    }
  };

  useEffect(() => {
    const input = document.getElementById('root') as HTMLInputElement;
    if (showMobileMenu) {
      input.classList.add('page-unscroll');
    } else {
      input.classList.remove('page-unscroll');
    }
  }, [showMobileMenu]);

  const { currentPage, headerType, partnerName, partnerLogo } = props;
  const { authUser } = useSelector<IAppState, AuthState>((s) => s.auth);
  const userName = getName(authUser);

  const logout = () => {
    history.push('/');
    AuthService.cleanAuth();
  };

  const renderLogo = () => {
    return history.location.pathname.includes('/shuteye')
      ? { path: partnerLogo, title: partnerName }
      : { path: partnerLogo, title: partnerName };
  };

  const { path, title } = renderLogo();
  const referred = headerType === 'Referred'
  return (
    <div className={`header flex-grid ${props.customClass || ''}`}>
      <div className="lefts">
        {(headerType === 'PhysicianLanding' ||
          headerType === 'AppointmentConfirm' ||
          headerType === 'Referred') && (
          <div className={`logo-area flex ${!referred ? 'center-content' : ''}`}>
            <img
              src="/assets/logo.svg"
              className="ognomy-logo"
              // className={`ognomy-logo${partnerName ? ' border-right' : ''}`}
              alt="img"
            />
            {/* {headerType === 'Referred' && (
              <div className="partner-logo">
                {path && <img className="round-logo" src={path} alt="img" />}
                <p className="logo-txt">{title}</p>
              </div>
            )} */}
            <span className="bold-txt-area">{getHeaderTitle()}</span>
          </div>
        )}

        {(headerType === 'PatientDashboard' ||
          headerType === 'NewPatientForms' ||
          headerType === 'FollowUpForms') && (
          <NavLink to="/patientDashboardPage" className="logo-area flex">
            <img src="/assets/logo.svg" alt="img" />
            <span className="bold-txt-area">{getHeaderTitle()}</span>
          </NavLink>
        )}
        {(headerType === 'Non-LoggedIn') && (
          <NavLink to="/" className="logo-area flex">
            <img src="/assets/logo.svg" alt="img" />
            <span className="bold-txt-area">{getHeaderTitle()}</span>
          </NavLink>
        )}
      </div>
      {(headerType === 'Non-LoggedIn' ||
        headerType === 'Referred' ||
        headerType === 'AppointmentConfirm') && (
        <div className="rights flex">
          <div
            className="talk-blue-point"
            onClick={() => {
              setShowMobileContactInfo(true);
            }}></div>
          <div className="right-txt">
            <div className="txt">You can also contact us at</div>
            <div className="txt">
              <a href="tel:1-877-664-6669" className="blue-link">
                1-877-664-6669
              </a>
              for live assistance
            </div>
          </div>
        </div>
      )}

      {headerType === 'PatientDashboard' && (
        <div className="right-user flex">
          <a href="#javascript" className="icons icon-bell hide">
            <i className="red-point"></i>
          </a>
          <OutsideClickHandler onOutsideClick={() => clickOutside()}>
            <div
              className={`user-module flex ${showDesktopMenu ? 'open' : ''}`}>
              <div
                className={`top-user flex ${
                  !showMobileMenu ? 'mobile-show' : 'mobile-hide'
                }`}>
                <div
                  className="photo-box"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}>
                  {authUser && <ProfileIcon user={authUser} color={'blue'} />}
                </div>
                <div
                  className="right-drop flex"
                  onClick={(event) => {
                    setShowDesktopMenu(!showDesktopMenu);
                    event.preventDefault();
                  }}>
                  <div className="left-txt">{userName}</div>
                  <span className="icons icon-drop"></span>
                </div>
              </div>
              <a
                href="#javascript"
                className={`mobile-btn-close ${
                  showMobileMenu ? 'mobile-show' : 'mobile-hide'
                }`}
                onClick={(event) => {
                  setShowMobileMenu(!showMobileMenu);
                  event.preventDefault();
                }}></a>
              <div className="drop-panel mobile-hide">
                <ul>
                  <li>
                    <NavLink to="/profile" className="tab-link">
                      Personal Info
                    </NavLink>
                  </li>
                  <li>
                    <div onClick={logout} className="tab-link">
                      Logout
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}

      {headerType === 'PhysicianLanding' && props.phy && (
        <div className="rights-three">
          <a href="#javascript" className="btn btn-blue-border">
            <i className="icons icon-upload"></i>
            <span className="txt mobile-hide">Share</span>
          </a>
        </div>
      )}

      {showMobileContactInfo && (
        <div className="mobile-header-topup">
          <div className="left-area">
            <div className="left-point"></div>
            <div className="right-txt">
              <div className="txt">You can also contact us at</div>
              <div className="txt">
                <a href="tel:1-877-664-6669" className="phone">
                  1-877-664-6669
                </a>
                for live assistance
              </div>
            </div>
          </div>
          <div className="right-area">
            <a
              href="#javascript"
              className="icons icon-close"
              onClick={() => {
                setShowMobileContactInfo(false);
              }}>
              &nbsp;
            </a>
          </div>
        </div>
      )}

      <div
        className={`mobile-header desktop-hide ${
          showMobileMenu ? 'mobile-show' : 'mobile-hide'
        }`}>
        <a href="#javascript" className="icons btn-close">
          &nbsp;
        </a>
        <div className="header-list">
          <ul className="list">
            <li>
              <NavLink
                to="/patientDashboardPage"
                className={`tab-items icon-dashboard ${
                  currentPage === 'Dashboard' ? 'current' : ''
                }`}>
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile"
                className={`tab-items icon-personal ${
                  currentPage === 'Personal Info' ? 'current' : ''
                }`}>
                Personal Info
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                onClick={() => AuthService.cleanAuth()}
                className="tab-items icon-log-out ">
                Log out
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="bottom-live">
          <div className="icons left-talk" />
          <div className="right-txt">
            <div className="top-txt">Live Assistance</div>
            <div className="tal-box">
              <a href="tel:1-877-664-6669" className="tel">
                1-877-664-6669
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
