
import React, { useEffect, useRef, useState } from 'react';
import ModalUseCreditCardOnFile from "../../components/WaitingRoomComponents/ModalUseCreditCardOnFile";
import ModalEnterNewPaymentInformation from "../../components/WaitingRoomComponents/ModalEnterNewPaymentInformation";
import ModalPaymentCompleted from "../../components/WaitingRoomComponents/ModalPaymentCompleted";
import { CreditCard } from "../../services/dto/Security";
import { showApiError, extractError } from "../../services/utils";
import UserService from "../../services/userService";
import AuthService from "../../services/authService";
import { injectAuthUser } from "../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/reducers";
import { AuthState } from "../../store/reducers/auth";

export type PayModalType =  'new' | 'exist' | 'close';
export interface PayModulesProps{
  amount: number
  entityId: string
  description: string
  showModal: PayModalType
  type: 'apt' | 'order'
  onClose: (card?:CreditCard)=>void;
}
export const PayModules = (props:PayModulesProps)=>{
  const [showModalUseCreditCardOnFile, setShowModalUseCreditCardOnFile] = useState<boolean>(false); // false
  const [showModalEnterNewPaymentInformation, setShowModalEnterNewPaymentInformation] = useState<boolean>(false); // false
  const [showModalPaymentCompleted, setShowModalPaymentCompleted] = useState<boolean>(false); // false
  const [loading,setLoading] = useState(false)
  const paidCard = useRef<CreditCard>()
  const {authUser} = useSelector<IAppState, AuthState>(s => s.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.showModal === 'new') setShowModalEnterNewPaymentInformation(true)
    else if (props.showModal === 'exist') setShowModalUseCreditCardOnFile(true)
  }, [props.showModal])

  const doPayment = async (card: CreditCard) => {
    const entity = {
      amount: Math.ceil(props.amount * 100),
      paymentMethod: card.paymentMethod,
      type: props.type
        ? props.type === 'order'
          ? 'order'
          : 'appointment'
        : null,
      entityId: props.entityId,
      description: props.description,
    };
    if (props.type === "apt") {
      await UserService.createPayment(entity)
    } else if (props.type === 'order') {
      await UserService.payOrder(props.entityId, entity)
    }
  }

  const doPaymentFromUI = (card: CreditCard) => {
    setLoading(true)
    doPayment(card).then(() => {
      paidCard.current = card;
      setShowModalUseCreditCardOnFile(false)
      setShowModalPaymentCompleted(true)
      setShowModalEnterNewPaymentInformation(false)
    }).catch((e) => extractError(e, "We are currently unable to process the payment."))
      .finally(() => setLoading(false))
  }


  const addCardAndPay = (number: string, issuer: string, date: string, ccv: string) => {
    if (!authUser) {
      showApiError('Invalid app context');
      return;
    }

    const body: any = {};
    const creditCard = {
      number: number,
      expired: date,
      cvc: ccv,
      type: issuer
    }

    body.creditCards = [...(authUser.creditCards || [])];
    body.creditCards.push(creditCard)

    setLoading(true);
    UserService.updateProfile(body, authUser.id)
      .then(rsp => {
        AuthService.updateUser(rsp.data)
        dispatch(injectAuthUser(rsp.data))
        // showSuccess('Credit card has been added successfully')
        const newCards = rsp.data.creditCards || []
        doPaymentFromUI(newCards[newCards.length - 1])
      }).catch(e => {
        extractError(e, "We are currently unable to add this payment method.");
        setLoading(true)
      })
  }

  const renderUseCreditCardPay = () => {
    return <ModalUseCreditCardOnFile
      loading={loading}
      copayValue={props.amount}
      onClose={() => {
        setShowModalUseCreditCardOnFile(false)
        props.onClose()
      }}
      onAddCard={() => {
        setShowModalUseCreditCardOnFile(false)
        setShowModalEnterNewPaymentInformation(true)
      }}
      onPayNow={doPaymentFromUI}/>
  }

  const renderAddNewCard = () => {
    return <ModalEnterNewPaymentInformation
      copayValue={props.amount}
      loading={loading}
      onClose={() => {
        setShowModalEnterNewPaymentInformation(false)
        props.onClose()
      }}
      onPayNow={addCardAndPay}/>
  }

  const renderPayCompleted = () => {
    return <ModalPaymentCompleted
      copayValue={props.amount}
      creditCardValue={paidCard.current ? paidCard.current.number : '-'}
      onClose={() => {
        setShowModalPaymentCompleted(false)
        props.onClose(paidCard.current)
      }}/>
  }

  return <>
    {showModalUseCreditCardOnFile && renderUseCreditCardPay()}
    {showModalEnterNewPaymentInformation && renderAddNewCard()}
    {showModalPaymentCompleted && renderPayCompleted()}
  </>
}
