import React from 'react';
import './OuraSetup.scss';
import { Grid, Paper } from '@mui/material';
import { LoadingElement, OgnomyOuraLogo } from './helper';

const OuraLoading = () => {
  return (
    <Paper className="layer-0">
      <Paper className="layer-1">
        <Grid container spacing={2}>
          <Grid item>
            <OgnomyOuraLogo />
          </Grid>
          <Grid item xs={12}>
            <LoadingElement />
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default OuraLoading;
