import {PaymentHistory, StateFullData} from "../../services/dto/Common";
import * as redux from 'redux'
import {commonApiAction} from "../helper";
import UserService from "../../services/userService";

export const API_LOAD_PAYMENT_HISTORY = 'API_LOAD_PAYMENT_HISTORY';

export interface ApiLoadPaymentHistory {
  type: typeof API_LOAD_PAYMENT_HISTORY,
  payload: StateFullData<PaymentHistory[]>
}


export const loadPaymentHistory = (params: any) => {
  return (dispatch: redux.Dispatch) => {
    commonApiAction(
      dispatch,
      API_LOAD_PAYMENT_HISTORY,
      UserService.getPaymentHistory(params)
    )
  }
}


export type PaymentTypes = ApiLoadPaymentHistory