import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import {getPhysicianLandingData} from '../../store/actions/dataAction';
import Header from '../../components/Header';
import LeftProfile from '../../components/PhysicianLandingComponents/LeftProfile';
import CenterInfo from '../../components/PhysicianLandingComponents/CenterInfo';
import Testimonials from '../../components/PhysicianLandingComponents/Testimonials';
import ScheduleAnAppointment from '../../components/PhysicianLandingComponents/ScheduleAnAppointment';
import SleepApneaTreatment from '../../components/PhysicianLandingComponents/SleepApneaTreatment';
import './styles.scss';
import UserService from "../../services/userService";
import {getErrorString, useIsMounted} from "../../services/utils";
import {User} from "../../services/dto/Security";
import {IAppState} from "../../store/reducers";


const PhysicianLandingPage = (props: any) => {

  const { doctorname } = useParams() as any;
  const [err, setErr] = useState<string>()
  const [phy, setPhy] = useState<User>()
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const {physicianLanding:demo} = useSelector<IAppState,any>(s=>s.dataReducer)
  useEffect(() => {
    dispatch(getPhysicianLandingData())
    UserService.getPhysicianRoute({landingRoute: doctorname})
      .then(rsp => {
        if (isMounted.current) {
          setPhy(rsp.data)
        }
      }).catch(e => {
    })
  }, [doctorname])


  const renderPhy = () => {
    if(!phy){
      return <></>
    }
    return (
      <div className="physician-landing-wrap">
        <div className="row">
          <div className="left-profile-root">
            <LeftProfile provider={phy}/>
          </div>
          <div className="col flex-2">
            <CenterInfo
              provider={phy}/>
            <Testimonials
              provider={phy}/>
          </div>
          <div className="col flex-1">
            {demo && <ScheduleAnAppointment provider={phy}/>}
            {demo && <SleepApneaTreatment dataList={demo.sleepApneaTreatment}/>}
          </div>
        </div>
      </div>
    )
  }
  if(!phy){
    return <></>
  }
  return (
    <React.Fragment>

      <div className="padding-wraper">
        <Header
          currentPage=""
          phy={phy}
          headerType="PhysicianLanding"/>
        {err && <div className="physician-landing-wrap">{err}</div>}
        {renderPhy()}
      </div>
    </React.Fragment>
  );
};

export default PhysicianLandingPage;