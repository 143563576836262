import React, { useState, useEffect } from 'react';
import '../../components/RegistrationProgressComponents/Modals/styles.scss';

const DeleteModal = ({
  onDelete,
  onClose,
}: {
  onDelete: () => void;
  onClose: () => void;
}) => {
  const [height, setHeight] = useState(1200);
  const body = document.body;
  const html = document.documentElement;
  useEffect(() => {
    setHeight(Math.max(body.scrollHeight, html.scrollHeight));
  }, [html.scrollHeight, body.scrollHeight]);

  return (
    <div className="base-modal delete-modal" style={{ height: height + 'px' }}>
      <div className="base-modal-content">
        <h1 className="terms-title">Delete Card</h1>
        <p className="terms-header">
          This will permanently delete your card on file. Do you wish to
          continue?
        </p>

        <div className="">
          <button className="btn btn-blue submit-btn" onClick={onDelete}>
            Delete
          </button>
        </div>
        <p className="open-upload">
          <button
            type="button"
            onClick={onClose}
            className="text-btn  --link"
            style={{ margin: '20px auto 0' }}>
            Cancel
          </button>
        </p>
      </div>
    </div>
  );
};

export default DeleteModal;
