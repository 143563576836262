import {User} from "../../services/dto/Security";
import * as redux from 'redux'

export const INJECT_AUTH_USER = 'INJECT_AUTH_USER';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';

export interface InjectAuthUser {
  type: string,
  payload: User
}

export const injectAuthUser = (user: User) => {
  return (dispatch: redux.Dispatch) => {
    dispatch({type: INJECT_AUTH_USER, payload: user})
  }
}

export const updateAuthUser = (data: object) => {
  return (dispatch: redux.Dispatch) => {
    dispatch({type: UPDATE_AUTH_USER, payload: data})
  }
}
export type AuthTypes = InjectAuthUser