import React, {useState} from 'react';
import InputBox from '../../../components/FormElement/InputBox';
import BaseDateInput from '../../../components/FormElement/BaseDateInput';
import formValidationSvc from '../../../services/formValidationSvc';
import './styles.scss';
import {CREDIT_FMT} from "../../../config";
import moment from "moment";

export interface IModalEnterNewPaymentInformationProps {
  copayValue: number
  onClose: () => void
  loading: boolean
  onPayNow: (number: string, issuer: string, date: string, ccv: string) => void
}

export const ModalEnterNewPaymentInformation: React.FunctionComponent<IModalEnterNewPaymentInformationProps> = (props) => {
  const {copayValue, onClose, onPayNow} = props;
  const [formData, setFormData] = useState<any>({
    creditCardNumber: '',
    expireDate: '',
    ccv: '',
  });

  // on Change Field
  const onChangeField = (fieldName: string, value: string) => setFormData({...formData, [fieldName]: value})

  // enabled Form
  const enabledForm = () => {
    return (formData.creditCardNumber !== ''
        && formData.expireDate !== ''
        && formData.ccv !== '')
      && !props.loading
  };
  const displayCopay = typeof copayValue === "string" ? copayValue : (copayValue || 0).toFixed(2)


  return (
    <div className="modal-default modal-enter-new-payment-information">
      <div className="modal-mains">
        <div className="modal-title">
          Enter New Payment Information
        </div>
        <div className="body-area">
          <div className="row">
            <div className="col col-md-12 col-12">
              <InputBox
                label="Credit Card Number"
                value={formData.creditCardNumber}
                placeholder="Enter card number"
                pattern="[0-9]{0,16}"
                onChange={(event) => {
                  onChangeField('creditCardNumber', formValidationSvc.validateInputEnteringPattern(event, formData.creditCardNumber))
                }}
              />
            </div>
            <div className="col col-md-12 col-12">
              <div className="row sub-row">
                <div className="col col-md-6 col-xs-12">
                  <BaseDateInput
                    label="Expiration Date"
                    value={formData.expireDate ? new Date(formData.expireDate) : null}
                    placeholder={CREDIT_FMT}
                    format={CREDIT_FMT}
                    showMonthYearPicker={true}
                    minDate={new Date()}
                    onChange={(
                      newDate
                    ) => {
                      onChangeField('expireDate', newDate ? newDate.toISOString() : '')
                    }}
                  />
                </div>
                <div className="col col-md-6 col-xs-12">
                  <InputBox
                    label="CCV"
                    value={formData.ccv}
                    placeholder="Enter CCV"
                    pattern="[0-9]{0,4}"
                    isShowInfoIcon={false}
                    onChange={(event) => {
                      onChangeField('ccv', formValidationSvc.validateInputEnteringPattern(event, formData.ccv))
                    }}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="price-area">
          <div className="thin-txt">
            Your copay is
          </div>
          <div className="price-txt">
            ${displayCopay}
          </div>
        </div>
        <div className="bottom-areas">
          <div
            className={`btn btn-blue ${enabledForm() ? '' : 'disabled'}`}
            onClick={() => {
              onPayNow(formData.creditCardNumber, formData.creditCardIssuer,
                moment(formData.expireDate).format(CREDIT_FMT), formData.ccv)
            }}>
            Pay Now
          </div>
          <div className="btn blue-links"
               onClick={() => {
                 onClose()
               }}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEnterNewPaymentInformation;