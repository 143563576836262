import React from 'react';
import FormikControl from '../../components/Formik/FormikControl';
import { Formik, Form } from 'formik';
import AuthService from '../../services/authService';
import * as Yup from 'yup';
import { borderClass, scrollToError, textAreaAutoGrow } from './utils';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';

const SocialHistory = (props) => {
  const socialHistory = props?.socialHistory;
  const medicalHistory = props?.medicalHistory;
  const apptId = props.apptId;

  const statusValues = medicalHistory?.socialHistory?.socialStatuses;
  const { user } = AuthService.getAuth();

  const baseValue = { response: false, description: null };
  const baseValidation = Yup.object({
    response: Yup.boolean(),
    description: Yup.string()
  }).nullable();

  const initalStatusVal = (key) =>
    statusValues?.[key]?.response && statusValues[key];
  const initialValues = {
    maritalStatus: socialHistory?.maritalStatus || 'Other',
    socialStatuses: {
      employed: initalStatusVal('employed') || null,
      exercise: initalStatusVal('exercise') || null,
      caffeine: initalStatusVal('caffeine') || null,
      alcohol: initalStatusVal('alcohol') || null,
      tobacco: initalStatusVal('tobacco') || null,
      substanceAbuseTreatment:
        initalStatusVal('substanceAbuseTreatment') || null,
    },
  };

  const validationSchema = Yup.object({
    maritalStatus: Yup.string(),
    socialStatuses: Yup.object({
      employed: baseValidation,
      exercise: baseValidation,
      caffeine: baseValidation,
      alcohol: baseValidation,
      tobacco: baseValidation,
      substanceAbuseTreatment: baseValidation,
    }),
  });

  const constructResponse = (values) => {
    Object.keys(values.socialStatuses).forEach((key) => {
      if (!values?.socialStatuses?.[key]) {
        values.socialStatuses[key] = baseValue;
      }
    });

    return values;
  };

  const onSubmit = async (values, setSubmitting) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const followUpProgress = '/followUp?step=medications';
    const data = {
      userId: user.id,
      type: 'Follow-up',
      appointmentId: apptId,
      form: { 
        // providerHistory: medicalHistory?.providerHistory,
        // medications: medicalHistory?.medications,
        // pastMedicalHistory: medicalHistory?.pastMedicalHistory,
        socialHistory: constructResponse(newValues),
        // familyHistory: medicalHistory?.familyHistory,
        // sleepMedicineHistory: medicalHistory?.sleepMedicineHistory,
        // reviewOfSystems: medicalHistory?.reviewOfSystems,
      },
    };

    try {
      const {  updateAppointmentProgress, updateMedicalHistory } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);
      await updateAppointmentProgress(apptId, { followUpProgress: 'medications', medicalId: formDataRecord?.data?.id });
      props.updateHistory(formDataRecord?.data?.form || {});
      props.onContinue({ followUpProgress });
    } catch (error) {
      toast.error('Unable to save social history. Please try again.');
      setSubmitting(false);
    }
  };
  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Past Social History</h3>
          <p>
            Please provide some key details about your social/personal life.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          const { maritalStatus, socialStatuses } = formik.values;
          return (
            <Form onSubmit={formik.handleSubmit}>
              <div className="form-container">
                <p className="mb-3">
                  Please select any of the following that applies to you.
                </p>
                <div className="medical-list">
                  {Object.keys(socialStatuses).map((propKey, i) => {
                    const key = `socialStatuses.${propKey}`;
                    const inputName = `${key}.description`;
                    const item = socialStatusItems[i];
                    const responseValue = socialStatuses[propKey]?.response;
                    const descriptionValue =
                      socialStatuses[propKey]?.description;
                    return (
                      <div key={key} className="item">
                        <FormikControl
                          control="custom-btn"
                          id="custom-btn-item"
                          onClick={() => {
                            formik.setFieldValue(
                              key,
                              !responseValue
                                ? { response: true, description: '' }
                                : null,
                            );
                          }}
                          options={[item]}
                          selected={responseValue && item}>
                          {responseValue && (
                            <FormikControl
                              className={borderClass(inputName, formik)}
                              control="textarea"
                              type="text"
                              name={inputName}
                              id={inputName}
                              value={descriptionValue}
                              placeholder="Describe"
                              showErrorMsg={false}
                              maxLength={99}
                              onClick={(e) => e.stopPropagation()}
                              style={{ resize: 'none' }}
                              onChange={(e) =>
                                textAreaAutoGrow(e, formik.setFieldValue)
                              }
                            />
                          )}
                        </FormikControl>
                      </div>
                    );
                  })}
                </div>
              </div>
              <button
                type="submit"
                className={`submit-btn`}
                onClick={() => scrollToError()}
                disabled={formik.isSubmitting}
                ref={props.continueRef}>
                Continue
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const socialStatusItems = [
  'I am currently employed',
  'I exercise',
  'I consume caffeinated beverages',
  'I consume alcoholic beverages',
  'I have used tobacco products',
  'I have undergone treatment for substance abuse',
];

export default SocialHistory;
