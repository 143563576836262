import React, { useState } from 'react';
import Header from '../../components/Header';
import './styles.scss';
import { useQuery } from '../../services/utils';
import { ResetPasswordBody } from './ResetPasswordBody';
import { PasswordForm } from './PasswordForm';

export const ResetPasswordPage = () => {
  const query = useQuery();
  const [step, setSteps] = useState(query.get('step') || '0');
  const [user, setUserData] = useState({
    email: query.get('email') || null,
    code: query.get('code') || null,
    password: null,
    skipVerification: query.get('setup') || false,
  });

  return (
    <React.Fragment>
      <div className="padding-wraper">
        <Header
          currentPage=""
          title="Forgot Password"
          headerType="Non-LoggedIn"
        />

        <div className="sign-in-page">
          {step === '0' && (
            <ResetPasswordBody onNextSteps={() => setSteps('2')} />
          )}
          {/* {step == 1 && <VerificationForm  userData={user} onNextSteps={() => setSteps(2)} onUpdateData={(e) => setUserData(e)} />} */}
          {step === '2' && (
            <PasswordForm
              userData={user}
              onUpdateData={(e) => setUserData(e)}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
