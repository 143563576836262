import React, { useEffect, useState, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import dataAction from '../../store/actions/dataAction';
import { Appointment } from '../../services/dto/Common';
import Header from '../../components/Header';
import BottomLiveBar from '../../components/BottomLiveBar';
import MyProgress from '../../components/PatientDashboardComponents/MyProgress';
import NextAppointment from '../../components/PatientDashboardComponents/NextAppointment';
import ToDo from '../../components/PatientDashboardComponents/ToDo';
import Orders from '../../components/PatientDashboardComponents/Orders';
import { AppointmentsState } from '../../store/reducers/appointments';
import moment from 'moment';
import { PatientDashboardData } from '../../models/PatientDashboardData';
import './styles.scss';
import _ from 'lodash';
import { getGreetingTime, getName } from '../../services/utils';
import { IAppState } from '../../store/reducers';
import { AuthState } from '../../store/reducers/auth';
import { loadAppointments } from '../../store/actions/appointments';
import { User } from '../../services/dto/Security';
import SleepSpecialistInfo from '../../components/RegistrationProgressComponents/SleepSpecialistInfo';
import { updateAuthUser } from '../../store/actions/auth';
import PolicyModal from '../../components/RegistrationProgressComponents/Modals/PolicyModal';
import AuthService from '../../services/authService';
import ContinueOnboardingModal from '../../components/RegistrationProgressComponents/Modals/ContinueOnboardingModal';
import FollowUpPaperWorksModal from '../../components/RegistrationProgressComponents/Modals/FollowUpPaperWorksModal';
import UploadInsuranceModal from '../../components/RegistrationProgressComponents/Modals/UploadInsuranceModal';

interface IPatientDashboardPageProps extends RouteComponentProps<any> {
  patientDashboard: PatientDashboardData;
  dataAction?: any;
}

const PatientDashboardPage: React.FunctionComponent<
  IPatientDashboardPageProps
> = (props) => {
  // old code
  const [dataList, setDataList] = useState<PatientDashboardData>();
  const { appointments } = useSelector<IAppState, AppointmentsState>(
    (s) => s.appointments,
  );
  const [nearestApt, setNearestApt] = useState<Appointment>();
  const nearestAptRef = useRef<Appointment>();
  const [modalsVisible, setModalsVisible] = useState({
    followup: false,
    onboarding: false,
    insurance: false,
  });

  const [provider, setProvider] = useState<User>();
  useEffect(() => {
    props.dataAction.getPatientDashboardData();
  }, [props.dataAction]);

  useEffect(() => {
    if (props.patientDashboard) {
      setDataList(props.patientDashboard || []);
    }
  }, [props.patientDashboard]);

  // ---- end of old code
  const { authUser } = useSelector<IAppState, AuthState>((s) => s.auth) || {};
  const dispatch = useDispatch();
  const userName = getName(authUser);

  useEffect(() => {
    dispatch(
      loadAppointments({
        types: ['upcoming', 'ongoing'],
        patientId: authUser?.id,
      }),
    );
  }, []);

  const onCheckDaysBefore = (apt?: Appointment) => {
    // Subtract three days from the start time
    const threeDaysBefore = moment(apt?.startTime).subtract(7, 'days');

    // Check if the difference is within the range of three days
    const currentDate = moment();
    const isWithinRange =
      currentDate.isSameOrAfter(threeDaysBefore, 'day') &&
      currentDate.isSameOrBefore(apt?.startTime, 'day');
    return isWithinRange;
  };

  useEffect(() => {
    const items = (appointments.data || []).filter((i) => i.status);
    if (
      items[0] &&
      items[0]?.followUp &&
      items[0]?.followUpProgress !== 'complete' &&
      !sessionStorage.getItem('delayPaperwork')
    ) {
      setModalsVisible((state) => ({
        ...state,
        followup: onCheckDaysBefore(items[0]),
      }));
      injectApt(items[0]);
    } else {
      setModalsVisible((state) => ({
        ...state,
        followup: false,
      }));
    }
    if (
      authUser?.onboardingProgress &&
      authUser?.onboardingProgress !== 'complete' &&
      !sessionStorage.getItem('delayPaperwork')
    ) {
      setModalsVisible((state) => ({
        ...state,
        onboarding: true,
      }));
    }
    if (
      authUser?.insuranceCards.length === 0 &&
      !authUser?.selfPay &&
      authUser.onboardingProgress === 'complete' &&
      !sessionStorage.getItem('delayPaperwork')
    ) {
      setModalsVisible((state) => ({
        ...state,
        insurance: true,
      }));
    }
  }, [appointments]);

  const handleDelayPaperwork = () => {
    setModalsVisible({
      followup: false,
      onboarding: false,
      insurance: false,
    });
    sessionStorage.setItem('delayPaperwork', 'true');
  };

  const getNearestAppointment = (aptList: any) => {
    let nearestIndex = 0;
    aptList.forEach((time: string, i: number) => {
      if (moment(time).isBefore(time[Number(i) === 0 ? i : i - 1])) {
        nearestIndex = i;
      }
    });

    return aptList[nearestIndex];
  };
  const injectApt = (apt?: Appointment) => {
    nearestAptRef.current = apt;
    apt && setNearestApt(getNearestAppointment([apt]));
  };

  return (
    <React.Fragment>
      {modalsVisible.onboarding && (
        <ContinueOnboardingModal onClose={handleDelayPaperwork} />
      )}
      {modalsVisible.insurance && (
        <UploadInsuranceModal onClose={handleDelayPaperwork} />
      )}
      {modalsVisible.followup && (
        <FollowUpPaperWorksModal onClose={handleDelayPaperwork} />
      )}
      {!authUser?.termsConsent && (
        <PolicyModal
          userId={authUser?.id}
          termsConsent={!!authUser?.termsConsent}
          updateAuthUser={(data: object) => {
            dispatch(updateAuthUser(data));
            AuthService.updateUserInfo(data);
          }}
        />
      )}
      {!!dataList && (
        <div className="padding-wraper">
          {provider && (
            <SleepSpecialistInfo
              hideScheduleBtn={true}
              onClose={() => setProvider(undefined)}
              onSelectProvider={_.noop}
              provider={provider}
            />
          )}

          <Header currentPage="Dashboard" headerType="PatientDashboard" />
          <div className="patient-dashboard-wrap">
            <div className="big-titles">
              {`${getGreetingTime()} ${userName}!`}
            </div>
            {false && <MyProgress />}

            <div className="row">
              <div className="col col-md-4 col-xs-12">
                <NextAppointment
                  onOpenProviderInfo={setProvider}
                  patientId={authUser?.id}
                />
              </div>
              <div className="col col-md-4 col-xs-12">
                <ToDo />
              </div>
              <div className="col col-md-4 col-xs-12">
                <Orders />
              </div>
              {/* <div className="col col-md-4 col-xs-12">
                <WatchAndLearn dataList={dataList.watchAndLearn} />
              </div> */}
            </div>

            <div className="row">
              {/* <div className="col col-md-4 col-xs-12">
                <Snewzz />
              </div>
              <div className="col col-md-4 col-xs-12">
                <AppStoreBanner dataList={dataList.appStoreBanner} />
              </div> */}
            </div>

            <BottomLiveBar />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({ ...state.dataReducer });

const matchDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch),
  dataAction: bindActionCreators({ ...dataAction }, dispatch),
});

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(withRouter(PatientDashboardPage));
