import React from 'react';
import './styles.scss';

export interface IBottomLiveBarProps {
}

export const BottomLiveBar: React.FunctionComponent<IBottomLiveBarProps> = (props) => {  
  return (
    <div className="bottom-live-bar">
      <div className="blue-bg flex">
        <div className="talk-blue-point"></div>
        <div className="right-txt">
          <div className="txt">
            Live Assistance 
            <a href="tel:1-877-664-6669" className="blue-link">
              1-877-664-6669 
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomLiveBar;
