import React, { useEffect } from 'react';
import AppointmentService from '../../../services/appointmentService';

const Whereby = ({ appointment }) => {
  const observer = new MutationObserver((mutations_list) => {
    mutations_list.forEach((mutation) => {
      mutation.addedNodes.forEach((added_node) => {
        if (added_node.id === 'whereby-embed') {
          added_node.addEventListener('join', (event) => {
            if (event.type === 'join') {
              AppointmentService.joinOrLeave(appointment.id, event.type);
            }
          });

          observer.disconnect();
        }
      });
    });
  });

  observer.observe(document.querySelector('#appointment-container'), {
    subtree: false,
    childList: true,
  });

  useEffect(() => {
    const cdn = document.createElement('script');
    cdn.src = 'https://cdn.srv.whereby.com/embed/v1.js';
    cdn.type = 'module';

    document.body.appendChild(cdn);
  }, []);

  const auth = JSON.parse(localStorage.getItem('O_AUTH_KEY'));
  const { firstName, lastName } = auth.user;
  return (
    <whereby-embed
      id="whereby-embed"
      style={{
        width: '100%',
        position: 'fixed',
        height: '100vh',
        zIndex: 999,
      }}
      minimal
      room={`${appointment.roomUrl}?displayName=${firstName} ${lastName}&leaveButton=off&settingsButton=off&breakout=off&timer=on&participantCount=off&people=off`}></whereby-embed>
  );
};

export default Whereby;
