import {Dispatch} from 'redux';
import * as types from '../../constants/actionTypes';
import dataSvc from '../../services/dataSvc';

// loads Patient Dashboard Data
export function loadPatientDashboardData(data: any) {
  return {type: types.LOAD_PATIENT_DASHBOARD_DATA, data};
}

// loads Waiting Room Data
export function loadWaitingRoomData(data: any) {
  return {type: types.LOAD_WAITING_ROOM_DATA, data};
}

// loads Physician Landing Data
export function loadPhysicianLandingData(data: any) {
  return {type: types.LOAD_PHYSICIAN_LANDING_DATA, data};
}

// loads Physician Info Details Data
export function loadPhysicianInfoDetailsData(data: any) {
  return {type: types.LOAD_PHYSICIAN_INFO_DETAILS_DATA, data};
}

// loads Profile Info Data
export function loadProfileInfoData(data: any) {
  return {type: types.LOAD_PROFILE_INFO_DATA, data};
}

// loads Schedule Appointment Data
export function loadScheduleAppointmentData(data: any) {
  return {type: types.LOAD_SCHEDULE_APPOINTMENT_DATA, data};
}

// loads Relation To Policy Holder Data
export function loadRelationToPolicyHolderData(data: any) {
  return {type: types.LOAD_RELATION_TO_POLICY_HOLDER_DATA, data};
}

// loads Credit Card Issuer Data
export function loadCreditCardIssuerData(data: any) {
  return {type: types.LOAD_CREDIT_CARD_ISSUER_DATA, data};
}

// loads Snewzzz Data
export function loadSnewzzzData(data: any) {
  return {type: types.LOAD_SNEWZZZ_DATA, data};
}

// get Patient Dashboard data
export function getPatientDashboardData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getPatientDashboardData()
      .then((data) => {
        // load data
        dispatch(loadPatientDashboardData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

// get Waiting Room data
export function getWaitingRoomData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getWaitingRoomData()
      .then((data) => {
        // load data
        dispatch(loadWaitingRoomData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

// get Physician Landing data
export function getPhysicianLandingData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getPhysicianLandingData()
      .then((data) => {
        // load data
        dispatch(loadPhysicianLandingData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

// get Physician Info Details data
export function getPhysicianInfoDetailsData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getPhysicianInfoDetailsData()
      .then((data) => {
        // load data
        dispatch(loadPhysicianInfoDetailsData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

// get Profile Info data
export function getProfileInfoData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getProfileInfoData()
      .then((data) => {
        // load data
        dispatch(loadProfileInfoData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

// get Schedule Appointment data
export function getScheduleAppointmentData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getScheduleAppointmentData()
      .then((data) => {
        // load data
        dispatch(loadScheduleAppointmentData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

// get Relation To Policy Holder data
export function getRelationToPolicyHolderData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getRelationToPolicyHolderData()
      .then((data) => {
        // load data
        dispatch(loadRelationToPolicyHolderData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

// get Credit Card Issuer data
export function getCreditCardIssuerData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getCreditCardIssuerData()
      .then((data) => {
        // load data
        dispatch(loadCreditCardIssuerData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

// get Snewzzz data
export function getSnewzzzData() {
  return function func(dispatch: Dispatch) {
    // fetches remote data
    dataSvc
      .getSnewzzzData()
      .then((data) => {
        // load data
        dispatch(loadSnewzzzData(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

const exportItem = {
  getPatientDashboardData,
  getWaitingRoomData,
  getPhysicianLandingData,
  getPhysicianInfoDetailsData,
  getProfileInfoData,
  getScheduleAppointmentData,
  getRelationToPolicyHolderData,
  getCreditCardIssuerData,
  getSnewzzzData,
};

export default exportItem;
