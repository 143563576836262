import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CustomBtnSelect = (props) => {
  const {
    options,
    label,
    action,
    onClick,
    className = '',
    selected = null,
    labelError = false,
    labelStyle= {},
    children,
    persist,
    ...rest
  } = props;
  const [active, setActive] = useState(selected);

  useEffect(() => {
    if (selected !== active) {
      setActive(selected);
    }
  }, [selected]);

  const setOption = (option) => {
    const selected = active === option;
    !action && setActive(selected ? null : option);
  };

  const labelStyles = labelError
    ? {
        color: '#FF0404',
        fontWeight: 500,
        ...labelStyle,
      }
    : {};
    
  return (
    <div className="custom-btn-select">
      <div className="label" style={labelStyles}>{label}</div>
      <div className="btns">
        {options.map((option) => {
          const isActive = !action && active === option;
          return (
            <button
              {...rest}
              key={option}
              type="button"
              className={
                isActive ? `custom-select-active ${className}` : className
              }
              onClick={(e) => {
                const same = persist && (active === option)
                if (e.detail === 0 || same) return;
                setOption(option);
                onClick(option, e);
              }}>
              {option}
              {children}
            </button>
          );
        })}
      </div>
    </div>
  );
};

CustomBtnSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  action: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.oneOfType([
    null,
    PropTypes.bool,
    PropTypes.string,
  ]),
  persist: PropTypes.bool,
};

export default CustomBtnSelect;
