import { useRef, useEffect } from 'react';
import MedicalHistory from './MedicalHistory';
import Medications from './Medications';
import ProviderHistory from './ProviderHistory';
import SocialHistory from './SocialHistory';
import FamilyHistory from './FamilyHistory';
import SleepHistory from './SleepHistory';
import SystemsReview from './SystemsReview';
import './styles.scss'


const FormSelect = (props) => {
  const { updateHistory, history, onContinue } = props;
  const elementRef = useRef(null);
  const formRef = useRef(null);
  const handleClick = () => {
    elementRef.current?.scrollIntoView({ behavior: 'smooth' });
    // document.getElementById("ScrollToBottom").style.display = "none";
  };

  const handleScroll = () => {
    if (elementRef && elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();

      const onScreen = (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );

      document.getElementById("ScrollToBottom").style.display = (onScreen) ? "none" : "block";
    }
  };

  useEffect(() => {
    handleScroll();
    let scrollInterval = setInterval(() => handleScroll(), 1000)
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(scrollInterval);
    };
  }, []);    

  const FormElement = (props) => {
    switch (props.step) {
      case 'provider':
        return <ProviderHistory
          updateHistory={updateHistory}
          providerHistory={history?.providerHistory || {}}
          onContinue={onContinue}
          continueRef={elementRef}
        />
      case 'medications':
        return <Medications
          updateHistory={updateHistory}
          medications={history?.medications || {}}
          onContinue={onContinue}
          continueRef={elementRef}
        />
      case 'medical-history':
        return <MedicalHistory
          updateHistory={updateHistory}
          pastMedicalHistory={history?.pastMedicalHistory || {}}
          onContinue={onContinue}
          continueRef={elementRef}
        />
      case 'social-history':
        return <SocialHistory
          updateHistory={updateHistory}
          socialHistory={history?.socialHistory || {}}
          onContinue={onContinue}
          continueRef={elementRef}
        />
      case 'family-history':
        return <FamilyHistory
          updateHistory={updateHistory}
          familyHistory={history?.familyHistory || {}}
          onContinue={onContinue}
          continueRef={elementRef}
        />
      case 'sleep-history':
        return <SleepHistory
          updateHistory={updateHistory}
          sleepHistory={history?.sleepHistory || {}}
          onContinue={onContinue}
          continueRef={elementRef}
        />
      case 'details':
        return <SystemsReview
          updateHistory={updateHistory}
          reviewOfSystems={history?.reviewOfSystems || {}}
          onContinue={onContinue}
          continueRef={elementRef}
        />
      default:
        return null;
    }
  }

  return <>
    <div id="ScrollToBottom" className="fixed-btn" onClick={() => handleClick()}>
      <h5 style={{fontWeight: 600, width: "fit-content", margin: "auto"}}>Jump to Bottom</h5>
    </div>
    <FormElement step={props.step} ref={formRef} />
  </>
}

export default FormSelect