import React, { Fragment, useEffect, useState } from 'react';
import FormikControl from '../../components/Formik/FormikControl';
import { Formik, Form } from 'formik';
import AuthService from '../../services/authService';
import * as Yup from 'yup';
import {
  borderClass,
  manualFormValidation,
  scrollToError,
  yupDateValidation,
} from './utils';
import { useQuery } from '../../services/utils';
import { useHistory } from 'react-router-dom';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';

const SystemsReview = (props) => {
  const details = props?.reviewOfSystems;
  const history = useHistory();
  const query = useQuery();
  const view = query.get('view') || '1';
  const { user } = AuthService.getAuth();
  const [showDateError, setShowDateError] = useState(false);

  useEffect(() => {
    if (!parseInt(view) || parseInt(view) > 3) {
      history.push('/');
    }
  }, []);

  const str = details?.height?.toString().split('.');
  const feet = str?.[0] ? str?.[0] + ' ft' : '';
  const inches = str?.[1]
    ? str?.[1] + ' in'
    : str?.[0] && !str?.[1] + ' in'
    ? '0'
    : '';

  const unit = details?.unit || 'Imperial';
  const imperial = unit === 'Imperial';
  const weight = details?.weight;
  const weightUnit = imperial ? ' lbs' : ' kg';

  const initialValues = {
    unit,
    height: !imperial ? details?.height + ' cm' : details?.height || '',
    ft: feet,
    inc: inches,
    weight: weight ? weight + weightUnit : '',
    recentSymptoms: details?.recentSymptoms || [],
    accidentRelated: details?.accidentRelated || {},
  };

  const numValidation = (unit) =>
    Yup.string().when('unit', {
      is: unit,
      then: Yup.string().required(),
    });

  const validationSchema = Yup.object({
    unit: Yup.string().required(),
    ft: numValidation('Imperial'),
    inc: numValidation('Imperial'),
    height: numValidation('Metric'),
    weight: Yup.string().required(),
    recentSymptoms: Yup.array().of(Yup.string()),
    accidentRelated:
      view === '3'
        ? Yup.object({
            accident: Yup.string().required(),
            date: Yup.string().when('accident', {
              is: 'No',
              then: Yup.string(),
              otherwise: yupDateValidation(
                {
                  lt: new Date(),
                  path: 'accidentRelated.date',
                  required: true,
                },
                {
                  match: (valid) => setShowDateError(false),
                  test: (valid) => setShowDateError(!valid),
                },
              ),
            }),
            accidentInsurance: Yup.string(),
            employer: Yup.string().when('accident', {
              is: 'Work Accident',
              then: Yup.string().required(),
            }),
          })
        : null,
  });

  const trim = (v) => v.replace(/\D/g, '').trim();
  const handleHeightInput = (e, formik, label) => {
    const { name, value } = e.target;
    const sym = label || name;
    const str = trim(value);
    const commonUnits = sym === 'lbs' || sym === 'cm' || sym === 'kg';
    if (!str) return;
    if (name === 'inc' && (str.length > 2 || parseInt(str) > 11)) return;
    if (commonUnits && str.length > 3) return;
    if (name === 'ft' && str.length > 1) return;

    formik.setFieldValue(name, str + ' ' + sym);
  };

  const nextView = (v) =>
    history.push(`/newPatientForms?step=details&view=${parseInt(view) + 1}`);

  const onSubmit = async (values, setSubmitting) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const { ft, inc, unit, weight: mass } = newValues;
    setSubmitting(false);
    let onboardingProgress =
      parseInt(view) < 3 
        ? `details&view=${parseInt(view) + 1}`
        : 'complete';

    const weight = parseInt(trim(mass));
    if (unit === 'Imperial') {
      const height = parseFloat(`${trim(ft)}.${trim(inc)}`).toFixed(
        `${trim(inc)}`.length,
      );
      newValues.height = height;
      newValues.weight = weight;
    } else {
      newValues.height = parseInt(trim(values.height));
      newValues.weight = weight;
    }

    delete newValues.ft;
    delete newValues.inc;

    const data = {
      userId: user.id,
      type: 'New Patient',
      form: { reviewOfSystems: newValues },
    };

    try {
      const { updateMedicalHistory, updateProfile } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);

      await updateProfile({ onboardingProgress }, user.id);
      AuthService.updateUserInfo({ onboardingProgress });
      props.updateHistory(formDataRecord?.data?.form || {});
      parseInt(view) < 3
        ? nextView()
        : props.onContinue({ onboardingProgress: 'complete' });
      setSubmitting(false);
    } catch (error) {
      toast.error('Unable to save social history. Please try again.');
      setSubmitting(false);
    }
  };

  const onChangeAccident = (v, formik) => {
    const value = v !== 'No' ? v.replace('Yes,', '') : v;
    formik.resetForm();
    formik.setFieldValue('accidentRelated', { accident: value.trim() });
  };

  const onChangeUnit = (unit, formik) => {
    formik.setFormikState((state) => ({
      ...state,
      values: {
        ...state.values,
        ft: '',
        inc: '',
        unit,
        height: '',
        weight: '',
      },
      touched: {
        ...state.touched,
        ft: false,
        inc: false,
        height: false,
        weight: false,
      },
    }));
  };

  const handleClear = (e, formik) => {
    if (e.key === 'Backspace' || e.keyCode === 8) {
      e.target.select();
      formik.setFieldValue(e.target.name, '');
    }
  };

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Current Health</h3>
          <p>
            Please provide some key details about your body and any recent
            health issues.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          const details = formik.values;
          const { unit, ft, inc, accidentRelated } = details;
          const imperial = unit === 'Imperial';
          const dateName = 'accidentRelated.date';
          const accident = accidentRelated?.accident;
          const weightUnit = imperial ? 'lbs' : 'kg';

          const weightBorder = borderClass('weight', formik);
          const ftBorder = borderClass('ft', formik);
          const incBorder = borderClass('inc', formik);
          const heightBorder = borderClass('height', formik);
          const heightLabelError = [ftBorder, incBorder, heightBorder].includes(
            'error-border',
          );
          const accidentBorder = borderClass(
            'accidentRelated.accident',
            formik,
            false,
          );
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                manualFormValidation(formik);
                formik.handleSubmit();
              }}>
              {view === '1' && (
                <div className="form-container systems-review">
                  <FormikControl
                    control="custom-btn"
                    onClick={(v) => onChangeUnit(v, formik)}
                    label="Please select the unit of measurement."
                    options={['Imperial', 'Metric']}
                    selected={unit}
                  />

                  <p
                    style={
                      heightLabelError ? { color: 'red', fontWeight: 500 } : {}
                    }>
                    Height
                  </p>
                  {imperial ? (
                    <div className="row">
                      <FormikControl
                        className={borderClass('ft', formik)}
                        control="input"
                        type="text"
                        name="ft"
                        id="ft"
                        placeholder={'feet'}
                        showErrorMsg={false}
                        onKeyDown={(e) => handleClear(e, formik)}
                        onChange={(e) => handleHeightInput(e, formik)}
                        onClick={(e) => e.target.select()}
                        spellCheck="false"
                        value={ft}
                      />
                      <FormikControl
                        className={borderClass('inc', formik)}
                        control="input"
                        type="text"
                        name="inc"
                        id="inc"
                        placeholder="inches"
                        showErrorMsg={false}
                        onKeyDown={(e) => handleClear(e, formik)}
                        onChange={(e) => handleHeightInput(e, formik, 'in')}
                        onClick={(e) => e.target.select()}
                        spellCheck="false"
                        value={inc}
                      />
                    </div>
                  ) : (
                    <FormikControl
                      className={borderClass('height', formik)}
                      control="input"
                      type="text"
                      name="height"
                      id="height"
                      style={{ width: '82%' }}
                      placeholder={'cm'}
                      showErrorMsg={false}
                      onKeyDown={(e) => handleClear(e, formik)}
                      onChange={(e) => handleHeightInput(e, formik, 'cm')}
                      onClick={(e) => e.target.select()}
                      spellCheck="false"
                    />
                  )}

                  <div className="mt-2">
                    <p
                      style={
                        weightBorder === 'error-border'
                          ? { color: 'red', fontWeight: 500 }
                          : {}
                      }>
                      Weight
                    </p>
                    <FormikControl
                      className={weightBorder}
                      control="input"
                      type="text"
                      name="weight"
                      id="weight"
                      style={{ width: '82%' }}
                      placeholder={weightUnit}
                      showErrorMsg={false}
                      onKeyDown={(e) => handleClear(e, formik)}
                      onChange={(e) => handleHeightInput(e, formik, weightUnit)}
                      onClick={(e) => e.target.select()}
                      spellCheck="false"
                    />
                  </div>
                </div>
              )}

              {view === '2' && (
                <div className="form-container systems-review">
                  <p className="mb-3 mt-1">
                    Have you experienced any of the following in the past few
                    weeks?
                  </p>
                  {recentSymptomItems.map((item, i) => {
                    const recentSymptoms = details?.recentSymptoms;
                    const selected = recentSymptoms.includes(item);
                    return (
                      <FormikControl
                        key={item}
                        control="custom-btn"
                        className="mb-0 mt-0"
                        style={{ width: '95%' }}
                        onClick={(v) => {
                          formik.setFormikState((state) => {
                            let newState = { ...state };
                            let values = newState.values.recentSymptoms;
                            const index = values.indexOf(v);
                            index !== -1
                              ? values.splice(index, 1)
                              : values.push(v);
                            return newState;
                          });
                        }}
                        options={[item]}
                        selected={selected && item}
                      />
                    );
                  })}
                </div>
              )}

              {view === '3' && (
                <Fragment>
                  <div className="form-container systems-review">
                    <p
                      className="mb-3 mt-1"
                      style={
                        accidentBorder === 'error-border'
                          ? { color: 'red', fontWeight: 500 }
                          : {}
                      }>
                      Is this visit related to an Automobile or Work Accident?
                    </p>

                    <FormikControl
                      className={accidentBorder}
                      control="custom-btn"
                      onClick={(v) => onChangeAccident(v, formik)}
                      style={{ width: '95%' }}
                      persist={true}
                      options={[
                        'No',
                        'Yes, Work Accident',
                        'Yes, Automobile Accident',
                      ]}
                      selected={accident === 'No' ? 'No' : `Yes, ${accident}`}
                    />
                  </div>
                  {accident && accident !== 'No' && (
                    <div className="form-container systems-review">
                      <FormikControl
                        className={borderClass(dateName, formik)}
                        control="input"
                        format="##/##/####"
                        formatType="date"
                        onBlur={formik.handleBlur}
                        value={accidentRelated?.date || ''}
                        onChange={(v) =>
                          formik.setFieldValue(dateName, v.formattedValue)
                        }
                        name={dateName}
                        id={dateName}
                        placeholder="Date of Accident"
                        showErrorMsg={
                          !accidentRelated.date ? false : showDateError
                        }
                      />

                      <FormikControl
                        className={borderClass(
                          'accidentRelated.accidentInsurance',
                          formik,
                        )}
                        control="input"
                        name="accidentRelated.accidentInsurance"
                        id="accidentRelated.accidentInsurance"
                        value={accidentRelated?.accidentInsurance || ''}
                        placeholder={
                          accident === 'Work Accident'
                            ? 'Name of Insurance Carrier'
                            : 'Name of No-Fault Carrier'
                        }
                        showErrorMsg={false}
                      />

                      {accident === 'Work Accident' && (
                        <FormikControl
                          className={borderClass(
                            'accidentRelated.employer',
                            formik,
                          )}
                          control="input"
                          name="accidentRelated.employer"
                          id="accidentRelated.employer"
                          placeholder="Name of Employer (At time of injury)"
                          showErrorMsg={false}
                          value={accidentRelated.employer || ''}
                        />
                      )}
                    </div>
                  )}
                </Fragment>
              )}
              <button
                type="submit"
                className={`submit-btn`}
                onClick={() => scrollToError(false)}
                disabled={formik.isSubmitting}
                ref={props.continueRef}>
                Continue
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SystemsReview;

const recentSymptomItems = [
  'Skin rash',
  'Sores',
  'Excessive bruising',
  'Excessive thirst',
  'Excessive urination',
  'Significant headaches',
  'Double or blurred vision',
  'Diminished hearing',
  'Dizziness',
  'Sinus problems',
  'Cough',
  'Shortness of breath',
  'Wheezing',
  'Asthma',
  'Coughing up sputum or blood',
  'Blackouts or loss of consciousness',
  'Chest pain or pressure',
  'Rapid or irregular heart beats',
  'Abnormal swelling in legs or feet',
  'Pain in calves when you walk',
  'Difficulty swallowing',
  'Heartburn',
  'Nausea',
  'Vomiting',
  'Significant problems with constipation',
  'Diarrhea',
  'Fever',
  'Large lymph nodes',
  'Weight loss/gain of more than 10 pounds (last 6 months)',
  'Experiencing an unusually stressful situation',
];
