import React, { Fragment, useEffect, useState } from 'react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import UserService from '../../services/userService';
import Header from '../../components/Header';
import FormSelect from './NewPatientPages/FormSelect';
import CompletedForms from './CompletedForms';
import ProgressBar from '../../components/ProgressBar';

const NoAuthNewPatientForms = (props) => {
  const { user, goTo, inOffice } = props;
  const { token } = useParams();

  const [currStep, setCurrStep] = useState(null);
  const [medicalHistory, setMedicalHistory] = useState(props.medicalHistory);
  useEffect(() => setMedicalHistory(props.medicalHistory), [props.medicalHistory])

  const progressSteps = [
    'provider',
    'medications',
    'medical-history',
    'social-history',
    'family-history',
    'sleep-history',
    'details',
    'complete',
  ];

  const getNextStep = (step) => get(progressSteps, progressSteps.findIndex(s => s === step) + 1, 'complete');

  // Go to current-progress page
  useEffect(async () => {
    const tmp = (user.onboardingProgress || '').split('&')[0];
    if (!progressSteps.includes(tmp)) {
      setCurrStep(progressSteps[0]);
      goTo(progressSteps[0]);
    } else {
      setCurrStep(tmp);
      goTo(user.onboardingProgress);
    }
  }, []);

  const updateHistory = (v) => setMedicalHistory({ ...medicalHistory, ...v });

  const onContinue = async (step, data) => {
    const formDataRecord = await UserService.verifyAndExecute({ 
      verificationCode: token,
      tasks: [
        { key: 'updateMedicalHistory', body: data },
        { key: 'updateOnboardingProgress', body: { onboardingProgress: step } }
      ]
    }).then(res => res.data.updateMedicalHistory);
    updateHistory(formDataRecord?.data?.form || {});
    if (step && !step.includes('&')) setCurrStep(step);
    return goTo(step);
  };

  return (!user) ? <div></div> : (
    <Fragment>
      <Header
        currentPage=""
        headerType="NewPatientForms"
        customClass="registeration-header"
      />
      {currStep && <ProgressBar
        numberOfSteps={progressSteps.length}
        activeStep={progressSteps.findIndex(s => s === currStep)}
        complete={currStep === 'complete'}
      />}
      <Fragment>
        <FormSelect
          step={currStep}
          token={token}
          userId={user.id}
          medicalHistory={medicalHistory}
          onContinue={(data, nextStep) => onContinue(nextStep || getNextStep(currStep), data)}
        />
        {currStep === 'complete' && <CompletedForms inOffice={inOffice} />}
      </Fragment>
    </Fragment>
  );
};

export default NoAuthNewPatientForms;