import axios from 'axios';
import { Api } from './api';
import userService from './userService';

/**** DO NOT COMMIT */
const OrbitzConfig = {
  LOCATION_NUMBER: 4218,
  TOKEN: '1743e1488d1944b88e35d237e8369867',
  ENV: 'qav.api.orbithc.net',
};

export const createOrbitzPresigns = async () => {
  axios.defaults.headers.common['Authorization'] = OrbitzConfig.TOKEN;
  try {
    const frontResponse = await axios.post(
      `https://${OrbitzConfig.ENV}/api/v1/presign`,
      {
        mimeType: 'image/jpg',
        fileName: 'front.jpg',
        locationNumber: OrbitzConfig.LOCATION_NUMBER,
      },
    );
    const { data: front } = frontResponse;
    const backResponse = await axios.post(
      `https://${OrbitzConfig.ENV}/api/v1/presign`,
      {
        mimeType: 'image/jpg',
        fileName: 'back.jpg',
        locationNumber: OrbitzConfig.LOCATION_NUMBER,
      },
    );
    const { data: back } = backResponse;
    return {
      front: { fileId: front.data.fileId, presign: front.data.signedUrl },
      back: { fileId: back.data.fileId, presign: back.data.signedUrl },
    };
  } catch (err) {
    console.log(err);
  }
};

export const sendUploadUrl = async (
  insuranceId: string,
  phone: string,
  userId: string,
  env: string,
  registration: boolean,
) => {
  return Api.post('/users/send-upload-link', {
    params: { insuranceId, userId, phone, env, registration },
  });
};

export const verifyUploadCode = (
  email: string,
  userId: string,
  code: string,
) => {
  return Api.post('/verifycode', {
    params: { email, userId, code },
  });
};

export const updateInsurancePhoto = async (preSignUrl: string, image: File) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append('x-ms-blob-type', 'BlockBlob');
    myHeaders.append('Content-Type', 'Content-Type');

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: image,
    };

    const { status, statusText } = await fetch(preSignUrl, requestOptions);
    if (status === 201) {
      return { status, body: image };
    } else {
      console.error(statusText);
      return { status, body: statusText };
    }
  } catch (err) {
    console.error(err);
  }
};

export const uploadImageToOgnomyServer = async (file: any) => {
  try {
    return await Api.post('/files/user/insurance', file);
  } catch (err) {
    console.error(err);
  }
};

export const getOrtbitzOCR = async (
  fileIdFront: string,
  fileIdBack: string,
) => {
  axios.defaults.headers.common['Authorization'] = OrbitzConfig.TOKEN;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  try {
    const instance = axios.create();
    instance.defaults.timeout = 5000;
    const { data } = await instance.post(
      `https://${OrbitzConfig.ENV}/api/v1/ocr`,
      {
        ReferenceId: '',
        locationNumber: OrbitzConfig.LOCATION_NUMBER,
        files: [
          {
            fileId: fileIdFront,
            fileName: 'front.jpg',
            fileExt: '.jpg',
            fileSize: 90,
            width: 1080,
            height: 780,
            dpi: 300,
            cardFace: 'Front',
            imageCropMethod: 'OSC',
            imageCaptureMethod: 'UG',
            imageCropped: false,
            imageCompressionRatio: 20,
            imageOcrQuality: 78.5,
            imageTypeDetails: null,
          },
          {
            fileId: fileIdBack,
            fileName: 'back.jpg',
            fileExt: '.jpg',
            fileSize: 0,
            width: 0,
            height: 0,
            dpi: 0,
            cardFace: 'Back',
            imageCropMethod: 'OSC',
            imageCaptureMethod: 'UG',
            imageCropped: false,
            imageCompressionRatio: 20,
            imageOcrQuality: 78.5,
            imageTypeDetails: null,
          },
        ],
      },
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const verifyInsuranceCard = async (insuranceCard: any) => {
  axios.defaults.headers.common['Authorization'] = OrbitzConfig.TOKEN;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  const {
    orbitzId,
    locationId,
    firstName,
    lastName,
    gender,
    dob,
    address,
    city,
    state,
    zip,
    subscriberName,
    payerName,
    subscriberId,
    payerId,
    orbitPayerId,
    planNumber,
    externalId,
    relationship,
    backFileId,
    frontFileId,
  } = insuranceCard || {};
  try {
    const { data } = await axios.post(
      `https://${OrbitzConfig.ENV}/api/v1/insuranceverification`,
      {
        id: orbitzId,
        ReferenceId: externalId,
        locationNumber: locationId,
        patientInfo: {
          firstName: firstName,
          lastName: lastName,
          middleName: '',
          gender: gender,
          dob: dob,
          addressLine1: address,
          cityTown: city,
          stateProvinceCode: state,
          postalCode: zip,
          countryCode: 'US',
          phoneExtension: '',
          phoneType: 'Mobile',
        },
        patientInsurance: {
          SubscriberFullName: subscriberName,
          subscriberId: subscriberId,
          payerName: payerName,
          orbitPayerId: orbitPayerId,
          payerId: payerId,
          groupNumber: planNumber,
          PlanNumber: '',
          PlanType: '',
          Deductible: 0,
          RxBin: '',
          RxId: '',
          RxPcn: '',
          RxGrp: '',
          EmployerName: '',
          Priority: 'Primary',
          Relationship: relationship,
        },
        insuranceCardFiles: [
          {
            fileId: frontFileId,
            fileName: 'IMG_1857.jpg',
            fileExt: '.jpg',
            fileSize: 90,
            width: 1080,
            height: 780,
            dpi: 300,
            cardFace: 'Front',
            imageCropMethod: 'OSC',
            imageCaptureMethod: 'UG',
            imageCropped: false,
            imageCompressionRatio: 20,
            imageOcrQuality: 78.5,
            imageTypeDetails: null,
          },
          {
            fileId: backFileId,
            fileName: 'IMG_1858.jpg',
            fileExt: '.jpg',
            fileSize: 0,
            width: 0,
            height: 0,
            dpi: 0,
            cardFace: 'Back',
            imageCropMethod: 'OSC',
            imageCaptureMethod: 'UG',
            imageCropped: false,
            imageCompressionRatio: 20,
            imageOcrQuality: 78.5,
            imageTypeDetails: null,
          },
        ],
      },
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};
