import React from 'react';
import './styles.scss';
import {User} from "../../../services/dto/Security";
import {ProfileIcon} from "../../ProfileIcon";
import {getFullAdd, getName} from "../../../services/utils";

export interface ILeftProfileProps {
  provider: User;
}

export const LeftProfile: React.FunctionComponent<ILeftProfileProps> = (props) => {  
  const { provider } = props;

  return (
    <div className="left-profile">
      <div className="photo">
        <ProfileIcon user={provider} color={'blue'} fontSize={64}/>
      </div>
      <div className="title">
        {getName(provider)} {(provider.providerInfo?.qualifications || []).join(', ')}
      </div>
      <div className="txt addr">
        <p>{getFullAdd(provider)}</p>
      </div>
    </div>
  );
};

export default LeftProfile;
