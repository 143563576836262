import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/Formik/FormikControl';
import Header from '../../components/Header';
import { useQuery, removeAllChars } from '../../services/utils';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import { FE_URL } from '../../config';
import { borderClass, scrollToError, yupDateValidation, yupRegex } from '../../components/RegistrationComponents/utils';
import './styles.scss';


const Refferal_Types = [
    { key: 'How should we address your referrals?', value: '', disabled: true },
    { key: 'Associates', value: 'Associate' },
    { key: 'Patients', value: 'Patient' },
    { key: 'Users', value: 'User' }
];

const FindTypes = [
    { key: 'Organization Type', value: '', disabled: true },
    { key: 'CBTI', value: 'CBTI' },
    { key: 'Consumer Product', value: 'Consumer Product' },
    { key: 'Dentistry', value: 'Dentistry' },
    { key: 'ENT', value: 'ENT' },
    { key: 'Primary Care', value: 'Primary Care' },
    { key: 'Sleep Specialists', value: 'Sleep Specialists' },
    { key: 'Other', value: 'Other' }
];

const AffiliateEnrollment = (props) => {
    const [success, setSuccess] = useState(false);
    const [partnerDetail, setPartnerDetail] = useState({});
    const query = useQuery();
    const source = query.get('source') || '';

    const initialValues = {
        name: '',
        phone: '',
        fax: '',
        email: '',
        userTitle: '',
        organization: '',
        source: source
    };

    const validationSchema = Yup.object({
        name: Yup.string().matches(yupRegex.letters).required(),
        phone: Yup.string().matches(yupRegex.phone).required(),
        fax: Yup.string().matches(yupRegex.phone),
        userTitle: Yup.string().matches(yupRegex.letters).required(),
        organization: Yup.string().matches(yupRegex.letters).required(),
        source: Yup.string().matches(yupRegex.letters).required(),
        email: Yup.string()
            .email('Must be a valid email')
            .required('Email is invalid'),
    });

    const onSubmit = async (values, setSubmitting) => {
        setSubmitting(false);
        const data = {
            name: values.name,
            phone: removeAllChars(values.phone),
            email: values.email,
            userTitle: values.userTitle,
            comments: `Fax: ${values.fax}
Organization Type: ${values.organization}
Source: ${values.source}
`,
            file: [],
            landingRoute: values.name
        }

        const uploadBody = new FormData();
        const filePath = `${FE_URL}/assets/GenericHealthcare.png`;

        const fileResponse = await fetch(filePath);
        const fileBlob = await fileResponse.blob();
        uploadBody.append("file", fileBlob);
        
        Object.keys(data).forEach((key) => {
            const value = data[key];
            if (key === "file") delete data[key];
            uploadBody.append(key, value);
        });


        UserService.enrollPartner(uploadBody).then((rsp) => {
            setPartnerDetail(rsp.data);
            setSuccess(true);
            setSubmitting(true);
        }).catch((err) => toast.error(err.message))
    };

    return (
        <Fragment>
            {props.renderType !== 'profile' && (
                <Header
                    currentPage=""
                    title="Affiliate Enrollment"
                    headerType={'Non-LoggedIn'}
                    customClass="registeration-header"
                />
            )}
            <div className="onboarding-form">
                <div className="header-info">
                    <div className='icon-patients-container'>
                        <div className='icon-patients'>
                            <img src="/assets/icon-patients.svg" alt="img" />
                        </div>
                    </div>
                    {!success && <div className="description">
                        <h3>Affiliate Enrollment</h3>
                        <p>Please provide some of your office's information so we can update you on the patient's progress.</p>
                    </div>}
                </div>
                {success && <div className='form-container form-success form-success__partner'>
                    <h3 style={{color: "#3f5c76"}}>You’re All Set</h3>
                    <p>Thank you for registering as an Ognomy partner. You can now refer patients to us using the following link:</p>
                    <div className='link-container' onClick={() => {
                        navigator.clipboard.writeText(`${window.location.protocol + "//" + window.location.host}/welcome/${partnerDetail.landingRoute}`)
                        .then(() => toast.success('Url has been copied to your clipboard'));
                    }}>
                        <p className='copy-link'>{`${window.location.protocol + "//" + window.location.host}/welcome/${partnerDetail.landingRoute}`}</p>
                        <i className="icons icon-copy" />
                    </div>
                </div>}
                {!success && <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, props) => {
                        onSubmit(values, props.setSubmitting);
                    }}>
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="form-container">
                                <FormikControl
                                    className={borderClass('name', formik)}
                                    control="input"
                                    type="char"
                                    name="name"
                                    placeholder="Company/Practice Name"
                                    showErrorMsg={false}
                                />
                                <FormikControl
                                    className={borderClass('email', formik)}
                                    control="input"
                                    type="email"
                                    name="email"
                                    placeholder="Practice/Entity Email Address "
                                    showErrorMsg={false}
                                />
                                <FormikControl
                                    className={borderClass('phone', formik)}
                                    control="input"
                                    type="text"
                                    name="phone"
                                    maxLength={15}
                                    placeholder="Practice/Entity Phone Number"
                                    format="(###) ###-####"
                                    value={formik.values.phone}
                                    onChange={(v) =>
                                        formik.setFieldValue('phone', v.formattedValue)
                                    }
                                    onBlur={formik.handleBlur}
                                    showErrorMsg={false}
                                />
                                <FormikControl
                                    className={borderClass('fax', formik)}
                                    control="input"
                                    type="text"
                                    name="fax"
                                    maxLength={15}
                                    placeholder="Practice/Entity Fax Number"
                                    format="(###) ###-####"
                                    value={formik.values.fax}
                                    onChange={(v) =>
                                        formik.setFieldValue('fax', v.formattedValue)
                                    }
                                    onBlur={formik.handleBlur}
                                    showErrorMsg={false}
                                />
                                <FormikControl
                                    className={borderClass('organization', formik)}
                                    control="select"
                                    expanding={true}
                                    handlers={formik}
                                    options={FindTypes}
                                    name="organization"
                                    showErrorMsg={false}
                                />
                                <FormikControl
                                    className={borderClass('userTitle', formik)}
                                    control="select"
                                    expanding={true}
                                    handlers={formik}
                                    options={Refferal_Types}
                                    name="userTitle"
                                    showErrorMsg={false}
                                />
                                <FormikControl
                                    className={borderClass('source', formik)}
                                    control="input"
                                    type="text"
                                    placeholder="How did you find us?"
                                    name="source"
                                    showErrorMsg={false}
                                />
                            </div>
                            <button
                                type="submit"
                                className="submit-btn"
                                onClick={() => scrollToError(false)}>
                                {'Submit'}
                            </button>
                        </Form>
                    )}
                </Formik>}
            </div>
        </Fragment>
    );
};

export default AffiliateEnrollment;
