import React, { useEffect, useRef, useState } from 'react';
import _, { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Appointment, DischargeSummary, Order, Slot } from "../../services/dto/Common";
import LeftStepList, { TodoItem } from "../../components/WaitingRoomComponents/LeftStepList";
import ScheduleHST from "../../components/WaitingRoomComponents/ScheduleHST";
import './styles.scss'
import { APPOINTMENT_TITLE, OrderType } from "../../config";
import UserService from "../../services/userService";
import { showApiError, showSuccess, useIsMounted } from "../../services/utils";
import FormScheduleAppointment from "../../components/RegistrationProgressComponents/FormScheduleAppointment";
import { User } from "../../services/dto/Security";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../../store/reducers";
import { AuthState } from "../../store/reducers/auth";
import { Api } from '../../services/api';
import { AxiosResponse } from 'axios';
import AuthService from '../../services/authService';
import { updateAuthUser } from '../../store/actions/auth';

export interface AppointmentCompletedProps {
  apt: Appointment
  onFinished: (steps: TodoItem[]) => void;
}


const HST_TODO: TodoItem = { title: 'Schedule Home Sleep Test', completed: false, type: 'hst' };
const FOLLOW_LIMITED_PROVIDER_TODO: TodoItem = { defaultTitle: 'Schedule Specialist Visit', completed: false, type: 'specialist' }
const FOLLOW_UP_TODO: TodoItem = { title: 'Schedule Follow-Up', completed: false, type: 'followup' }
const PULL_DISCHARGE_SUMMARY = 1000 * 8;

export const AppointmentCompleted = (props: AppointmentCompletedProps) => {
  const { apt } = props;
  const [orders, setOrders] = useState<Order[]>();
  const [waiting, setWaiting] = useState<boolean>(true)
  const [hasFollowup, setHasFollowup] = useState<boolean>(false)
  const [steps, setSteps] = useState<TodoItem[]>([])
  const [limitedProviderList, setlimitedProviderList] = useState([])
  const [limitedProviderIndex, setlimitedProviderIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const { authUser } = useSelector<IAppState, AuthState>(s => s.auth) //
  const isMounted = useIsMounted();
  const timeHandler = useRef<any>();
  const defaultFollowup = useRef<string>();

  const checkOrders = (aptId: string, NoFollowUp: boolean) => {
    UserService.getOrders({ appointmentId: aptId, perPage: 0 })
      .then(async (rsp) => {
        let todos = [];
        let limitedProviders: any = [];
        let subRoleTitle: { [key: string]: string } = {};
        const hasOrders = rsp.data.length;
        const hasHSTOrder = hasOrders && rsp.data.find((order) => order.type === OrderType.HST);
        const nonHSTOrders = hasOrders && rsp.data.filter((order) => order.type !== OrderType.HST);
        const isPickUp = hasOrders && rsp.data.find(
          (order) => order.rawOrder.orderDetail.deliveryType === 'Pick-Up',
        );
        const isPSGorTitration = hasOrders && rsp.data.find(
          (order) => ['PSG', 'Titration', 'MWT', 'Splitnight'].includes(order.rawOrder.orderDetail.formType),
        );
        if (nonHSTOrders) {
          const providersWithNoApt = await Api.get('/limitedproviders', {
            params: { checkAppointment: true },
          }).catch((err) => err);
          const providerData = providersWithNoApt?.data || [];
          providerData.filter((p: any, i: number) => {
            const provider = nonHSTOrders.find(
              (order) => p.id === get(order, 'otherProvider.physicianId'),
            );
            const hasDuplicate = limitedProviders.find(
              (obj: any) => obj.subrole === p.subrole,
            );
            const title = p?.subrole || 'Specialist';
            subRoleTitle[`provider${i}`] = `Schedule ${title} Visit`;

            if (provider && !hasDuplicate) {
              limitedProviders.push(p);
            }
          });

          setlimitedProviderList(limitedProviders);
        }
        if (isMounted.current) {
          setWaiting(false);
          if (hasHSTOrder && !isPickUp && !isPSGorTitration) {
            setOrders([hasHSTOrder]);
            todos.push({ ...HST_TODO });
          }
          if (limitedProviders.length) {
            limitedProviders.forEach((p: object, i: number) => {
              const defaultTitle = FOLLOW_LIMITED_PROVIDER_TODO.defaultTitle;
              todos.push({
                ...FOLLOW_LIMITED_PROVIDER_TODO,
                title: subRoleTitle[`provider${i}`] || defaultTitle,
                ...subRoleTitle,
              });
            });
          }
          if (!NoFollowUp) {
            todos.push({ ...FOLLOW_UP_TODO });
            setHasFollowup(true);
          }

          todos = todos.map((todo, i) => ({...todo, title: `${i + 1}. ${todo.title}`}))
          setSteps(todos);
        }
      })
      .catch(showApiError);
  };

  useEffect(() => {
    checkDischargeSummary();
    updatedUserAndAuth();

    return () => {
      clearTimeout(timeHandler.current);
    };
  }, []);

  const checkDischargeSummary = () => {
    const aptId = apt.id;
    UserService.getDischargeSummary(aptId)
      .then((rsp: AxiosResponse<DischargeSummary>) => {
        checkOrders(aptId, rsp.data.form.NoFollowUp);
      })
      .catch((e) => {
        checkDischargeSummaryAgain();
      });
  };

  const updatedUserAndAuth = async () => {
    await UserService.getUser(AuthService.getAuth().user.id)
      .then((user: AxiosResponse<User>) => {
        UserService.updateProfile({ isNewPatient: false }, user.data.id)
        user.data.isNewPatient = false;
        AuthService.updateUserInfo(user.data);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  }

  const checkDischargeSummaryAgain = () => {
    timeHandler.current = setTimeout(() => checkDischargeSummary(), PULL_DISCHARGE_SUMMARY)
  }

  const markAsCompleted = (key: string) => {
    const newSteps = [...(steps || [])]

    const todoItem: any = newSteps?.find(i => i.type === key && !i.completed)
    if (todoItem) {
      todoItem.completed = true
    }
  
    setSteps(newSteps)
  }

  const isStepCompleted = (key: string) => {
    if (key === 'specialist') {
      const specialists = steps.filter((step: TodoItem) => step.type === key);
      const items = steps.filter(
        (step: TodoItem) => step.type === key && step.completed,
      );
      return specialists.length === items.length;
    }
    
    const todoItem = steps.find((i) => i.type === key);
    return get(todoItem, 'completed', true);
  }

  const showFollowup = () => {
    if (!steps || steps.length <= 0) {
      return false;
    }
    return isStepCompleted('hst') && isStepCompleted('specialist')
  }

  const showSpecialist = () => {
    if (!steps || steps.length <= 0) {
      return false;
    }
    return isStepCompleted('hst') && !isStepCompleted('specialist')
  }

  const onHSTDone = () => {
    markAsCompleted('hst');
  };


  const onFollowUpDone = (provider: User, slot: Slot) => {
    const body = {
      description: APPOINTMENT_TITLE,
      providerId: provider.id,
      patientId: authUser?.id,
      startTime: slot.start,
      endTime: slot.end,
      on: moment(slot.start).format('MMM DD, YYYY'),
      at: moment(slot.start).format('hh:mm a'),
    };
    setLoading(true);
    UserService.createAppointment(body)
      .then(rsp => {
        showSuccess('Appointment has been created successfully')

        if(isStepCompleted('specialist')) {
          markAsCompleted('followup')
          props.onFinished(steps || []);
        } else if(limitedProviderList.length - 1 > limitedProviderIndex) {
          const index = limitedProviderIndex + 1
          setlimitedProviderIndex(index)
          markAsCompleted('specialist')
        } else if(limitedProviderList.length - 1 === limitedProviderIndex) {
          markAsCompleted('specialist')
          !hasFollowup && setSteps([])
        }

        setLoading(false)
      })
      .catch(e => {
        showApiError(e)
        setLoading(false)
      })
  }

  return (
    <div className="three-grid re">
      <div className="row">
        <div className="col col-md-4 col-12">
          <LeftStepList waiting={waiting} steps={steps || []} apt={apt} />
        </div>

        {!waiting && (
          <div className="col col-md-8 col-12">
            {!isStepCompleted('hst') && steps && steps.length > 0 && (
              <ScheduleHST
                order={orders ? orders[0] : undefined}
                onDone={onHSTDone}
              />
            )}
            {!loading && (showFollowup() || showSpecialist()) && (
              <FormScheduleAppointment
                aptCount={2}
                hideProvider={true}
                provider={
                  isStepCompleted('specialist')
                    ? apt.provider
                    : limitedProviderList[limitedProviderIndex]
                }
                onNextStep={_.noop}
                loading={loading}
                savingAppointment={loading}
                onConfirmed={onFollowUpDone}
                onPreviousStep={_.noop}
              />
            )}
            {steps.length === 0 && (
              <NavLink
                to="/patientDashboardPage"
                className="btn btn-blue btn-dash">
                Go To Dashboard
              </NavLink>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
