import { isArray, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthService from '../../services/authService';
import UserService from '../../services/userService';
import { useQuery } from '../../services/utils';
import AppointmentConfirmPage from './AppointmentConfirmPage';
import { useSelector, useDispatch } from 'react-redux';
import { updateAuthUser } from '../../store/actions/auth';
import Header from '../../components/Header';
import { Api } from '../../services/api';

const AppointmentContainer = (props) => {
  const { user } = AuthService.getAuth();
  const query = useQuery();
  const view = query.get('view');
  const [state, setState] = useState({
    fetchingLogo: false,
    partner: {},
    base64: '',
  });

  const setGenericState = (value) =>
  setState((state) => ({ ...state, ...value }));

  useEffect(() => {
    if (query.get('refId')) {
      setGenericState({ fetchingLogo: true });
      Api.get(`/referralUsers?refId=${query.get('refId') || ''}`)
        .then(async (res) => {
          let data = res.data[0];
          const logoBuffer = data?.logoBuffer?.data;
          const base64 = btoa(
            new Uint8Array(logoBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          );
          setState({
            partner: res.data[0],
            base64: 'data:;base64,' + base64,
          });
        })
        .finally(() => setGenericState({ fetchingLogo: false }));
    }
  }, []);


  return (
    <Fragment>
       <Header
          currentPage=""
          headerType={state?.partner?.name ? 'Referred' : 'AppointmentConfirm'}
          partnerLogo={state?.base64}
          partnerName={state?.partner?.name}
          customClass="registeration-header"
        />
      <Fragment>
        {view === 'confirm' && (
          <AppointmentConfirmPage />
        )}
      </Fragment>
    </Fragment>
  );
};

export default AppointmentContainer;
