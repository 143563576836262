import React, { useEffect } from 'react';
import FormikControl from '../../../components/Formik/FormikControl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { borderClass, scrollToError, textAreaAutoGrow } from '../utils';
import { useQuery } from '../../../services/utils';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const MedicalHistory = (props) => {
  const { userId, pastMedicalHistory } = props;
  console.log("mh", pastMedicalHistory)
  const history = useHistory();
  const query = useQuery();
  const step = query.get('step');
  const view = query.get('view') || '1';

  useEffect(() => {
    if (step === 'medical-history' && parseInt(view) && parseInt(view) > 3) {
      history.push('/');
    }
  }, []);

  const initializeValues = (items, values) => {
    return items.reduce((obj, v, i) => {
      const value = values?.find((k) => k.name === v);
      return { ...obj, [i]: value || null };
    }, {});
  };

  const initialValues = {
    hasPaceMaker: pastMedicalHistory?.hasPaceMaker,
    genericMedicalProblems: initializeValues(
      genericItems,
      pastMedicalHistory?.genericMedicalProblems,
    ),
    specificMedicalProblems: initializeValues( 
      specificItems,
      pastMedicalHistory?.specificMedicalProblems,
    ),
  };

  const initializeValidation = (items, schema) =>
    items.reduce(
      (obj, v, i) => ({
        ...obj,
        [i]: schema,
      }),
      {},
    );

  const baseValidation = (withType) =>
    Yup.object({
      name: Yup.string().required(),
      description: Yup.string(),
      type: withType && Yup.string().required(),
    }).nullable();

  const validationSchema = Yup.object({
    hasPaceMaker: Yup.boolean().required(),
    genericMedicalProblems: Yup.object(
      initializeValidation(genericItems, baseValidation()),
    ),
    specificMedicalProblems: Yup.object(),
  });

  const constructArrays = (obj) =>
    Object.keys(obj).flatMap((i) => (obj[i] ? [obj[i]] : []));

  const onSubmit = async (values, setSubmitting) => {
    const { genericMedicalProblems, specificMedicalProblems } = values;
    const data = {
      userId: userId,
      type: 'New Patient',
      form: {
        pastMedicalHistory: {
          hasPaceMaker: values.hasPaceMaker,
          genericMedicalProblems: constructArrays(genericMedicalProblems),
          specificMedicalProblems: constructArrays(specificMedicalProblems),
        },
      },
    };

    try {
      (view === '3') ? props.onContinue(data) : props.onContinue(data, `medical-history&view=${parseInt(view) + 1}`);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error('Unable to save medical history. Please try again.');
    }
  };

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Past Medical History</h3>
          <p>Please provide some key details about your medical past.</p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          const {
            hasPaceMaker,
            genericMedicalProblems,
            specificMedicalProblems,
          } = formik.values;
          const isBoolean = typeof hasPaceMaker === 'boolean';

          return (
            <Form onSubmit={formik.handleSubmit}>
              <div className="form-container">
                {(!view || view === '1') && (
                  <FormikControl
                    control="custom-btn"
                    onClick={(v) => {
                      formik.setFieldValue('hasPaceMaker', v === 'Yes');
                    }}
                    label="Do you have a pacemaker?"
                    options={['Yes', 'No']}
                    persist={true}
                    selected={!isBoolean ? '' : hasPaceMaker ? 'Yes' : 'No'}
                  />
                )}
                <div className="medical-list">
                  {view === '2' && (
                    <p className="title mb-3">
                      Have you had any of the following? Click all that apply.
                    </p>
                  )}
                  {view === '2' &&
                    genericItems.map((name, i) => {
                      const inputName = `genericMedicalProblems.${i}.description`;
                      const hasObj = genericMedicalProblems?.[i];
                      return (
                        <div key={name} className="item">
                          <FormikControl
                            control="custom-btn"
                            id="custom-btn-item"
                            onClick={() => {
                              formik.setFieldValue(
                                `genericMedicalProblems.${i}`,
                                hasObj ? null : { name, description: '' },
                              );
                            }}
                            options={[name]}
                            selected={hasObj && name}>
                            {hasObj && (
                              <FormikControl
                                className={borderClass(inputName, formik)}
                                control="textarea"
                                type="text"
                                name={inputName}
                                id={inputName}
                                style={{ resize: 'none' }}
                                placeholder="Describe"
                                showErrorMsg={false}
                                maxLength={100}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) =>
                                  textAreaAutoGrow(e, formik.setFieldValue)
                                }
                              />
                            )}
                          </FormikControl>
                        </div>
                      );
                    })}

                  {view === '3' &&
                    specificItems.map((name, i) => {
                      const inputName = `specificMedicalProblems.${i}.description`;
                      const btnName = `specificMedicalProblems.${i}.type`;
                      const hasObj = specificMedicalProblems?.[i];

                      return (
                        <div key={name} className="item">
                          <FormikControl
                            control="custom-btn"
                            id="custom-btn-item"
                            onClick={() => {
                              formik.setFieldValue(
                                `specificMedicalProblems.${i}`,
                                hasObj
                                  ? null
                                  : { name, description: '', type: '' },
                              );
                            }}
                            options={[name]}
                            selected={hasObj && name}>
                            <div>
                              {hasObj && (
                                <FormikControl
                                  control="custom-btn"
                                  className={`medical-type-btn ${borderClass(
                                    btnName,
                                    formik,
                                  )}`}
                                  onClick={(v, e) => {
                                    e.stopPropagation();
                                    formik.setFieldValue(btnName, v);
                                  }}
                                  options={['Medical', 'Surgical']}
                                  selected={hasObj && hasObj.type}
                                />
                              )}
                            </div>
                            {hasObj && (
                              <FormikControl
                                className={borderClass(inputName, formik)}
                                control="textarea"
                                type="text"
                                name={inputName}
                                id={inputName}
                                style={{ resize: 'none' }}
                                placeholder="Describe"
                                showErrorMsg={false}
                                maxLength={100}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) =>
                                  textAreaAutoGrow(e, formik.setFieldValue)
                                }
                              />
                            )}
                          </FormikControl>
                        </div>
                      );
                    })}
                </div>
              </div>
              <button
                type="submit"
                className={`submit-btn ${!isBoolean ? 'disabled' : ''}`}
                onClick={() => scrollToError()}
                disabled={view === '1' && (formik.isSubmitting || !isBoolean)}
                ref={props.continueRef}>
                Continue
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const genericItems = [
  'Anesthesia Complications',
  'Anxiety, Depression or Mental Illness',
  'Blood Problems (Anemia, High/low White count)',
  'Diabetes',
  'High Blood Pressure',
  'High Cholesterol or Triglycerides',
  'Stroke or TIA',
];
const specificItems = [
  'Eyes (Cataracts, Glaucoma)',
  'Ears, Nose Sinuses, Tonsils',
  'Thyroid, Parathyroid Glands',
  'Heart Valves, Heart Rhythm, Heart Failure',
  'Coronary (Heart), Arteries (Angina, Heart Attack)',
  'Arteries (Aorta, Arteries to head, arms, legs)',
  'Veins, Blood clots in veins',
  'Lungs',
  'Esophagus or Stomach (Ulcer)',
  'Bowel (Small or large intestine)',
  'Appendix',
  'Liver or Gallbladder',
  'Hernia',
  'Kidneys, Bladder',
  'Bones, Joints, Muscles',
  'Back, Neck, Spine',
  'Brain',
  'Skin',
  'Breasts',
  'Uterus, Tubes, Ovaries ',
  'Prostate, Penis, Testes, Vasectomy ',
  'Other (Please describe)',
];

export default MedicalHistory;
