import React from 'react';
import './styles.scss';
import dayjs from 'dayjs';
import { Button, Link,  Grid, Paper } from "@mui/material"
import { User } from "../../../services/dto/Security";
import { Slot } from '../../../services/dto/Common';
import AppointmentInfo from './AppointmentInfo';

type aptInfoType = {
  subject: string,
  provider: User,
  times: Slot,
  duration: number,
  location: string,
  timeZone: string,
}

type buttonType = {
  text: string,
  effect: Function,
  disabled?: boolean,
}

export interface IScheduleConfirmProps {
  aptInfo: aptInfoType
  buttons?: {
    cancel: buttonType
    confirm: buttonType
  }
  title?: string
}

const ScheduleConfirm: React.FunctionComponent<IScheduleConfirmProps> = (props) => {
  const { aptInfo, buttons } = props;
  const [startTime, endTime] = [dayjs(aptInfo.times.start).tz(aptInfo.timeZone), dayjs(aptInfo.times.end).tz(aptInfo.timeZone)];

  const fullDate = 
    <div>
      <div className="time-string">{startTime.format("dddd, MMM D, YYYY")}</div>
      <div className="time-string">{`${startTime.format("h:mma")} - ${endTime.format("h:mma")}`}</div>
    </div>;
  const LINK_FORMAT = 'YYYYMMDDTHHmmssZ'
  const calendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=Ognomy+${aptInfo.subject.split(' ').join('+')}&dates=${dayjs(startTime).format(LINK_FORMAT)}/${dayjs(endTime).format(LINK_FORMAT)}&details=To+join+your+virtual+appointment,+go+to+your+<a href="https://patient.ognomy.com/signInPage">Ognomy+dashboard</a>&location=Virtual&sf=true&output=xml`
  const linkElement = <Link href={calendarLink} underline="always" target="_blank" rel="noopener noreferrer">Add To Google Calendar</Link>;
  
  return (
    <Paper className="scheduling-confirm-modal center">
      <Grid container rowSpacing={2}>
        
        {/* Title */}
        {props.title &&
        <Grid item>
          <h3>{props.title}</h3>
        </Grid>}

        {/* Details */}
        <Grid item className="modal-body">
          <AppointmentInfo 
            provider={aptInfo.provider}
            subject={aptInfo.subject}
            info={{
              location: aptInfo.location,
              date: fullDate,
              time: `${aptInfo.duration} minutes`,
              timeZone: aptInfo.timeZone,
              link: linkElement,
            }}
            iconWidth={1.5}
            spacing={1}
          />
        </Grid>

        {/* Buttons */}
        {buttons && <Grid item className="btn-row">
          <Button variant="outlined" onClick={() => buttons.cancel.effect()} disabled={!!buttons.cancel.disabled}>
            {buttons.cancel.text}
          </Button>
          <Button variant="contained" onClick={() => buttons.confirm.effect()} disabled={!!buttons.confirm.disabled} autoFocus>
            {buttons.confirm.text}
          </Button>
        </Grid>}
      </Grid>
    </Paper>
  );
};

export default ScheduleConfirm;
