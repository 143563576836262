/**
 * appointment api service
 */
import {Api} from './api';

export default class AppointmentService {
  /**
   * create meeting
   * @param appointmentId the appointment id
   * @param entity the post entity
   */
  static createMeeting(appointmentId: string, entity: any) {
    return Api.post(`/meetings?appointmentId=${appointmentId}`, entity);
  }

  /**
   * end appointment
   * @param appointmentId the appointment
   */
  static endAppointment(appointmentId: string) {
    return Api.put(`appointments/${appointmentId}/complete`);
  }

  /**
   * join or leave appointment
   * @param appointmentId the appointment id
   * @param type the type
   */
  static joinOrLeave(appointmentId: string, type: string) {
    return Api.put(`appointments/${appointmentId}/joinOrLeave?type=${type}`);
  }

  /**
   * delete appointment
   * @param id the appointment id
   */
  static deleteAppointment(id: string, params: any) {
    return Api.delete(`appointments/${id}`, {params});
  }

  /**
   * get follow up appointments
   * @param patientId the patient id
   */
  static getFollowUp(patientId: string) {
    return Api.get(`/appointments/followUp?patientId=${patientId}`);
  }

  /**
   * update returning document
   * @param appointmentId the appointment id
   * @param entity the entity
   */
  static updateReturnDocumentPage(appointmentId: string, entity: string) {
    return Api.put(`appointments/${appointmentId}/updateReturnDocumentPage`, entity);
  }

  /**
   * get availability
   * @param params the params
   */
  static getProviderAvailability(params: any) {
    return Api.get('/availability', {params});
  }
}
