import React from 'react';
import './styles.scss';
import { Dayjs } from 'dayjs';
import { Card, CardMedia, Grid } from "@mui/material"
import { Event } from "@mui/icons-material"
import { AppointmentInfoRows } from './AppointmentInfo';

export interface ITopCardMediaProps {
  startTime: Dayjs,
  endTime: Dayjs,
  location: string
}

const TopCardMedia: React.FunctionComponent<ITopCardMediaProps> = ({ startTime, endTime, location, }) => {
  return (
    <CardMedia className="date">
      <Card className="schedule-card">
        <Grid container>
          <Grid item xs={2} className="calendar-icon">
            <Event />
          </Grid>
          <Grid item xs={10} className="date-info">
            <Grid container spacing={0.5}>
              <Grid item>
                <h6 style={{ fontSize: 18 }}>
                  {startTime.format('dddd, MMM D')}
                </h6>
              </Grid>
              <Grid item>
                <AppointmentInfoRows
                  info={{
                    time: `${startTime.format('h:mma')} - ${endTime.format(
                      'h:mma',
                    )}`,
                    location: location,
                  }}
                  spacing={0.4}
                  color={'white'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </CardMedia>
  );
};

export default TopCardMedia;
