import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { User } from '../../../services/dto/Security';
import { Appointment } from '../../../services/dto/Common';
import { ProfileIcon } from '../../ProfileIcon';
import {
  checkIsCanMeeting,
  getErrorString,
  getName,
} from '../../../services/utils';
import moment from 'moment';
import { FORMAT_HOUR_FULL, FORMAT_MONTH_SHORT } from '../../../config';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/reducers';
import { AppointmentsState } from '../../../store/reducers/appointments';
import GetReady from '../GetReady';
import { connectSocket } from '../../../socket.io';
import UserService from '../../../services/userService';
import { toast } from 'react-toastify';
import AppointmentService from '../../../services/appointmentService';
import CancelModal from './CancelModal';
import dayjs from 'dayjs';
import ArrowButton from './ArrowButton';

export interface INextAppointmentProps {
  apts?: Appointment[];
  patientId?: string;
  onOpenProviderInfo: (p: User) => void;
}

export const NextAppointment = (props: INextAppointmentProps) => {
  const { appointments } = useSelector<IAppState, AppointmentsState>(
    (s) => s.appointments,
  );
  const [nearestApt, setNearestApt] = useState<Appointment>();
  const [appointmentList, setAppointmentList] = useState<any>([]);
  const [appointmentIndex, setAppointmentIndex] = useState(0);
  const [cancelModal, setCancelModal] = useState(false);
  const nearestAptRef = useRef<Appointment>();
  useEffect(() => {
    const socket = connectSocket();
    socket.on('joined-room', (info: any) => {
      info?.data?.participantType === 'Physician' && getAppointment();
    });
    socket.on('connect', () => {
      socket.emit('join-meeting', {
        participantType: 'Patient',
        appointmentId: nearestApt?.id,
      });
    });

    return () => {
      socket.close();
    };
  }, [nearestApt]);

  useEffect(() => {
    const data = appointments.data || [];
    const sortedAppointments = data.sort(
      (a, b) => dayjs(a.startTime).valueOf() - dayjs(b.startTime).valueOf(),
    );
    setAppointmentList(data);
    injectApt(
      sortedAppointments.find((apt) =>
        apt.description.includes('Consultation'),
      ),
    );
  }, [appointments]);

  const getAppointment = () => {
    UserService.getAppointments({
      types: ['upcoming', 'ongoing'],
      patientId: props.patientId,
    }).catch((err) => toast.error(err));
  };

  const getNearestAppointment = (aptList: any) => {
    let nearestIndex = 0;
    aptList.forEach((time: string, i: number) => {
      if (moment(time).isBefore(time[i == 0 ? i : i - 1])) {
        nearestIndex = i;
      }
    });

    return aptList[nearestIndex];
  };
  const injectApt = (apt?: Appointment) => {
    nearestAptRef.current = apt;
    apt && setNearestApt(getNearestAppointment([apt]));
  };

  const handleDeleteAppointment = async () => {
    try {
      await AppointmentService.deleteAppointment(nearestApt?.id || '', {});
      setNearestApt(undefined);
      nearestAptRef.current = undefined;
      setCancelModal(false);
    } catch (err) {
      toast.error(
        'Failed to cancel appointment. Try again, if this continues please contact us.',
      );
    }
  };

  const isMoreThan24Hours =
    new Date(nearestApt?.startTime || '').getTime() - new Date().getTime() >
    24 * 60 * 60 * 1000;

  const renderApt = (apt: Appointment) => {
    return (
      <>
        <div className="item-data">
          <div className="item green-bg">
            <div className="photo">
              <ProfileIcon user={apt.provider} />
            </div>
            <div className="right-txt">
              <div className="name-txt">{getName(apt.provider)}</div>
              <div className="title-txt">
                {(apt.provider.providerInfo?.qualifications || []).join(', ')}
              </div>
            </div>
            <div
              className="btn icons icon-info"
              onClick={() => props.onOpenProviderInfo(apt.provider)}></div>
          </div>
          <div className="item blue-bg">
            <div className="left-white-date">
              <div className="month-txt">
                {moment(apt.startTime).format(FORMAT_MONTH_SHORT)}
              </div>
              <div className="date-txt">
                {moment(apt.startTime).format('DD')}
              </div>
            </div>

            <div className="right-txt">
              {`${moment(apt.startTime).format('dddd')}, 
                  ${moment(apt.startTime).format(FORMAT_MONTH_SHORT)} 
                  ${moment(apt.startTime).format('Do')}`}
              <div className="time-txt">{`${moment(apt.startTime).format(
                FORMAT_HOUR_FULL,
              )}-${moment(apt.endTime).format(FORMAT_HOUR_FULL)}`}</div>
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="btn-edit">
            <NavLink
              to={`/editScheduleAppointmentPage/${apt.id}?aptCount=${
                appointments.data?.length || 0
              }&provider=${apt.providerId}`}
              className="btn-blue btn">
              Edit
            </NavLink>
          </div>
          {isMoreThan24Hours && (
            <div className="btn-delete ">
              <button
                className="btn btn-blue-border"
                onClick={() => setCancelModal(true)}>
                Cancel
              </button>
            </div>
          )}
          {cancelModal && (
            <CancelModal
              onDelete={handleDeleteAppointment}
              onClose={() => setCancelModal(false)}
            />
          )}
        </div>
      </>
    );
  };
  if (nearestApt && checkIsCanMeeting(nearestApt)) {
    return <GetReady apt={nearestApt} />;
  }

  const firstApt = appointmentList[appointmentIndex];
  return (
    <div className="next-appointment h-478">
      <div className="title">Next Appointment</div>
      {appointments.loading && <div className="font-b">Loading...</div>}
      {appointments.err && (
        <div className="font-b">{getErrorString(appointments.err)}</div>
      )}
      {!appointmentList.length && (
        <div className="no-apt-container">
          <img
            src="/assets/icon-calendar.svg"
            alt="icon"
            className="icon-cal"
          />
          <div className="msg">No Appointments Scheduled</div>
          <NavLink
            to={`/scheduleAppointmentPage?aptCount=${
              (appointments.data?.length || 0) + 1
            }`}
            className="btn btn-blue"
            style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            Schedule Appointment
          </NavLink>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {firstApt && <div className="font-b">{firstApt.description}</div>}
        {appointmentList.length > 1 && (
          <div>
            <ArrowButton
              disabled={appointmentIndex === 0}
              prev={() => setAppointmentIndex(appointmentIndex - 1)}
            />
            <ArrowButton
              disabled={appointmentList.length - 1 === appointmentIndex}
              next={() => setAppointmentIndex(appointmentIndex + 1)}
            />
          </div>
        )}
      </div>
      {firstApt && renderApt(firstApt)}
    </div>
  );
};

export default NextAppointment;
