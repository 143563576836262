import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import PropTypes from 'prop-types';

const Select = (props) => {
  const [selecting, setSelecting] = useState(false);
  const { label, name, options, handlers, expanding, showErrorMsg, ...rest } =
    props;
  let baseProps = {};

  if (handlers) {
    const { handleBlur, handleChange } = handlers;
    baseProps = {
      style: { height: selecting ? '100px' : '35px' },
      size: selecting ? 4 : '',
      onFocus: (e) => {
        const value = e.target.value;
        const el = value && document.querySelector(`option[value='${value}']`);
        setSelecting(true);
        el && el.scrollIntoView();
      },
      onBlur: (e) => {
        setSelecting(false);
        document.getElementById(name).blur();
        handleBlur && handleBlur(e);
      },
      onChange: (e) => {
        setSelecting(false);
        document.getElementById(name).blur();
        handleChange(e);
      },
    };
  }

  return (
    <div className="formik-control">
      <label htmlFor={name}>{label}</label>
      <Field as="select" id={name} name={name} {...baseProps} {...rest}>
        {options.map((option) => {
          return (
            <option
              disabled={!!option.disabled}
              key={option.value}
              value={option.value}>
              {option.key}
            </option>
          );
        })}
      </Field>
      {!!showErrorMsg !== false && (
        <ErrorMessage component={TextError} name={name} />
      )}
    </div>
  );
};

/** Can take all other select element attributes */
Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  showErrorMsg: PropTypes.bool,
  expanding: PropTypes.bool,
  options: PropTypes.array,
  handlers: PropTypes.shape({
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func
  }),
};

export default Select;
