import { setNestedObjectValues } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export const scrollToError = (scrollToTop = true) => {
  setTimeout(() => {
    const firstErrorEl = document.querySelector('.error-border');
    if (firstErrorEl) {
      scrollToTop && window.scrollTo(0, firstErrorEl.offsetTop);
      toast.error("One or more values are incorrect or missing. Please update before continuing.")
    }
  }, 100);
}

export const borderClass = (field, formik, checkTouched = true) => {
  const error = get(formik.errors, field);
  const touched = get(formik.touched, field);

  if (!checkTouched) {
    return error ? 'error-border' : !error ? 'success-border' : '';
  }
  return error && touched
    ? 'error-border'
    : !error && touched
    ? 'success-border'
    : '';
};

export const getPrevState = (setFormikState) => {
  setFormikState((state) => {
    const newState = {
      ...state,
      ...state.prevState,
      prevState: {},
    };
    return newState;
  });
};

export const setPrevState = (setFormikState) => {
  setFormikState((state) => {
    const newState = {
      ...state,
      prevState: {
        values: { ...state.values },
        errors: { ...state.errors },
        touched: { ...state.touched },
      },
    };
    return newState;
  });
};

export const manualFormValidation = async (formik, cb) => {
  const validationErrors = await formik.validateForm();
  if (Object.keys(validationErrors).length > 0) {
    await formik.setTouched(setNestedObjectValues(validationErrors, true));
  } else {
    formik.setTouched(setNestedObjectValues(formik.values, true));
    cb && cb();
  }
};

export const textAreaAutoGrow = (e, setFieldValue) => {
  const { id, value } = e.target;
  const el = document.getElementById(id);
  if (el) {
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.height = el.scrollHeight + 6 + 'px';
  }

  setFieldValue(id, value);
};

export const dateMaxMinValidate = ({
  date,
  gt,
  lt,
  optional,
  customMsg = {},
}) => {
  const format = 'MM/DD/YYYY';
  const passed = moment(date);
  const gtDate = moment(gt);
  const ltDate = moment(lt);
  const invalid = 'Invalid date';

  if (optional && !date) return { valid: true };
  if (
    passed.format(format) === invalid ||
    (gt && gtDate.format(format) === invalid) ||
    (lt && ltDate.format(format) === invalid)
  ) {
    return new Error('Date is invalid');
  }

  let valid = false;
  const isAfter = passed.isAfter(gtDate);
  const isBefore = passed.isBefore(ltDate);
  if (!moment(date, format).isValid()) {
    return { valid, message: 'Date is invalid' };
  } else if (gt && lt) {
    valid = isAfter && isBefore;
    return {
      valid,
      message:
        customMsg.both ||
        `Must be between ${gtDate.format(format)} and ${ltDate.format(format)}`,
    };
  } else if (gt) {
    valid = isAfter;
    return {
      valid,
      message: customMsg.gt || `Cannot be before ${gtDate.format(format)}`,
    };
  } else if (lt) {
    valid = isBefore;
    return {
      valid,
      message: customMsg.lt || `Cannot be after ${ltDate.format(format)}`,
    };
  }
};

// YUP VALIDATION
export const yupRegex = {
  letters: /^[a-zA-Z\s,]+$/,
  date: /^(\d\d)(\/)((\d\d))(\/)\d{4}$/,
  phone: /^(\(\d{3}\)|\d{3})[- ]?(\d{3})[- ]?(\d{4})$/,
  zipcode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  card: /(\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4})|(?:\d{4}[ ]\d{6}[ ]\d{5})$/,
  dateMMYY: /^(\d\d)(\/)((\d\d))$/,
  cvv: /^(\d{3,4})$/,
  numberDash: /^[\d,-\s]+$/,
};

export const yupDateValidation = (options, cb) => {
  return Yup.string()
    .matches(yupRegex.date, (cb) => {
      cb.match && cb.match();
    })
    .required('Cannot be empty')
    .test({
      name: 'Date greater than',
      test: function (val) {
        const { valid, message } = dateMaxMinValidate({
          date: val,
          ...options,
        });
        cb.test && cb.test(valid);
        return valid
          ? valid
          : this.createError({ message, path: options.path });
      },
    });
};
