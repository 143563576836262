import 'react-credit-cards/es/styles-compiled.css';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import './styles.scss';
import AuthService from '../../services/authService';
import OuraService, { OuraConfig } from '../../services/ouraService';
import { OuraHome, OuraConnected, OuraDisconnected, OuraSubmitted, OuraLoading } from '../OuraSetup';
import { useQuery } from '../../services/utils';
import _ from 'lodash';

const OuraInfo = (props) => {
  const { user } = AuthService.getAuth();
  const queryParams = useQuery();
  const history = useHistory();
  const [view, setView] = useState('loading'); // { home, connected, disconnected, submitted, loading }
  const [succeeded, setSucceeded] = useState(false);

  useEffect(() => {
    if (user.ouraConnected) { // Already Connected
      setView('connected');
  
    } else if (queryParams.get('code')) { // Successful Connection
      const scope = queryParams.get('scope').split(' ');
      if (_.xor(scope, OuraConfig.DEFAULT_SCOPE).length) { // insufficient access granted
        toast.error("Looks like you didn't provide access to all requested Oura data.");
        setSucceeded(false);
        setView('submitted');
      } else {
        OuraService.setUpUser(queryParams.get('code'))
          .then(() => {
            AuthService.updateUserInfo({ ouraConnected: true })
            setSucceeded(true);
            setView('submitted');
          })
          .catch((e) => toast.error(e));
        }
      
        
    } else if (queryParams.get('error')) { // Unsuccessful Connection
      setSucceeded(false);
      setView('submitted');

    } else if (queryParams.get('disconnected')) { // Successful Connection
      setView('disconnected');
  
    } else { // Default
      setView('home');
  
    }

    if (queryParams.size) {
      history.push('/profile')
    }
  }, [])


  return (
    <div className="onboarding-form mt-3 payment-info">
      {view === "home" && <OuraHome />}
      {view === "connected" && <OuraConnected />}
      {view === "disconnected" && <OuraDisconnected />}
      {view === "submitted" && <OuraSubmitted succeeded={succeeded} />}
      {view === "loading" && <OuraLoading />}
    </div>
  );
};

export default OuraInfo;
