import React from 'react';
import './styles.scss';

export interface ICheckboxProps {
  checked: boolean;
  label: any;
  id: string;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FunctionComponent<ICheckboxProps> = (props) => {
  const { checked, label, id } = props;

  return (
    <a href={label.link} className='todo-click'>
    <div className={`checkbox-item-wrap ${label !== '' ? 'have-txt' : ''}`}>
      <input type="checkbox"
        checked={checked}
        id={id}
        onChange={(evt) => props.onChange(evt.target.checked)}/>

    <div dangerouslySetInnerHTML={{ __html: label.title}}></div>
    </div>
    </a>
  );
};

export default Checkbox;
