import React, {useEffect, useState} from 'react';
import './styles.scss';
import {User} from "../../../services/dto/Security";
import SleepSpecialistInfo from '../SleepSpecialistInfo';
import { Card, Grid } from '@mui/material';
import TopCardHeader from '../FormScheduleAppointmentSelectDate/TopCardHeader';

export interface ISelectSleepSpecialistProps {
  providers: User[];
  onClickProvider?: (p: User) => void;
  provider?: User;
  onBack: () => void;
  onSelectProvider: (p: User) => void;
}

export const SelectSleepSpecialist: React.FunctionComponent<ISelectSleepSpecialistProps> = (props) => {
  const { providers, provider } = props;
  const [selected, setSelected] = useState<User>();
  const [showProviderDetails, setShowProviderDetails] = useState<User>();

  useEffect(() => {
    setSelected(provider);
  }, [provider]);

  const checkVerticalWindow = () =>
    window.innerWidth < 1200 ||
    window.screen.orientation.type === 'portrait-primary';
  const [displayVertical, setDisplayVertical] =
    useState<boolean>(checkVerticalWindow);
  window.addEventListener('resize', () =>
    setDisplayVertical(checkVerticalWindow),
  );
  useEffect(() => {
    setDisplayVertical(window.innerWidth < window.innerHeight);
  }, [window.innerWidth, window.innerHeight]);

  return (
    <>
      <Grid container className="select-sleep-specialist center">
        <Grid item xs={12}>
          <div className="title">
            Select <span className="mobile-hide">Sleep </span>Specialist
          </div>
        </Grid>
        <Grid item xs={12} className="center">
          <Grid container spacing={1} className="providers-container">
            {providers.map((provider) => 
              <Grid item xs={12 / (displayVertical ? 1 : 2)} onClick={() => setSelected(provider)}>
                <Card className={`provider-card ${selected === provider ? "selected": ""}`}>
                  <TopCardHeader provider={provider} onInfo={() => setShowProviderDetails(provider)}/>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className="bottom-btns center">
          <button className="btn btn-blue-border" onClick={props.onBack}>
            Cancel
          </button>
          <button
            className={`btn btn-blue ${selected ? '' : 'disabled'}`}
            onClick={() => selected && props.onSelectProvider(selected)}>
            Confirm
          </button>
        </Grid>
      </Grid>
      
      {showProviderDetails && <SleepSpecialistInfo
        provider={showProviderDetails}
        hideScheduleBtn={true}
        onClose={() => setShowProviderDetails(undefined)}
        onSelectProvider={(p: User) => null}
      />}
    </>
  );
};

export default SelectSleepSpecialist;
