import 'react-credit-cards/es/styles-compiled.css';
import React, { useState } from 'react';
import { Formik, Form, setNestedObjectValues } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../Formik/FormikControl';
import { toast } from 'react-toastify';
import { setPrevState, getPrevState, yupRegex } from './utils';
import './styles.scss';
import AuthService from '../../services/authService';
import Card from 'react-credit-cards';
import { Api } from '../../services/api';
import { useDispatch } from 'react-redux';
import { updateAuthUser } from '../../store/actions/auth';
import PaymentForm from '../PaymentForm';
import PaymentInfoModal from '../RegistrationProgressComponents/Modals/PaymentInfoModal';
import Modal from '../Modal'

const PaymentInfo = (props) => {
  const { user } = AuthService.getAuth();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    addCard: !user?.creditCards?.length,
    hasCreditCard: !!user?.creditCards?.length,
    edit: !user?.creditCards?.length,
    viewIndex: 0,
    showStartDateErr: false,
    showEndDateErr: false,
    careStartDate: '',
    showPaymentInfoModal: false,
  });

  const creditCards = user?.creditCards || [];

  const setGenericState = (values) =>
    setState((state) => ({ ...state, ...values }));

  const baseValues = {
    number: '',
    expired: '',
    name: '',
    ccv: '',
  };

  const required = Yup.string().required();
  const validationSchema = Yup.object({
    creditCards: Yup.array().of(
      Yup.object().shape({
        number: required.matches(yupRegex.card),
        name: required.required(),
        expired: required.matches(yupRegex.dateMMYY),
        ccv: required.matches(yupRegex.cvv),
      }),
    ),
  });

  const onAddCard = async (formik) => {
    setPrevState(formik.setFormikState);
    await formik.setFormikState((state) => {
      state.values.creditCards = [baseValues];
      return state;
    });

    await formik.setTouched(setNestedObjectValues(formik.values, false));

    setGenericState({
      edit: true,
      addCard: true,
      viewIndex: 0,
    });
  };

  const onCancel = (setFormikState) => {
    getPrevState(setFormikState);
    setGenericState({
      edit: false,
      addCard: false,
    });
  };

  const onDeleteCard = async (creditCards, i) => {
    const pmId = creditCards?.[i]?.paymentMethod;

    if (pmId) {
      try {
        creditCards.splice(i, 1);
        let newCards = creditCards;
        await Api.delete('/payments/delete', { params: { pmId } });
        setGenericState({ creditCards: newCards });
        AuthService.updateUserInfo({ creditCards: newCards });
        dispatch(updateAuthUser({ creditCards: newCards }));
        toast.success('Card removed successfully.');
      } catch (error) {
        return toast.error(
          error?.message || 'Unable to remove card. Please try again.',
        );
      }
    }

    setGenericState({
      edit: false,
      addCard: false,
    });
  };

  const onSubmit = async () => {
    if (props.onContinue) {
      props.onContinue();
    }
    setGenericState({
      edit: false,
      addCard: false,
    });
  };

  const issuerTypes = {
    'American Express': 'amex',
    amex: 'amex',
    Visa: 'visa',
    Master: 'mastercard',
    mastercard: 'mastercard',
    Discover: 'discover',
  };

  const handleCloseModal = (skipped) => {
    setGenericState({ showPaymentInfoModal: false });
    if (skipped && props.onContinue) props.onContinue()
  }

  return (
    <div className="onboarding-form mt-3 payment-info">
      {!props.profile && <div className="header-info">
        <div className="description">
          <h3>Just one last thing!</h3>
          <p>We need a form of payment to finalize your appointment booking. You will NOT be charged at this time.</p>
          <br/>
          <a
            className="link"
            style={{ cursor: 'pointer' }}
            onClick={() => setGenericState({ showPaymentInfoModal: true })}
          >
            Why do we need this?
          </a>
        </div>
        <Modal open={state.showPaymentInfoModal} onClose={() => handleCloseModal(false)}
          buttonActions={[
            {
              action: () => handleCloseModal(false),
              style: 'confirm-small',
              text: 'Close',
            },
            {
              action: () => handleCloseModal(true),
              style: 'cancel-small',
              text: window.screen.orientation.type === 'portrait-primary' ? "Skip" : "Skip For Now",
            },
          ]}>
          <p style={{textAlign: "center", fontSize: "18px"}}>
            In addition to any co-pay, co-insurance, or deductible, we have a no-show policy to protect the clinician's time. Putting this information in now will ensure we can keep your appointment time.
            <br/><br/>
            If you decide to skip this now, you will get a reminder to enter your payment information later.  If we do not get this information within 72 hours, your appointment may be cancelled, although you can always reschedule.
            <br/><br/>
            Still have questions? Please call us at 1-877-664-6669.
          </p>
        </Modal>
      </div>}
      {state.addCard && (
        <>
          <div className="form-container payment-form-container">
            <PaymentForm onSubmit={onSubmit} noBottomText />
          </div>
        </>
      )}
      {!state.edit && !!creditCards.length && (
        <div className="form-container credit-card-info">
          {(creditCards || []).map((card, i) => (
            <div key={card.type + i} className="credit-card">
              <div className="edit">
                <a href="#" onClick={() => onDeleteCard(creditCards, i)}>
                  <img src="/assets/delete-icon.png" alt="" />
                </a>
              </div>
              <Card
                number={
                  issuerTypes?.[card?.type] === 'amex'
                    ? card?.number?.substring(1, 16)
                    : card.number
                }
                name={card?.name || ''}
                expiry={card?.expired}
                cvc={'***'}
                issuer={issuerTypes?.[card?.type]}
                preview={true}
              />
            </div>
          ))}
        </div>
      )}
      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={(values, props) => {
          onSubmit(values, props);
        }}>
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              {!state.edit && (
                <div className="">
                  <FormikControl
                    control="custom-btn"
                    onClick={(v) => onAddCard(formik)}
                    label={
                      !creditCards?.length ? 'No saved credit cards' : null
                    }
                    options={['Add Card']}
                    action={true}
                  />
                </div>
              )}
              {(!props.profile) && !state.edit && !!creditCards.length && (
                <button className='submit-btn' style={{marginTop: "10px"}}>Continue</button>
              )}
              {(!props.profile) && state.edit && (
                <div className="mt-2 mb-2">
                  <a
                    href="#"
                    onClick={() => setGenericState({ showPaymentInfoModal: true })}
                    className="cancel">
                    Skip For Now
                  </a>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PaymentInfo;
