import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { PaperWorkPdfGenerator }  from './PaperWorkPdfGenerator';
import * as Yup from 'yup';
import FormikControl from '../../components/Formik/FormikControl';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  borderClass,
  dateMaxMinValidate,
  getPrevState,
  manualFormValidation,
  scrollToError,
  setPrevState,
  yupDateValidation,
  yupRegex,
} from './utils';
import './styles.scss';
import AuthService from '../../services/authService';
import { get } from 'lodash';

const ProviderHistory = (props) => {
  const {medicalId, apptId, providerHistory, medicalHistory} = props;
  const history = useHistory();
  const providers = providerHistory?.providers;
  const [state, setState] = useState({
    addProvider: false,
    hasProvider: !!providers?.length,
    edit: false,
    viewIndex: 0,
    showReferralForm: false,
    isReferred: !!providerHistory?.referringProvider || null,
    showStartDateErr: false,
    showEndDateErr: false,
    careStartDate: '',
  });

  const setGenericState = (values) =>
    setState((state) => ({ ...state, ...values }));

  const { user } = AuthService.getAuth();

  const baseValues = {
    providerName: '',
    providerCredentials: '',
    practicingCity: '',
    careStartDate: '',
    careEndDate: '',
  };

  const initialValues = {
    referringProvider: providerHistory?.referringProvider || '',
    providers: providers?.length
      ? providers
      : state.hasProvider
      ? [baseValues]
      : [],
  };

  const validationSchema = Yup.object({
    providers: Yup.array().of(
      Yup.object().shape({
        providerName: Yup.string().required(),
        providerCredentials: Yup.string().required(),
        practicingCity: Yup.string().matches(yupRegex.letters).required(),
        careStartDate: yupDateValidation(
          {
            lt: new Date(),
            path: `providers.${state.viewIndex}.careStartDate`,
            required: true,
          },
          {
            match: (valid) => setGenericState({ showStartDateErr: false }),
            test: (valid) => setGenericState({ showStartDateErr: !valid }),
          },
        ),
        careEndDate: Yup.string()
          .matches(yupRegex.date, (cb) => {
            setGenericState({ showEndDateErr: false });
          })
          .test(function (val) {
            const info = dateMaxMinValidate({
              date: val,
              gt: state.careStartDate,
              lt: new Date(),
              optional: true,
              customMsg: { gt: 'Cannot be before care start date' },
            });

            if (!info) return true;
            const { valid, message } = info;
            setGenericState({ showEndDateErr: !valid });
            return valid
              ? valid
              : this.createError({
                  message,
                  path: `providers.${state.viewIndex}.careEndDate`,
                });
          }),
      }),
    ),
  });

  const onEdit = (formik, i) => {
    setPrevState(formik.setFormikState);
    manualFormValidation(formik, () =>
      setGenericState({ edit: true, viewIndex: i }),
    );
  };

  const onAddProvider = (providers, setFormikState) => {
    const viewIndex = providers?.length ? providers.length : 0;

    setGenericState({
      edit: true,
      hasProvider: true,
      addProvider: true,
      viewIndex,
    });

    setFormikState((state) => {
      state.values.providers.push(baseValues);
      return state;
    });
  };

  const clearEmptyProviderData = (formik, fieldValue) => {
    const provider = get(formik, `values.${fieldValue}`, [])
    const values = Object.values(provider)
    const falseyValueLength = values.filter(v => !v)
    if(falseyValueLength.length === values.length) {
      onDeleteProvider(
        formik.setFormikState,
        state.viewIndex,
      )
    }
  }

  const onDeleteProvider = (setFormikState, i) => {
    let hasProvider = false;
    setFormikState((state) => {
      state.values.providers.splice(i, 1);
      state?.touched?.providers?.splice(i, 1);
      hasProvider = !!state.values?.providers?.length;
      if (!hasProvider) {
        state.values.referringProvider = '';
      }

      return state;
    });

    setGenericState({
      hasProvider,
      edit: false,
      addProvider: false,
    });
  };

  const onSubmit = async (values, setSubmitting) => {
    if (state.hasProvider && !state.showReferralForm) {
      setSubmitting(false);
      return setGenericState({ showReferralForm: true });
    }

    let followUpProgress =  'complete' ;

    const data = {
      type: 'Follow-up',
      userId: user.id,
      appointmentId: apptId,
      form: { providerHistory: values },
    };

    try {
      const { updateAppointmentProgress, updateMedicalHistory } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);
      await updateAppointmentProgress(apptId, { followUpProgress, medicalId: formDataRecord?.data?.id });
      props.updateHistory(formDataRecord?.data?.form || {});
      const formdata = {
        ...formDataRecord?.data?.form,
        ...medicalHistory,
      };
      try {
        const generator = new PaperWorkPdfGenerator(user, formdata);
        generator.draw();
        const file = new File(
          [generator.doc.output('blob')],
          `Follow-up Patient Form.pdf`,
          { type: 'application/pdf' },
        );
  
        const body = {
          category: 'OTHER',
          patientId: user.id,
          usedFor: 'patient form',
        };
        UserService.createForm(file, body)
          .then(() => {
            history.push('/patientDashboardPage');
          })
          .catch(err => toast.error(err.message));
      } catch (error) {
        // TODO: handle error for pdf
        // currently running into an issue where the data does not exist
        // e.g error: Cannot read properties of undefined (reading 'height')
        // This trycatch allows the user to continue without seeing an error
      }

    } catch (error) {
      setSubmitting(false);
      console.log(error)
      toast.error('Unable to save provider history. Please try again.');
    }
  };

  const namePrefix = `providers.${state.viewIndex}`;

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Provider History</h3>
          <p>
          Please provide information on your primary care provider and any providers that you would like to receive updates on your care
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        // validationSchema={state.hasProvider ? validationSchema : null}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          const { providers, referringProvider } = formik.values;
          const careStart = providers?.[state.viewIndex]?.careStartDate;
          const careEnd = providers?.[state.viewIndex]?.careEndDate;
          const fieldNames = {
            providerName :`${namePrefix}.providerName`,
            providerCredentials :`${namePrefix}.providerCredentials`,
            practicingCity :`${namePrefix}.practicingCity`,
            careStartDate :`${namePrefix}.careStartDate`,
            careEndDate :`${namePrefix}.careEndDate`
          }

          if (state?.showReferralForm)
            return (
              <Referral
                isReferred={state?.isReferred}
                providers={providers}
                referringProvider={referringProvider}
                setIsReferred={(v) => setGenericState({ isReferred: v })}
                setReferringProvider={(v) =>
                  formik.setFieldValue('referringProvider', v)
                }
              />
            );

          return (
            <Form onSubmit={formik.handleSubmit}>
              {!state.edit && (
                <div className="form-container">
                  {(providers || []).map((provider, i) => (
                    <div
                      key={provider.providerName + i}
                      className="list-info provider-info">
                      <div className="info">
                        <p className="title">{provider.providerName}</p>
                        <p className="providerCredentials">
                          {provider.providerCredentials}
                        </p>
                        <p className="practicingCity">
                          {provider.practicingCity}
                        </p>
                      </div>

                      <div className="dates">
                        <p>
                          {provider.careStartDate} -{' '}
                          {provider?.careEndDate || 'Present'}
                        </p>
                        <div className="edit">
                          <a href="#" onClick={() => onEdit(formik, i)}>
                            Edit
                          </a>
                          <a
                            href="#"
                            onClick={() =>
                              onDeleteProvider(formik.setFormikState, i)
                            }>
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}

                  <FormikControl
                    control="custom-btn"
                    onClick={(v) =>
                      onAddProvider(providers, formik.setFormikState)
                    }
                    label={!providers?.length ? 'No Saved Providers' : null}
                    options={['Add Provider']}
                    action={true}
                  />
                </div>
              )}

              {state.edit && (
                <div className="form-container">
                  <FormikControl
                    className={borderClass(
                      fieldNames.providerName,
                      formik,
                    )}
                    control="input"
                    type="text"
                    name={fieldNames.providerName}
                    placeholder="Provider Name"
                    showErrorMsg={false}
                  />

                  <FormikControl
                    className={borderClass(
                      fieldNames.providerCredentials,
                      formik,
                    )}
                    control="input"
                    type="text"
                    name={fieldNames.providerCredentials}
                    placeholder="Specialty"
                    showErrorMsg={false}
                  />

                  <FormikControl
                    className={borderClass(
                      fieldNames.practicingCity,
                      formik,
                    )}
                    control="input"
                    type="text"
                    name={fieldNames.practicingCity}
                    placeholder="Location"
                    showErrorMsg={false}
                  />

                  <FormikControl
                    className={borderClass(
                      fieldNames.careStartDate,
                      formik,
                    )}
                    control="input"
                    format="##/##/####"
                    formatType="date"
                    type="text"
                    name={fieldNames.careStartDate}
                    placeholder="Approx. Start of Care Date"
                    showErrorMsg={
                      !careStart ? false : state.showStartDateErr ? true : false
                    }
                    value={careStart}
                    onChange={(v) => {
                      formik.setFieldValue(
                        fieldNames.careStartDate,
                        v.formattedValue,
                      );
                      setGenericState({ careStartDate: v.formattedValue });
                    }}
                    onBlur={formik.handleBlur}
                  />

                  <FormikControl
                    className={borderClass(fieldNames.careEndDate, formik)}
                    control="input"
                    format="##/##/####"
                    formatType="date"
                    type="text"
                    name={fieldNames.careEndDate}
                    placeholder="End of Care Date (if applicable)"
                    showErrorMsg={
                      !careEnd ? false : state.showEndDateErr ? true : false
                    }
                    value={careEnd}
                    onChange={(v) => {
                      formik.validateField(fieldNames.careEndDate);
                      formik.setFieldValue(
                        fieldNames.careEndDate,
                        v.formattedValue,
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />

                  <div className="copay-info">
                    <p>
                      By adding this provider, you authorize Ognomy to send this
                      person and their organization care summaries from Ognomy.
                    </p>
                  </div>

                  <FormikControl
                    control="custom-btn"
                    onClick={(v) => {
                      clearEmptyProviderData(formik, namePrefix)
                      scrollToError(false);
                      manualFormValidation(formik, () =>
                        setGenericState({
                          edit: false,
                          addProvider: false,
                        }),
                      );
                    }}
                    options={['Done']}
                    action={true}
                  />

                  <a
                    href="#"
                    onClick={() => {
                      state.edit && getPrevState(formik.setFormikState);
                      setGenericState({
                        edit: false,
                        addProvider: false,
                      });
                      state.addProvider &&
                        onDeleteProvider(
                          formik.setFormikState,
                          state.viewIndex,
                        );
                    }}
                    className="cancel">
                    Cancel
                  </a>
                </div>
              )}

              {!state.addProvider && !state.edit && (
                <button
                  type="submit"
                  className="submit-btn"
                  disabled={formik.isSubmitting}
                  ref={props.continueRef}>
                  Continue
                </button>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const Referral = ({
  isReferred,
  providers,
  referringProvider,
  setIsReferred,
  setReferringProvider,
}) => {
  return (
    <Form>
      <div className="form-container custom-option">
        <FormikControl
          control="custom-btn"
          onClick={(v) => {
            setIsReferred(v === 'Yes');
            v === 'No' && setReferringProvider('');
          }}
          label="Were you referred to Ognomy by any of the health care providers  you have just listed?"
          options={['Yes', 'No']}
          selected={isReferred ? 'Yes' : isReferred === false ? 'No' : null}
        />
      </div>

      {isReferred && (
        <div className="form-container">
          <FormikControl
            control="custom-btn"
            onClick={(v) => setReferringProvider(v)}
            label={'Please select the provider that referred you to Ognomy'}
            style={{ width: '95%', borderRadius: '10px' }}
            options={providers.map((p) => p.providerName)}
            asList={true}
            selected={referringProvider}
          />
        </div>
      )}

      {((isReferred && referringProvider) || !isReferred) && (
        <button type="submit" className="submit-btn">
          Continue
        </button>
      )}
    </Form>
  );
};
export default ProviderHistory;
