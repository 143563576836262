import React, {useState} from 'react';
import DropdownSelect from '../../../components/FormElement/DropdownSelect';
import './styles.scss';
import {useSelector} from "react-redux";
import {IAppState} from "../../../store/reducers";
import {AuthState} from "../../../store/reducers/auth";
import {CreditCard} from "../../../services/dto/Security";

export interface IModalUseCreditCardOnFileProps {
  copayValue: number
  onClose: () => void
  onAddCard: () => void
  loading: boolean
  onPayNow: (card: CreditCard) => void
}

export const ModalUseCreditCardOnFile: React.FunctionComponent<IModalUseCreditCardOnFileProps> = (props) => {
  const {copayValue, onClose, onPayNow, onAddCard} = props;
  const [curCard, setCurCard] = useState<CreditCard>();
  // enabled Form
  const enabledForm = () => !!curCard && !props.loading
  const {authUser} = useSelector<IAppState, AuthState>(s => s.auth)
  const displayCopay = typeof copayValue === "string" ? copayValue : (copayValue || 0).toFixed(2)

  if (!authUser) {
    return <></>
  }
  return (
    <div className="modal-default modal-use-credit-card-on-file">
      <div className="modal-mains">
        <div className="modal-title">
          Use Credit Card On File
        </div>
        <DropdownSelect
          label="Select Credit Card"
          value={curCard}
          items={authUser.creditCards?.map((item, index) => ({label: item.number, value: item})) || []}
          onChange={(item: any) => setCurCard(item.value || item)}
        />
        <div className="text-btn" onClick={onAddCard}>or add new payment method</div>
        <div className="price-area">
          <div className="thin-txt">
            Your copay is
          </div>
          <div className="price-txt">${displayCopay}</div>
        </div>
        <div className="bottom-areas">
          <div className={`btn btn-blue ${enabledForm() ? '' : 'disabled'}`} onClick={() => curCard && onPayNow(curCard)}>
            Pay Now
          </div>
          <div className="btn blue-links" onClick={() => onClose()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUseCreditCardOnFile;
