import React from "react";
import { Paper, Stack } from "@mui/material";
import { Appointment } from "../../services/dto/Common";
import ProviderCard from "./WaitingRoomProviderCard";
import WaitingRoomActions from "./WaitingRoomActions";
import { User } from "../../services/dto/Security";

type WaitingRoomCardProps = {
  apt: Appointment;
  provider: User;
  loadApt: () => void;
  onEnter: () => void;
  onReschedule: () => void;
}

const WaitingRoomCard = (props: WaitingRoomCardProps) => {
  const { apt, provider, loadApt, onEnter, onReschedule } = props;
  const dim = 500;
  const cardDim = dim - dim/10;

  return (<div className="three-grid">
    <Paper
      elevation={0}
      sx={{ 
        borderRadius: `${dim/20}px`, 
        display: "flex", 
        justifyContent: "center", 
        margin: "auto", 
        alignItems: "center", 
        maxWidth: `${dim}px`, 
      }}
    >
      <Stack spacing={2} justifyContent="center" alignItems="center" sx={{margin: `5%`}}>
        <h1 style={{fontWeight: "700", textAlign: "center"}}>You're checked in!</h1>

        {/* Provider Card */}
        <p>A reminder of who you will be meeting with today:</p>
        <ProviderCard cardDim={cardDim} provider={provider} />

        {/* Action Display */}
        <WaitingRoomActions 
          apt={apt} cardDim={cardDim}
          loadApt={loadApt}
          onEnter={onEnter}
          onReschedule={onReschedule}
        />
      </Stack>
    </Paper>
  </div>)
}

export default WaitingRoomCard;