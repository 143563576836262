import React from 'react';
import Header from '../../components/Header';
import FormReviewDetails from '../../components/ProfileInfoComponents/FormReviewDetails';

import './styles.scss';

const ProfileInfoPage = () => {
  return (
    <React.Fragment>
      <div className="padding-wraper">
        <Header
          currentPage="Personal Info"
          headerType="PatientDashboard"
          title="Profile"
          />

        <div className="profile-info-page-mains">
          <div className="tab-right-content">
            <FormReviewDetails/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileInfoPage;