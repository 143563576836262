import React from 'react';
import './styles.scss';

export interface IModalPaymentCompletedProps {
  copayValue: number
  creditCardValue: string
  onClose: () => void
}

export const ModalPaymentCompleted: React.FunctionComponent<IModalPaymentCompletedProps> = (props) => {
  const {copayValue, creditCardValue, onClose} = props;
  const displayCopay = typeof copayValue === "string" ? copayValue : (copayValue || 0).toFixed(2)

  return (
    <div className="modal-default modal-payment-completed">
      <div className="modal-mains">
        <div className="big-bold">Thank you!</div>
        <div className="modal-title">
          <span className="check-done">
            <img src="/assets/check-ed.svg" alt="svg" />
          </span>
          Payment Completed
        </div>
        <div className="price-area">
          <div className="thin-txt">Your copay is</div>
          <div className="price-txt">${displayCopay}</div>
          <div className="little-txt">Billed to {creditCardValue}</div>
        </div>
        <div className="bottom-areas">
          <div className="btn btn-blue-border" style={{padding: "0 25px"}}>Request a receipt</div>
          <div className="btn blue-links" onClick={onClose}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPaymentCompleted;
