import _ from 'lodash';
import moment from 'moment';
import {DATE_FORMAT, OPEN_MEETING_BEFORE} from '../config';
import {toast} from "react-toastify";
import {useEffect, useRef} from "react";
import {Appointment} from "./dto/Common";
import {useLocation} from "react-router-dom";
import { User } from './dto/Security';

/**
 * check component is mounted
 */
export function useIsMounted(): { current: boolean } {
  const componentIsMounted = useRef(true);
  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    [],
  );
  return componentIsMounted;
}


/**
 * local storage warp
 */
export class LStorage {
  /**
   * set item
   * @param key the key
   * @param v the value
   */
  static setItem(key: string, v: any) {
    localStorage.setItem(key, JSON.stringify(v));
  }

  /**
   * get item by key
   * @param key the key
   * @param isObject is object value
   * @param defaultValue the default value
   * @returns {string|any|{}}
   */
  static getItem(key: string, isObject = false, defaultValue = {}) {
    const v = localStorage.getItem(key);
    if (isObject) {
      try {
        return JSON.parse(v || '') || defaultValue;
      } catch (e) {
        return defaultValue;
      }
    }
    return v;
  }

  /**
   * remove item
   * @param key the key
   */
  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
}

export const removeAllChars = (str: any) => {
  return str ? (str || "").replace(/\D/g, "") : ""
};

/**
 * pad 0
 * @param num the number
 * @param size the length
 */
export const pad = (num: any, size: number) => {
  let s = _.isNil(num) ? '' : `${num}`;
  while (s.length < (size || 2)) {
    s = `0${s}`;
  }
  return s;
};

/**
 * get user name
 * @param user the user name
 * @return {string|*}
 */
export const getName = (user: any) => {
  if (!user) {
    return '';
  }
  const name = _.filter(
    [user.firstName, user.lastName],
    v => (v || '').trim().length > 0,
  ).join(' ');
  if ((name || '').trim().length === 0) {
    return truncate(user.email || '', 40);
  }
  return truncate(name, 40);
};
export const truncate = (input: string, length = 40) => (input.length > length ? `${input.substring(0, length)}...` : input);

/**
 * get provider name with credentials
 * @param provider provider document (with firstName, lastName, providerInfo.qualifications)
 * @return {string|*}
 */
export const getCredentials = (provider: User) => {
  if (!provider) return "";
  return _.get(provider, "providerInfo.qualifications", []).join(", ")
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  if (!phoneNumberString || phoneNumberString === "") {
    return "No Phone";
  }
  phoneNumberString = removeAllChars(phoneNumberString);
  const p1 = phoneNumberString.substr(0, 3);
  const p2 = phoneNumberString.substr(3, 3);
  const p3 = phoneNumberString.substr(6);
  let r = "";
  if (p1) {
    r = `(${p1})`;
  }
  if (p2) {
    r += ` ${p2}`;
  }
  if (p3) {
    r += `-${p3}`;
  }
  return r;
};

/**
 * get user id
 * @param uid
 * @return {string|string}
 */
export const getUid = (uid: any) => pad(uid, 9);

/**
 * get full address
 * @param user the user
 * @param prefix the address prefix
 */
export const getFullAdd = (user: any, prefix = '') => {
  const getKey = (v: string) => {
    if (_.get(user, `${prefix}AddressGroup`) || !prefix) {
      return v;
    }
    return `${prefix}${_.startCase(v)}`;
  };
  const getV = (key: string) => _.get(user, getKey(key));
  return (
    [
      getV('address'),
      getV('city'),
      getV('state'),
      getV('zipcode') || getV('zip'),
    ]
      .filter(v => (v || '').toString().trim().length > 0)
      .join(', ') || '')
    .split(',').join(', ')
};

/**
 * get gender and age
 * @param user the user
 */
export const getGenderAndAge = (user: any) => {
  let age = null;
  if (user.dateOfBirth) {
    age = `${-moment(user.dateOfBirth, DATE_FORMAT).diff(
      moment(),
      'years',
    )} years old`;
  }

  return (
    [user.gender, age].filter(v => (v || '').trim().length > 0).join(', ')
    || ''
  );
};

/**
 * get gender and date of birth
 * @param user the user
 */
export const getGenderAndDoB = (user: any) => [user.gender, user.dateOfBirth]
  .filter(v => (v || '').trim().length > 0)
  .join(', ') || '';


/**
 * get random string
 * @param len the length
 * @param chars the chars
 * @return {string}
 */
export const randomStr = (len: number, chars?: string) => {
  const newLen = len || 32;
  const $chars = chars || 'abcdefhijkmnprstwxyz0123456789';
  const maxPos = $chars.length;
  let pwd = '';
  for (let i = 0; i < newLen; i += 1) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

export const getGreetingTime = () => {
  const currentTime = moment();
  if (!currentTime || !currentTime.isValid()) {
    return 'Hello';
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(currentTime.format('HH'));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return 'Good Afternoon';
  }
  if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return 'Good Evening';
  }
  // Between dawn and noon
  return 'Good Morning';
};

export const showApiError = (e: any) => toast.error(e.message || JSON.stringify(e))
export const showError = (e: string) => toast.error(e)
export const showSuccess = (e: string) => toast.success(e)
export const extractError = (error: any, message: string) =>
  toast.error(error.friendly ? error.message : message);
  
export const getRandomNumber = (): number => {
  const crypto = window.crypto || (window as any).msCrypto
  const array = new Uint32Array(1)
  crypto.getRandomValues(array) // Compliant for security-sensitive use cases
  return array[0] / (0xffffffff + 1)
}

/**
 * request map
 */
const ignoreReqMap: any = {};

export const ignoreReq = (reqId: string | number) => {
  _.set(ignoreReqMap, [reqId], true);
};
/**
 * is requrest ignored
 * @param reqId the request id
 */
export const isReqIgnored = (reqId: string | number) => {
  return _.get(ignoreReqMap, [reqId]);
};


/**
 * is appointment status ongoing or upcoming
 * @param a the appointment
 * @return {boolean}
 */
export const isAppointmentOnOrUp = (a:Appointment) =>
  a.status === 'ongoing' || a.status === 'upcoming';


/**
 * check appointment is can meeting or not
 * @param e the appointment
 * @return {boolean|*}
 */
export const checkIsCanMeeting = (e:Appointment) => {
  if (!e) {
    return false;
  }

  const m = moment(e.startTime).diff(moment(), 'minutes');
  return OPEN_MEETING_BEFORE >= m || e.hostJoined;
};


/**
 * get error string
 * @param err the error
 */
export const getErrorString = (err: any): string => {
  if (!err) {
    return '-'
  }
  return err.message || err
}

/**
 * use query
 */
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ordinal = (dateStr: string)=> {
  return (dateStr || '').toUpperCase().replace(/\d+/,'')
}