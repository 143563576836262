import { get, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthService from '../../services/authService';
import UserService from '../../services/userService';
import { useQuery } from '../../services/utils';
import Header from '../../components/Header';
import FormSelect from './FormSelect';
import { useDispatch } from 'react-redux';
import { updateAuthUser } from '../../store/actions/auth';
import { Api } from '../../services/api';
import CompletedForms from './CompletedForms';
import ProgressBar from '../../components/ProgressBar';

const NewPatientForms = (props) => {
  const { user } = AuthService.getAuth();
  const query = useQuery();
  const step = query.get('step') || user?.onboardingProgress;
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    showComplete: false,
    medicalHistory: null,
    fetchingHistory: true,
    fetchingLogo: false,
    partner: {},
    base64: '',
    currStep: null,
  });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  const progressSteps = [
    'provider',
    'medications',
    'medical-history',
    'social-history',
    'family-history',
    'sleep-history',
    'details',
  ];

  const getNextStep = (currStep) => get(progressSteps, progressSteps.findIndex(s => s === currStep) + 1, 'complete');

  useEffect(() => {
    getPartnerLogo();
    getMedicalHistory();
    let currStep = (user.onboardingProgress || '').split('&')[0]
    if (!progressSteps.includes(currStep)) {
      currStep = 'provider'
      history.push(`/newPatientForms?step=${currStep}`)
    } else {
      history.push(`/newPatientForms?step=${user.onboardingProgress}`)
    }
    setGenericState({ currStep })
  }, []);

  useEffect(() => {
    if (!state.medicalHistory) {
      getMedicalHistory();
    } else if (state.fetchingHistory)
      setGenericState({ fetchingHistory: false });
  }, [step]);

  const updateHistory = (v) =>
    setGenericState({
      medicalHistory: {
        ...state.medicalHistory,
        ...v,
      },
    });

  const getPartnerLogo = () => {
    if (query.get('refId')) {
      setGenericState({ fetchingLogo: true });
      Api.get(`/referralUsers?refId=${query.get('refId') || ''}`)
        .then(async (res) => {
          const logoBuffer = res.data[0]?.logoBuffer?.data;
          const base64 = btoa(
            new Uint8Array(logoBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          setState({ partner: res.data[0], base64: 'data:;base64,' + base64 });
        })
        .finally(() => setGenericState({ fetchingLogo: false }));
    }
  };

  const getMedicalHistory = () => {
    setGenericState({ fetchingHistory: true });
    UserService.getMedicalHistory(user.id)
      .then((res) => setGenericState({ medicalHistory: res?.data?.form }))
      .catch((e) => toast.error('Unable to retrieve medical history.'))
      .finally(() => setGenericState({ fetchingHistory: false }));
  };

  const onContinue = (step, data) => {
    if (step && !step.includes('&')) setGenericState({ currStep: step });
    if (!isEmpty(data)) {
      AuthService.updateUserInfo(data);
      dispatch(updateAuthUser(data));
    }
    if (props.onSave) return props.onSave();
    return history.push(`/newPatientForms?step=${step}`);
  };

  if (state.fetchingHistory || state.fetchingLogo) return <div></div>;
  return (
    <Fragment>
      <Header
        currentPage=""
        headerType="NewPatientForms"
        partnerLogo={state?.base64}
        partnerName={state?.partner?.name}
        customClass="registeration-header"
      />
      {state.currStep && <ProgressBar
        numberOfSteps={progressSteps.length}
        activeStep={progressSteps.findIndex(s => s === state.currStep)}
        complete={state.currStep === 'complete'}
      />}
      <Fragment>
        <FormSelect
          step={step}
          updateHistory={updateHistory}
          history={state.medicalHistory}
          onContinue={(user) => onContinue(getNextStep(step), user)}
        />
        {step === 'complete' && (
          <CompletedForms />
        )}
      </Fragment>
    </Fragment>
  );
};

export default NewPatientForms;