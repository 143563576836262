import React from 'react';
import './OuraSetup.scss';
import { Grid, Paper } from '@mui/material';
import { ConnectButton, LearnMoreButton, NoLongerInterested, OgnomyOuraLogo } from './helper';

interface Props {
  succeeded: boolean;
}

const OuraSubmitted = ({ succeeded }: Props) => {
  return (
    <Paper className="layer-0">
      <Paper className="layer-1">
        <Grid container spacing={4}>
          <Grid item>
            <OgnomyOuraLogo />
          </Grid>
          <Grid item xs={12}>
            {(succeeded)
              ? <p><b>Success!</b> Your Oura Ring is now connected to Ognomy. Your Ognomy Sleep Specialist will be given a summary of the past 30 days of your Oura Ring data, including sleep metrics, and will continue to receive reports throughout your Ognomy journey. You can cancel this connection at any time if you no longer wish to share your data.</p>
              : <p><b>Hmmm</b>... Seems there was a problem connecting your Oura account. Click below to try again.<br/>If you continue to have trouble, reach out to our support at +1 (877) 664-6669.</p>}
          </Grid>
          <Grid item xs={12}>
            {(succeeded)
              ? <LearnMoreButton />
              : <ConnectButton text="Try Again" />}
          </Grid>    
        </Grid>
      </Paper>
      {(succeeded) && <NoLongerInterested />}
    </Paper>
  );
};

export default OuraSubmitted;
