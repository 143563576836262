import React, { useState, useEffect } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

const ProgressBar = ({ numberOfSteps = 0, time, activeStep = 0, complete }) => {
  const [currentTime, setCurrentTime] = useState(numberOfSteps);

  useEffect(() => {
    setCurrentTime(numberOfSteps - activeStep);
  }, [activeStep]);

  const steps = Array(numberOfSteps);
  const pluralMinute = (time || currentTime) > 1;
  if (numberOfSteps === 0) return null;
  return (
    <div className="custom-progress-bar">
      <div className="progress-container">
        {Array.from(steps, (_, i) =>
          activeStep > i || complete ? 'active' : 'inactive',
        ).map((status, i) => (
          <div key={`${status}${i}`} className={`progress-step ${status}`}></div>
        ))}
      </div>
      {!complete && (
        <p className="title">
          Approximate time to complete:{' '}
          <b>
            {time || currentTime} minute{pluralMinute ? 's' : ''}
          </b>
        </p>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  numberOfSteps: PropTypes.number,
  time: PropTypes.number,
  activeStep: PropTypes.number,
};
export default ProgressBar;
