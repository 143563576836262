import {Appointment, MyProgressItem, PatientTodoRsp, StateFullData} from "../../services/dto/Common";
import {User} from "../../services/dto/Security";
import {
  API_LOAD_APPOINTMENTS,
  API_LOAD_MY_PROGRESS,
  API_LOAD_PROVIDERS,
  API_LOAD_TODO,
  AppointmentTypes
} from "../actions/appointments";

export interface AppointmentsState {
  providers: StateFullData<User[]>
  appointments: StateFullData<Appointment[]>
  userProgress: StateFullData<MyProgressItem[]>
  myTodo: StateFullData<PatientTodoRsp>
}

const defaultState: AppointmentsState = {
  providers: {},
  appointments: {},
  userProgress: {},
  myTodo: {}
}


export const appointmentReducer = (
  state = defaultState,
  action: AppointmentTypes
): AppointmentsState => {
  switch (action.type) {
    case API_LOAD_PROVIDERS:
      return {
        ...state,
        providers: action.payload
      }
    case API_LOAD_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload
      }
    case API_LOAD_MY_PROGRESS:
      return {...state, userProgress: action.payload}
    case API_LOAD_TODO:
      return {...state, myTodo: action.payload}
    default:
      return state
  }
}
