import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../services/authService';
import './styles.scss';

const UploadInsuranceModal = (props) => {
  const [height, setHeight] = useState(1200);
  const body = document.body;
  const html = document.documentElement;
  const history = useHistory();
  useEffect(() => {
    setHeight(Math.max(body.scrollHeight, html.scrollHeight));
  }, [html.scrollHeight, body.scrollHeight]);

  const onSubmit = () => {
    if (window.innerWidth <= 767) {
        history.push(`/upload-insurance`);
    } else {
        history.push('profile?step=insurance')
    }
  }
    

  return (
    <div
      className="base-modal continue-onboarding"
      style={{ height: height + 'px' }}>
      <div className="base-modal-content">
        <h1 className="terms-title">Add Insurance</h1>
        <p className="terms-header">
          Before your appointment, we need you to have a valid insurance card on
          file.
        </p>

        <div className="submit-btn">
          <button className="btn btn-blue" onClick={() => onSubmit()} style={{textWrap: "nowrap"}}>
            Continue to insurance information
          </button>
        </div>
        <p className="open-upload">
          <button
            type="button"
            onClick={props.onClose}
            className="text-btn  --link"
            style={{ margin: '50px auto 0' }}>
            Finish later
          </button>
        </p>
      </div>
    </div>
  );
};

export default UploadInsuranceModal;
