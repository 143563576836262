import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { getWaitingRoomData } from '../../store/actions/dataAction';
import Header from '../../components/Header';
import BottomLiveBar from '../../components/BottomLiveBar';
import { TodoItem } from '../../components/WaitingRoomComponents/LeftStepList';
import SessionSummary from '../../components/WaitingRoomComponents/SessionSummary';
import './styles.scss';
import { getErrorString, useIsMounted, useQuery } from '../../services/utils';
import UserService from '../../services/userService';
import { Appointment } from '../../services/dto/Common';
import { CreditCard, User } from '../../services/dto/Security';
import { AppointmentCompleted } from './AppointmentCompleted';
import Whereby from '../../components/WaitingRoomComponents/Whereby';
import { connectSocket } from '../../socket.io';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import TelemedicineSignatureModal from '../../components/RegistrationProgressComponents/Modals/TelemedicineSignatureModal';
import AuthService from '../../services/authService';
import WaitingRoomCard from './WaitingRoomCard';
import AppointmentService from '../../services/appointmentService';
import { Timeout } from 'react-number-format/types/types';

const WAITING_TITLE_MAP = [
  'Waiting Room',
  'Consultation Completed',
  'Session Summary',
];

export const PULL_APPOINTMENT_INTERVAL = 10 * 1000;

const WaitingRoomPage = () => {
  const [showFullScreen, setShowFullScreen] = useState<boolean>(false); // false
  const [stepIndex, setStepIndex] = useState<number>(0); // 0
  const dispatch = useDispatch();
  const query = useQuery();
  const aptId = query.get('apt');
  const isMounted = useIsMounted();
  const [showAuthorizationModal, setShowAuthorizationModal] = useState<boolean>(true);
  const [apt, setApt] = useState<Appointment>();
  const [provider, setProvider] = useState<User>();
  const aptRef = useRef<Appointment>();
  const paidCard = useRef<CreditCard>();
  const steps = useRef<TodoItem[]>([]);
  const workerRef = useRef<any>();
  const history = useHistory();
  const { user } = AuthService.getAuth();

  let interval: Timeout;
  let timeout1: Timeout;
  let timeout2: Timeout;

  useEffect(() => {
    dispatch(getWaitingRoomData());
  }, []);

  useEffect(() => {
    loadApt().then((newApt) => setProvider(newApt?.provider));
  }, [aptId]);

  const socketEvents = (socket: any) => {
    socket.emit('join-meeting', {
      participantType: 'Patient',
      appointmentId: aptId,
    });
    socket.on('joined-room', (data: any) => data);
    socket.on('copay-received', () => loadApt());
    socket.on('host-ended-meeting', () =>
      setStepIndex((i) => {
        // Run only if steps not complete
        // Web emits end-meeting multiple time causing the fn to run
        i !== 2 && leaveMeeting();
        return i;
      }),
    );
  };

  useEffect(() => {
    const socket = connectSocket();
    socket.on('connect', () => {
      socketEvents(socket);
      clearInterval(interval);
    });

    socket.on('disconnect', () => {
      let count = 0;
      interval = setInterval(() => {
        count++;
        if (count >= 5) return clearInterval(interval);
        else if (window.navigator.onLine) {
          count = 5;
          loadApt();
        }
      }, 5000);
    });

    window.addEventListener('offline', networkDown);
    window.addEventListener('online', () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      loadApt();
    });
    return () => {
      socket.close();
      window.removeEventListener('offline', networkDown);
      window.removeEventListener('online', () => {});
      clearInterval(interval);
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(workerRef.current);
    };
  }, []);

  const networkDown = () => {
    timeout1 = setTimeout(() => {
      toast.error("Difficulties connecting to server. Please don't refresh we are trying to reconnect!");
    }, 45000);

    timeout2 = setTimeout(() => {
      toast.error(
        'The network has timed out. Please call our office at 1-877-664-6669 to reschedule.',
        { autoClose: 30000 },
      );
    }, 120000);
  };

  const fetchAgain = () => {
    workerRef.current = setTimeout(() => {
      loadApt();
    }, PULL_APPOINTMENT_INTERVAL);
  };

  const injectApt = (newApt: Appointment) => {
    setApt(newApt);

    
    if (aptId && !newApt.participantCheckIn) {
      const body = {
        providerId: newApt.providerId,
        patientId: newApt.patientId,
        participantCheckIn: true,
      };
      UserService.updateAppointment(aptId, body)
        .then(() => console.log('Patient checked in!'))
        .catch((err) => console.log(getErrorString(err)));
    }
    aptRef.current = newApt;
  };

  const checkHostEnded = (apt: any) => {
    const time = apt?.hostEndTime;
    const compareTime = moment(time).add(8, 'minutes');
    if (time && moment().isAfter(compareTime.format())) {
      history.push('/patientDashboardPage');
    } else if (time) {
      leaveMeeting();
    } else if (_.includes(['cancelled', 'past'], apt.status)) {
      history.push('/patientDashboardPage');
    }

  };

  const loadApt = async () => {
    if (stepIndex !== 2 && aptId) {
      return await UserService.getAppointment(aptId)
        .then((rsp) => {
          if (rsp.data.patientId !== user.id) {
            AuthService.cleanAuth();
            history.push(`/signInPage?aptId=${aptId}`);
            return;
          }
          checkHostEnded(rsp.data);
          if (!isMounted.current) return;
          injectApt(rsp.data);
          if (!rsp.data.meetingId || !rsp.data.patientPaid) fetchAgain();
          return rsp.data;
        })
        .catch((e) => {
          history.push('/patientDashboardPage');
        })
    }
  };

  const onReschedule = async () => {
    if (!apt) return;

    // cancel apt immediately, then go to reschedule page
    await AppointmentService.deleteAppointment(apt.id, {
      reason: 'Provider Running Late',
      cancelDescription: 'Patient Rescheduled from Waiting Room',
      override: true,
    }).then(() => {
      history.push('/scheduleAppointmentPage?aptCount=1')
    }).catch((e) => toast.error(e.message));
  }

  const leaveMeeting = () => {
    setShowFullScreen(false);
    setStepIndex(1);
  };

  return (
    <React.Fragment>
      <div id="appointment-container" className="padding-wraper">
        {showFullScreen && apt && <Whereby appointment={apt} />}

        <Header currentPage="Dashboard" headerType="PatientDashboard" />

        <div className="waiting-room-wrap">
          <div className={`top-title ${stepIndex === 1 ? 'green-txt' : ''}`}>
            {WAITING_TITLE_MAP[stepIndex]}
          </div>

          {!user.isPlatformAuthorized && showAuthorizationModal && (
            <TelemedicineSignatureModal toggleModal={setShowAuthorizationModal} />
          )}

          {/* Before Meeting */}
          {stepIndex === 0 && apt && provider && <WaitingRoomCard 
            apt={apt}
            provider={provider}
            loadApt={loadApt}
            onEnter={() => setShowFullScreen(true)}
            onReschedule={onReschedule}
          />}

          {/* After Meeting */}
          {stepIndex === 1 && apt && <AppointmentCompleted
            apt={apt}
            onFinished={(s) => {
              steps.current = s;
              setStepIndex(2);
            }}
          />}

          {/* Final Summary */}
          {stepIndex === 2 && apt && <SessionSummary
            copayValue={apt?.copayAmount || 0}
            card={paidCard.current}
            steps={steps.current}
          />}

          <BottomLiveBar />
        </div>
      </div>
    </React.Fragment>
  );
};

export default WaitingRoomPage;
