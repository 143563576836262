import React, {useState, ChangeEvent} from 'react';
import { PasswordRule } from '../../../config';
import './styles.scss';
import FormValidationSvc from "../../../services/formValidationSvc";

export interface IInputBoxProps {
  type?: string;
  label: string;
  value: string;
  disabled?: boolean;
  isPasswordType?: boolean;
  isShowPasswordInfo?: boolean;
  isShowError?: boolean;
  isShowInfoIcon?: boolean;
  classNameContainer?: string;
  placeholder?: string;
  pattern?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  maxLength?: number;
  boxColor?: string;
}

export const InputBox: React.FunctionComponent<IInputBoxProps> = (props) => {
  const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);
  const [showPasswordLabel, setShowPasswordLabel] = useState<boolean>(false);
  
  const { label, value, disabled, isPasswordType, isShowPasswordInfo, isShowError, isShowInfoIcon, classNameContainer, placeholder, pattern, onClick } = props;
  
  // enabled Form
  const validatePasswordRule = (ruleIndex: number) => FormValidationSvc.validatePasswordRuleByIndex(ruleIndex, value)
  
  return (   
    <div className={`input-gray-block ${props.boxColor ? 'input-box-' + props.boxColor : ''}
                     ${(showInfoPopup && isShowPasswordInfo) ? 'open' : ''}
                     ${isPasswordType ? 'password-inputs' : ''}
                     ${isShowError ? 'error' : ''}
                     ${classNameContainer ? classNameContainer : ''}`}>
      <div className="label-txt">
        {label}
      </div>
      <div className="inputs">
        <input 
          type={isPasswordType
              ? showPasswordLabel
                ? 'text'
                : 'password'
              : 'text'
          }
          name={props.type === "email" ? "username" : isPasswordType ? "password" : ""} 
          autoComplete="off"
          placeholder={placeholder || ''}
          value={value}
          readOnly={disabled}
          pattern={pattern} maxLength={props.maxLength}
          onChange={(
            evt: ChangeEvent<HTMLInputElement>,
          ) => {
            props.onChange(evt)
          }}
          onFocus={() => {
            setShowInfoPopup(true)
          }}
          onBlur={() => {
            setShowInfoPopup(false)
          }}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }} />
          <a href="#javascript"
            className={`icons right-eye ${showPasswordLabel ? 'no-eye' : ''}`}
            onClick={(event) => {
              setShowPasswordLabel(!showPasswordLabel);
              event.preventDefault();
            }}>
          </a>
          {isShowInfoIcon && (
            <a href="#javascript"
            className={`icons right-info`}
            onClick={(event) => {
              event.preventDefault();
            }}>&nbsp;</a>
          )}
      </div>
      {(showInfoPopup && isShowPasswordInfo) && (
        <div className="dropdown-panel">
          <div className="bold-title">Password must have</div> 
          <ul>
            {
              PasswordRule.map((item, index) => (
                <li key={index}>
                  <div className="list-item">
                    {validatePasswordRule(index) && (
                      <i className="icons icon-done"></i>
                    )}
                    <span className={`txt ${validatePasswordRule(index) ? 'gray' : ''}`}>
                      {item}
                    </span>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      )}
    </div>
  );
};

export default InputBox;
