import React from 'react';
import './OuraSetup.scss';
import { Grid, Paper } from '@mui/material';
import { LearnMoreButton, OgnomyOuraLogo, ShareMyData } from './helper';

const OuraHome = () => {
  return (
    <Paper className="layer-0">
      <Paper className="layer-1">
        <Grid container spacing={2}>
          <Grid item>
            <OgnomyOuraLogo />
          </Grid>
          <Grid item xs={12}>
            <p>Ognomy and Oura have partnered to optimize sleep apnea therapy with the power of wearable technology data and the highest quality of care. Click below to learn more.</p>
          </Grid>
          <Grid item xs={12}>
            <LearnMoreButton />
          </Grid>
        </Grid>
      </Paper>
      <ShareMyData />
    </Paper>
  );
};

export default OuraHome;
