import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import AuthService from '../../../services/authService';
import './styles.scss';
import { get } from 'lodash';
import UserService from '../../../services/userService';

const FollowUpPaperWorksModal = (props) => {
  const [height, setHeight] = useState(1200);
  const body = document.body;
  const { user } = AuthService.getAuth();
  const html = document.documentElement;
  const { appointments } = useSelector((r) => r.appointments);
  const history = useHistory();

 
  useEffect(() => {
    setHeight(Math.max(body.scrollHeight, html.scrollHeight));
  }, [html.scrollHeight, body.scrollHeight]);

  const onSubmit = async () => {
    // window.open('https://form.jotform.com/210105987675058', "_blank")
    // const aptId = get(appointments, "data.0.id")
    // if(aptId) {
    //  await UserService.updateAppointmentProgress(aptId, { followUpProgress: "complete" }).catch(err => console.log(err))
    //  props?.handleClose()
    // }
    const appoinment = appointments.data[0];

    let redirectUrl = '';
    if (appoinment?.followUpProgress === '' && appoinment?.medicalId === '') {
      redirectUrl = appoinment?.followUpProgress || 'details&view=2';
    } else {
      redirectUrl = appoinment?.followUpProgress || 'details&view=2';
    }
    history.push(`/followUp?step=${redirectUrl}`);
  }


  return (
    <div
      className="base-modal continue-onboarding"
      style={{ height: height + 'px' }}>
      <div className="base-modal-content">
        <h1 className="terms-title">Follow Up Paperwork</h1>
        <p className="terms-header">
          Before your appointment, you need to answer a few more questions.
        </p>

        <p className="terms-header">
          These questions are just like those you would answer when visiting an
          in-person doctors office (the forms on the clipboard!).
        </p>

        <div className="submit-btn">
          <button className="btn btn-blue" onClick={() => onSubmit()}>
            Continue to forms
          </button>
        </div>
        <div>
          <p className="open-upload">
            <button
              type="button"
              onClick={props.onClose}
              className="text-btn  --link"
              style={{ margin: '30px 0 0' }}>
              Finish later
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FollowUpPaperWorksModal;
