import {Api} from './api';
import {LStorage} from './utils';
import {LoginReq, LoginRsp, User} from "./dto/Security";

export const AUTH_KEY = 'O_AUTH_KEY';
export const TAB_KEY = 'PROFILE_TAB';

export const ROLES = {
  Physician: 'Physician',
  Admin: 'Admin',
  Patient: 'Patient',
  Technician: 'Technician',
  Secretary: 'Secretary',
};
/**
 * auth service, include cache user
 */
export default class AuthService {
  /**
   * login api
   */
  static login(entity: LoginReq) {
    return Api.post<LoginRsp>('/login', entity).then((rsp) => {
      if (rsp.data.user.isNeedChangePassword) {
        throw new Error('Password expired, please reset your password');
      }
      if (rsp.data.user.roles.includes(ROLES.Patient)) {
        return rsp;
      }
      throw new Error('Only patients can login to the web portal');
    });
  }

  /**
   * get jwt token
   * @returns {Object.value|CancelToken}
   */
  static getJwtToken() {
    return this.getAuth().token;
  }

  /**
   * verify token
   *
   */
  static verifyToken(params: any) {
    Api.defaults.withCredentials = true;
    const response = Api.get('/verify-source', { params });
    Api.defaults.withCredentials = false;
    return response;
  }

  /**
   * clear auth
   */
  static cleanAuth() {
    LStorage.removeItem(AUTH_KEY);
    LStorage.removeItem(TAB_KEY);
    LStorage.removeItem('holdAppointment');
    LStorage.removeItem('completeRegister');
    sessionStorage.removeItem('delayPaperwork');
  }

  /**
   * get auth object
   * @returns {string|any|{}}
   */
  static getAuth(): LoginRsp {
    return LStorage.getItem(AUTH_KEY, true);
  }

  static saveAuth(rsp: LoginRsp) {
    return LStorage.setItem(AUTH_KEY, rsp);
  }

  static updateUser(rsp: User) {
    const auth = this.getAuth() || {};
    this.saveAuth({ ...auth, user: rsp });
  }

  static updateUserInfo(update: any) {
    let auth = this.getAuth() || {};
    auth.user = {
      ...auth.user,
      ...update,
    };
    this.saveAuth(auth);
  }
}
