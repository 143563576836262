import {PaymentHistory, StateFullData} from "../../services/dto/Common";
import {API_LOAD_PAYMENT_HISTORY, PaymentTypes} from "../actions/payment";

export interface PaymentState {
  history: StateFullData<PaymentHistory[]>
}

const defaultState: PaymentState = {
  history: {},
}


export const paymentReducer = (
  state = defaultState,
  action: PaymentTypes
): PaymentState => {
  switch (action.type) {
    case API_LOAD_PAYMENT_HISTORY:
      return {
        ...state,
        history: action.payload
      }
    default:
      return state
  }
}
