import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../../services/authService';
import UserService from '../../services/userService';
import { useQuery } from '../../services/utils';
import InsuranceInfo from '../../components/RegistrationComponents/InsuranceInfo';
import PersonalInfo from '../../components/RegistrationComponents/PersonalInfo';
import Registration from '../../components/RegistrationComponents/Registration';
import IdentityVerification from '../../components/RegistrationComponents/IdentityVerification';
import Verification from '../../components/RegistrationComponents/Verification';
import { useDispatch } from 'react-redux';
import { updateAuthUser } from '../../store/actions/auth';
import Header from '../../components/Header';
import PaymentInfo from '../../components/RegistrationComponents/PaymentInfo';
import CompletedRegistration from '../../components/RegistrationComponents/CompletedRegistration';

const RegistrationContainer = (props) => {
  const { user } = AuthService.getAuth();
  const query = useQuery();
  const step = query.get('step') || user?.onboardingProgress;
  const email = query.get('email');
  const history = useHistory();
  const dispatch = useDispatch();
  // const { providers } = useSelector((r) => r.appointments);
  const [state, setState] = useState({
    showVerify: false,
    email: '',
    partner: {},
    base64: '',
    identifiedUserId: '',
    showIdentity: (step === 'identity'),
    // providers,
  });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  const validSteps = [
    'identity',
    'register',
    'info',
    'insurance',
    'payment',
    'complete',
  ];

  useEffect(() => {
    if (
      (!user?.id && !['identity', 'register'].includes(step)) ||
      (!validSteps.includes(step) && !state.showVerify)
    ) {
      AuthService.cleanAuth();
      return history.push('/');
    }

  }, [step]);

  const onContinue = (step) => (data, prov) => {
    // const providerData = prov || (isArray(state.providers) ? state.providers : []);
    if (!isEmpty(data)) {
      AuthService.updateUserInfo(data);
      dispatch(updateAuthUser(data));
    }
    
    if (step === 'apt') {
      UserService.getAppointments({
        types: ['upcoming', 'ongoing'],
        patientId: user.id,
      })
        .then((res) => history.push((isEmpty(res.data))
          ? '/scheduleAppointmentPage?registration=1'
          : '/registration?step=complete&type=existing'
        ))
        .catch((err) => toast.error(err));
    }
    else return history.push(`/registration?step=${step}`);
  };

  return (
    <Fragment>
      <Header
        currentPage=""
        headerType={state?.partner?.name ? 'Referred' : 'Non-LoggedIn'}
        partnerLogo={state?.base64}
        partnerName={state?.partner?.name}
        customClass="registeration-header"
      />
      <Fragment>
        {(step === 'identity') && ((state.showIdentity)
          ? (<IdentityVerification onContinue={(identifiedUserId) => setGenericState({ identifiedUserId, showIdentity: false })} />)
          : (state.showVerify)
            ? (<Verification
                email={state.email}
                onContinue={() => {
                  setGenericState({ showVerify: false });
                  onContinue('info')();
                }}
                existingUserId={state.identifiedUserId}
              />)
            : (<Registration
                history={history}
                email={email}
                title={"Set Account Email"}
                onContinue={(email) => setGenericState({ showVerify: true, email })}
              />))}
        {(step === 'register') && (
          (state.showVerify)
          ? (<Verification
              email={state.email}
              onContinue={() => {
                setGenericState({ showVerify: false });
                onContinue('info')();
              }}
            />)
          : (<Registration
              history={history}
              email={email}
              onContinue={(email) => setGenericState({ showVerify: true, email })}
            />))}
        {(step === 'info') && ( <PersonalInfo onContinue={onContinue(user?.bypassPlatformFees ? 'apt' : 'insurance')} />)}
        {(step === 'insurance') && ( <InsuranceInfo onContinue={onContinue('payment')} />)}
        {(step === 'payment') && ( <PaymentInfo onContinue={onContinue('apt')} />)}
        {(step === 'complete') && ( <CompletedRegistration />)}
      </Fragment>
    </Fragment>
  );
};

export default RegistrationContainer;
