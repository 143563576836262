import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/data',
});

// data service
export default class DataSvc {  
  static async getPatientDashboardData() { 
    let jsonUrl = ''

    if (Math.round(Math.random() * 10) >= 5) {
      jsonUrl = '/dataPatientDashboard-1.json'
    } else {
      jsonUrl = '/dataPatientDashboard-2.json'
    }
   
    const response = await axiosInstance.get(jsonUrl);
    return response.data;
  }
  
  static async getWaitingRoomData() {
    const response = await axiosInstance.get('/dataWaitingRoom.json');
    return response.data;
  }
  
  static async getPhysicianLandingData() {
    const response = await axiosInstance.get('/dataPhysicianLanding.json');
    return response.data;
  }
  
  static async getPhysicianInfoDetailsData() {
    const response = await axiosInstance.get('/dataPhysicianInfoDetails.json');
    return response.data;
  }
  
  static async getProfileInfoData() {
    let jsonUrl = ''

    if (Math.round(Math.random() * 10) >= 5) {
      jsonUrl = '/dataProfileInfo-1.json'
    } else {
      jsonUrl = '/dataProfileInfo-2.json'
    }
   
    const response = await axiosInstance.get(jsonUrl);
    return response.data;
  }
  
  static async getScheduleAppointmentData() {
    const response = await axiosInstance.get('/dataScheduleAppointment.json');

    return response.data;
  }
  
  static async getRelationToPolicyHolderData() {
    const response = await axiosInstance.get('/dataRelationToPolicyHolder.json');
    return response.data;
  }
  
  static async getCreditCardIssuerData() {
    const response = await axiosInstance.get('/dataCreditCardIssuer.json');
    return response.data;
  }
  
  static async getSnewzzzData() {
    const response = await axiosInstance.get('https://ognomy.com/wp-json/wp/v2/posts?per_page=100&categories=10,8,6,5,4,3,1&exclude=22,271,264');
    return response.data;
  }
}
