import NoAuthNewPatientForms from './NoAuthNewPatientForms'
import NoAuthFollowUpPatientForms from './NoAuthFollowUpPatientForms'
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UserService from '../../services/userService';
import { useQuery } from '../../services/utils';

const NoAuthPatientForms = (props) => {
  const history = useHistory();
  const query = useQuery();
  const isInOffice = query.get('inOffice');
  const { token } = useParams();
  let url = `/patientForms/${token}`;
  if (isInOffice) { url += '?inOffice=true'; }

  const [user, setUser] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [medicalHistory, setMedicalHistory] = useState(null);

  const goTo = (step) => (url.includes('?'))
    ? history.push(`${url}&step=${step}`)
    : history.push(`${url}?step=${step}`);

  useEffect(async () => {
    const tasks = ['getUser', 'getAppointment', 'getMedicalHistory'].map(type => ({ key: type }));
    await UserService.verifyAndExecute({ verificationCode: token, tasks })
      .then((verificationRsp) => {
        setUser(verificationRsp.data.getUser)
        setAppointment(verificationRsp.data.getAppointment)
        setMedicalHistory(verificationRsp.data.getMedicalHistory?.form)
      })
      .catch((e) => {
        console.log(e.message);
        history.push('/');
      });
  }, []);

  return (user && appointment) && (appointment.followUp
    ? <NoAuthFollowUpPatientForms 
        user={user} 
        appointment={appointment} 
        medicalHistory={medicalHistory}
        goTo={goTo} 
        inOffice={isInOffice} />
    : <NoAuthNewPatientForms 
        user={user} 
        medicalHistory={medicalHistory} 
        goTo={goTo} 
        inOffice={isInOffice} />
  )

}

export default NoAuthPatientForms;