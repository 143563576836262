import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/Formik/FormikControl';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import {
  borderClass,
  getPrevState,
  manualFormValidation,
  scrollToError,
  setPrevState,
} from './utils';
import './styles.scss';
import AuthService from '../../services/authService';
import { useDispatch } from 'react-redux';
import { LOAD_LOADER } from '../../constants/actionTypes';
import { isNil } from 'lodash';
import Dropzone from '../../components/Dropzone';
import { useQuery } from '../../services/utils';
import { useHistory } from 'react-router-dom';
import { Api } from '../../services/api';

const Medications = (props) => {
  const dispatch = useDispatch();
  const apptId = props.apptId;
  const medicalHistory = props?.medicalHistory;
  const medications =  medicalHistory?.medications;
  const activeMedications = medications?.activeMedications || [];
  const medicationAllergies = medications?.medicationAllergies || [];
  const { user } = AuthService.getAuth();
  const history = useHistory();
  const query = useQuery();
  const view = query.get('view') || '1';
  const [state, setState] = useState({
    addMed: false,
    hasMeds: (!!activeMedications.length && 'Yes') || null,
    hasAllergyMeds: (!!medicationAllergies.length && 'Yes') || null,
    edit: false,
    viewIndex: 0,
    medFiles: {},
  });

  const setGenericState = (values) =>
    setState((state) => ({ ...state, ...values }));

  const medsBaseValue = {
    medicationName: '',
    dosage: '',
    frequency: '',
  };

  useEffect(() => {
    if (view === '1') getFileList();
  }, []);

  useEffect(() => {
    if (!parseInt(view) || parseInt(view) > 2) {
      history.push('/');
    }
  }, [view]);

  const initialValues = {
    medicationAllergies: medicationAllergies?.length ? medicationAllergies : [],
    activeMedications: activeMedications?.length ? activeMedications : [],
  };

  const validationSchema = Yup.object({
    medicationAllergies: state.hasAllergyMeds
      ? Yup.array().of(Yup.string().required())
      : null,
    activeMedications: state.hasMeds
      ? Yup.array().of(
          Yup.object().shape({
            medicationName: Yup.string().required(),
            dosage: Yup.string().required(),
            frequency: Yup.string().required(),
          }),
        )
      : null,
  });

  const getFileList = () => {
    UserService.getFileList(`Key=patients/${user.id}/medications`)
      .then((res) => {
        let medFiles = {};
        let hasFile = false;
        if (res?.data?.length) {
          res.data.forEach((file) => {
            hasFile = true;
            medFiles[file.name] = {
              name: file.name,
              loading: false,
              uploaded: true,
            };
          });

          const el = document.querySelector('.custom-btn-select .btns');
          if (el.firstChild && hasFile && !state.hasMeds) {
            el.firstChild.click();
          }
          setGenericState({ medFiles, hasMeds: hasFile });
        }
      })
      .catch((error) => toast.error('Unable to retrieve file list!'));
  };

  // const handleFileChange = (file) => {
  //   const parts = file.name.split(".")
  //   const ext = parts.pop();
  //   const fileName = `MedicationUpload-${parts.join(".")}.${ext}`

  //   setState((state) => {
  //     const newState = { ...state };
  //     newState.medFiles[fileName] = {
  //       name: fileName,
  //       loading: true,
  //       uploaded: false,
  //     };
  //     return newState;
  //   });
  //   const data = new FormData();
  //   dispatch({ type: LOAD_LOADER, data: false });
  //   data.append('file', file, fileName);
  //   data.append('keyPath', `patients/${user.id}/medications/`);
  //   UserService.uploadFile(data)
  //     .then((res) => {
  //       dispatch({ type: LOAD_LOADER, data: true });
  //       const uploadedFileName = res?.data?.file;

  //       Api.post('/forms', {
  //         category: 'OTHER',
  //         name: uploadedFileName,
  //         key: `patients/${user.id}/medications/${uploadedFileName}`,
  //         patientId: user.id,
  //         usedFor: 'Patient Onboarding',
  //       }).catch(() => {});

  //       setState((state) => {
  //         const newState = { ...state };
  //         delete newState.medFiles[fileName];
  //         newState.medFiles[uploadedFileName] = {
  //           name: uploadedFileName,
  //           loading: false,
  //           uploaded: true,
  //         };
  //         return newState;
  //       });
  //     })
  //     .catch((error) =>
  //       toast.error(`Unable to upload ${file.name}. Please try again!`),
  //     );
  // };

  // const deleteFile = (name) => {
  //   UserService.deleteFile(`Key=patients/${user.id}/medications/${name}`)
  //     .then((res) => {
  //       let medFiles = { ...state.medFiles };
  //       delete medFiles[name];
  //       setGenericState({ medFiles });
  //     })
  //     .catch((error) =>
  //       toast.error('Unable to delete this file. Please try again!'),
  //     );
  // };
  const onEdit = (formik, i) => {
    setPrevState(formik.setFormikState);
    manualFormValidation(formik, () =>
      setGenericState({ edit: true, viewIndex: i }),
    );
  };

  const onAddMed = (medState, medType, setFormikState) => {
    const viewIndex = medState?.length ? medState.length : 0;

    setGenericState({
      edit: true,
      hasMeds: true,
      addMed: true,
      viewIndex,
    });

    setFormikState((state) => {
      if (medType === 'activeMedications') {
        state.values[medType].push(medsBaseValue);
      } else {
        state.values[medType].push('');
      }
      return state;
    });
  };

  const onDeleteMeds = (setFormikState, medType, i) => {
    setFormikState((state) => {
      if (state.values[medType]?.length === 1) {
        state.values[medType] = [];
        state.touched = {};
      } else {
        state.values[medType].splice(i, 1);
        state?.touched?.[medType]?.splice(i, 1);
      }

      return state;
    });

    setGenericState({
      edit: false,
      addMed: false,
    });
  };

  const onSubmitActiveMeds = async (values, setSubmitting) => {
    const data = {
      userId: user.id,
      type: 'Follow-up',
      appointmentId: apptId,
      form: {
        // providerHistory: medicalHistory?.providerHistory,
        medications: {
          activeMedications: state.hasMeds ? values.activeMedications : [],
          medicationAllergies: state.hasAllergyMeds
            ? values.medicationAllergies
            : [],
        },
        // pastMedicalHistory: medicalHistory?.pastMedicalHistory,
        // socialHistory: medicalHistory?.socialHistory,
        // familyHistory: medicalHistory?.familyHistory,
        // sleepMedicineHistory: medicalHistory?.sleepMedicineHistory,
        // reviewOfSystems: medicalHistory?.newValues,
      },
    };
    let followUpProgress = `/followUp?step=medications&view=${parseInt(view) + 1}`;
    try {
      const { updateAppointmentProgress, updateMedicalHistory } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);
      await updateAppointmentProgress(apptId, { followUpProgress:`medications&view=${parseInt(view) + 1}`, medicalId: formDataRecord?.data?.id });
      props.updateHistory(formDataRecord?.data?.form || {});
      props.onContinue({ followUpProgress });
      setSubmitting(false);
      history.push(followUpProgress);
    } catch (error) {
      setSubmitting(false);
      toast.error('Unable to save medication history. Please try again.');
    }
  };

  const onSubmitMedAllergies = async (values, setSubmitting) => {
    const data = {
      userId: user.id,
      type: 'Follow-up',
      appointmentId: apptId,
      form: {
        // providerHistory: medicalHistory.providerHistory,
        medications: {
          medicationAllergies: state.hasAllergyMeds
            ? values.medicationAllergies
            : [],
        },
        // pastMedicalHistory: medicalHistory.pastMedicalHistory,
        // socialHistory: medicalHistory.socialHistory,
        // familyHistory: medicalHistory.familyHistory,
        // sleepMedicineHistory: medicalHistory.sleepMedicineHistory,
        // reviewOfSystems: medicalHistory.newValues,
      },
    };
    
    try {
      let followUpProgress = `/followUp?step=provider`;
      const {  updateAppointmentProgress, updateMedicalHistory } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);
      await updateAppointmentProgress(apptId, { followUpProgress: 'provider', medicalId: formDataRecord?.data?.id });
      
      history.push(followUpProgress);
    } catch (error) {
      setSubmitting(false);
      toast.error('Unable to save medication allergies. Please try again.');
    }
  };

  const setBaseOption = (propName, v) => {
    if (v === 'No') {
      setGenericState({
        [propName]: v === 'Yes',
        edit: false,
        addMed: false,
      });
    } else {
      setGenericState({
        [propName]: v === 'Yes',
      });
    }
  };
  const getDisableBtn = (values) => {
    let disableBtn = false;

    if (view === '1') {
      const uploading = Object.values(state.medFiles).find(
        (file) => file.loading && !file.uploaded,
      );
      return state.hasMeds === false
        ? false
        : isNil(state.hasMeds) ||
            uploading ||
            !(
              values?.activeMedications?.length ||
              Object.keys(state.medFiles).length
            );
    } else if (view === '2') {
      return state.hasAllergyMeds === false
        ? false
        : isNil(state.hasAllergyMeds) || !values?.medicationAllergies?.length;
    } else {
      for (const key in state.medFiles) {
        const loading = state.medFiles[key].loading;
        disableBtn = loading;
        if (loading) break;
      }

      return disableBtn;
    }
  };

  const { edit, viewIndex, addMed } = state;
  const formValueName =
    view === '1' ? 'activeMedications' : 'medicationAllergies';
  const namePrefix = `${formValueName}.${viewIndex}`;

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Medications</h3>
          <p>
            Please provide information on any medications you are currently
            taking.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={(values, props) => {
          const submitFn =
            view === '1' ? onSubmitActiveMeds : onSubmitMedAllergies;

          submitFn(values, props.setSubmitting);
        }}>
        {(formik) => {
          const { activeMedications, medicationAllergies } = formik.values;
          const selected = state.hasMeds
            ? 'Yes'
            : state.hasMeds === false
            ? 'No'
            : null;
          return (
            <Form onSubmit={formik.handleSubmit}>
              {!edit && (
                <div className="form-container custom-option">
                  {view === '1' && (
                    <FormikControl
                      control="custom-btn"
                      onClick={(v) => setBaseOption('hasMeds', v)}
                      label="Are you currently taking any medications?"
                      options={['Yes', 'No']}
                      persist={true}
                      selected={selected}
                    />
                  )}

                  {view === '2' && (
                    <FormikControl
                      control="custom-btn"
                      onClick={(v) => setBaseOption('hasAllergyMeds', v)}
                      label="Are you allergic to any medications?"
                      options={['Yes', 'No']}
                      selected={
                        state.hasAllergyMeds
                          ? 'Yes'
                          : state.hasAllergyMeds === false
                          ? 'No'
                          : null
                      }
                    />
                  )}
                </div>
              )}

              {state.hasMeds && !edit && view === '1' && (
                <div className="form-container mb-3">
                  {activeMedications.map((medication, i) => (
                    <div
                      key={medication.medicationName + i}
                      className="list-info med-info">
                      <div className="info">
                        <p className="title">{medication.medicationName}</p>
                        <p>Dose: {medication.dosage}</p>
                        <p>Freq: {medication.frequency}</p>
                      </div>

                      <div className="edit">
                        <a href="#" onClick={() => onEdit(formik, i)}>
                          Edit
                        </a>
                        <a
                          href="#"
                          onClick={() =>
                            onDeleteMeds(
                              formik.setFormikState,
                              'activeMedications',
                              i,
                            )
                          }>
                          Delete
                        </a>
                      </div>
                    </div>
                  ))}

                  <FormikControl
                    control="custom-btn"
                    onClick={(v) =>
                      onAddMed(
                        activeMedications,
                        'activeMedications',
                        formik.setFormikState,
                      )
                    }
                    label={
                      !activeMedications?.length ? 'No Saved Medications' : null
                    }
                    options={['Add Medication']}
                    action={true}
                  />
                </div>
              )}

              {state.hasAllergyMeds && !edit && view === '2' && (
                <div className="form-container mb-3">
                  {medicationAllergies.map((medication, i) => (
                    <div key={medication + i} className="list-info med-info">
                      <div className="info">
                        <p className="title">{medication}</p>
                      </div>

                      <div className="edit">
                        <a href="#" onClick={() => onEdit(formik, i)}>
                          Edit
                        </a>
                        <a
                          href="#"
                          onClick={() =>
                            onDeleteMeds(
                              formik.setFormikState,
                              'medicationAllergies',
                              i,
                            )
                          }>
                          Delete
                        </a>
                      </div>
                    </div>
                  ))}

                  <FormikControl
                    control="custom-btn"
                    onClick={(v) =>
                      onAddMed(
                        medicationAllergies,
                        'medicationAllergies',
                        formik.setFormikState,
                      )
                    }
                    label={
                      !medicationAllergies?.length
                        ? 'No Saved Medications'
                        : null
                    }
                    options={['Add Medication']}
                    action={true}
                  />
                </div>
              )}

              {edit && (
                <div className="form-container">
                  {view === '1' && (
                    <Fragment>
                      <FormikControl
                        className={borderClass(
                          `${namePrefix}.medicationName`,
                          formik,
                        )}
                        control="input"
                        type="text"
                        name={`${namePrefix}.medicationName`}
                        placeholder="Medication Name"
                        showErrorMsg={false}
                      />

                      <FormikControl
                        className={borderClass(`${namePrefix}.dosage`, formik)}
                        control="input"
                        type="text"
                        name={`${namePrefix}.dosage`}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `${namePrefix}.dosage`,
                            e.target.value,
                          )
                        }
                        placeholder="Dosage"
                        showErrorMsg={false}
                      />

                      <FormikControl
                        className={borderClass(
                          `${namePrefix}.frequency`,
                          formik,
                        )}
                        control="input"
                        type="text"
                        name={`${namePrefix}.frequency`}
                        placeholder="How often do you take it?"
                        showErrorMsg={false}
                      />
                    </Fragment>
                  )}

                  {view === '2' && (
                    <FormikControl
                      className={borderClass(`${namePrefix}`, formik)}
                      control="input"
                      type="text"
                      name={`${namePrefix}`}
                      placeholder="Medication Name"
                      showErrorMsg={false}
                    />
                  )}

                  <FormikControl
                    control="custom-btn"
                    onClick={(v) => {
                      scrollToError(false);
                      manualFormValidation(formik, () =>
                        setGenericState({
                          edit: false,
                          addMed: false,
                        }),
                      );
                    }}
                    options={['Done']}
                    action={true}
                  />
                  <a
                    href="#"
                    onClick={() => {
                      edit && getPrevState(formik.setFormikState);
                      setGenericState({
                        edit: false,
                        addMed: false,
                      });
                      addMed &&
                        onDeleteMeds(
                          formik.setFormikState,
                          view === '1'
                            ? 'activeMedications'
                            : 'medicationAllergies',
                          viewIndex,
                        );
                    }}
                    className="cancel">
                    Cancel
                  </a>
                </div>
              )}

              {/* {state.hasMeds && view === '1' && !addMed && (
                <p className="option-txt">OR</p>
              )} */}

              {/* {state.hasMeds && view === '1' && !addMed && (
                <div className="form-container">
                  <Dropzone
                    files={state.medFiles}
                    handleFileChange={handleFileChange}
                    handleRemoveFile={deleteFile}
                  />
                </div>
              )} */}

              {!addMed && !edit && (
                <button
                  type="submit"
                  className="submit-btn"
                  // className={`submit-btn ${
                  //   getDisableBtn(formik.values) ? 'disabled' : ''
                  // }`}
                  // disabled={
                  //   getDisableBtn(formik.values) || formik.isSubmitting
                  // }>
                  ref={props.continueRef}
                >
                  Continue
                </button>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Medications;
