import React, { useState } from 'react';
import Input from '../../components/Formik/Input';
import { sendUploadUrl } from '../../services/orbitzService';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';
import { User } from '../../services/dto/Security';

type Props = {
  user: User;
  onSubmit: () => void;
  onSetManual: () => void;
  observing: boolean;
};

const SMS = ({ onSubmit, onSetManual, user, observing }: Props) => {
  const [phone, setPhone] = useState(user?.phone || '');
  const [verificationCode, setVerificationCode] = useState('');

  const handleSendSms = async () => {
    try {
      await sendUploadUrl(
        '0',
        phone,
        user?.id,
        window.location.origin,
        window.location.pathname.includes('registration'),
      );
      const response = await UserService.sendVerificationCode(
        user?.email,
        'uploadInsurance',
        'direct',
      );
      const { data } = response;
      const { verificationCodeValue } = data;
      setVerificationCode(verificationCodeValue);
      onSubmit();
    } catch (err) {
      toast.error(
        'We were unable to send you a link to upload your insurance card. Please try again. ',
      );
    }
  };

  return (
    <>
      <div className="sms-send-form">
        <>
          {observing ? (
            <div className="flex-box">
              <p>
                Use this code in the link sent to the number you provided and
                your uploaded insurance cards will appear in the list above:
              </p>
              <p style={{ fontSize: '2rem', margin: '1rem auto 1rem' }}>
                {verificationCode}
              </p>
              <p></p>
            </div>
          ) : (
            <>
              <p>
                Confirm your phone number to receive a text message link
                allowing you to take a photo of your insurance card.
              </p>
              <div className="flex-box --input">
                <Input
                  className="phone"
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  format="(###) ###-####"
                  value={phone}
                  onChange={(e) => setPhone(e.formattedValue)}
                  showErrorMsg={false}
                />
                <button
                  className="btn inline-btn"
                  type="button"
                  onClick={handleSendSms}>
                  Send
                </button>
              </div>
            </>
          )}

          <p className="insurance-upload_open-manual mt-3 mb-3">
            Having trouble providing photos of your card?
          </p>
          <button
            type="button"
            onClick={onSetManual}
            className="btn">
            Enter Manually
          </button>
        </>
      </div>
    </>
  );
};

export default SMS;
