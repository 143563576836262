import { META } from './config';
import RegistrationLandingPage from './containers/RegistrationLandingPage';
import SignInPage from './containers/SignInPage';
import Callback from './components/Callback';
import RegistrationPage from './containers/RegistrationPage';
import FollowUpPage from './containers/FollowUpPage';
import AffiliateEnrollment from './containers/ReferPage/AffiliateEnrollment';
import AppointmentPage from './containers/AppointmentPage';
import RegistrationDonePage from './containers/RegistrationDonePage';
import PatientDashboardPage from './containers/PatientDashboardPage';
import WaitingRoomPage from './containers/WaitingRoomPage';
import PhysicianLandingPage from './containers/PhysicianLandingPage';
import ProfileInfoPage from './containers/ProfileInfoPage';
import EditScheduleAppointmentPage from './containers/EditScheduleAppointmentPage';
import { ResetPasswordPage } from './containers/SignInPage/ResetPasswordPage';
import { UploadInsuranceImage } from './containers/MobileInsuranceUpload';
import MarketingRegistration from './containers/MarketingRegistration';
import NewPatientForms from './containers/NewPatientForms';
import NoAuthPatientForms from './containers/NoAuthPatientForms';
import MoveUpAppointmentPage from './containers/MoveUpAppointmentPage';

/**
 * Generate an object with all necessary fields to render a page.
 * @param {string} path - The page path
 * @param {string} title - THe page title (for SEO)
 * @param {Function} component - The component to be rendered. Containers can also be used
 * @param isRequiredLogin is need login
 * @param {string} description - The page description (for SEO) [OPTIONAL]
 * @param {string} keywords - The comma separated page keywords (for SEO) [OPTIONAL]
 * @param {string} from - The comma separated page keywords (for SEO) [OPTIONAL]
 * @returns {object}
 */
export const createPage = (
  path: any,
  title: any,
  component: any,
  isRequiredLogin?: boolean,
  description?: any,
  keywords?: any,
  from?:any,
) => ({
  path,
  title: `${title} | ${META.PAGE_TITLE_SUFFIX}`,
  component,
  description: description || META.PAGE_DESCRIPTION,
  keywords: keywords || META.PAGE_KEYWORDS,
  isRequiredLogin,
  from: from,
});

const exportRoute = [
  createPage('/', 'RegistrationLandingPage', RegistrationLandingPage),
  createPage(
    '/doctor/:doctorname',
    'PhysicianLandingPage',
    PhysicianLandingPage,
  ),
  // createPage('/registrationLandingPage', 'RegistrationLandingPage', RegistrationLandingPage), // old page
  createPage('/signInPage', 'SignInPage', SignInPage),
  createPage('/resetPasswordPage', 'Reset Password', ResetPasswordPage),
  createPage('/registration', 'Registration', RegistrationPage),
  createPage('/newPatientForms', 'New Patient Forms', NewPatientForms),
  createPage('/followUp', 'FollowUp Questionnaire', FollowUpPage),
  createPage(
    '/appointment/confirmation',
    'Appointment Confirmation',
    AppointmentPage,
  ),
  createPage('/partner/enroll', 'Affiliate Enrollment', AffiliateEnrollment),
  createPage(
    '/upload-insurance',
    'Upload Insurance Card',
    UploadInsuranceImage,
  ),
  createPage('/patientForms/:token', 'Patient Forms', NoAuthPatientForms),
  createPage('/welcome/:partner', 'Welcome', RegistrationLandingPage),
  createPage(
    '/registrationDonePage',
    'RegistrationDonePage',
    RegistrationDonePage,
  ),
  createPage(
    '/patientDashboardPage',
    'PatientDashboardPage',
    PatientDashboardPage,
    true,
  ),
  createPage(
    '/waitingRoomPage',
    'WaitingRoomPage',
    WaitingRoomPage,
    true,
    '',
    '',
    'waitingRoom',
  ),
  createPage('/profile', 'ProfileInfoPage', ProfileInfoPage, true),
  createPage(
    '/editScheduleAppointmentPage/:id',
    'EditScheduleAppointmentPage',
    EditScheduleAppointmentPage,
    true,
  ),
  createPage(
    '/moveUpAppointmentPage',
    'MoveUpAppointmentPage',
    MoveUpAppointmentPage,
    false,
  ),
  createPage(
    '/scheduleAppointmentPage',
    'scheduleNewAppointmentPage',
    EditScheduleAppointmentPage,
    true,
  ),
  createPage('/scheduling', 'MarketingRegistration', MarketingRegistration),
];

export default exportRoute;
