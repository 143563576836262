import React, {useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Checkbox from '../../../components/FormElement/Checkbox';
import './styles.scss';
import {useDispatch, useSelector} from "react-redux";
import {IAppState} from "../../../store/reducers";
import {AppointmentsState} from "../../../store/reducers/appointments";
import {loadMyTodo} from "../../../store/actions/appointments";
import {TodoRow, Appointment} from "../../../services/dto/Common";
import AuthService from '../../../services/authService';


export const ToDo = () => {
  const {myTodo, appointments} = useSelector<IAppState, AppointmentsState>(s => s.appointments)
  const [nearestApt, setNearestApt] = useState<Appointment>();
  const dispatch = useDispatch();
  const { user } = AuthService.getAuth();
  useEffect(() => {
    dispatch(loadMyTodo())
  }, [])

  useEffect(() => {
    const items = (appointments.data || []).filter((i) => i.status);
    setNearestApt(items[0])
  }, [appointments]);

  const renderLabel = (item: TodoRow)=>{
    if (item.type === 'creditCard') {
      return {
        title: `<div class='todo-title'>${item.title}</div> This is needed in case there are any copays or deductibles associated with your insurance coverage and to account for our no-show policy in case of a missed appointment.`,
        link: 'profile?step=payment',
      };
    } else if (item.type === 'newForms') {
      if (item.completed) {
        return {
          title: `<div class='todo-title'>${item.title}</div>This information allows the sleep specialist to design a tailored treatment plan best suited to your needs.`,
          link: '#',
        };
      } else {
        return {
          title: `<div class='todo-title'>${item.title}</div>This information allows the sleep specialist to design a tailored treatment plan best suited to your needs.`,
          link: `newPatientForms`,
        };
      }
    } else if (item.type === 'insurance') {
      return {
        title: `<div class='todo-title'>${item.title}</div>We are missing information or you haven’t uploaded a photo of your insurance card. We need this to verify your coverage for Ognomy appointments and assessments.`,
        link: `profile?step=insurance`,
      };
    } else {
      let redirectUrl = 'details&view=2';
      if (nearestApt?.followUpProgress === '' && nearestApt?.medicalId === '') {
        redirectUrl = nearestApt?.followUpProgress || 'details&view=2';
      } else if (
        nearestApt?.followUpProgress === 'complete' &&
        nearestApt?.medicalId != ''
      ) {
        redirectUrl = '/patientDashboardPage';
      } else {
        redirectUrl = nearestApt?.followUpProgress || 'details&view=2';
      }
      return {
        title: `<div class='todo-title'>Fill out follow up appointment form </div>It may take a few days to show complete, as we need to approve them on our end.`,
        // link: `${!item.completed ? 'https://form.jotform.com/210105987675058' : '#'}`,
        link:  `${!item.completed ? `followUp?step=${redirectUrl}` : '#'}`
      };
    }
  }

  const renderContent = () => {
    if (!myTodo.data) {
      return <></>
    }
    return <>
      <div className="font-b">{_.filter(myTodo.data.items,i=>i.completed).length}<span className="font-normal">/{myTodo.data.items.length} Completed</span></div>
      <div className="check-list todo-container">
        {
          myTodo.data.items.map((item, index) => (
            <Checkbox key={index}
                      checked={item.completed}
                      label={renderLabel(item)}
                      id={`check-${index}`}
                      onChange={_.noop}
            />
          ))
        }
      </div>
    </>
  }
  return (
    <div className="to-do h-478  m-26">
      <div className="title">To-Do</div>
      {myTodo.loading && <div className="font-b"><span className="font-normal">Loading...</span></div>}
      {!myTodo.loading && (myTodo.err || !myTodo.data) && <div className="font-b"><span className="font-normal">No data available</span></div>}
      {myTodo.data && renderContent()}
    </div>
  );
};

export default ToDo;
