import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './styles.scss';
import {User} from "../../../services/dto/Security";
import {ProfileIcon} from "../../ProfileIcon";
import {getFullAdd} from "../../../services/utils";
import {TESTIMONIALS} from "../../../config";
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';

export interface ITestimonialsProps {
  provider: User;
}

export const Testimonials: React.FunctionComponent<ITestimonialsProps> = (
  props,
) => {
  const { provider } = props;

  return (
    <div className="testimonials-section">
      <div className="flex-bet">
        <div className="title">Testimonials</div>
      </div>
      <Carousel interval={null}>
        {TESTIMONIALS.map((item, index) => (
          <Carousel.Item key={index}>
            <div className="tog-box">
              <div className="tog-txt">
                <div className="pic">
                  <FormatQuoteRoundedIcon fill="#fff" />
                </div>
                <div className="txt-box">
                  <p className="txt-p">{item}</p>
                </div>
                <div className="info-box">
                  <div className="photo hide">
                    <ProfileIcon color={'blue'} user={provider} />
                  </div>
                  <div className="info">
                    <div className="name">- Current Ognomy Patient</div>
                    <div className="address hide">{getFullAdd(provider)}</div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
