import React from 'react';
import Modal from '../../Modal';

interface Props {
  open: boolean;
  onClose: () => void;
}

const PaymentInfoModal = ({ open, onClose }: Props) => {
  return (
    <Modal open={open} onClose={() => onClose()}>
      <p>
        In order to share information between you and your doctor securely
        throughout your sleep care journey, you will need to create an account.
        Your email will be used for logging in to this account, including on the
        day of your appointment. Still have questions? Please call us at
        1-877-664-6669.
      </p>
    </Modal>
  );
};

export default PaymentInfoModal;
