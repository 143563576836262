import React from 'react';
import { useQuery } from '../../services/utils';

const CompletedRegistration = (props) => {
  const query = useQuery();

  return (
    <div className="onboarding-form">
      <div className="header-info complete-view">
        <div className="description">
          <h3>{(query.get('type') === "existing") ? "You're All Set!" : "Appointment Booked!"}</h3>
          <p>
            {(query.get('type') !== "existing") && "Congratulations on taking your first step on your path to better sleep. "}
            Let's continue to view your patient dashboard.
          </p>
        </div>
        <a
          href="/patientDashboardPage"
          type="submit"
          className="submit-btn mt-4">
          Enter Dashboard
        </a>
      </div>
    </div>
  );
};

export default CompletedRegistration;
