import React, { ReactChild } from 'react';

type Props = {
  cardList: any;
  onInitiateNewCard: any;
  onEditCard: (id: string) => void;
  onDeleteCard: (index: any) => void;
  onUpload: () => void;
  openSMS?: boolean;
  cardCheck: boolean;
};

const CardList = ({
  cardList,
  onInitiateNewCard,
  onEditCard,
  onDeleteCard,
  onUpload,
  openSMS,
  cardCheck,
}: Props) => {
  return (
    <>
      {cardList.length === 0 ? (
        <></>
      ) : (
        <div className="card-list" style={{ marginBottom: 30 }}>
          {cardList.map((card: any, index: string) => {
            let {
              _id,
              subscriberId = '',
              insuranceCarrier = '',
              frontPhoto,
              backPhoto,
            } = card;
            if (insuranceCarrier.length > 24) {
              insuranceCarrier =
                insuranceCarrier.substring(0, 24).trim() + '...';
            }
            const checkForPhotos = Boolean(frontPhoto) && Boolean(backPhoto);
            return (
              <div className="card-container" key={_id}>
                <div
                  className={`card ${
                    checkForPhotos ? 'confirmed' : 'unconfirmed'
                  }`}
                  id={_id}>
                  <div className="card-details">
                    <p>
                      {insuranceCarrier !== ''
                        ? insuranceCarrier
                        : 'Unknown Carrier'}
                    </p>
                    <p>{subscriberId !== '' ? subscriberId : 'Unknown ID'}</p>
                  </div>
                  {Boolean(frontPhoto) && Boolean(backPhoto) ? (
                    <div className="confirm">
                      <div className="confirm-circle">
                        {checkForPhotos && <ConfirmCircleIcon />}
                      </div>
                      <CardIcon />
                      <p style={{ fontSize: 12 }}>Images Uploaded</p>
                    </div>
                  ) : (
                    <button
                      className="btn inline-btn"
                      type="button"
                      onClick={() => onUpload()}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '0',
                      }}>
                      <img src="/assets/add-photo.svg" className="add-photo" />
                      <p style={{ fontSize: 12 }}>Upload Photos</p>
                    </button>
                  )}
                </div>
                {!openSMS && (
                  <div className="card-actions">
                    <button
                      type="button"
                      onClick={() => onEditCard(_id)}
                      className="text-btn  --link">
                      <img src="/assets/edit.svg" alt="" />
                    </button>
                    <button
                      type="button"
                      onClick={() => onDeleteCard(index)}
                      className="text-btn  --link">
                      <img src="/assets/delete.svg" alt="delete" />
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {!openSMS && (
        <>
          {cardList.length !== 0 && (
            <p className="footer-text">
              Not seeing a plan? Add an insurance plan by tapping below.
            </p>
          )}

          <button
            style={{ lineHeight: 'inherit' }}
            type="button"
            className="btn add-plan"
            onClick={() => onInitiateNewCard()}>
            Add Insurance Card
          </button>
        </>
      )}
    </>
  );
};

const ConfirmCircleIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_111)">
      <path
        d="M3.39233 15.6261C3.39233 12.2777 4.7225 9.06634 7.09022 6.69862C9.45794 4.3309 12.6693 3.00073 16.0177 3.00073C19.3662 3.00073 22.5775 4.3309 24.9452 6.69862C27.3129 9.06634 28.6431 12.2777 28.6431 15.6261C28.6431 18.9746 27.3129 22.1859 24.9452 24.5536C22.5775 26.9213 19.3662 28.2515 16.0177 28.2515C12.6693 28.2515 9.45794 26.9213 7.09022 24.5536C4.7225 22.1859 3.39233 18.9746 3.39233 15.6261Z"
        fill="#20C997"
      />
      <path
        d="M11.2832 15.451L14.7899 18.9577L21.8049 11.9443"
        stroke="#ffffff"
        strokeWidth="2"
        strokeMiterlimit="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect
      x="1.89233"
      y="1.50073"
      width="28.2508"
      height="28.2508"
      rx="14.1254"
      stroke="#20C997"
      strokeWidth="3"
    />
    <defs>
      <clipPath id="clip0_1_111">
        <rect
          x="3.39233"
          y="3.00073"
          width="25.2508"
          height="25.2508"
          rx="12.6254"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

const CardIcon = () => (
  <svg
    width="79"
    height="57"
    viewBox="0 0 79 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="card-icon">
    <path
      d="M8.21929 56.6283C3.68658 56.6283 0 52.9402 0 48.409V8.95391C0 4.41966 3.68658 0.734619 8.21929 0.734619H70.6893C75.2205 0.734619 78.9086 4.4212 78.9086 8.95391V48.409C78.9086 52.9402 75.2189 56.6283 70.6893 56.6283H8.21929ZM3.28741 48.409C3.28741 51.1292 5.49905 53.3409 8.21929 53.3409H70.6893C73.4096 53.3409 75.6212 51.1292 75.6212 48.409V17.1732H3.28741V48.409ZM75.6227 13.8858V8.95391C75.6227 6.23367 73.4111 4.02203 70.6909 4.02203H8.21929C5.49905 4.02203 3.28741 6.23367 3.28741 8.95391V13.8858H75.6227Z"
      fill="#3f5c76"
    />
    <path
      d="M44.3867 30.3268C43.4789 30.3268 42.7422 29.5901 42.7422 28.6823C42.7422 27.7745 43.4789 27.0378 44.3867 27.0378H70.6906C71.5983 27.0378 72.335 27.7745 72.335 28.6823C72.335 29.5901 71.5983 30.3268 70.6906 30.3268H44.3867Z"
      fill="#3f5c76"
    />
    <path
      d="M44.3867 40.1901C43.4789 40.1901 42.7422 39.4534 42.7422 38.5456C42.7422 37.6378 43.4789 36.9011 44.3867 36.9011H54.2504C55.1582 36.9011 55.8949 37.6378 55.8949 38.5456C55.8949 39.4534 55.1582 40.1901 54.2504 40.1901H44.3867Z"
      fill="#3f5c76"
    />
    <path
      d="M18.9066 46.7647C17.5457 46.7647 16.4406 45.6596 16.4406 44.2988V40.1883H12.3302C10.9693 40.1883 9.86426 39.0833 9.86426 37.7224V32.7905C9.86426 31.4296 10.9693 30.3246 12.3302 30.3246H16.4406V26.2157C16.4406 24.8548 17.5457 23.7498 18.9066 23.7498H23.8384C25.1993 23.7498 26.3044 24.8548 26.3044 26.2157V30.3261H30.4148C31.7757 30.3261 32.8807 31.4312 32.8807 32.7921V37.7239C32.8807 39.0848 31.7757 40.1899 30.4148 40.1899H26.3044V44.3003C26.3044 45.6612 25.1993 46.7662 23.8384 46.7662H18.9066V46.7647ZM23.0154 43.4773V38.5454C23.0154 37.6376 23.7521 36.9009 24.6599 36.9009H29.5918V33.6135H24.6599C23.7521 33.6135 23.0154 32.8768 23.0154 31.969V27.0372H19.728V31.969C19.728 32.8768 18.9913 33.6135 18.0836 33.6135H13.1517V36.9009H18.0836C18.9913 36.9009 19.728 37.6376 19.728 38.5454V43.4773H23.0154Z"
      fill="#3f5c76"
    />
  </svg>
);

export default CardList;
