import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import {Appointment} from "../../../services/dto/Common";
import moment from "moment";
import {FORMAT_HOUR_FULL} from "../../../config";
import {checkIsCanMeeting} from "../../../services/utils";

export interface IGetReadyProps {
  apt: Appointment | any;
}

export const GetReady: React.FunctionComponent<IGetReadyProps> = (props) => {  
  const { apt } = props;
  const timeLeft = moment(apt.startTime).fromNow()
  const renderWait = () => {
    return (
      <div className="green-box">
        <div className="font-small">
          STARTS IN
        </div>
        <div className="font-time">
          {!timeLeft.includes("ago") ? timeLeft : "The provider will start the meeting shortly"}
        </div>
        <div className="font-bold">
          {`${moment(apt.startTime).format(FORMAT_HOUR_FULL)}-${moment(apt.endTime).format(FORMAT_HOUR_FULL)}`}
        </div>
      </div>
    )
  }
  const renderStartNow = ()=>{
    return (
      <div className="green-box">
        <div className="font-small">
          The specialist has started the meeting
        </div>
        <div className="font-time sm">
          Enter the waiting room below to join appointment
        </div>
        <div className="font-bold">
          {`${moment(apt.startTime).format(FORMAT_HOUR_FULL)}-${moment(apt.endTime).format(FORMAT_HOUR_FULL)}`}
        </div>
      </div>
    )
  }
  return (
    <div className="get-ready h-478">
      <div className="title">Get Ready</div>
      <div className="font-b">Your session is about to begin</div>
      {checkIsCanMeeting(props.apt) && props.apt.hostJoined
        ? renderStartNow()
        : renderWait()}
      <div className="txt-groups">
        <p className="txt">
          Please be ready and in the waiting room at least 5 minutes before your
          visit.
        </p>
        <p className="txt">
          You can learn some fun facts about sleep while you wait.
        </p>
      </div>
      <NavLink to={`/waitingRoomPage?apt=${apt.id}`} className="btn btn-blue">
        Go to Waiting Room
        {/* <i className="icons icon-right-arrow"/> */}
      </NavLink>
    </div>
  );
};

export default GetReady;
