import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import _ from 'lodash'
import 'react-datepicker/dist/react-datepicker.css'
import { DATE_FORMAT } from '../../../config';
import './styles.scss'
import InputMask from "react-input-mask";
export type IBaseDateInputProps = {
  label: string;
  placeholder: string
  format?: string;
  value: Date | null
  maxDate?: Date
  minDate?: Date
  onChange?: (newDate: Date | null) => void
  showMonthYearPicker?: boolean
}

const BaseDateInput: React.FunctionComponent<IBaseDateInputProps> = (props) => {
  const { label, value, placeholder, format, onChange, maxDate, minDate } = props
  const minD = props.minDate ? moment(props.minDate) : moment().year(moment().year() - 122)
  const maxD = props.maxDate ? moment(props.maxDate) : moment().year(moment().year() + 80)
  const startYear = minD.year()
  const endYear = maxD.year()
  const yearRange = endYear + 1 - startYear
  const years = Array(Math.max(yearRange,1)).fill(1).map((v, i) => i + startYear)
  const months = Array(12).fill(1).map((v, i) => moment().month(i).format('MMMM'))
  const baseFmt = format || DATE_FORMAT;
  const fmt = baseFmt
    .replace('DD','dd')
    .replace('YYYY','yyyy')
    .replace('YY','yy');
  const maskInput = baseFmt.toUpperCase().replace(/[MDY]/ig,'9')
  return (
    <div className="calendar-gray-block">
      <div className="label-txt">
        {label}
      </div>
      <div className="inputs">
        <DatePicker
          dateFormat={fmt}
          placeholderText={placeholder}
          selected={value}
          showMonthYearPicker={props.showMonthYearPicker}
          onChange={(date) => {
            let d: Date | null
            if (_.isArray(date)) {
              d = date[0]
            } else {
              d = date
            }
            if (!d || !onChange) {
              return;
            }
            if (moment(d).isBefore(minD)) {
              d = minD.toDate()
            } else if (moment(d).isAfter(maxD)) {
              d = maxD.toDate()
            }
            onChange && onChange(d);
          }}
          maxDate={maxDate}

          customInput={<InputMask mask={maskInput} />}
          renderCustomHeader={({
                                 date,
                                 changeYear,
                                 changeMonth,
                                 decreaseMonth,
                                 increaseMonth,
                                 prevMonthButtonDisabled,
                                 nextMonthButtonDisabled,
                               }) => (
            <div className={'date-input-header'}>
              <button className={"left-btn"} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
              </button>
              {!props.showMonthYearPicker && <select
                value={months[moment(date).month()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>}

              <select
                className={'select-year'}
                value={moment(date).year()}
                onChange={({ target: { value } }) => changeYear(parseInt(value,10))}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button className={'right-btn'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
              </button>
            </div>
          )}
        />
        <span className="icons right-calender"/>
      </div>
    </div>
  )
}

export default BaseDateInput;