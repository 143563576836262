import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import React from 'react';

interface ArrowButtonProps {
  prev?: () => void;
  next?: () => void;
  disabled?: boolean;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ prev, next, disabled }) => {
  if (!prev && !next) return null;

  return (
    <Button
      onClick={prev || next} // Use the first available function
      variant="text"
      disabled={disabled}
      sx={{
        // mr: 0.2,
        // ml: 0.2,
        // padding: 0.2,
        borderRadius: '50%',
        minWidth: 'auto',
        '&:hover': {
          background: 'none',
        },
      }}>
      {prev ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
    </Button>
  );
};

export default ArrowButton;
