import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/Formik/FormikControl';
import { useHistory } from 'react-router-dom';
import { isNil } from 'lodash';
import formValidationSvc from '../../services/formValidationSvc';
import './styles.scss';
import AuthService from "../../services/authService";
import UserService from "../../services/userService";
import { showApiError, useQuery } from "../../services/utils";
import { borderClass, scrollToError } from '../../components/RegistrationComponents/utils';
import { useDispatch } from "react-redux";
import { injectAuthUser } from "../../store/actions/auth";

export const SignInBanner = (props) => {
  const initialValues = {
    email: props.email || '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is invalid'),
    password: Yup.string().required('Password is invalid'),
  });

  const dispatch = useDispatch();

  const [state, setState] = useState({
    password: false,
    cpassword: false,
  });
  const history = useHistory();
  const query = useQuery();

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  const showEye = (type = 'password') => {
    return (
      <a
        href="#javascript"
        className={`icons right-eye ${state[type] ? 'no-eye' : ''}`}
        onClick={(event) => {
          state[type] = !state[type];
          setGenericState(state);
          event.preventDefault();
        }}></a>
    );
  };

  // click Continue
  const clickContinue = (values) => {
    let obj = { email: values.email, password: values.password };
    if (query.get('apptflg')) {
      obj.checkAppointmentId = query.get('apptflg');
    }

    AuthService.login(obj)
      .then((rsp) => {
        const user = rsp.data.user;
        // Check url to see if credentials were emailed to user
        if (user && user.setupPassword) {
          AuthService.saveAuth(rsp.data);
          history.replace(
            `/resetPasswordPage?step=2&email=${rsp.data.user.email}&setup=1&code=${rsp.data.user.requestToken}`,
          );
        } else if (user && rsp.data.user.requestPassword) {
          history.replace(
            '/resetPasswordPage?reset=1&token=' + rsp.data.user.requestToken,
          );
        } else if (user && rsp.data.user.needConfirm) {
          AuthService.saveAuth(rsp.data);
          dispatch(injectAuthUser(rsp.data.user));
          history.replace(
            `/appointment/confirmation?view=confirm&apptId=${obj.checkAppointmentId}`,
          );
        } else {
          AuthService.saveAuth(rsp.data);
          dispatch(injectAuthUser(rsp.data.user));
          if (query.get('aptId')) {
            const aptId = query.get('aptId');
            UserService.getAppointment(aptId)
              .then((aptRsp) => {
                if (aptRsp.data.patientId === rsp.data.user.id) {
                  history.replace(`/waitingRoomPage?apt=${aptId}`);
                } else {
                  history.replace('patientDashboardPage');
                }
              })
            return;
          }
          if (query.get('insuranceRedirect')) {
            if (
              /iPhone/i.test(navigator.userAgent) ||
              /Android/i.test(navigator.userAgent)
            ) {
              history.replace('/upload-insurance?v=1');
            } else {
              history.replace('/profile?step=insurance');
            }
          } else if (query.get('paperwork')) {
            history.replace(
              `/registration?step=${user?.onboardingProgress || 'provider'}`,
            );
          } else if (query.get('ouraRedirect')) {
            history.replace(
              `/profile?step=oura`,
            );
          } else {
            history.replace('/patientDashboardPage');
          }
        }
      })
      .catch((e) => {
        showApiError(e);
      });
  };

  // Credit card not on registration
  // Add thank you screen for registration

  return (
    <div className="signin-banner">
      <div className="header-info">
        <div className="description">
          <h3>Welcome Back</h3>
          <p>
            Please enter the email address and password associated with your
            Ognomy account.
          </p>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, props) => {
          clickContinue(values);
        }}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <div className="form-container">
              <FormikControl
                className={borderClass('email', formik)}
                control="input"
                type="email"
                name="email"
                placeholder="Email Address"
              />
              <FormikControl
                className={borderClass('password', formik)}
                control="input"
                type={state.password ? 'text' : 'password'}
                name="password"
                icon={showEye()}
                placeholder="Password"
              />
              <p className="forgot">
                <NavLink to="/resetPasswordPage" className="blue-link">
                  Forgot Your Password?
                </NavLink>
              </p>
            </div>
            <button
              type="submit"
              onClick={() => scrollToError(false)}
              className="submit-btn">
              Continue
            </button>

            <div className="bottom-btns">
              New to Ognomy?{' '}
              <NavLink
                to={`/registration?step=register${
                  formik.values.email && '&email='
                }${formik.values.email}`}
                className="blue-link">
                Create account
              </NavLink>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignInBanner;
