import React from 'react';
import Header from '../../components/Header';
import SignInBanner from './SignInPage';
import { useQuery } from '../../services/utils';
import './styles.scss';


const SignInPage = (props) => {
  const email = useQuery().get('email');
  return (
    <React.Fragment>     
      <div className="padding-wraper">
      
      <Header
          currentPage=""
          title="Login"
          headerType={'Non-LoggedIn'}
          customClass="registeration-header"
        />
        
        <SignInBanner email={email}/>
      </div> 
    </React.Fragment>
  );
};

export default SignInPage;
