import * as redux from 'redux'
import { get, set } from 'lodash'
import {AxiosResponse} from "axios";
import {getRandomNumber, ignoreReq, isReqIgnored} from "../services/utils";

const preApiTsHistoryReq: any = {}

/**
 * common api redux action
 */
export const commonApiAction = <T>(
  dispatch: redux.Dispatch,
  actionName: string,
  apiPromise: Promise<AxiosResponse<T>>,
  params?: any
) => {
  dispatch({
    type: actionName,
    payload: {
      loading: true,
    },
  })
  const reqId = Date.now() + '' + getRandomNumber() + actionName
  ignoreReq(get(preApiTsHistoryReq, actionName))
  set(preApiTsHistoryReq, actionName, reqId)
  apiPromise
    .then((rsp) => {
      if (isReqIgnored(reqId)) {
        return
      }
      dispatch({
        type: actionName,
        payload: {
          loading: false,
          data: rsp.data,
          params,
        },
      })
    })
    .catch((e) => {
      if (isReqIgnored(reqId)) {
        return
      }
      dispatch({
        type: actionName,
        payload: {
          loading: false,
          err: e,
        },
      })
    })
}
