import React from 'react';
import './OuraSetup.scss';
import { Grid, Paper } from '@mui/material';
import { LearnMoreButton, NoLongerInterested, OgnomyOuraLogo } from './helper';

const OuraConnected = () => {
  return (
    <Paper className="layer-0">
      <Paper className="layer-1">
        <Grid container spacing={2}>
          <Grid item>
            <OgnomyOuraLogo />
          </Grid>
          <Grid item xs={12}>
            <p><b>Connected!</b> Your Oura Ring data is synced to Ognomy, and will continue to provide regular reports to your Ognomy sleep clinician.</p>
          </Grid>
          <Grid item xs={12}>
            <LearnMoreButton />
          </Grid>
        </Grid>
      </Paper>
      <NoLongerInterested />
    </Paper>
  );
};

export default OuraConnected;
