import {combineReducers} from 'redux';
import dataReducer from './dataReducer';

import {AppointmentsState, appointmentReducer} from './appointments';
import {AuthState, authReducer} from "./auth";
import {paymentReducer, PaymentState} from "./payment";

export interface IAppState {
  dataReducer: any,
  appointments: AppointmentsState
  auth: AuthState
  payment: PaymentState
}

const allReducers = combineReducers<IAppState>({
  dataReducer,
  appointments: appointmentReducer,
  auth: authReducer,
  payment: paymentReducer
});

export default allReducers;
