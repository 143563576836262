import React, { Fragment, useEffect, useState } from 'react';
import FormikControl from '../../components/Formik/FormikControl';
import { Formik, Form } from 'formik';
import AuthService from '../../services/authService';
import * as Yup from 'yup';
import { borderClass, manualFormValidation, scrollToError, textAreaAutoGrow } from './utils';
import { useQuery } from '../../services/utils';
import { useHistory } from 'react-router-dom';
import UserService from '../../services/userService';
import { toast } from 'react-toastify';

const FamilyHistory = (props) => {
  const familyHistory = props?.familyHistory;
  const family = props?.familyHistory?.familyMedicalConditions;
  const history = useHistory();
  const query = useQuery();
  const view = query.get('view') || '1';
  const { user } = AuthService.getAuth();
  const [state, setState] = useState({
    selectedRelative: '',
    showList: false,
    showInital: false,
    viewIndex: null,
  });

  const setGenericState = (value) =>
    setState((state) => ({ ...state, ...value }));

  useEffect(() => {
    if (!parseInt(view) || parseInt(view) > 2) {
      history.push('/');
    }

    if (view === '1') {
      setGenericState({ showInital: false });
    } else if (!family?.[0]?.age && view === '2') {
      setGenericState({ showInital: true });
    } else if (family?.[0]?.age) {
      setGenericState({ showList: true });
    }
  }, [family, view]);

  const familyData =
    view === '2' && !family?.length
      ? defaultFamily
      : family?.length
      ? family
      : [];

  const initialValues = {
    badSleep: familyHistory?.badSleep || { response: null, description: '' },
    familyMedicalConditions: familyData,
  };

  const validationSchema = Yup.object({
    badSleep: Yup.object({
      response: Yup.boolean(),
      description: Yup.string(),
    }),
    familyMedicalConditions:
      view === '2'
        ? Yup.array().of(
            Yup.object({
              relationship: Yup.string().required(),
              unknown: Yup.boolean().required(),
              deceased: Yup.boolean().when('unknown', {
                is: true,
                then: Yup.boolean().nullable(true),
                otherwise: Yup.boolean().required(),
              }),
              age: Yup.string().required(),
              description: Yup.string(),
            }),
          )
        : null,
  });

  const addFamilyMember = (formik) => {
    const { selectedRelative } = state;
    formik.setFormikState((state) => {
      const newState = { ...state };
      const viewIndex = state.values.familyMedicalConditions.length;
      newState.values.familyMedicalConditions.push(
        baseRelativeValue(selectedRelative),
      );

      setGenericState({
        selectedRelative: '',
        viewIndex,
        showList: false,
      });
      return newState;
    });
  };

  const onDeleteRelative = (setFormikState, i) => {
    setFormikState((state) => {
      const newState = { ...state };
      newState.values.familyMedicalConditions.splice(i, 1);
      return newState;
    });
  };

  const onCancelOtherRelative = (formik) => {
    formik.setFormikState((fState) => {
      fState.values.familyMedicalConditions.splice(state.viewIndex, 1);
      return fState;
    });
    setGenericState({
      showList: true,
      showInital: false,
      viewIndex: null,
    });
  };

  const nextView = (v) =>
    history.push(
      `/newPatientForms?step=family-history&view=${parseInt(view) + 1}`,
    );

  const onSubmit = async (values, setSubmitting) => {
    setSubmitting(false);
    const newValues = JSON.parse(JSON.stringify(values));
    let onboardingProgress =
      view === '1'
        ? `family-history&view=${parseInt(view) + 1}`
        : 'sleep-history';

    const data = {
      userId: user.id,
      type: 'New Patient',
      form: { familyHistory: newValues },
    };

    try {
      const { updateMedicalHistory, updateProfile } = UserService;
      const formDataRecord = await updateMedicalHistory(user.id, data);

      await updateProfile({ onboardingProgress }, user.id);
      AuthService.updateUserInfo({ onboardingProgress });
      props.updateHistory(formDataRecord?.data?.form || {});
      view === '1' ? nextView() : props.onContinue({ onboardingProgress });
      setSubmitting(false);
    } catch (error) {
      toast.error('Unable to save social history. Please try again.');
      setSubmitting(false);
    }
  };

  const ageChange = (e, formik) => {
    const value = e.target.value.split('-');
    const maxValue = 200;
    const maxLength = 10;
    let valid = true;
    if (value.length > 2) valid = false;
    value.forEach((x) => {
      if (parseInt(x) > maxValue) valid = false;
      if (x.length > maxLength) valid = false;
    });
    if (valid) formik.handleChange(e);
  };

  const renderRelativeInfo = (relative, i, formik) => {
    const prefix = `familyMedicalConditions.${i}`;
    let { familyMedicalConditions: family } = formik.values;
    const deceasedBorder = borderClass(`${prefix}.deceased`, formik);
    return (
      <div className="relative-item" key={relative.relationship + i}>
        <p className="relation">{relative.relationship}</p>
        <FormikControl
          control="custom-btn"
          label="Alive?"
          labelError={deceasedBorder === 'error-border'}
          className={deceasedBorder}
          options={['Yes', 'No', 'Unknown']}
          persist={true}
          onClick={(v) => {
            formik.setFieldValue(`${prefix}.unknown`, v == 'Unknown');
            formik.setFieldValue(
              `${prefix}.deceased`,
              v == 'No' ? true : v == 'Yes' ? false : null,
            );
          }}
          selected={
            relative.unknown
              ? 'Unknown'
              : relative.deceased
              ? 'No'
              : relative.deceased === false
              ? 'Yes'
              : null
          }
        />
        <FormikControl
          className={borderClass(`${prefix}.age`, formik)}
          control="input"
          type="text"
          name={`${prefix}.age`}
          onChange={(v) => ageChange(v, formik)}
          placeholder={relative.deceased ? 'Age when deceased' : 'Age'}
          showErrorMsg={false}
        />
        <FormikControl
          className={borderClass(`${prefix}.description`, formik)}
          control="textarea"
          name={`${prefix}.description`}
          style={{ resize: 'none' }}
          maxLength={100}
          placeholder="Describe"
          showErrorMsg={false}
          onChange={(e) => textAreaAutoGrow(e, formik.setFieldValue)}
        />
        {family?.length - 1 !== i && state.showInital && (
          <div className="breaker"></div>
        )}
      </div>
    );
  };

  return (
    <div className="onboarding-form">
      <div className="header-info">
        <div className="description">
          <h3>Family History</h3>
          <p>
            Please provide some key details about your family's medical past.
          </p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, props) => {
          onSubmit(values, props.setSubmitting);
        }}>
        {(formik) => {
          let { badSleep, familyMedicalConditions: family } = formik.values;
          const response = badSleep?.response;
          const relativeList = relativeOptions.filter(
            (a) =>
              family.find((b) => b.relationship === a.key)?.relationship !==
              a.key,
          );

          return (
            <Form onSubmit={formik.handleSubmit}>
              <div className="form-container pb-4">
                <p className="mb-3 mt-1" style={{ fontSize: 14 }}>
                  {view === '1'
                    ? `Do any of your immediate family members have sleep disorders
                  including sleep apnea, insomnia or narcolepsy?`
                    : `Please indicate if your immediate family members have any medical conditions.`}
                </p>

                {view === '1' && (
                  <Fragment>
                    <FormikControl
                      control="custom-btn"
                      onClick={(v) => {
                        formik.setFieldValue('badSleep.response', v == 'Yes');
                        formik.setFieldTouched('badSleep.description', false);
                        if (v == 'No') {
                          formik.setFieldValue('badSleep.description', '');
                          formik.setFieldValue('familyMedicalConditions', []);
                        }
                      }}
                      options={['Yes', 'No']}
                      persist={true}
                      selected={
                        response ? 'Yes' : response === false ? 'No' : null
                      }
                    />

                    {response && (
                      <FormikControl
                        className={borderClass('badSleep.description', formik)}
                        control="textarea"
                        name="badSleep.description"
                        style={{ resize: 'none' }}
                        maxLength={100}
                        placeholder="Describe"
                        showErrorMsg={false}
                        onChange={(e) =>
                          textAreaAutoGrow(e, formik.setFieldValue)
                        }
                      />
                    )}
                  </Fragment>
                )}

                {view === '2' && (
                  <Fragment>
                    {/* INITIAL RELATIVES */}
                    {state.showInital &&
                      family.map((relative, i) =>
                        renderRelativeInfo(relative, i, formik),
                      )}
                    {state.viewIndex !== null &&
                      renderRelativeInfo(
                        family[state.viewIndex],
                        state.viewIndex,
                        formik,
                      )}

                    {(state.showInital || state.viewIndex !== null) && (
                      <FormikControl
                        className="ml-1 mt-0 mb-0"
                        control="custom-btn"
                        options={['Done']}
                        onClick={(v) => {
                          scrollToError();
                          manualFormValidation(formik, () =>
                            setGenericState({
                              showList: true,
                              showInital: false,
                              viewIndex: null,
                            }),
                          );
                        }}
                        action={true}
                      />
                    )}
                    {state.viewIndex && state.viewIndex > 1 && (
                      <a
                        href="#"
                        onClick={() => onCancelOtherRelative(formik)}
                        className="cancel">
                        Cancel
                      </a>
                    )}
                  </Fragment>
                )}

                {view === '2' && state.showList && (
                  <Fragment>
                    {/* LIST RELATIVES */}
                    {family.map((relative, i) => {
                      return (
                        <div
                          key={`list${relative.relationship}${i}`}
                          className="list-info med-info">
                          <div className="info">
                            <p className="title">
                              {`${relative.relationship}${
                                relative.unknown
                                  ? ``
                                  : ` - ${relative.age} yrs old`
                              }`}
                            </p>
                            <p>
                              {relative.unknown
                                ? 'Unknown'
                                : !relative.deceased
                                ? 'Alive'
                                : 'Deceased'}
                            </p>
                          </div>
                          <div className="edit">
                            <a
                              href="#"
                              onClick={() =>
                                setGenericState({
                                  viewIndex: i,
                                  showList: false,
                                })
                              }>
                              Edit
                            </a>
                            {!['Father', 'Mother'].includes(
                              relative.relationship,
                            ) && (
                              <a
                                href="#"
                                onClick={() =>
                                  onDeleteRelative(formik.setFormikState, i)
                                }>
                                Delete
                              </a>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {/* ADD RELATIVE */}
                    {relativeList?.length > 1 && (
                      <div style={{ marginTop: 20 }}>
                        <FormikControl
                          control="select"
                          expanding={true}
                          handlers={{
                            handleChange: (e) =>
                              setGenericState({
                                selectedRelative: e.target.value,
                              }),
                          }}
                          options={relativeList}
                          value={state.selectedRelative}
                          name="state"
                          showErrorMsg={false}
                        />

                        <FormikControl
                          className={`ml-1 mt-0 mb-0 ${
                            !state.selectedRelative ? 'disabled' : ''
                          }`}
                          control="custom-btn"
                          disabled={!state.selectedRelative}
                          options={['Add Relative']}
                          onClick={(v) => addFamilyMember(formik)}
                          action={true}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
              </div>

              {!(state.showInital && view === '2') &&
                state.viewIndex === null && (
                  <button
                    type="submit"
                    className={`submit-btn ${
                      response === null ? 'disabled' : ''
                    }`}
                    disabled={formik.isSubmitting || response === null}
                    ref={props.continueRef}>
                    Continue
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const baseRelativeValue = (relationship) => ({
  relationship,
  unknown: null,
  deceased: null,
  age: '',
  description: '',
});

const defaultFamily = [
  baseRelativeValue('Father'),
  baseRelativeValue('Mother'),
];

const relativeOptions = [
  { key: 'Select a relative to add', value: '' },
  { key: 'Brother', value: 'Brother' },
  { key: 'Sister', value: 'Sister' },
  { key: 'Child', value: 'Child' },
];
export default FamilyHistory;
