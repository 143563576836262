import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './styles.scss';
import { FORMAT_YEAR_MONTH } from "../../../config";
import { DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import dayjs, { Dayjs } from 'dayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

export interface ISchedulingCalendarProps {
  currDate: Dayjs,
  monthlyAvailability: number[],
  onDateChange: (date: Dayjs) => void,
  onMonthChange: (month: Dayjs) => void,
  maxDate?: Dayjs,
}

export const SchedulingCalendar: React.FunctionComponent<ISchedulingCalendarProps> = (props) => {  

  const isUnavailable = (date: Dayjs) => {
    return props.currDate.format(FORMAT_YEAR_MONTH) === date.format(FORMAT_YEAR_MONTH)
      && !props.monthlyAvailability.includes(date.date())
  }

  const handleDateChange = (date: Dayjs) => props.onDateChange(date);
  const handleMonthChange = (month: Dayjs) => props.onMonthChange(month);

  const ServerDay = (props: PickersDayProps<Dayjs>)  => {
    const { day, outsideCurrentMonth, ...other } = props;
    const available = !props.outsideCurrentMonth && !isUnavailable(day);
    return <PickersDay {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      className={(available) ? "highlighted" : ""}/>;
  }

  return (
    <div className="scheduling-calendar center">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar 
          renderLoading={() => <DayCalendarSkeleton/>}
          value={props.currDate}
          onChange={(d) => d && handleDateChange(d)}
          onMonthChange={handleMonthChange}
          onYearChange={handleMonthChange}
          views={['year', 'month', 'day']}
          shouldDisableDate={isUnavailable}
          slots={{ day: ServerDay }}
          sx={{ padding: "0", width: "90%", minWidth: "320px" }}
          disablePast={true}
          disableHighlightToday={true}
          minDate={dayjs()}
          maxDate={props.maxDate || dayjs().add(1, "year")}
        />
      </LocalizationProvider>
    </div>
  )
}

export default SchedulingCalendar;