import React from 'react';
import LeftProfile from '../../PhysicianLandingComponents/LeftProfile';
import CenterInfo from '../../PhysicianLandingComponents/CenterInfo';
import Testimonials from '../../PhysicianLandingComponents/Testimonials';
import './styles.scss';
import {User} from "../../../services/dto/Security";
import Close from '@mui/icons-material/Close';

export interface ISleepSpecialistInfoProps {
  provider: User;
  hideScheduleBtn?: boolean;
  onClose: () => void;
  onSelectProvider: (p: User) => void;
}

const SleepSpecialistInfo = (props: ISleepSpecialistInfoProps) => {
  const { provider } = props;
  return (
    <React.Fragment>
      <div className="sleep-specialist-info">
        <div className="physician-landing-wrap">
          <div className="btn btn-close" onClick={props.onClose}>
            <Close />
          </div>
          <div className="logo-boxs">
            <img src="/assets/logo.svg" alt="" />
          </div>
          <div className="row">
            <div className="col col-md-4 col-xs-12">
              <LeftProfile provider={provider} />
              {!props.hideScheduleBtn && (
                <div
                  className="blue-block"
                  onClick={() => {
                    props.onSelectProvider(provider);
                  }}>
                  Schedule with this physician
                </div>
              )}
            </div>
            <div className="col col-md-8 col-xs-12">
              <CenterInfo provider={provider} />
              {/* <Testimonials provider={provider} /> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SleepSpecialistInfo;